import { useNavigate, useParams } from "react-router-dom";
import { Success } from "./Success";
import businessChart from "../../../../../assets/businessChart.svg";
import { gotoViewOrder } from "../../../../../Routes";
import useProduct from "../../common/UseProduct";

const SubmittedAFS = () => {
    const product = useProduct();
    const { opportunityId, clientName } = useParams();

    const subtitle =
        `Thank you for submitting ${clientName}'s new AFS information. ` +
        `We will continue to monitor ${clientName}'s restriction status and notify you if anything changes in the future.`;

    const image = { src: businessChart, width: "217px" };

    const navigate = useNavigate();
    const action = {
        text: "VIEW ORDER",
        onClick: () => gotoViewOrder(navigate, product.id, opportunityId)
    };

    return (
        <Success
            title="New AFS submitted"
            subtitle={subtitle}
            image={image}
            action={action}
        />
    );
};

export default SubmittedAFS;
