import LoadingOrDisplay from "../../../../../../common/LoadingOrDisplay";
import { formatNumber } from "../../../../../../common/Util";

const InitialTerm = ({ props, loading }) => {
    const { formState } = props;

    return (
        <div>
            <div className="round-card-black-top">
                <div className="p-b-16">
                    <span className="appkiticon icon-calendar-outline a-font-24 a-text-red p-r-16"></span>
                    <span className="text-16 bold">Initial Term</span>
                </div>
                <div className="light-gray-text-12">
                    <div className="row g-0">
                        <div className="col-9 m-v-8 gy-0">
                            Annual Subscription Fee
                        </div>
                        <div className="col-3 m-v-8 gy-0 right">
                            <LoadingOrDisplay
                                loading={loading}
                                display={formatNumber(formState.annualFees)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="black-background-rounded">
                <div className="row">
                    <span className="col">Subtotal before tax</span>
                    <span className="col right">
                        <LoadingOrDisplay
                            loading={loading}
                            display={formatNumber(formState.annualFees)}
                        />{" "}
                        USD
                    </span>
                </div>
            </div>
        </div>
    );
};

export default InitialTerm;
