import { Button } from "appkit-react";

const Continue = ({ disabled, gray, onContinue, label }) => {
    return (
        <div className={gray ? "row p-b-125 gray-background" : "row p-b-125"}>
            <div className="col-3"></div>
            <div className="col-6 flex-justify-center">
                <div className="content">
                    <div className="nav-buttons m-t-48">
                        <Button onClick={onContinue} disabled={disabled}>
                            {label}
                        </Button>
                    </div>
                </div>
            </div>
            <div className="col-3"></div>
        </div>
    );
};

export default Continue;
