import WrappedInput from "../../../../common/WrappedInput";
import { PRODUCT_STAKEHOLDERS } from "../../../Constants";

const AdditionalContacts = ({
    formData,
    handleRemove,
    handleAdditionalContactChange,
    validationErrors,
    hideTopSeparator
}) => {
    const additionalContacts = [];
    let i = 0;

    for (let contactType in formData.additionalContacts) {
        additionalContacts.push(
            <div key={i++}>
                {!hideTopSeparator && <div className="separator-line"></div>}
                <span
                    className="appkiticon icon-circle-minus-fill a-font-16 a-text-red v-middle clickable"
                    onClick={() => {
                        handleRemove(contactType);
                    }}
                ></span>
                <span className="orange v-middle p-l-8 text-12 bold">
                    Remove
                </span>
                <div className="orange text-16 bold p-b-8">
                    {PRODUCT_STAKEHOLDERS[contactType].label}
                </div>
                {contactType === PRODUCT_STAKEHOLDERS.LEAD_TAX_DIRECTOR.id && (
                    <WrappedInput
                        name="fullName"
                        label="*FULL NAME"
                        primary={true}
                        value={
                            formData.additionalContacts[contactType]
                                ?.fullName ?? ""
                        }
                        onChange={(value) =>
                            handleAdditionalContactChange(contactType)(
                                "fullName",
                                value
                            )
                        }
                        hasError={validationErrors[contactType].fullName.error}
                        errorMessage={
                            validationErrors[contactType].fullName.message
                        }
                    />
                )}
                <WrappedInput
                    name="email"
                    label="*WORK EMAIL"
                    primary={true}
                    value={
                        formData.additionalContacts[contactType]?.email ?? ""
                    }
                    onChange={(value) =>
                        handleAdditionalContactChange(contactType)(
                            "email",
                            value.toLowerCase()
                        )
                    }
                    hasError={validationErrors[contactType].email.error}
                    errorMessage={validationErrors[contactType].email.message}
                />
            </div>
        );
    }

    return <div>{additionalContacts}</div>;
};

export default AdditionalContacts;
