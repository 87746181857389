import { useRef } from "react";
import { Button } from "appkit-react";

import OrderIconHeader from "../../../common/order/OrderIconHeader";
import { validate } from "../../../common/Validation";
import useUpdatePricing from "./UseUpdatePricing";

const ContractWrapper = (props) => {
    const {
        formData,
        buttonActions,
        buttonLabels,
        validations,
        validationErrors,
        setValidationErrors,
        haveData,
        dependencies,
        getPricingParams,
        setPricingOptions,
        loading,
        setLoading,
        content
    } = props;

    const onContinue = () => {
        if (
            validate(
                validations,
                formData,
                validationErrors,
                setValidationErrors
            )
        ) {
            buttonActions[1]();
        }
    };

    const isMounted = useRef(false);
    useUpdatePricing(
        setLoading,
        isMounted,
        haveData,
        getPricingParams,
        setPricingOptions,
        dependencies
    );

    return (
        <div className="content">
            {props.useIconHeader ? (
                <div className="m-b-28">
                    <OrderIconHeader />
                </div>
            ) : (
                !props.hideHeader && (
                    <div className="text-22 half-bold m-b-28">
                        Order Details
                    </div>
                )
            )}
            {content}
            <div className="nav-buttons m-t-48">
                <Button
                    onClick={buttonActions[0]}
                    kind={"secondary"}
                    disabled={loading}
                >
                    {buttonLabels[0]}
                </Button>
                <Button onClick={onContinue} disabled={loading}>
                    {buttonLabels[1]}
                </Button>
            </div>
        </div>
    );
};

export default ContractWrapper;
