import CalendarPicker from "appkit-react-datepicker/lib/CalendarPicker";
import LabelErrorWrapper, {
    WRAPPED_COMPONENT_TYPE
} from "./LabelErrorWrapper.js";
import "./WrappedSelect.css";

const WrappedCalendar = (props) => {
    const wrappedCalendar = (
        <CalendarPicker
            value={props.value}
            onValueChange={props.onValueChange}
            type={WRAPPED_COMPONENT_TYPE.CALENDAR}
            mode={props.mode}
            editable={props.editable}
            format={props.format}
            minDate={props.minDate}
            maxDate={props.maxDate}
            placeholder={props.placeholder}
        ></CalendarPicker>
    );

    return (
        <LabelErrorWrapper
            name={props.name}
            className={props.className}
            wrappedComponent={wrappedCalendar}
            label={props.label}
            hasError={props.hasError}
            errorMessage={props.errorMessage}
            primary={props.primary}
        />
    );
};

export default WrappedCalendar;
