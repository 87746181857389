import Menu from "../common/Menu";
import AcceptanceHeader from "../../common/order/AcceptanceHeader";
import ProductCard from "../../common/order/ProductCard";
import { greenIcon } from "../../common/order/StatusIcons";
import Client from "../../create/review/Client";
import Order from "../../create/review/Order";
import Contact from "../../create/review/Contact";
import AccountInfo from "../../common/order/AccountInfo";

const Edit = (props) => {
    const { menuLabels, menuActions } = props;

    return (
        <>
            {menuLabels && menuActions && (
                <Menu labels={menuLabels} actions={menuActions} />
            )}
            <div className="row">
                <div className="col-3"></div>
                <div className="col-6 flex-justify-center">
                    <div className="content">
                        <div className="text-22 half-bold">{props.header}</div>
                        <div className="text-16 gray m-b-12">
                            {props.description}
                        </div>
                    </div>
                </div>
                <div className="col-3"></div>
            </div>
            <AcceptanceHeader />
            <div className="row gray-background p-b-36">
                <div className="col-3"></div>
                <div className="col-6 flex-justify-center">
                    <div className="content">
                        <ProductCard
                            icon={greenIcon}
                            label={props.product.label}
                        />
                        <div className="gray-text-12 m-b-12 m-t-32">
                            CLIENT OPPORTUNITY
                        </div>
                        <div className="round-card">
                            <AccountInfo
                                clientAccount={props.formState.clientAccount}
                            />
                        </div>
                        <div className="red-separator-line"></div>
                        <Client props={props} />
                        <div className="red-separator-line"></div>
                        <Order props={props} />
                        <div className="red-separator-line"></div>
                        <Contact props={props} />
                    </div>
                </div>
                <div className="col-3"></div>
            </div>
        </>
    );
};

export default Edit;
