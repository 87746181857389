export const TAB = {
    active: { id: "active", label: "ACTIVE ORDERS" },
    pending: { id: "pending", label: "PENDING ORDERS" },
    draft: { id: "draft", label: "DRAFT ORDERS" },
    renewal: { id: "renewal", label: "UPCOMING RENEWALS" },
    afs: { id: "afs", label: "AFS INFO REQUIRED" }
};

export const ORDER_TABLE_DATA = {
    orders: null,
    total: 0,
    pageNumber: 1,
    pageSize: 10
};

export const RENEW_MODAL_DATA = {
    product: null,
    client: "",
    opportunityId: "",
    endDate: ""
};

const styleActionColumn = (state, rowInfo, column) => {
    return {
        style: {
            width: "190px",
            minWidth: "190px",
            lineHeight: "1rem",
            margin: "12px 0"
        }
    };
};

const styleColumn = (state, rowInfo, column) => {
    return {
        style: {
            lineHeight: "1rem",
            margin: "16px 0"
        }
    };
};

const styleColumnHeader = (state, rowInfo, column) => {
    return {
        style: {
            lineHeight: "1rem",
            margin: "8px 0"
        }
    };
};

export const COLUMNS = {
    ACTIVE: [
        {
            Header: "Client name",
            accessor: "clientName",
            getHeaderProps: styleColumnHeader,
            getProps: styleColumn
        },
        {
            Header: "Signatory",
            accessor: "contactName",
            getHeaderProps: styleColumnHeader,
            getProps: styleColumn
        },
        {
            Header: "Submitter",
            accessor: "PWCContactName",
            getHeaderProps: styleColumnHeader,
            getProps: styleColumn
        },
        {
            Header: "Subscription end date",
            accessor: "endDate",
            getHeaderProps: styleColumnHeader,
            getProps: styleColumn
        },
        {
            Header: "",
            accessor: "actions",
            getHeaderProps: styleActionColumn,
            getProps: styleActionColumn,
            sortable: false
        }
    ],
    PENDING: [
        {
            Header: "Client name",
            accessor: "clientName",
            getHeaderProps: styleColumnHeader,
            getProps: styleColumn
        },
        {
            Header: "Signatory",
            accessor: "contactName",
            getHeaderProps: styleColumnHeader,
            getProps: styleColumn
        },
        {
            Header: "Submitter",
            accessor: "PWCContactName",
            getHeaderProps: styleColumnHeader,
            getProps: styleColumn
        },
        {
            Header: "",
            accessor: "actions",
            getHeaderProps: styleActionColumn,
            getProps: styleActionColumn,
            sortable: false
        }
    ],
    DRAFT: [
        {
            Header: "Client name",
            accessor: "clientName",
            getHeaderProps: styleColumnHeader,
            getProps: styleColumn
        },
        {
            Header: "Signatory",
            accessor: "contactName",
            getHeaderProps: styleColumnHeader,
            getProps: styleColumn
        },
        {
            Header: "Submitter",
            accessor: "PWCContactName",
            getHeaderProps: styleColumnHeader,
            getProps: styleColumn
        },
        {
            Header: "Created date",
            accessor: "endDate",
            getHeaderProps: styleColumnHeader,
            getProps: styleColumn
        },
        {
            Header: "",
            accessor: "actions",
            getHeaderProps: styleActionColumn,
            getProps: styleActionColumn,
            sortable: false
        }
    ],
    RENEWAL: [
        {
            Header: "Client name",
            accessor: "clientName",
            getHeaderProps: styleColumnHeader,
            getProps: styleColumn
        },
        {
            Header: "Signatory",
            accessor: "contactName",
            getHeaderProps: styleColumnHeader,
            getProps: styleColumn
        },
        {
            Header: "Status",
            accessor: "PWCContactName",
            getHeaderProps: styleColumnHeader,
            getProps: styleColumn
        },
        {
            Header: "Subscription end date",
            accessor: "endDate",
            getHeaderProps: styleColumnHeader,
            getProps: styleColumn
        },
        {
            Header: "",
            accessor: "actions",
            getHeaderProps: styleActionColumn,
            getProps: styleActionColumn,
            sortable: false
        }
    ],
    AFS: [
        {
            Header: "Client name",
            accessor: "clientName",
            getHeaderProps: styleColumnHeader,
            getProps: styleColumn
        },
        {
            Header: "Signatory",
            accessor: "contactName",
            getHeaderProps: styleColumnHeader,
            getProps: styleColumn
        },
        {
            Header: "Submitter",
            accessor: "PWCContactName",
            getHeaderProps: styleColumnHeader,
            getProps: styleColumn
        },
        {
            Header: "AFS expiration date",
            accessor: "endDate",
            getHeaderProps: styleColumnHeader,
            getProps: styleColumn
        },
        {
            Header: "",
            accessor: "actions",
            getHeaderProps: styleActionColumn,
            getProps: styleActionColumn,
            sortable: false
        }
    ]
};
