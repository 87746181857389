// remove the element at index from array and shift everything after index left
export const removeElement = (array, index) => {
    if (index < 0 || index >= array.length) return array;
    const before = array.slice(0, index);
    const after = array.slice(index + 1, array.length);
    return [...before, ...after];
};

// format number from 1000 to $1,000
export const formatNumber = (num, currencySymbol) => {
    if (!num && num !== 0) {
        return "";
    }
    var value = num + "";
    var len = value.length;
    var j = len;
    var str = "";
    for (var i = len - 1; i >= 0; i--) {
        var char = value[i];
        var _j = j - 3;
        if (_j === i && _j !== 0) {
            char = "," + char;
            j = i;
        }
        str = char + str;
    }
    return (currencySymbol !== undefined ? currencySymbol : "$") + str;
};

// format date to mm/dd/yyyy
export const formatDate = (time) => {
    if (!time) {
        return "";
    }
    var date = time;
    if (typeof time === "string") {
        // already formatted as mm/dd/yyyy?
        if (time.indexOf("/") === 2) {
            return time;
        }
        // If the time is the format of 'yyyy-mm-dd'
        if (time.indexOf("T00:00:00") === -1) {
            time = time + "T00:00:00";
        }
        date = new Date(time);
    }
    var mm = date.getMonth() + 1;
    mm = mm < 10 ? "0" + mm : mm;
    var dd = date.getDate();
    dd = dd < 10 ? "0" + dd : dd;
    var yyyy = date.getFullYear();
    return mm + "/" + dd + "/" + yyyy;
};

// returns a new Date() instance in the EST/EDT time zone
// note that console.log(newDateEST()) will still show the date as being in the browser's time zone
// even though the actual date and time are in EST/EDT
export const newDateEST = () => {
    var local = new Date();
    var adjusted = new Date(
        local.toLocaleString("en-US", { timeZone: "America/New_York" })
    );
    var diff = local.getTime() - adjusted.getTime();
    return new Date(local.getTime() - diff);
};

export const splitServiceModules = (serviceModules) => {
    if (serviceModules) {
        // salesforce drops the quotes so we can't use JSON.parse() here
        return serviceModules
            .replace("[", "")
            .replace("]", "")
            .replaceAll(", ", ",")
            .split(",")
            .filter((s) => s); // remove empty strings in the array [""]
    } else {
        return [];
    }
};

export const AFS_NUMBER_REGEX = /^AFS-[0-9]{6,7}$/;

export const EMAIL_REGEX =
    /^[\w!#$%&'*+/=?`{|}~^-]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}$/;

export const PWC_EMAIL_REGEX =
    /^[\w!#$%&'*+/=?`{|}~^-]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@pwc\.com$/;

export const isEmail = (email) => {
    return EMAIL_REGEX.test(email);
};

export const DOMAIN_REGEX = /^(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}$/;

export const getDomain = (email) => {
    const index = email.lastIndexOf("@");
    return email.substring(index + 1);
};

export const getAllIndices = (searchArray, searchValue) => {
    return searchArray.reduce((previousValue, currentValue, currentIndex) => {
        if (currentValue === searchValue) previousValue.push(currentIndex);
        return previousValue;
    }, []);
};
