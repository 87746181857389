import { useNavigate, useParams } from "react-router-dom";
import { Success } from "./Success";
import teamWork from "../../../../../assets/teamWork.svg";
import { formatDate } from "../../../../common/Util";
import { gotoViewOrder } from "../../../../../Routes";
import useProduct from "../../common/UseProduct";

const RenewedOrder = () => {
    const product = useProduct();
    const { opportunityId, clientName, renewDate } = useParams();

    const subtitle = `Thank you for renewing ${clientName}'s ${
        product.label
    }'s subscription. 
    ${clientName}'s ${
        product.label
    }'s subscription period has been successfully extended to ${formatDate(
        renewDate
    )}.`;

    const image = { src: teamWork, width: "246px" };

    const navigate = useNavigate();
    const action = {
        text: "VIEW ORDER",
        onClick: () => gotoViewOrder(navigate, product.id, opportunityId)
    };

    return (
        <Success
            title="Subscription renewed"
            subtitle={subtitle}
            description={``}
            image={image}
            action={action}
        />
    );
};

export default RenewedOrder;
