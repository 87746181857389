import { EXTERNAL_URLS } from "../../common/Constants";
import "./SupportBanner.css";

const SupportBanner = () => {
    const goToSupport = () => {
        window.open(EXTERNAL_URLS.supportHome);
    };

    return (
        <div className="support-banner support-border">
            <button
                className="link-button orange"
                kind="secondary"
                size="lg"
                onClick={goToSupport}
            >
                <span className="appkiticon icon-help-question-outline a-font-32">
                    <span className="text-14 p-l-8">Support</span>
                </span>
            </button>
        </div>
    );
};

export default SupportBanner;
