import Checkbox from "appkit-react/lib/Checkbox/Checkbox";
import "../../common/LabelErrorWrapper.css";
import "./TermsConditionsAgree.css";
import TermsConditionsModal from "./TermsConditionsModal";
import React from "react";

const TermsConditionsAgree = ({ props }) => {
    const errorClasses = ["a-form-error"];
    let checkboxClass = "";
    const hasError = props.validationErrors.agreeToTC.error;
    if (hasError === undefined) errorClasses.push("error");
    if (hasError === false) errorClasses.push("error hide");
    if (hasError === true) {
        checkboxClass = "error-highlight";
        errorClasses.push("error show");
    }

    return (
        <div className="d-flex justify-content-start">
            <div className="m-checkbox-agree">
                <Checkbox
                    name="agreeToTC"
                    onChange={e => props.handleChange("agreeToTC")(e.target.checked)}
                    checked={props.formData.agreeToTC}
                    className={checkboxClass}
                />
            </div>
            <div>
                <div>
                    <span className="text-14">I have read and agree to the </span>
                    <TermsConditionsModal
                        country={props.formData.country}
                    />
                </div>
                <div className={errorClasses.join(" ")}>{props.validationErrors.agreeToTC.message ? props.validationErrors.agreeToTC.message : "\u00A0"}</div>
            </div>
        </div>
    );
};

export default TermsConditionsAgree;