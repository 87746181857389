import { CONTRACT_TYPE } from "../../Constants";

// intial terms for DOD
export const ONE_TIME_FEE = {
    [CONTRACT_TYPE.fourtyFiveDays]: {
        title: "One-Time Fee | Due as of renewal effective date",
        subTitle:
            "Targeted training to engage Users and accelerate the impact of Digital on Demand. Includes the following for Trial Users and can be repeated one time at Client’s option upon renewal of the Agreement:",
        desc: [
            "Digital on Demand Onboarding Session - a facilitated session (up to 90 minutes) to onboard Users and introduce the platform’s functionality.",
            "Digital Academy - a facilitated session (max 4 hours) for up to 10 participants to deliver targeted training on a specific technology tool.",
            "Digital Champions playbook - a playbook to assist Client with creating a digital champion network.",
            "Automation Discovery sessions - a facilitated session (up to 2 hours) for up to 10 participants to walk Users through process flows and relevant assets currently available for Payroll."
        ]
    },
    [CONTRACT_TYPE.ninetyDays]: {
        title: "One-Time Fee | Due as of Order Effective Date",
        subTitle:
            "Targeted training to engage Users and accelerate the impact of Digital on Demand. Includes the following and can be repeated one time at Client’s option upon renewal of the Agreement:",
        desc: [
            "Digital on Demand Onboarding Session - a facilitated session (up to 90 minutes) to onboard Users and introduce the platform’s functionality.",
            "Digital Academy - a facilitated session (max 4 hours) for up to 30 participants to deliver targeted training on a specific technology tool.",
            "Digital Champions playbook - a playbook to assist Client with creating a digital champion network.",
            "Automation Discovery sessions - a facilitated session (up to 2 hours) for up to 30 participants to walk Users through process flows and relevant assets currently available for Payroll."
        ]
    },
    [CONTRACT_TYPE.twelveMonths]: {
        title: "One-Time Fee | Invoiced as of Order Effective Date",
        subTitle:
            "Targeted training to engage Users and accelerate the impact of Digital on Demand. Includes the following and can be repeated one time at Client’s option upon renewal of the Agreement:",
        desc: [
            "Digital on Demand Onboarding Session - a facilitated session (up to 90 minutes) to onboard Users and introduce the platform’s functionality.",
            "Digital Academy - a facilitated session (max 4 hours) for up to 30 participants to deliver targeted training on a specific technology tool.",
            "Digital Champions playbook - a playbook to assist Client with creating a digital champion network.",
            "Automation Discovery sessions - a facilitated session (up to 2 hours) for up to 30 participants to walk Users through process flows and relevant assets currently available for Payroll."
        ]
    }
};

// initial terms for WFO
export const ANNUAL_SUBSCRIPTION_FEE = {
    [CONTRACT_TYPE.frontlineWorkers]: [
        {
            title: "Contract Type:",
            text: ["Front-line Workers: 12-month"]
        },
        {
            title: "Product Features:",
            text: [
                "Role-based messages, activities, and tasks",
                "AI-enabled chatbot",
                "Surveys"
            ]
        }
    ],
    [CONTRACT_TYPE.officeWorkers]: [
        {
            title: "Contract Type:",
            text: ["Office Workers: 12-month"]
        },
        {
            title: "Product Features:",
            text: [
                "Role-based messages, activities, and tasks",
                "AI-enabled chatbot",
                "Surveys"
            ]
        }
    ],
    [CONTRACT_TYPE.employeeExperience]: [
        {
            title: "Contract Type:",
            text: ["Employee Experience"]
        }
    ],
    [CONTRACT_TYPE.customerExperience]: [
        {
            title: "Contract Type:",
            text: [
                "Customer Experinece",
                "Use Fee: US$2.00 per response for the first 100,000 responses per annual period of the Term. US$0.50 per response thereafter for the remainder of such annual period of the Term."
            ]
        }
    ],
    [CONTRACT_TYPE.v3ContractCN]: [
        {
            title: "Subscription Tier:",
            text: ["Medium"]
        },
        {
            title: "Subscription Term:",
            text: ["1 year contract"]
        },
        {
            title: "Product Features:",
            text: [
                "Number of Projects Permitted Per Year: Up to 5",
                "User Account Limit: Up to 50",
                "Number of permitted custom exportable reports: 1"
            ]
        }
    ]
};
