import { useNavigate, useParams } from "react-router-dom";
import { gotoError } from "../../../../Routes";
import { PRODUCTS } from "../../Config";

const useProduct = (allowNull) => {
    // the route MUST have a :productId parameter for this to work
    const { productId } = useParams();
    const product = PRODUCTS[productId?.toUpperCase()];
    const navigate = useNavigate();
    if (product === undefined && !allowNull) gotoError(navigate);
    return product;
};

export default useProduct;
