import envelope from "../../../../../assets/envelope.svg";

const ContactIconHeader = () => {
    return (
        <div className="review-icon-container col-6">
            <div className="step-icon active-icon review-icon">
                <img src={envelope} alt="Contact Icon"></img>
            </div>
            <span className="p-l-8 text-22 half-bold">Contacts</span>
        </div>
    );
};

export default ContactIconHeader;
