import Modal from "appkit-react/lib/Modal/Modal";
import ModalHeader from "appkit-react/lib/Modal/ModalHeader";
import "./GeneralOrderModal.css";

const OfflineContractingModal = (props) => {
    const { isVisible, setIsVisible } = props;

    const handleCancel = () => {
        setIsVisible(false);
    };

    return (
        <Modal
            className="offline-contracting-modal"
            visible={isVisible}
            onCancel={handleCancel}
        >
            <ModalHeader>
                <span>
                    <span>
                        <div className="modal-title">
                            Offline contracting required
                        </div>
                    </span>
                    <span>
                        <div className="modal-description-long">
                            <div className="p-b-16">
                                The standard SLP order form is not supported for
                                this client.
                            </div>
                            <div>
                                Please work with your contracting team to tailor
                                the contract for the client based on the
                                offering that is permissible for them. Once the
                                contract has been signed by the client offline,
                                please upload the signed contract to this order
                                within 14 days after contract signing.{" "}
                                <a
                                    href="https://docs.google.com/document/d/1YlUDaUbnPHwVkt9WdMMs-plAfg17ZntEm1wbn8hkNdY/edit#bookmark=id.saa8q1nerloj"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="banner-a orange"
                                >
                                    Click here
                                </a>{" "}
                                for more information.
                            </div>
                        </div>
                    </span>
                </span>
            </ModalHeader>
        </Modal>
    );
};
export default OfflineContractingModal;
