import { PRODUCTS } from "../../Config";

// contract type
export const CONTRACT_TYPE = {
    fourtyFiveDays: "45 days",
    ninetyDays: "90 days",
    twelveMonths: "12 months",
    frontlineWorkers: "Front-line Workers: 12-month",
    officeWorkers: "Office Workers: 12-month",
    employeeExperience: "Employee Experience",
    customerExperience: "Customer Experience",
    v1ContractCN: "1 Year Contract",
    v2ContractCN: "2 Year Contract",
    v3ContractCN: "3 Year Contract",
    v4ContractCN: "4 Year Contract",
    v5ContractCN: "5 Year Contract"
};

// Contants for contract page status
export const PAGE_STATUS = {
    order: "order",
    unavailable: "unavailable",
    success: "success",
    wrong: "wrong"
};

// remain the same field for each product
export const CONTRACT_FIELD = {
    [PRODUCTS.DOD.id]: "contractType",
    [PRODUCTS.WFO.id]: "productContractType",
    [PRODUCTS.LISTEN.id]: "productContractType",
    [PRODUCTS.CN.id]: "productTerm"
};
