import Button from "appkit-react/lib/Button/Button";

const ButtonsWrapper = (props) => {
    const buttonComponents = [];

    const { submissionInfo, buttons } = props;
    let i = 0;
    for (let key in buttons) {
        if (buttons[key].display(submissionInfo)) {
            buttonComponents.push(
                <div key={++i}>
                    {buttons[key].instructions && buttons[key].instructions}
                    <div className="white-background p-t-24 flex-justify-center">
                        <Button
                            className="w-380"
                            size={"xl"}
                            kind={buttons[key].kind}
                            disabled={false}
                            onClick={buttons[key].action}
                        >
                            {buttons[key].icon}
                            {buttons[key].label}
                        </Button>
                    </div>
                </div>
            );
        }
    }

    return <>{buttonComponents}</>;
};

export default ButtonsWrapper;
