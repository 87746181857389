import { JSO } from "jso";
import { useState, useEffect } from "react";
import { MOCK_USER, USER } from "../products/Constants";
import App from "../../App";

const SSO = (props) => {
    // default finishedSSO to true if:
    //  - SSO is not enabled (dev sandboxes, etc) OR
    //  - the requested route bypasses SSO (i.e. page is public)
    const [finishedSSO, setFinishedSSO] = useState(
        !window.SSOEnabled || props.bypass
    );
    const [user, setUser] = useState(window.SSOEnabled ? USER : MOCK_USER);

    useEffect(() => {
        // without the if (!finishedSSO) {...} this code will loop infinitely
        // as getProfile() updates state which re-renders this component
        // note that this block will still run at least once if window.SSOEnabled is true
        if (window.SSOEnabled && !finishedSSO) {
            const get = (url, bearer, handler) => {
                const init = { headers: {} };
                init.headers["Authorization"] = "Bearer " + bearer;
                fetch(url, init)
                    .then((response) => response.json())
                    .then((data) => handler(data));
            };

            const getProfile = (access_token) => {
                get(window.userInfoURL, access_token, (response) => {
                    setUser({
                        name: response?.name,
                        email: response?.preferredMail,
                        guid: response?.uid
                    });
                    setFinishedSSO(true);
                });
            };

            // save search params and hash in sessionStorage unless there is an #access_token (SSO is redirecting back)
            if (window.location.hash.indexOf("#access_token=") === -1) {
                sessionStorage.setItem("hash", window.location.hash);
                sessionStorage.setItem("params", window.location.search);
            }

            const opts = {
                client_id: window.clientID,
                redirect_uri: window.redirectURL,
                authorization: window.authorizationURL,
                scopes: {
                    request: [
                        "openid",
                        "profile",
                        "cloudEmail",
                        "email",
                        "employeeNumber",
                        "country",
                        "upwcjobtitle",
                        "jobtitle",
                        "preferredMail"
                    ]
                },
                debug: false
            };

            const client = new JSO(opts);
            client.callback();
            client.getToken(opts).then((token) => {
                // restore saved search params and hash from above
                let hash = sessionStorage.getItem("hash");
                let params = sessionStorage.getItem("params");
                hash = hash ? hash : "";
                params = params ? params : "";
                if (hash || params) {
                    window.location.href =
                        window.location.origin +
                        window.location.pathname +
                        params +
                        hash;
                }

                getProfile(token.access_token);
            });
        }
    }, [finishedSSO]);

    return (
        <div>
            {finishedSSO ? (
                <App user={user} bypassSSO={props.bypass} />
            ) : (
                <div></div>
            )}
        </div>
    );
};

export default SSO;
