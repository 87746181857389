import WrappedSelect from "../../../../common/WrappedSelect";
import SelectOption from "appkit-react/lib/Select/SelectOption";

const ContactSelect = (props) => {
    const options = [];
    for (const contact of props.options) {
        options.push(
            <SelectOption key={contact.id} value={contact.id}>
                {contact.label}
            </SelectOption>
        );
    }

    return (
        <WrappedSelect
            name={props.name}
            label={props.label}
            placeholder={props.placeholder}
            hasError={props.hasError}
            errorMessage={props.errorMessage}
            options={options}
            onChange={props.onChange}
            value={props.value}
            primary={true}
            multiple={true}
        />
    );
};

export default ContactSelect;
