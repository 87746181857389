import LineItem from "../../common/LineItem";

import { UNITED_STATES } from "../Constants";

const ViewpointPricing = ({ props }) => {
    const handleQuantityChange = (name) => (value) => {
        let names = [name];
        let values = [value];
        // "Global/International Content cannot be less than the maximum of Canada Content and UK Content."
        if (name === "canadaContent" || name === "ukContent") {
            if (value > props.formData.globalContent) {
                names.push("globalContent");
                values.push(value);
            }
        }
        props.handleChanges(names)(values);
    };

    return (
        <div>
            <div className="text-16 orange p-v-32">
                Please provide the number of licenses desired for each territory
                below. Global/International Content cannot be less than the
                maximum of Canada Content and UK Content.
                <br/><br/>
                Effective July 1, US content is free for all registered users.
            </div>
            <h5>Individual territory pricing:</h5>
            {/* <LineItem
                name={"usContent"}
                label={"US Content:"}
                onChange={(value) => handleQuantityChange("usContent")(value)}
                value={props.formData.usContent}
                total={props.formData.usContentTotal}
                min={0}
                max={100}
                currency={props.formState.currencySymbol}
            />
            <div className="light-gray-text-12 italic right">
                {props.formState.billingTerritory === UNITED_STATES
                    ? "Inquire with the US licensing team for pricing of 10+ users."
                    : ""}
            </div>

            <div className="light-gray-text-12 italic right m-b-8">
                (excludes GASB and international content).
            </div> */}
            <LineItem
                name={"globalContent"}
                label={"Global/International Content:"}
                onChange={(value) =>
                    handleQuantityChange("globalContent")(value)
                }
                value={props.formData.globalContent}
                total={props.formData.globalContentTotal}
                min={Math.max(
                    props.formData.canadaContent,
                    props.formData.ukContent
                )}
                max={100}
                currency={props.formState.currencySymbol}
            />
            <div className="light-gray-text-12 italic right">
                {props.formState.billingTerritory === UNITED_STATES
                    ? "Inquire with the US licensing team for pricing of 10+ users."
                    : ""}
            </div>
            <div className="light-gray-text-12 italic right m-b-26">
                Includes IFRS.
            </div>
            <LineItem
                name={"canadaContent"}
                label={"Canada Content:"}
                onChange={(value) =>
                    handleQuantityChange("canadaContent")(value)
                }
                value={props.formData.canadaContent}
                total={props.formData.canadaContentTotal}
                min={0}
                max={100}
                currency={props.formState.currencySymbol}
            />
            <div className="light-gray-text-12 italic right">
                {props.formState.billingTerritory === UNITED_STATES
                    ? "Inquire with the US licensing team for pricing of 10+ users."
                    : ""}
            </div>
            <div className="light-gray-text-12 italic right m-b-26">
                Subscribers to this content must also license the
                Global/International territory site.
            </div>
            <LineItem
                name={"ukContent"}
                label={"UK (including UK GAAP) Content:"}
                onChange={(value) => handleQuantityChange("ukContent")(value)}
                value={props.formData.ukContent}
                total={props.formData.ukContentTotal}
                min={0}
                max={100}
                currency={props.formState.currencySymbol}
            />
            <div className="light-gray-text-12 italic right">
                Content incorporates relevant IFRS for the UK & UK GAAP
                materials.
            </div>
            <div className="light-gray-text-12 italic right">
                Subscribers to this content must also license the
                Global/International territory site.
            </div>
            <div className="light-gray-text-12 italic right">
                {props.formState.billingTerritory === UNITED_STATES
                    ? "Inquire with the US licensing team for pricing of 10+ users."
                    : ""}
            </div>
            <LineItem
                name={"gaapContent"}
                label={"UK GAAP Only:"}
                onChange={(value) => handleQuantityChange("gaapContent")(value)}
                value={props.formData.gaapContent}
                total={props.formData.gaapContentTotal}
                min={0}
                max={100}
                currency={props.formState.currencySymbol}
            />
            <div className="m-b-44"></div>
            <LineItem
                name={"japanContent"}
                label={"Japan Content:"}
                onChange={(value) =>
                    handleQuantityChange("japanContent")(value)
                }
                value={props.formData.japanContent}
                total={props.formData.japanContentTotal}
                min={0}
                max={100}
                currency={props.formState.currencySymbol}
            />
            <div className="light-gray-text-12 italic right m-b-8">
                Japan content is in the Japanese language.
            </div>
            {/* <LineItem
                name={"gasbContent"}
                label={"US GASB Only:"}
                onChange={(value) => handleQuantityChange("gasbContent")(value)}
                value={props.formData.gasbContent}
                total={props.formData.gasbContentTotal}
                min={0}
                max={100}
                currency={props.formState.currencySymbol}
            />
            <div className="light-gray-text-12 italic right">
                Requires subscription to US Content.
            </div>
            <div className="light-gray-text-12 italic right">
                {props.formState.billingTerritory === UNITED_STATES
                    ? "Inquire with the US licensing team for pricing of 10+ users."
                    : ""}
            </div> */}
            <div className="m-b-44"></div>
            <div className="separator-line m-t-40"></div>
            <div className="row gx-0 px-0">
                <span className="light-gray-text-12 m-r-18 col">
                    Total annual cost for all licenses (amount does not include
                    any applicable jurisdictional taxes; they will be added to
                    your invoice).
                </span>
                <span className="subtotal-w col-3 d-flex justify-content-end">
                    <div>
                        <div className="text-12 m-b-12">TOTAL:</div>
                        <div className="subtotal">
                            {props.formState.currencySymbol}
                            {props.formData.viewpointTotal}
                        </div>
                    </div>
                </span>
            </div>
            <div className="separator-line m-t-40"></div>
            <div className="text-16 m-b-16">Viewpoint user information</div>
            <div className="light-gray-text-12">
                Viewpoint is licensed on a “banded” licensed model to allow for
                a personalized experience. In other words, only one user will be
                permitted to register per license. The banded license model
                allows for personalization of content for users and now
                consistent best practice for content management.
            </div>
        </div>
    );
};

export default ViewpointPricing;
