import { useState } from "react";
import { CardTitle } from "../../CardTitle";
import { ANNUAL_SUBSCRIPTION_FEE } from "./Constants";
import { formatNumber, splitServiceModules } from "../../../../../common/Util";
import { CONTRACT_FIELD } from "../../Constants";

const WFO = (props) => {
    const [detailShow, setDetailShow] = useState(false);
    const { orderDetails, productId } = props;
    const annualSubscriptionFee =
        ANNUAL_SUBSCRIPTION_FEE[orderDetails[CONTRACT_FIELD[productId]]];
    // prevent Initial term card bottom radius bug when there is no RenewalTerm1Value
    const borderBottomRadius = "border-radius-b";

    const selectedModules = [];
    let i = 0;
    for (const module of splitServiceModules(orderDetails.ServiceModule)) {
        selectedModules.push(<li key={i++}>{module}</li>);
    }

    return (
        <div className="p-t-26 m-b-28">
            <div className="m-h-24 flex-justify-between">
                <CardTitle
                    iconClass="icon-calendar-outline"
                    text="Initial Term"
                />
                <div
                    onClick={() => setDetailShow(!detailShow)}
                    className="orange text-12 half-bold a-hover underline text-bold"
                >
                    {detailShow ? "Hide Details" : "View Details"}
                </div>
            </div>
            <ul className="m-h-24 ul-clear-left-p">
                {orderDetails.productPrice ? (
                    <li className="p-t-15">
                        <div className="flex-justify-between text-12 half-bold gray">
                            <h6 className="text-12 half-bold mb-0">
                                Annual Subscription Fee
                            </h6>
                            <span>
                                {formatNumber(orderDetails.productPrice)}
                            </span>
                        </div>
                        {detailShow ? (
                            <div>
                                {annualSubscriptionFee.map((item, key) => (
                                    <div
                                        className="gray-text-10 half-bold one-time-fee  w-percent-70"
                                        key={"asf-key-" + key}
                                    >
                                        <p className="mb-2 mt-2">
                                            {item.title}
                                        </p>
                                        <ul>
                                            {item.text.map(
                                                (subitem, subkey) => (
                                                    <li
                                                        key={
                                                            "asf-key-" +
                                                            key +
                                                            subkey
                                                        }
                                                    >
                                                        {subitem}
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    </div>
                                ))}
                            </div>
                        ) : null}
                    </li>
                ) : null}
                <li className="p-t-15">
                    <div className="flex-justify-between text-12 half-bold gray">
                        <h6 className="text-12 half-bold mb-0">
                            Total Number of Users
                        </h6>
                        <span>
                            {formatNumber(orderDetails.numberOfUsers, "")}
                        </span>
                    </div>
                </li>
                {selectedModules.length > 0 && (
                    <li className="p-t-15">
                        <div className="flex-justify-between text-12 half-bold gray">
                            <h6 className="text-12 half-bold mb-0">
                                Service Module(s):
                            </h6>
                            <span>-</span>
                        </div>
                        {detailShow ? (
                            <div>
                                <div className="gray-text-10 half-bold one-time-fee">
                                    <ul className="clear-margin-bottom">
                                        {selectedModules}
                                    </ul>
                                    <p className="orange text-10 clear-margin-bottom">
                                        Service Module fees will be calculated
                                        and billed separately on a quarterly
                                        basis.
                                    </p>
                                </div>
                            </div>
                        ) : null}
                    </li>
                )}
                <li className="p-t-15">
                    <div className="flex-justify-between text-12 gray half-bold">
                        <h6 className="text-12 half-bold mb-0">
                            Communication Channel
                        </h6>
                        <span>Included</span>
                    </div>
                    {detailShow ? (
                        <div>
                            <div className="gray-text-10 half-bold">
                                <p className="clear-margin-bottom">
                                    {orderDetails.communicationChannel}
                                </p>
                            </div>
                        </div>
                    ) : null}
                </li>
            </ul>
            <div
                className={`bg-black white flex-justify-between p-h-24 p-v-15 m-t-15 text-bold ${borderBottomRadius}`}
            >
                <h3 className="text-16 clear-margin-bottom text-bold">
                    Subtotal before tax
                </h3>
                <span>{formatNumber(orderDetails.productPrice)} USD</span>
            </div>
        </div>
    );
};

export default WFO;
