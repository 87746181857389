import ContractWrapper from "../ContractWrapper";
import InitialTerm from "./InitialTerm";

const Contract = ({ props }) => {
    const { formData, buttonActions, buttonLabels } = props;

    const content = (
        <>
            <div className="text-18 bold">Subscription plan</div>
            <div className="light-gray-text-16 p-b-16">
                All PwC clients receive a complimentary subscription with
                unlimited users.
            </div>
            <div className="gray-text-12 p-v-8">ORDER TERMS</div>
            <div className="round-card-black">
                <InitialTerm props={props} />
            </div>
        </>
    );

    return (
        <ContractWrapper
            formData={formData}
            buttonActions={buttonActions}
            buttonLabels={buttonLabels}
            validations={{}}
            setValidationErrors={() => {}}
            content={content}
            hideHeader={true}
        />
    );
};

export default Contract;
