// routes that start with these prefixes will bypass SSO
//   note that we are checking window.location.hash.startsWith() in index.js
//   so these all must start with a hash
export const NON_SSO_PREFIXES = [
    "#/cn/contract/",
    "#/dod/contract/",
    "#/listen/contract",
    "#/wfo/contract"
];

export const ROUTES = {
    root: "/",
    error: "/error",
    accessDenied: "/access/denied",
    scheduledMaintenance: "/scheduled/maintenance",
    landing: "/:productId",
    landingFragment: (productId) => {
        return `/${productId ? productId.toLowerCase() : ""}`;
    },
    // client action page
    contract: "/:productId/contract/:opportunityId",
    // pwc action pages
    manage: "/:productId/:tabId",
    manageFragment: (productId, tabId) => {
        return `/${productId.toLowerCase()}/${tabId}`;
    },
    newOrder: "/:productId/new/order",
    newOrderFragment: (productId) => {
        return `/${productId.toLowerCase()}/new/order`;
    },
    viewOrder: "/:productId/view/order/:opportunityId",
    viewOrderFragment: (productId, opportunityId) => {
        return `/${productId.toLowerCase()}/view/order/${opportunityId}`;
    },
    submitAFS: "/:productId/submit/afs/:opportunityId",
    submitAFSFragment: (productId, opportunityId) => {
        return `/${productId.toLowerCase()}/submit/afs/${opportunityId}`;
    },
    resendOrder: "/:productId/resend/order/:opportunityId",
    resendOrderFragment: (productId, opportunityId) => {
        return `/${productId.toLowerCase()}/resend/order/${opportunityId}`;
    },
    submitOrder: "/:productId/submit/order/:opportunityId",
    submitOrderFragment: (productId, opportunityId) => {
        return `/${productId.toLowerCase()}/submit/order/${opportunityId}`;
    },
    // success pages
    createdOrder:
        "/:productId/created/order/:opportunityId/:clientName/:offline",
    createdOrderFragment: (productId, opportunityId, clientName, offline) => {
        return `/${productId.toLowerCase()}/created/order/${opportunityId}/${encodeURIComponent(
            clientName
        )}/${offline}`;
    },
    submittedOrder: "/:productId/submitted/order/:opportunityId/:clientName",
    submittedOrderFragment: (productId, opportunityId, clientName) => {
        return `/${productId.toLowerCase()}/submitted/order/${opportunityId}/${encodeURIComponent(
            clientName
        )}`;
    },
    resentOrder: "/:productId/resent/order/:opportunityId/:clientName",
    resentOrderFragment: (productId, opportunityId, clientName) => {
        return `/${productId.toLowerCase()}/resent/order/${opportunityId}/${encodeURIComponent(
            clientName
        )}`;
    },
    submittedAFS: "/:productId/submitted/afs/:opportunityId/:clientName",
    submittedAFSFragment: (productId, opportunityId, clientName) => {
        return `/${productId.toLowerCase()}/submitted/afs/${opportunityId}/${encodeURIComponent(
            clientName
        )}`;
    },
    terminatedOrder:
        "/:productId/terminated/order/:opportunityId/:clientName/:terminationDate",
    terminatedOrderFragment: (
        productId,
        opportunityId,
        clientName,
        terminationDate
    ) => {
        return `/${productId.toLowerCase()}/terminated/order/${opportunityId}/${encodeURIComponent(
            clientName
        )}/${encodeURIComponent(terminationDate)}`;
    },
    withdrawnOrder: "/:productId/withdrawn/order/:opportunityId/:clientName",
    withdrawnOrderFragment: (productId, opportunityId, clientName) => {
        return `/${productId.toLowerCase()}/withdrawn/order/${opportunityId}/${encodeURIComponent(
            clientName
        )}`;
    },
    uploadedContract:
        "/:productId/uploaded/contract/:opportunityId/:clientName",
    uploadedContractFragment: (productId, opportunityId, clientName) => {
        return `/${productId.toLowerCase()}/uploaded/contract/${opportunityId}/${encodeURIComponent(
            clientName
        )}`;
    },
    renewedOrder:
        "/:productId/renewed/order/:opportunityId/:clientName/:renewDate",
    renewedOrderFragment: (productId, opportunityId, clientName, renewDate) => {
        return `/${productId.toLowerCase()}/renewed/order/${opportunityId}/${encodeURIComponent(
            clientName
        )}/${encodeURIComponent(renewDate)}`;
    },
    cancelledOrder:
        "/:productId/cancelled/order/:opportunityId/:clientName/:cancellationDate",
    cancelledOrderFragment: (
        productId,
        opportunityId,
        clientName,
        cancellationDate
    ) => {
        return `/${productId.toLowerCase()}/cancelled/order/${opportunityId}/${encodeURIComponent(
            clientName
        )}/${encodeURIComponent(cancellationDate)}`;
    }
};

// encapsulate ROUTES constant via goto functions
export const gotoRoot = (navigate) => {
    navigate(ROUTES.root);
};
export const gotoLanding = (navigate, productId) => {
    navigate(ROUTES.landingFragment(productId));
};
export const gotoError = (navigate) => {
    navigate(ROUTES.error, { replace: true });
};
export const gotoAcessDenied = (navigate) => {
    navigate(ROUTES.accessDenied, { replace: true });
};
export const gotoManage = (navigate, productId, tabId) => {
    navigate(ROUTES.manageFragment(productId, tabId));
};
export const gotoManageDefault = (navigate, product) => {
    gotoManage(navigate, product.id, product.defaultTab.id);
};
export const gotoNewOrder = (navigate, productId) => {
    navigate(ROUTES.newOrderFragment(productId));
};
export const gotoViewOrder = (navigate, productId, opportunityId) => {
    navigate(ROUTES.viewOrderFragment(productId, opportunityId));
};
// note that navigate() does not reload the page
// but history.go() does reload the page
export const redirectToViewOrder = (history, productId, opportunityId) => {
    history.go(ROUTES.viewOrderFragment(productId, opportunityId));
};
export const gotoSubmitAFS = (navigate, productId, opportunityId) => {
    navigate(ROUTES.submitAFSFragment(productId, opportunityId));
};
export const gotoResendOrder = (navigate, productId, opportunityId) => {
    navigate(ROUTES.resendOrderFragment(productId, opportunityId));
};
export const gotoSubmitOrder = (navigate, productId, opportunityId) => {
    navigate(ROUTES.submitOrderFragment(productId, opportunityId));
};
export const gotoCreatedOrder = (
    navigate,
    productId,
    opportunityId,
    clientName,
    offline
) => {
    navigate(
        ROUTES.createdOrderFragment(
            productId,
            opportunityId,
            clientName,
            offline
        ),
        {
            replace: true
        }
    );
};
export const gotoSubmittedOrder = (
    navigate,
    productId,
    opportunityId,
    clientName
) => {
    navigate(
        ROUTES.submittedOrderFragment(productId, opportunityId, clientName),
        {
            replace: true
        }
    );
};
export const gotoResentOrder = (
    navigate,
    productId,
    opportunityId,
    clientName
) => {
    navigate(ROUTES.resentOrderFragment(productId, opportunityId, clientName), {
        replace: true
    });
};
export const gotoSubmittedAFS = (
    navigate,
    productId,
    opportunityId,
    clientName
) => {
    navigate(
        ROUTES.submittedAFSFragment(productId, opportunityId, clientName),
        {
            replace: true
        }
    );
};
export const gotoTerminatedOrder = (
    navigate,
    productId,
    opportunityId,
    clientName,
    terminationDate
) => {
    navigate(
        ROUTES.terminatedOrderFragment(
            productId,
            opportunityId,
            clientName,
            terminationDate
        ),
        {
            replace: true
        }
    );
};
export const gotoWithdrawnOrder = (
    navigate,
    productId,
    opportunityId,
    clientName
) => {
    navigate(
        ROUTES.withdrawnOrderFragment(productId, opportunityId, clientName),
        {
            replace: true
        }
    );
};
export const gotoUploadedContract = (
    navigate,
    productId,
    opportunityId,
    clientName
) => {
    navigate(
        ROUTES.uploadedContractFragment(productId, opportunityId, clientName),
        { replace: true }
    );
};
export const gotoRenewedOrder = (
    navigate,
    productId,
    opportunityId,
    clientName,
    renewDate
) => {
    navigate(
        ROUTES.renewedOrderFragment(
            productId,
            opportunityId,
            clientName,
            renewDate
        )
    );
};
export const gotoCancelledOrder = (
    navigate,
    productId,
    opportunityId,
    clientName,
    cancellationDate
) => {
    navigate(
        ROUTES.cancelledOrderFragment(
            productId,
            opportunityId,
            clientName,
            cancellationDate
        ),
        { replace: true }
    );
};
