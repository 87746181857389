// environment MUST be imported first for mocking and webpack dynamic loading to work
import "./environment";
import React from "react";
import ReactDOM from "react-dom";
import ViewpointApp from "./components/viewpoint/ViewpointApp";
import NotSupportedApp from "./components/error/NotSupportedApp";
import SSO from "./components/common/SSO";
import { NON_SSO_PREFIXES } from "./Routes";

import "bootstrap/dist/css/bootstrap.css";
import "appkit-react/style/appkit-react.default.css";
import "./index.css";

import reportWebVitals from "./reportWebVitals";

let rootElement = <SSO bypass={false} />;
// do all the SSO redirects BEFORE routing

// the react app is served from two different locations:
//  /viewpointorderform  => viewpoint only
// initially, DoD will be served from /dod
//  /dod                 => dod
// once PoD is added, we will serve all products from /productlicensing
//  /productlicensing    => all other products:  PoD, DoD, etc.

// apps that are served from a different root URI go here
if (window.location.pathname.startsWith("/viewpointorderform")) {
    rootElement = <ViewpointApp />;
} else {
    rootElement = <NotSupportedApp />;
}

// routes that are not behind SSO go here
for (let prefix of NON_SSO_PREFIXES) {
    if (window.location.hash.startsWith(prefix)) {
        rootElement = <SSO bypass={true} />;
        break;
    }
}

// appkit-react/lib/Select/Select gives errors when using StrictMode  =(
// Warning: Legacy context API has been detected within a strict-mode tree.
// Warning: Using UNSAFE_componentWillReceiveProps in strict mode is not recommended and may indicate bugs in your code. See https://reactjs.org/link/unsafe-component-lifecycles for details.
// Warning: findDOMNode is deprecated in StrictMode. findDOMNode was passed an instance of SelectList which is inside StrictMode. Instead, add a ref directly to the element you want to reference. Learn more about using refs safely here: https://reactjs.org/link/strict-mode-find-node
ReactDOM.render(
    //    <React.StrictMode>
    rootElement,
    //    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
