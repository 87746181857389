const AccountInfo = (props) => {
    const { clientAccount } = props;

    return (
        <div className="row location-icon">
            <span className="col-account-icon d-flex justify-content-center align-items-start">
                <span className="appkiticon icon-location-outline a-font-32 a-text-yellow"></span>
            </span>
            <span className="col-account-text half-bold g-0">
                <div className="text-14">{clientAccount.name}</div>
                <div className="light-gray-text-12">
                    {clientAccount.address}
                </div>
                <div className="light-gray-text-12">
                    {clientAccount.city}, {clientAccount.state}{" "}
                    {clientAccount.zip}
                </div>
                <div className="light-gray-text-12">
                    {clientAccount.country}
                </div>
            </span>
        </div>
    );
};

export default AccountInfo;
