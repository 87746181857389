import WrappedSelect from "./../common/WrappedSelect";
import SelectOption from "appkit-react/lib/Select/SelectOption";
import { PRODUCTS } from "../products/Config";

const ProductSelect = (props) => {
    const options = [];
    for (const [key, value] of Object.entries(PRODUCTS)) {
        if (value.id === PRODUCTS.POD.id) {
            options.push(
                <SelectOption key={key} value={value.id}>
                    {value.label}
                </SelectOption>
            );
        }
    }

    return (
        <WrappedSelect
            name={props.name}
            label={props.label}
            placeholder={props.placeholder}
            hasError={props.hasError}
            errorMessage={props.errorMessage}
            options={options}
            onChange={props.onChange}
            value={props.value}
        />
    );
};

export default ProductSelect;
