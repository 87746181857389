import { formatDate } from "../../../../common/Util";

const AFSSummary = ({ formData }) => {
    return (
        <>
            <div className="gray-text-12 m-b-12 m-t-32">AFS APPROVAL</div>
            <div className="round-card">
                <div className="row">
                    <span className="col-account-icon d-flex justify-content-center align-items-start">
                        <span className="appkiticon icon-calendar-accepted-outline a-font-32 a-text-tangerine"></span>
                    </span>
                    <span className="col-account-text half-bold g-0">
                        <div className="gray-text-14">{formData.afsNumber}</div>
                        <div className="light-gray-text-12 p-t-2">
                            {formatDate(formData.afsApprovalDate)}
                        </div>
                    </span>
                </div>
            </div>
        </>
    );
};

export default AFSSummary;
