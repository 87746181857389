const LoadingOrDisplay = (props) => {
    return (
        <>
            {props.loading ? (
                <span className="a-loading a-primary a-loading-14"></span>
            ) : (
                props.display
            )}
        </>
    );
};

export default LoadingOrDisplay;
