import Checkbox from "appkit-react/lib/Checkbox/Checkbox";
import Button from "appkit-react/lib/Button/Button";
import LoadingDark from "appkit-react/style/images/loading-dark.svg";
import DisclosureChecklistPricing from "./DisclosureChecklistPricing";
import ViewpointPricing from "./ViewpointPricing";
import Error from "../../common/Error";
import "./RightColumn.css";

import TermsConditionsAgree from "./TermsConditionsAgree";
import { UNITED_STATES } from "../Constants";
import TermsConditionsAgreeUS from "./TermsConditionsAgreeUS";
import TCApprover from "./TCApprover";

const LeftColumn = (props) => {
    return (
        <div>
            <div className="m-t-40">
                Please choose the product(s) you would like to license:
            </div>
            <div className="m-v-32 light-gray-text-14">
                Disclosure Checklist subscribers can access specific standard
                guidance within Viewpoint, via links, if they subscribe to both
                products.
            </div>
            <div className="separator-line"></div>
            <div className="d-flex justify-content-start">
                <div className="m-checkbox">
                    <Checkbox
                        name="orderViewpoint"
                        onChange={(e) =>
                            props.handleChange("orderViewpoint")(
                                e.target.checked
                            )
                        }
                        checked={props.formData.orderViewpoint}
                        disabled={!props.formState.theatre}
                    />
                </div>
                <div>
                    <h3>Viewpoint</h3>
                    <div className="light-gray-text-14">
                        Accounting & auditing research at your fingertips
                    </div>
                </div>
            </div>
            <div
                className={
                    props.formData.orderViewpoint
                        ? "fade-section expanded"
                        : "fade-section collapsed"
                }
            >
                <ViewpointPricing props={props} />
            </div>
            <div className="separator-line m-t-32"></div>
            <div className="d-flex justify-content-start">
                <div className="m-checkbox">
                    <Checkbox
                        name="orderDisclosureChecklist"
                        onChange={(e) =>
                            props.handleChange("orderDisclosureChecklist")(
                                e.target.checked
                            )
                        }
                        checked={props.formData.orderDisclosureChecklist}
                        disabled={!props.formState.theatre}
                    />
                </div>
                <div>
                    <h3>Disclosure Checklist</h3>
                    <div className="light-gray-text-14">
                        The cornerstone of quality financial reporting
                    </div>
                </div>
            </div>
            <div
                className={
                    props.formData.orderDisclosureChecklist
                        ? "fade-section expanded"
                        : "fade-section collapsed"
                }
            >
                <DisclosureChecklistPricing props={props} />
            </div>
            <div className="separator-line m-t-32"></div>
            <div
                className={
                    props.formData.orderDisclosureChecklist ||
                    props.formData.orderViewpoint
                        ? "fade-section expanded"
                        : "fade-section collapsed"
                }
            >
                {props.formData.orderViewpoint &&
                    props.formState.billingTerritory !== UNITED_STATES && (
                        <TermsConditionsAgree props={props} />
                    )}
                {props.formState.billingTerritory === UNITED_STATES && (
                    <>
                        <TCApprover
                            handleChange={props.handleChange}
                            formData={props.formData}
                            validationErrors={props.validationErrors}
                        />
                        <TermsConditionsAgreeUS />
                    </>
                )}
                <div className="d-flex justify-content-end align-items-center m-b-32 m-t-12">
                    <Error
                        hasError={props.validationErrors.nonZeroOrder.error}
                        errorMessage={
                            props.validationErrors.nonZeroOrder.message
                        }
                        className={"m-v-2"}
                    />
                    <Button
                        size={"xl"}
                        kind={"primary"}
                        onClick={props.submit}
                        disabled={props.submitting}
                        className="submit-button"
                    >
                        {props.submitting ? (
                            <img
                                src={LoadingDark}
                                className="a-loading dark-spinner"
                                alt="busy spinner"
                            />
                        ) : (
                            "SUBMIT"
                        )}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default LeftColumn;
