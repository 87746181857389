import {
    DOMAIN_REGEX,
    EMAIL_REGEX,
    getAllIndices,
    getDomain,
    isEmail
} from "../../../../common/Util";
import { PRODUCTS } from "../../../Config";
import { NEW_ORDER_DATA, CLIENT_EMAIL } from "../../../Constants";
import { areDomainsUnique } from "../../../service/External";
import { VALIDATION_TYPE } from "../../common/Validation";

const contactValidations = {
    clientFirstName: {
        type: VALIDATION_TYPE.REQUIRED,
        errorMessage: "Please enter a first name."
    },
    clientLastName: {
        type: VALIDATION_TYPE.REQUIRED,
        errorMessage: "Please enter a last name."
    },
    clientEmail: {
        type: VALIDATION_TYPE.PATTERN,
        pattern: EMAIL_REGEX,
        errorMessage: "Please enter a valid email address."
    },
    // index = 0 => auto-populate domain name = 0
    // index > 0 => ensure there exists a corresponding domain name > 0
    clientEmail_1: {
        type: VALIDATION_TYPE.CUSTOM,
        errorMessage:
            "This domain name is not listed in the Allowed domain names below.",
        callback: (formData, fieldValue, index) => {
            if (index !== 0 && isEmail(fieldValue)) {
                const domain = getDomain(fieldValue);
                const duplicateIndices = getAllIndices(
                    formData.clientDomainName,
                    domain
                );
                if (duplicateIndices.length < 1) {
                    return true;
                }
            }
            return false;
        }
    },
    clientTitle_1: {
        type: VALIDATION_TYPE.CUSTOM,
        errorMessage: "Please enter contract signatory's job title.",
        callback: (formData, fieldValue, index) => {
            if (index === 0) {
                return fieldValue ? false : true;
            } else {
                return false;
            }
        }
    }
};

export const domainValidations = {
    clientDomainName: {
        type: VALIDATION_TYPE.PATTERN,
        pattern: DOMAIN_REGEX,
        errorMessage: "Please enter a vaild domain name."
    },
    // duplicate domain check: front end
    //   check that the user-entered domain names are unique
    clientDomainName_1: {
        type: VALIDATION_TYPE.CUSTOM,
        errorMessage: "Please enter a unique domain name.",
        callback: (formData, fieldValue, index) => {
            for (let i = 0; i < formData.clientDomainName.length; ++i) {
                if (i === index) continue;
                if (fieldValue === formData.clientDomainName[i]) return true;
            }
            return false;
        }
    },
    // duplicate domain check: back end
    //   check that there does not exist an existing order with any of the user-entered domain names
    //   without the key property, React complains:
    //     Warning: Each child in a list should have a unique "key" prop.
    clientDomainName_2: {
        type: VALIDATION_TYPE.CUSTOM,
        errorMessage: (
            <div key="exists">
                A client with this domain name already exists. If you are trying
                to add additional users to an existing client, please click{" "}
                <a
                    href="https://bit.ly/2JiBl70"
                    target="_blank"
                    rel="noreferrer"
                >
                    here
                </a>
                .
            </div>
        ),
        callback: (formData, fieldValue, index) => {
            // always return false here, this validation runs once in validate() and not once per domain field
            return false;
        }
    }
};

const allValidations = { ...contactValidations, ...domainValidations };
// add multiple validations per field, ending in _x
const allFields = {
    ...NEW_ORDER_DATA,
    clientEmail_1: "",
    clientDomainName_1: "",
    clientDomainName_2: "",
    clientTitle_1: ""
};
// copy initialFormData:
//   for each field, replace value="" with { error: undefined, message: validations.<>.errorMessage }
// note that error has 3 states:
//   {undefined, true, false} => {hide error message, fade in error message, fade out error message}
export const initialValidationErrors = Object.fromEntries(
    Object.entries(allFields).map(([key, value]) => [
        key,
        { error: [undefined], message: allValidations[key]?.errorMessage }
    ])
);

export const validate = (
    productId,
    formData,
    validationErrors,
    setValidationErrors,
    maxContactIndex,
    maxDomainIndex,
    emailOnly,
    setDomainValidating,
    successCallback,
    errorCallback
) => {
    const validContact = _validate(
        productId,
        formData,
        contactValidations,
        validationErrors,
        setValidationErrors,
        maxContactIndex,
        emailOnly
    );

    const validDomain = _validate(
        productId,
        formData,
        domainValidations,
        validationErrors,
        setValidationErrors,
        maxDomainIndex,
        false
    );
    const valid = validContact && validDomain;
    if (productId !== PRODUCTS.POD.id) {
        if (valid) successCallback();
    } else {
        const callback = (success, otherFieldsAreValid, result) => {
            if (success) {
                const domainIsUnique = result.results[0];
                let allDomainsAreUnique = true;
                // set field validation errors
                for (let i = 0; i < formData.clientDomainName.length; ++i) {
                    const domain = formData.clientDomainName[i];
                    if (
                        domainIsUnique[domain.toLowerCase()] !== undefined &&
                        !domainIsUnique[domain.toLowerCase()]
                    ) {
                        validationErrors.clientDomainName_2.error[i] = true;
                        allDomainsAreUnique = false;
                    }
                }
                setDomainValidating(false);
                if (otherFieldsAreValid && allDomainsAreUnique) {
                    successCallback();
                }
            } else {
                errorCallback();
            }
        };

        if (formData.clientDomainName.length > 0) {
            setDomainValidating(true);
            areDomainsUnique(formData.clientDomainName, valid, callback);
        }
    }
};

const _validate = (
    productId,
    formData,
    validations,
    validationErrors,
    setValidationErrors,
    maxIndex,
    emailOnly
) => {
    let hasError = false;
    for (let index = 0; index < maxIndex; ++index) {
        for (const _field in validations) {
            // only run the _x validations for POD
            if (productId !== PRODUCTS.POD.id && _field.indexOf("_") >= 0) {
                continue;
            }

            const field =
                _field.indexOf("_") >= 0
                    ? _field.substring(0, _field.indexOf("_"))
                    : _field;
            // special case for additional contacts that only have an email
            if (emailOnly && index > 0 && field !== CLIENT_EMAIL) continue;

            const result = validationErrors[_field];
            const fieldValue = formData[field][index];
            switch (validations[_field].type) {
                // field must be truthy
                case VALIDATION_TYPE.REQUIRED: {
                    result.error[index] = fieldValue?.trim() ? false : true;
                    break;
                }
                // field is required to match a pattern
                case VALIDATION_TYPE.PATTERN: {
                    const pattern = validations[field].pattern;
                    const hasError = !pattern.test(fieldValue);
                    result.error[index] = hasError ? true : false;
                    break;
                }
                // run the callback function to determine if there is a validation error
                case VALIDATION_TYPE.CUSTOM: {
                    result.error[index] = validations[_field].callback(
                        formData,
                        fieldValue,
                        index
                    );
                    break;
                }

                default:
                    break;
            }
            hasError = hasError || result.error[index];
        }
    }
    setValidationErrors({ ...validationErrors });
    return !hasError;
};
