import "./FullScreenModal.css";

const FullScreenModal = (props) => {
    return (
        <div className="full-screen-modal">
            <div className="full-screen-modal-content">
                <span className="a-loading a-primary loading-large"></span>
                <div>{props.content}</div>
            </div>
        </div>
    );
};

export default FullScreenModal;
