import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Checkbox from "appkit-react/lib/Checkbox/Checkbox";
import Button from "appkit-react/lib/Button/Button";

import { submitDraftOrder } from "../../../service/External";
import { gotoError, gotoSubmittedOrder } from "../../../../../Routes";

const Buttons = (props) => {
    const { opportunityId, product, gotoSummary } = props;
    const [checked1, setChecked1] = useState(false);
    const [checked2, setChecked2] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const navigate = useNavigate();

    const submitOrder = () => {
        const callback = (success, data) => {
            if (success) {
                gotoSubmittedOrder(
                    navigate,
                    product.id,
                    opportunityId,
                    data[0]?.PartnerLed_Client_Entity_Name__c
                );
            } else {
                gotoError(navigate);
            }
        };

        setSubmitting(true);
        submitDraftOrder(opportunityId, callback);
    };

    const cancelSubmit = () => {
        gotoSummary();
    };

    return (
        <div className="flex-justify-center flex-column p-b-80">
            <div className="reset-checkbox w-400 m-t-36">
                <Checkbox
                    defaultChecked={checked1}
                    onChange={() => setChecked1(!checked1)}
                    disabled={submitting}
                >
                    <p className=" clear-margin-bottom">
                        I confirm an EAF request has been approved for this
                        client.
                    </p>
                </Checkbox>
            </div>
            <div className="reset-checkbox w-400 m-t-40">
                <Checkbox
                    defaultChecked={checked2}
                    onChange={() => setChecked2(!checked2)}
                    disabled={submitting}
                >
                    <p className=" clear-margin-bottom">
                        I acknowledge the information entered is correct and
                        understand an email will be sent to the client
                        signatory.
                    </p>
                </Checkbox>
            </div>
            <div className="nav-buttons m-t-48">
                <Button
                    kind="secondary"
                    onClick={() => cancelSubmit()}
                    disabled={submitting}
                >
                    CANCEL
                </Button>
                <Button
                    kind="primary"
                    disabled={!checked1 || !checked2 || submitting}
                    onClick={() => submitOrder()}
                    isLoading={submitting}
                    isLoadingBlocking={true}
                >
                    SUBMIT
                </Button>
            </div>
        </div>
    );
};

export default Buttons;
