import React from "react";
import "./LabelErrorWrapper.css";

const Error = (props) => {
    const errorClasses = ["a-form-error", props.className];
    const hasError = props.hasError;
    if (hasError === undefined) errorClasses.push("error");
    if (hasError === false) errorClasses.push("error hide");
    if (hasError === true) errorClasses.push("error show");

    return (
        <div className={errorClasses.join(" ")}>{props.errorMessage ? props.errorMessage : "\u00A0"}</div >
    );
}

export default Error;