import { CONTRACT_TYPE } from "../../Constants";
const DOD = {
    [CONTRACT_TYPE.fourtyFiveDays]: {
        preText: "I confirm I have read and agree to the terms in the ",
        lastText: " on behalf of the organization.",
        url: "https://productcentral.products.pwc.com/legal/Hosted-Software-Terms"
    },
    [CONTRACT_TYPE.ninetyDays]: {
        preText: "I confirm I have read and agree to the terms in the ",
        lastText: "on behalf of the organization.",
        url: "https://productcentral.products.pwc.com/legal/Hosted-Software-Terms"
    },
    [CONTRACT_TYPE.twelveMonths]: {
        preText: "I confirm I have read and agree to the terms in the ",
        lastText: " on behalf of the organization.",
        url: "https://productcentral.products.pwc.com/legal/Hosted-Software-Terms"
    }
};
export default DOD;
