import LoadingOrDisplay from "../../../../../../common/LoadingOrDisplay";
import { formatNumber } from "../../../../../../common/Util";

const RenewalTerm = ({ props, loading }) => {
    const { formData, formState } = props;

    return (
        <div>
            <div className="round-card-black-top">
                <div className="p-b-16">
                    <span className="appkiticon icon-transform-outline a-font-24 a-text-red p-r-16"></span>
                    <span className="text-16 bold">Renewal Term</span>
                </div>
                <div className="light-gray-text-12">
                    <div className="row g-0">
                        <div className="col-9 m-v-8 gy-0">
                            Downloadable Assets - {formData.serviceModule}{" "}
                            Module
                        </div>
                        <div className="col-3 m-v-8 gy-0 right">
                            <LoadingOrDisplay
                                loading={loading}
                                display={formatNumber(
                                    formState.renewalTermTotal
                                )}
                            />
                        </div>
                    </div>
                    <div className="row g-0">
                        <div className="col-9 m-v-8 gy-0">
                            Downloadable Assets - General Utility
                        </div>
                        <div className="col-3 m-v-8 gy-0 right">Included</div>
                    </div>
                    <div className="row g-0">
                        <div className="col-9 m-v-8 gy-0">
                            Cloud-Based Assets - {formData.serviceModule}
                        </div>
                        <div className="col-3 m-v-8 gy-0 right">Included</div>
                    </div>
                </div>
            </div>
            <div className="black-background-rounded">
                <div className="row">
                    <span className="col">Subtotal before tax</span>
                    <span className="col right">
                        <LoadingOrDisplay
                            loading={loading}
                            display={formatNumber(formState.renewalTermTotal)}
                        />{" "}
                        USD
                    </span>
                </div>
            </div>
        </div>
    );
};

export default RenewalTerm;
