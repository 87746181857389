export const ContactUsLink = (props) => {
    const { email } = props;
    return (
        <a
            className="underline orange"
            href={`mailto:${email}`}
            rel="noopener noreferrer"
            target="_blank"
        >
            contact us
        </a>
    );
};
