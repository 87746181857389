export const calculate = (quantity, contentAreaPricing) => {
    if (!contentAreaPricing) return 0;

    contentAreaPricing.sort((a, b) => {
        return a.maxUnits - b.maxUnits;
    });

    let total = 0, totalUnits = 0, remaining = quantity;
    for (const priceTier of contentAreaPricing) {
        if (remaining <= (priceTier.maxUnits - totalUnits)) {
            total += (remaining * priceTier.unitPrice);
            break;
        } else {
            const unitsRemainingForThisTier = (priceTier.maxUnits - totalUnits)
            total += (unitsRemainingForThisTier * priceTier.unitPrice);
            totalUnits = priceTier.maxUnits;
            remaining -= unitsRemainingForThisTier;
        }
    }
    return total;
}
