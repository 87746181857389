import { useState } from "react";
import { Checkbox } from "appkit-react/lib/Checkbox";

import InitialTerm from "./InitialTerm";
import Select from "../../Select";
import {
    buildInitialValidationErrors,
    VALIDATION_TYPE
} from "../../../../common/Validation";
import ContractWrapper from "../ContractWrapper";
import WaiveModal from "./WaiveModal";
import RenewalTerm from "./RenewalTerm";
import { NEW_ORDER_DATA } from "../../../../../Constants";

const Contract = ({ props }) => {
    const {
        formData,
        formState,
        setFormState,
        handleChange,
        buttonActions,
        buttonLabels
    } = props;

    const validations = {
        contractType: {
            type: VALIDATION_TYPE.REQUIRED,
            errorMessage: "Please select a contract type."
        },
        serviceModule: {
            type: VALIDATION_TYPE.REQUIRED,
            errorMessage: "Please select a service module."
        },
        clientTier: {
            type: VALIDATION_TYPE.REQUIRED,
            errorMessage: "Please select a client tier."
        }
    };

    const [validationErrors, setValidationErrors] = useState(
        buildInitialValidationErrors(validations, NEW_ORDER_DATA)
    );

    const [loading, setLoading] = useState(false);

    const dependencies = [
        formData.contractType,
        formData.serviceModule,
        formData.clientTier,
        formData.waiveEnablementFee
    ];
    const haveData =
        formData.contractType && formData.serviceModule && formData.clientTier;

    const getPricingParams = {
        productFamily: props.product.id,
        term: formData.contractType,
        module: formData.serviceModule,
        tier: formData.clientTier,
        waiveEnablementFee: formData.waiveEnablementFee
    };

    const setPricingOptions = (result) => {
        setFormState((current) => {
            return {
                ...current,
                oneTimeFees: result.oneTimeFees,
                annualFees: result.annualFees,
                renewalAnnualFees: result.renewalAnnualFees,
                initialTermTotal: result.oneTimeFees + result.annualFees,
                renewalTermTotal: result.renewalAnnualFees,
                selectedProductId: result.productId,
                selectedRenewalProductId: result.renewalProductId,
                selectedWaiveEnablementProdId: result.waiveEnablementProdId
            };
        });
    };

    const [visible, setVisible] = useState(false);
    const showWaiveModal = () => {
        setVisible(true);
    };
    const hideWaiveModal = () => {
        setVisible(false);
    };
    const showRenewalTerm = formState.renewalTermTotal > 0;
    const content = (
        <>
            <div>
                <Select
                    primary={true}
                    label="CONTRACT TYPE"
                    options={formState.pricingOptions.terms}
                    value={formData.contractType}
                    onChange={handleChange("contractType")}
                    errorMessage={validationErrors.contractType.message}
                    hasError={validationErrors.contractType.error}
                />
                <div className="text-14 p-b-16">
                    <Checkbox
                        name="autoRenew"
                        checked={formData.autoRenew}
                        onChange={(e) =>
                            handleChange("autoRenew")(e.target.checked)
                        }
                    >
                        Renew automatically when the annual subscription ends.
                    </Checkbox>
                </div>
                <Select
                    primary={true}
                    label="SERVICE MODULE"
                    options={formState.pricingOptions.serviceModules}
                    value={formData.serviceModule}
                    onChange={handleChange("serviceModule")}
                    errorMessage={validationErrors.serviceModule.message}
                    hasError={validationErrors.serviceModule.error}
                />
                <Select
                    primary={true}
                    label="CLIENT TIER"
                    options={formState.pricingOptions.clientTiers}
                    value={formData.clientTier}
                    onChange={handleChange("clientTier")}
                    errorMessage={validationErrors.clientTier.message}
                    hasError={validationErrors.clientTier.error}
                />
            </div>
            {haveData && (
                <div>
                    <WaiveModal
                        visible={visible}
                        hideWaiveModal={hideWaiveModal}
                        formData={formData}
                        handleChange={handleChange}
                        product={props.product}
                    />
                    <div className="gray-text-12 p-v-8">ORDER TERMS</div>
                    <div className="round-card-black">
                        <InitialTerm
                            props={props}
                            showWaiveModal={showWaiveModal}
                            showRenewalTerm={showRenewalTerm}
                            loading={loading}
                        />
                        {showRenewalTerm && (
                            <RenewalTerm props={props} loading={loading} />
                        )}
                    </div>
                </div>
            )}
        </>
    );

    return (
        <ContractWrapper
            formData={formData}
            buttonActions={buttonActions}
            buttonLabels={buttonLabels}
            validations={validations}
            validationErrors={validationErrors}
            setValidationErrors={setValidationErrors}
            haveData={haveData}
            dependencies={dependencies}
            getPricingParams={getPricingParams}
            setPricingOptions={setPricingOptions}
            loading={loading}
            setLoading={setLoading}
            content={content}
        />
    );
};

export default Contract;
