import { PRODUCTS } from "../products/Config";

export const MESSAGES = {
    maintenance: "Friday, August 28, 2020 at 5:00 AM EST"
};

export const EXTERNAL_URLS = {
    supportHome:
        "https://wwwpwcnetwork.pwc.myshn.net/hub?id=sc_cat_item&sys_id=3f1dd0320a0a0b99000a53f7604a2ef9&channel=5166dcdddba16384a416f5261d961957&shn-direct",
    gCRMbaseURL: "https://lightningcrm.pwc.myshn.net/",
    taFail: "https://docs.google.com/document/d/1YlUDaUbnPHwVkt9WdMMs-plAfg17ZntEm1wbn8hkNdY/edit#bookmark=id.ppgpwdf7q3bq",
    taSecondary:
        "https://docs.google.com/document/d/1YlUDaUbnPHwVkt9WdMMs-plAfg17ZntEm1wbn8hkNdY/edit#bookmark=id.1fpkh8c49idd",
    taCES: "https://ces.rni.pwcinternal.com/",
    taNotSupported:
        "https://docs.google.com/document/d/1YlUDaUbnPHwVkt9WdMMs-plAfg17ZntEm1wbn8hkNdY/edit#bookmark=id.saa8q1nerloj",
    taAFSNotification:
        "https://docs.google.com/document/d/1YlUDaUbnPHwVkt9WdMMs-plAfg17ZntEm1wbn8hkNdY/edit#bookmark=id.8rjjaewofwst",
    afsMoreInformation:
        "https://docs.google.com/document/d/1YlUDaUbnPHwVkt9WdMMs-plAfg17ZntEm1wbn8hkNdY/edit#bookmark=id.qqt0vjk3t4t9",
    gotoAFS: "https://pwc.my.salesforce.com/",
    addMoreUsers: "https://bit.ly/2JiBl70"
};

const ACKNOWLEDGE_WORKFORCE_TEXT =
    "I acknowledge the information entered is correct and understand " +
    "an email will be sent to the Product Support Team to obtain EAF approval immediately.";

export const ACKNOWLEDGE_TEXT = {
    [PRODUCTS.DOD.id]:
        "I acknowledge the information entered is correct and understand " +
        "an email will be sent to the Deals Desk to obtain EAF approval immediately.",
    [PRODUCTS.WFO.id]: ACKNOWLEDGE_WORKFORCE_TEXT,
    [PRODUCTS.CN.id]: ACKNOWLEDGE_WORKFORCE_TEXT,
    [PRODUCTS.LISTEN.id]: ACKNOWLEDGE_WORKFORCE_TEXT,
    [PRODUCTS.POD.id]:
        "I acknowledge the information entered is correct and understand " +
        "an email will be sent to the contract signatory immediately."
};

const EDIT_SUMMARY_WORKFORCE_TEXT =
    "An email with the updated contract will be sent to the Product Support Team to obtain EAF approval. The original contract will be invalidated.";

export const EDIT_SUMMARY_TEXT = {
    [PRODUCTS.DOD.id]:
        "An email with the updated contract will be sent to the Deals Desk to obtain EAF approval. The original contract will be invalidated.",
    [PRODUCTS.WFO.id]: EDIT_SUMMARY_WORKFORCE_TEXT,
    [PRODUCTS.CN.id]: EDIT_SUMMARY_WORKFORCE_TEXT,
    [PRODUCTS.LISTEN.id]: EDIT_SUMMARY_WORKFORCE_TEXT
};

export const NUMBER_TO_WORD = [
    "zero",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten"
];
