export const USER = {
    name: "",
    email: "",
    guid: ""
};

// all other mock objects are scoped to the window object
// this is an exception as SSO is not configured locally
export const MOCK_USER = {
    name: "Local User",
    email: "local@user.com",
    guid: "luser007"
};

// holds data that is entered by the user
export const NEW_ORDER_DATA = {
    // acceptance \\
    salesforceURL: "",
    afsCompleted: "",
    afsNumber: "",
    afsApprovalDate: null,
    // client \\
    // client contact fields, index 0 is the signatory, the rest are additional contacts
    clientFirstName: [],
    clientLastName: [],
    clientEmail: [],
    clientTitle: [],
    clientPhone: [],
    // 1 or more
    clientDomainName: [],
    // order \\
    // dod
    contractType: "",
    serviceModule: "",
    clientTier: "",
    autoRenew: true,
    waiveEnablementFee: false,
    // cn
    subscriptionTier: "",
    subscriptionTerm: "",
    // wfo
    numberOfUsers: 1,
    communicationChannel: "",
    // contact \\
    requiredContacts: {},
    nonEditableContacts: {},
    additionalContacts: {},
    additionalContactsSelect: []
};

// when additional contacts only have an email field, validate CLIENT_EMAIL
// the value needs to match the field name in NEW_ORDER_DATA
export const CLIENT_EMAIL = "clientEmail";

// holds front end state
export const NEW_ORDER_STATE = {
    // acceptance //
    lookupMessage: "",
    clientAccount: null,
    clientOpportunity: null,
    // order //
    pricingOptions: null,
    oneTimeFees: "",
    annualFees: "",
    renewalAnnualFees: "",
    initialTermTotal: "",
    renewalTermTotal: "",
    selectedProductId: "",
    selectedRenewalProductId: "",
    selectedWaiveEnablementProdId: ""
};

// acceptance view - states returned from opportunity lookup
export const LOOKUP_MESSAGE_STATES = {
    NOT_FOUND: "NOT_FOUND",
    TA_FAIL: "TA_FAIL",
    TA_ERROR: "TA_ERROR",
    AFS_NOTIFICATION_REQUIRED: "AFS_NOTIFICATION_REQUIRED",
    AFS_INFORMATION_REQUIRED: "AFS_INFORMATION_REQUIRED",
    PASS_NA: "PASS_NA",
    SECONDARY_NA: "SECONDARY_NA",
    NOT_SUPPORTED: "NOT_SUPPORTED",
    // not returned from opportunity lookup, go here after entering AFS info
    PASS_NO_BANNER: "PASS_NO_BANNER"
};

// order terms view - states for each product
export const ORDER_TERMS = {
    SUBSCRIPTION: 0,
    DOD: 1,
    CN: 2,
    WFO: 3,
    LISTEN: 4,
    POD: 5
};

// contact view - required and additional contacts
// if salesforceViewField is not present, assume that submit and view use the same field name
export const PRODUCT_STAKEHOLDERS = {
    GLOBAL_RELATIONSHIP_PARTNER: {
        id: "GLOBAL_RELATIONSHIP_PARTNER",
        label: "Global Relationship Partner",
        salesforceField: "globalRelationshipPartner"
    },
    ENGAGEMENT_LEADER: {
        id: "ENGAGEMENT_LEADER",
        label: "Engagement Leader",
        salesforceField: "engagementLeader"
    },
    ENGAGEMENT_MANAGER: {
        id: "ENGAGEMENT_MANAGER",
        label: "Engagement Manager",
        salesforceField: "engagementManager"
    },
    SALES_AMBASSADOR: {
        id: "SALES_AMBASSADOR",
        label: "Sales Ambassador",
        salesforceField: "salesAmbssador"
    },
    PRODUCT_LEADER: {
        id: "PRODUCT_LEADER",
        label: "Product Leader",
        salesforceField: "productLeader"
    },
    DEALS_DESK: {
        id: "DEALS_DESK",
        label: "Deals Desk",
        salesforceField: "dealsDesk"
    },
    CUSTOMER_SUCCESS: {
        id: "CUSTOMER_SUCCESS",
        label: "Customer Success",
        salesforceField: "customerSuccess"
    },
    CENTRALIZED_BILLING_TEAM: {
        id: "CENTRALIZED_BILLING_TEAM",
        label: "Centralized Billing Team",
        salesforceField: "centralizedBillingTeam"
    },
    CC_RECIPIENT: {
        id: "CC_RECIPIENT",
        label: "Cc Recipient",
        salesforceField: "addCC",
        salesforceViewField: "ccRecepient"
    },
    GENERAL_MANAGER: {
        id: "GENERAL_MANAGER",
        label: "General Manager",
        salesforceField: "generalManager"
    },
    LEAD_CLIENT_PARTNER: {
        id: "LEAD_CLIENT_PARTNER",
        label: "Lead Client Partner",
        salesforceField: "leadClientPartner"
    },
    CLIENT_RELATIONSHIP_EXECUTIVE: {
        id: "CLIENT_RELATIONSHIP_EXECUTIVE",
        label: "Client Relationship Executive",
        salesforceField: "clientRelationshipExecutive"
    },
    CLIENT_RELATIONSHIP_PARTNER: {
        id: "CLIENT_RELATIONSHIP_PARTNER",
        label: "Client Relationship Partner",
        salesforceField: "clientRelationshipPartner"
    },
    LEAD_TAX_PARTNER: {
        id: "LEAD_TAX_PARTNER",
        label: "Lead Tax Partner",
        salesforceFields: {
            fullName: "leadTaxPtrName",
            email: "leadTaxPtrEmail",
            region: "leadTaxPtrRegion",
            market: "leadTaxPtrMarket"
        }
    },
    LEAD_TAX_DIRECTOR: {
        id: "LEAD_TAX_DIRECTOR",
        label: "Lead Tax Director",
        salesforceFields: {
            fullName: "leadTaxDirName",
            email: "leadTaxDirEmail"
        }
    }
};

// submit new afs view
export const AFS_DATA = {
    afsNumber: "",
    afsApprovalDate: null
};
