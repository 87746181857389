import { CONTRACT_TYPE } from "../../Constants";
const CN = {
    [CONTRACT_TYPE.v1ContractCN]: {
        preText:
            "This Order incorporates the following documents by reference: (a) the PricewaterhouseCoopers Standard Hosted Product License Terms and Conditions at ",
        lastText:
            " (the “Standard Terms”); and (b) documents incorporated into the Standard Terms by reference, including the Change Navigator Offering Overview, Change Navigator Maintenance and Support terms, and the Change Navigator Data Processing Addendum, each available as described in the Standard Terms.",
        url: "https://productcentral.products.pwc.com/legal/Hosted-Software-Terms"
    },
    [CONTRACT_TYPE.v2ContractCN]: {
        preText:
            "This Order incorporates the following documents by reference: (a) the PricewaterhouseCoopers Standard Hosted Product License Terms and Conditions at ",
        lastText:
            " (the “Standard Terms”); and (b) documents incorporated into the Standard Terms by reference, including the Change Navigator Offering Overview, Change Navigator Maintenance and Support terms, and the Change Navigator Data Processing Addendum, each available as described in the Standard Terms.",
        url: "https://productcentral.products.pwc.com/legal/Hosted-Software-Terms"
    },
    [CONTRACT_TYPE.v3ContractCN]: {
        preText:
            "This Order incorporates the following documents by reference: (a) the PricewaterhouseCoopers Standard Hosted Product License Terms and Conditions at ",
        lastText:
            " (the “Standard Terms”); and (b) documents incorporated into the Standard Terms by reference, including the Change Navigator Offering Overview, Change Navigator Maintenance and Support terms, and the Change Navigator Data Processing Addendum, each available as described in the Standard Terms.",
        url: "https://productcentral.products.pwc.com/legal/Hosted-Software-Terms"
    },
    [CONTRACT_TYPE.v4ContractCN]: {
        preText:
            "This Order incorporates the following documents by reference: (a) the PricewaterhouseCoopers Standard Hosted Product License Terms and Conditions at ",
        lastText:
            " (the “Standard Terms”); and (b) documents incorporated into the Standard Terms by reference, including the Change Navigator Offering Overview, Change Navigator Maintenance and Support terms, and the Change Navigator Data Processing Addendum, each available as described in the Standard Terms.",
        url: "https://productcentral.products.pwc.com/legal/Hosted-Software-Terms"
    },
    [CONTRACT_TYPE.v5ContractCN]: {
        preText:
            "This Order incorporates the following documents by reference: (a) the PricewaterhouseCoopers Standard Hosted Product License Terms and Conditions at ",
        lastText:
            " (the “Standard Terms”); and (b) documents incorporated into the Standard Terms by reference, including the Change Navigator Offering Overview, Change Navigator Maintenance and Support terms, and the Change Navigator Data Processing Addendum, each available as described in the Standard Terms.",
        url: "https://productcentral.products.pwc.com/legal/Hosted-Software-Terms"
    }
};
export default CN;
