import { CONTRACT_TYPE } from "../../Constants";
const WFO = {
    [CONTRACT_TYPE.frontlineWorkers]: [
        {
            title: "Invoicing and Payment:",
            showList: true,
            desc: [
                "The Initial Term Annual Platform License Fee, and Per User Annual Fees for the first Minimum Commitment will be invoiced on the Effective Date.",
                "Thereafter, subsequent Annual Platform License Fees and Per End User Annual Fees for Minimum Commitments will be invoiced on or around January 1 of each year.",
                "PwC will invoice for further Additional Services Fees (including services provided on a time and materials basis) monthly in arrears.",
                "Additional User Fees will be invoiced as set forth above.",
                "Client will pay PwC amounts due within thirty (30) days after the date of invoice."
            ]
        },
        {
            title: "",
            desc: [
                "Each party hereto, intending to be legally bound by this Order and the Standard Terms, has cause this Order to be executed on its behalf by its duly authorized representative, executed this Order as of the Effective Date."
            ]
        }
    ],
    [CONTRACT_TYPE.officeWorkers]: [
        {
            title: "Invoicing and Payment:",
            showList: true,
            desc: [
                "The Initial Term Annual Platform License Fee, and Per User Annual Fees for the first Minimum Commitment will be invoiced on the Effective Date.",
                "Thereafter, subsequent Annual Platform License Fees and Per End User Annual Fees for Minimum Commitments will be invoiced on or around January 1 of each year.",
                "PwC will invoice for further Additional Services Fees (including services provided on a time and materials basis) monthly in arrears.",
                "Additional User Fees will be invoiced as set forth above.",
                "Client will pay PwC amounts due within thirty (30) days after the date of invoice."
            ]
        },
        {
            title: "",
            desc: [
                "Each party hereto, intending to be legally bound by this Order and the Standard Terms, has cause this Order to be executed on its behalf by its duly authorized representative, executed this Order as of the Effective Date."
            ]
        }
    ]
};
export default WFO;
