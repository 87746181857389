import { useNavigate, useParams } from "react-router-dom";
import { Success } from "./Success";
import orderSubmitted from "../../../../../assets/order-submitted.svg";
import { gotoViewOrder } from "../../../../../Routes";
import useProduct from "../../common/UseProduct";

const SubmittedOrder = () => {
    const product = useProduct();
    const { opportunityId, clientName } = useParams();

    const subtitle = `The ${
        product.label
    } order has successfully been submitted to ${decodeURIComponent(
        clientName
    )}.`;

    const image = { src: orderSubmitted, width: "517px" };

    const navigate = useNavigate();
    const action = {
        text: "VIEW ORDER",
        onClick: () => gotoViewOrder(navigate, product.id, opportunityId)
    };

    return (
        <Success
            title="Order submitted to client"
            subtitle={subtitle}
            description={
                "An email to complete the order will be sent to the client signatory. You will get notified via email when the client has completed the order."
            }
            image={image}
            action={action}
        />
    );
};

export default SubmittedOrder;
