import { CONTRACT_TYPE } from "../../Constants";
const LISTEN = {
    [CONTRACT_TYPE.employeeExperience]: [
        {
            title: "Term",
            text: [
                "The “Agreement” between Client and PwC means the Order and the documents incorporated by reference and described above; if there is a conflict among the provisions, this Order will prevail. The “Effective Date” of the Agreement is the date set forth above. Capitalized terms used but not defined in this Order are defined elsewhere in the Agreement.",
                "Upon expiration of the Initial Term, this Order will automatically renew for additional successive Renewal Terms of one (1) year each until a party provides the other with notice of its election not to renew at least sixty (60) days prior to the end of the then-current Initial Term or Renewal Term (as applicable).",
                "Fees may be updated for any Renewal Term upon notice of the update from PwC at least seventy (70) days prior to the end of the then-current Initial Term or Renewal Term (as applicable). "
            ]
        },
        {
            title: "Initial Term End Date",
            text: [
                "11:59pm Eastern time on the day prior to the first anniversary of the Effective Date"
            ]
        },
        {
            title: "Activation Date",
            text: ["Order Effective Date."]
        },
        {
            title: "User Accounts",
            text: [
                "Unlimited user accounts.",
                "If Single Sign-On will be used: User Accounts Access will be enabled through identity federation and a Single Sign-On process."
            ]
        },
        {
            title: "Authorized Contractor(s)",
            text: [
                "Any third party who otherwise meets the definition of Authorized Contractor in the Standard Terms, subject to all other restrictions in the Agreement."
            ]
        }
    ],
    [CONTRACT_TYPE.customerExperience]: [
        {
            title: "Term",
            text: [
                "The “Agreement” between Client and PwC means the Order and the documents incorporated by reference and described above; if there is a conflict among the provisions, this Order will prevail. The “Effective Date” of the Agreement is the date set forth above. Capitalized terms used but not defined in this Order are defined elsewhere in the Agreement.",
                "Upon expiration of the Initial Term, this Order will automatically renew for additional successive Renewal Terms of one (1) year each until a party provides the other with notice of its election not to renew at least sixty (60) days prior to the end of the then-current Initial Term or Renewal Term (as applicable).",
                "Fees may be updated for any Renewal Term upon notice of the update from PwC at least seventy (70) days prior to the end of the then-current Initial Term or Renewal Term (as applicable)."
            ]
        },
        {
            title: "Initial Term End Date",
            text: [
                "11:59pm Eastern time on the day prior to the first anniversary of the Effective Date"
            ]
        },
        {
            title: "Activation Date",
            text: ["Order Effective Date."]
        },
        {
            title: "User Accounts",
            text: [
                "Unlimited user accounts.",
                "If Single Sign-On will be used: User Accounts Access will be enabled through identity federation and a Single Sign-On process."
            ]
        },
        {
            title: "Authorized Contractor(s)",
            text: [
                "Any third party who otherwise meets the definition of Authorized Contractor in the Standard Terms, subject to all other restrictions in the Agreement."
            ]
        }
    ]
};
export default LISTEN;
