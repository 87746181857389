import { useState } from "react";
import { useNavigate } from "react-router-dom";

import EditSummary from "./EditSummary";
import Menu from "../common/Menu";
import Client from "../../create/client/Client";
import OrderTerms from "../../create/order/Terms";
import Contact from "../../create/contact/Contact";
import DiscardChangesModal from "../../common/DiscardChangesModal";
import EditButtons from "./EditButtons";

import { gotoCreatedOrder, gotoError } from "../../../../../Routes";
import usePricingOptions from "../../common/order/UsePricingOptions";
import { updateOrder } from "../../../service/External";
import { NEW_ORDER_DATA, NEW_ORDER_STATE } from "../../../Constants";
import { MENU_LABELS } from "../common/Menu";
import { EDIT_SUMMARY_TEXT } from "../../../../common/Constants";

const EditManager = (props) => {
    const {
        opportunityId,
        product,
        formState,
        setFormState,
        formData,
        setFormData,
        additionalContactCount,
        setAdditionalContactCount,
        submissionInfo,
        gotoAllOrders,
        gotoViewOrder
    } = props;

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isDiscardAllModal, setIsDiscardAllModal] = useState(false);
    // the backend requires that we tell it what sections were updated... why?
    const [isClientUpdate, setIsClientUpdate] = useState(false);
    const [isContactUpdate, setIsContactUpdate] = useState(false);
    const [isPricingUpdate, setIsPricingUpdate] = useState(false);
    // holds form data / state prior to section edit, so that it can be restored if discard is clicked
    const [priorFormData, setPriorFormData] = useState(NEW_ORDER_DATA);
    const [priorFormState, setPriorFormState] = useState(NEW_ORDER_STATE);
    // order terms page \\
    const [pricingOptionsLoaded, setPricingOptionsLoaded] = useState(false);
    usePricingOptions(
        setFormState,
        product,
        pricingOptionsLoaded,
        setPricingOptionsLoaded
    );
    // client page \\
    const [priorAdditionalContactCount, setPriorAdditionalContactCount] =
        useState(0);
    const [domainNameCount, setDomainNameCount] = useState(0);
    const [priorDomainNameCount, setPriorDomainNameCount] = useState(0);

    const PAGES = {
        EDIT_SUMMARY: 0,
        EDIT_CLIENT: 1,
        EDIT_ORDER_TERMS: 2,
        EDIT_CONTACTS: 3
    };
    const [page, setPage] = useState(PAGES.EDIT_SUMMARY);

    const navigate = useNavigate();
    const handleUpdateOrder = () => {
        const callback = (success, result) => {
            if (success) {
                gotoCreatedOrder(
                    navigate,
                    product.id,
                    opportunityId,
                    formState.clientAccount.name,
                    submissionInfo.displaySlpWarning
                );
            } else {
                gotoError(navigate);
            }
        };

        updateOrder(
            opportunityId,
            formData,
            formState,
            product,
            submitter,
            isClientUpdate,
            isContactUpdate,
            isPricingUpdate,
            callback
        );
    };

    // treat each field in formData as an array and update according to index
    const handleIndexedChange = (name, index) => (value) => {
        formData[name][index] = value;
        setFormData({ ...formData });
    };
    const handleChange = (name) => (value) => {
        formData[name] = value;
        setFormData({ ...formData });
    };

    // store the current data so that it can be restored when discard is clicked
    const storeCurrentData = () => {
        // the spread operator ... only clones 1 level deep
        setPriorFormData({
            ...formData,
            clientFirstName: [...formData.clientFirstName],
            clientLastName: [...formData.clientLastName],
            clientEmail: [...formData.clientEmail],
            clientTitle: [...formData.clientTitle],
            clientPhone: [...formData.clientPhone],
            clientDomainName: [...formData.clientDomainName],
            requiredContacts: { ...formData.requiredContacts },
            additionalContacts: { ...formData.additionalContacts },
            additionalContactsSelect: [...formData.additionalContactsSelect]
        });
    };
    const restorePriorData = () => {
        setFormData({
            ...priorFormData,
            clientFirstName: [...priorFormData.clientFirstName],
            clientLastName: [...priorFormData.clientLastName],
            clientEmail: [...priorFormData.clientEmail],
            clientTitle: [...priorFormData.clientTitle],
            clientPhone: [...priorFormData.clientPhone],
            clientDomainName: [...priorFormData.clientDomainName],
            requiredContacts: { ...priorFormData.requiredContacts },
            additionalContacts: { ...priorFormData.additionalContacts },
            additionalContactsSelect: [
                ...priorFormData.additionalContactsSelect
            ]
        });
    };
    const storeCurrentState = () => {
        setPriorFormState((current) => {
            return {
                ...current,
                oneTimeFees: formState.oneTimeFees,
                annualFees: formState.annualFees,
                renewalAnnualFees: formState.renewalAnnualFees,
                initialTermTotal: formState.initialTermTotal,
                renewalTermTotal: formState.renewalAnnualFees,
                selectedProductId: formState.productId,
                selectedRenewalProductId: formState.renewalProductId,
                selectedWaiveEnablementProdId:
                    formState.selectedWaiveEnablementProdId
            };
        });
    };
    const restorePriorState = () => {
        setFormState((current) => {
            return {
                ...current,
                oneTimeFees: priorFormState.oneTimeFees,
                annualFees: priorFormState.annualFees,
                renewalAnnualFees: priorFormState.renewalAnnualFees,
                initialTermTotal: priorFormState.initialTermTotal,
                renewalTermTotal: priorFormState.renewalAnnualFees,
                selectedProductId: priorFormState.productId,
                selectedRenewalProductId: priorFormState.renewalProductId,
                selectedWaiveEnablementProdId:
                    priorFormState.selectedWaiveEnablementProdId
            };
        });
    };
    // store everything that could change
    const storeCurrent = () => {
        storeCurrentData();
        storeCurrentState();
        setPriorAdditionalContactCount(additionalContactCount);
        setPriorDomainNameCount(domainNameCount);
    };
    // restore everything that could have changed
    const restorePrior = () => {
        restorePriorData();
        restorePriorState();
        setAdditionalContactCount(priorAdditionalContactCount);
        setDomainNameCount(priorDomainNameCount);
    };

    // cancel discard => hide modal
    const handleCancel = () => {
        setIsModalVisible(false);
    };
    // confirm discard => restore prior states
    const handleDiscard = () => {
        restorePrior();
        setIsModalVisible(false);
        gotoEditSummary();
    };

    const gotoEditSummary = () => {
        setPage(PAGES.EDIT_SUMMARY);
    };

    const gotoEditClient = () => {
        storeCurrent();
        setPage(PAGES.EDIT_CLIENT);
    };
    const saveClient = () => {
        setIsClientUpdate(true);
        gotoEditSummary();
    };

    const gotoEditOrderTerms = () => {
        storeCurrent();
        setPage(PAGES.EDIT_ORDER_TERMS);
    };
    const saveOrderTerms = () => {
        setIsPricingUpdate(true);
        gotoEditSummary();
    };

    const gotoEditContacts = () => {
        storeCurrent();
        setPage(PAGES.EDIT_CONTACTS);
    };
    const saveContacts = () => {
        setIsContactUpdate(true);
        gotoEditSummary();
    };

    const showDiscardModal = () => {
        setIsDiscardAllModal(false);
        setIsModalVisible(true);
    };
    const showDiscardAllModal = () => {
        setIsDiscardAllModal(true);
        setIsModalVisible(true);
    };

    const submitter = {
        name: submissionInfo.submitterName,
        email: submissionInfo.submitterEmail
    };

    const menuLabels = [
        MENU_LABELS.ALL_ORDERS,
        MENU_LABELS.VIEW_ORDER,
        MENU_LABELS.EDIT_ORDER
    ];
    const menuActions = [gotoAllOrders, gotoViewOrder];

    const pageComponents = [];
    pageComponents[PAGES.EDIT_SUMMARY] = (
        <>
            <EditSummary
                header={"Edit order"}
                description={EDIT_SUMMARY_TEXT[product.id]}
                user={submitter}
                product={product}
                formState={formState}
                formData={formData}
                gotoClient={gotoEditClient}
                gotoTerms={gotoEditOrderTerms}
                gotoContact={gotoEditContacts}
                menuLabels={menuLabels}
                menuActions={menuActions}
            />
            <EditButtons
                showDiscardAllModal={showDiscardAllModal}
                handleUpdateOrder={handleUpdateOrder}
            />
        </>
    );
    pageComponents[PAGES.EDIT_CLIENT] = (
        <>
            <Menu
                labels={menuLabels.concat([MENU_LABELS.CLIENT_DETAILS])}
                actions={menuActions.concat([gotoEditSummary])}
            />
            <Client
                product={product}
                handleIndexedChange={handleIndexedChange}
                formData={formData}
                setFormData={setFormData}
                buttonLabels={["DISCARD", "SAVE"]}
                buttonActions={[showDiscardModal, saveClient]}
                additionalContactCount={additionalContactCount}
                setAdditionalContactCount={setAdditionalContactCount}
                domainNameCount={domainNameCount}
                setDomainNameCount={setDomainNameCount}
                useIconHeader={true}
            />
        </>
    );
    pageComponents[PAGES.EDIT_ORDER_TERMS] = (
        <>
            <Menu
                labels={menuLabels.concat([MENU_LABELS.ORDER_TERMS])}
                actions={menuActions.concat([gotoEditSummary])}
            />
            <OrderTerms
                product={product}
                handleChange={handleChange}
                formData={formData}
                formState={formState}
                setFormState={setFormState}
                buttonLabels={["DISCARD", "SAVE"]}
                buttonActions={[showDiscardModal, saveOrderTerms]}
                useIconHeader={true}
                pricingOptionsLoaded={pricingOptionsLoaded}
            />
        </>
    );
    pageComponents[PAGES.EDIT_CONTACTS] = (
        <>
            <Menu
                labels={menuLabels.concat([MENU_LABELS.PWC_CONTACTS])}
                actions={menuActions.concat([gotoEditSummary])}
            />
            <Contact
                user={submitter}
                product={product}
                formData={formData}
                setFormData={setFormData}
                buttonLabels={["DISCARD", "SAVE"]}
                buttonActions={[showDiscardModal, saveContacts]}
                useIconHeader={true}
            />
        </>
    );

    return (
        <>
            <DiscardChangesModal
                visible={isModalVisible}
                discardAll={isDiscardAllModal}
                handleCancel={handleCancel}
                handleDiscard={handleDiscard}
                handleDiscardAll={gotoViewOrder}
            />
            {pageComponents[page]}
        </>
    );
};

export default EditManager;
