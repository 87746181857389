import WrappedInput from "../../../../common/WrappedInput";
import { PRODUCT_STAKEHOLDERS } from "../../../Constants";
import Select from "../order/Select";

const RequiredContacts = ({
    formData,
    formState,
    product,
    handleRequiredContactChange,
    validationErrors
}) => {
    const requiredContacts = [];
    const requiredContactsLength =
        product.optionalComponent.contact.requiredContacts.length;

    for (let i = 0; i < requiredContactsLength; ++i) {
        const last = requiredContactsLength - i === 1;
        const contact = product.optionalComponent.contact.requiredContacts[i];

        const isLeadTaxPartner =
            contact.id === PRODUCT_STAKEHOLDERS.LEAD_TAX_PARTNER.id;

        requiredContacts.push(
            <div key={i}>
                <div className="orange text-16 bold p-b-8">
                    *{contact.label}
                </div>
                {isLeadTaxPartner && (
                    <WrappedInput
                        name="fullName"
                        label="*FULL NAME"
                        primary={true}
                        value={
                            formData.requiredContacts?.[contact.id]?.fullName ??
                            ""
                        }
                        onChange={(value) =>
                            handleRequiredContactChange(contact.id)(
                                "fullName",
                                value
                            )
                        }
                        hasError={validationErrors[contact.id].fullName.error}
                        errorMessage={
                            validationErrors[contact.id].fullName.message
                        }
                    />
                )}
                <WrappedInput
                    name="email"
                    label="*WORK EMAIL"
                    primary={true}
                    value={formData.requiredContacts?.[contact.id]?.email ?? ""}
                    onChange={(value) =>
                        handleRequiredContactChange(contact.id)(
                            "email",
                            value.toLowerCase()
                        )
                    }
                    hasError={validationErrors[contact.id].email.error}
                    errorMessage={validationErrors[contact.id].email.message}
                />
                {isLeadTaxPartner && (
                    <>
                        <Select
                            primary={true}
                            label="*LEAD TAX PARTNER REGION"
                            options={
                                formState.contactOptions
                                    .leadTaxPartnerRegionOptions
                            }
                            value={
                                formData.requiredContacts?.[contact.id]?.region
                            }
                            onChange={(value) =>
                                handleRequiredContactChange(contact.id)(
                                    "region",
                                    value
                                )
                            }
                            hasError={validationErrors[contact.id].region.error}
                            errorMessage={
                                validationErrors[contact.id].region.message
                            }
                        />
                        <Select
                            primary={true}
                            label="*LEAD TAX PARTNER MARKET"
                            options={
                                formState.contactOptions
                                    .leadTaxPartnerMarketOptions
                            }
                            value={
                                formData.requiredContacts?.[contact.id]?.market
                            }
                            onChange={(value) =>
                                handleRequiredContactChange(contact.id)(
                                    "market",
                                    value
                                )
                            }
                            hasError={validationErrors[contact.id].market.error}
                            errorMessage={
                                validationErrors[contact.id].market.message
                            }
                        />
                    </>
                )}
                {!last && <div className="separator-line"></div>}
            </div>
        );
    }

    return (
        <div>
            <div className="text-12 bold orange p-t-36 p-b-8">
                The following contacts are required to license {product.label}
            </div>
            {requiredContacts}
        </div>
    );
};

export default RequiredContacts;
