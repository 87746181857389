import { HashRouter, Route, Routes } from "react-router-dom";
import { ORDER_LIST } from "./components/landing/Constants";

import SomethingWentWrong from "./components/error/SomethingWentWrong";
import ScheduledMaintenance from "./components/error/ScheduledMaintenance";

import Landing from "./components/landing/Landing";

import NewOrder from "./components/products/layout/create/NewOrder";
import CreatedOrder from "./components/products/layout/view/success/CreatedOrder";

import Contract from "./components/products/layout/contract/Index";

import ViewOrder from "./components/products/layout/view/ViewOrder";
import AFS from "./components/products/layout/view/afs/AFS";
import SubmittedAFS from "./components/products/layout/view/success/SubmittedAFS";
import TerminatedOrder from "./components/products/layout/view/success/TerminatedOrder";
import WithdrawnOrder from "./components/products/layout/view/success/WithdrawnOrder";
import Resend from "./components/products/layout/view/resend/Resend";
import ResentOrder from "./components/products/layout/view/success/ResentOrder";
import UploadedContract from "./components/products/layout/view/success/UploadedContract";
import SubmittedOrder from "./components/products/layout/view/success/SubmittedOrder";

import ManageOrder from "./components/products/layout/manage/ManageOrder";
import RenewedOrder from "./components/products/layout/view/success/RenewedOrder";
import CancelledRenewal from "./components/products/layout/view/success/CancelledRenewal";
import SubmitOrder from "./components/products/layout/view/submit/SubmitOrder";
import { ROUTES } from "./Routes";
import { PrivateRoute } from "./PrivateRoute";
import AccessDenied from "./components/error/AccessDenied";

const App = (props) => {
    // if there are no matching routes, go here
    // this MUST be the last route in the list of routes
    const catchAllRoute = <Route path="*" element={<SomethingWentWrong />} />;

    const commonRoutes = [
        <Route
            key={ROUTES.error}
            path={ROUTES.error}
            element={<SomethingWentWrong />}
        />,
        <Route
            key={ROUTES.accessDenied}
            path={ROUTES.accessDenied}
            element={<AccessDenied />}
        />,
        <Route
            key={ROUTES.scheduledMaintenance}
            path={ROUTES.scheduledMaintenance}
            element={<ScheduledMaintenance message={props.message} />}
        />
    ];

    const SSORoutes = (
        <HashRouter>
            <Routes>
                {commonRoutes}
                {/* action pages */}
                <Route
                    path={ROUTES.root}
                    element={<Landing user={props.user} />}
                />
                <Route
                    path={ROUTES.landing}
                    element={<Landing user={props.user} />}
                />
                <Route
                    path={ROUTES.manage}
                    element={
                        <PrivateRoute user={props.user} page={ORDER_LIST}>
                            <ManageOrder user={props.user} />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={ROUTES.newOrder}
                    element={<NewOrder user={props.user} />}
                />
                <Route path={ROUTES.viewOrder} element={<ViewOrder />} />
                <Route
                    path={ROUTES.submitAFS}
                    element={<AFS user={props.user} />}
                />
                <Route
                    path={ROUTES.resendOrder}
                    element={<Resend user={props.user} />}
                />
                <Route path={ROUTES.submitOrder} element={<SubmitOrder />} />
                {/* success pages */}
                <Route path={ROUTES.createdOrder} element={<CreatedOrder />} />
                <Route
                    path={ROUTES.submittedOrder}
                    element={<SubmittedOrder />}
                />
                <Route path={ROUTES.resentOrder} element={<ResentOrder />} />
                <Route path={ROUTES.submittedAFS} element={<SubmittedAFS />} />
                <Route
                    path={ROUTES.terminatedOrder}
                    element={<TerminatedOrder />}
                />
                <Route
                    path={ROUTES.withdrawnOrder}
                    element={<WithdrawnOrder />}
                />
                <Route
                    path={ROUTES.uploadedContract}
                    element={<UploadedContract />}
                />
                <Route path={ROUTES.renewedOrder} element={<RenewedOrder />} />
                <Route
                    path={ROUTES.cancelledOrder}
                    element={<CancelledRenewal />}
                />
                {catchAllRoute}
            </Routes>
        </HashRouter>
    );

    const nonSSORoutes = (
        <HashRouter>
            <Routes>
                {commonRoutes}
                <Route path={ROUTES.contract} element={<Contract />} />
                {catchAllRoute}
            </Routes>
        </HashRouter>
    );

    return props.bypassSSO ? nonSSORoutes : SSORoutes;
};

export default App;
