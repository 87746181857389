import React from "react";
import "./LabelErrorWrapper.css";

export const WRAPPED_COMPONENT_TYPE = Object.freeze({
    INPUT: "INPUT",
    SELECT: "SELECT",
    CALENDAR: "CALENDAR"
});

const WRAPPED_CLASSES = Object.freeze({
    INPUT: "a-input-text",
    SELECT: "",
    CALENDAR:  ""
});

const WRAPPED_ERROR_CLASSES = Object.freeze({
    INPUT: "a-input-error",
    SELECT: "a-select-error",
    CALENDAR: "a-input-error"
});

// a-input-text, a-input-error, a-form-error, a-input-size-sm and a-form-label
// are defined in appkit-react/style/appkit-react.default.css
const LabelErrorWrapper = (props) => {
    const wrappedClass = WRAPPED_CLASSES[props.wrappedComponent.props.type];
    const wrappedError =
        WRAPPED_ERROR_CLASSES[props.wrappedComponent.props.type];
    const wrappedClasses =
        wrappedClass + (props.hasError ? " " + wrappedError : "");

    const errorClasses = ["a-form-error"];
    if (props.hasError === undefined) errorClasses.push("error");
    if (props.hasError === true) errorClasses.push("error show");
    if (props.hasError === false) errorClasses.push("error hide");

    let divClasses = "a-input-size-sm";
    if (props.className) {
        divClasses = divClasses + " " + props.className;
    }
    let innerDivClass = "";
    if (!props.primary) {
        divClasses = divClasses + " secondary-input-container";
        innerDivClass = "w-75";
    }

    return (
        <div className={divClasses}>
            <label className="a-form-label " htmlFor={props.name}>
                {props.label}
            </label>
            <div className={innerDivClass}>
                {React.cloneElement(props.wrappedComponent, {
                    className: wrappedClasses
                })}
            </div>
            {/* need a non-breaking space so that the div's horizontal space is rendered */}
            <div className={errorClasses.join(" ")}>
                {props.errorMessage ? props.errorMessage : "\u00A0"}
            </div>
        </div>
    );
};

export default LabelErrorWrapper;
