import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import orderSummary from "../../../../assets/order-summary.svg";

import Checkbox from "appkit-react/lib/Checkbox/Checkbox";
import Button from "appkit-react/lib/Button/Button";

import { StatusContext } from "./Index";
import { completeOrder } from "../../service/External";

import { PriceCom } from "./PriceCom";

import { formatNumber } from "../../../common/Util";
import { CONTRACT_FIELD, CONTRACT_TYPE, PAGE_STATUS } from "./Constants";
import { gotoError } from "../../../../Routes";
import STATEMENT from "./templates/statement/Statement";
import { PRODUCTS } from "../../Config";

export const OrderSummary = (props) => {
    const { orderDetails, opportunityId, product } = props;

    const [checked, setChecked] = useState(false);
    const [clicked, setClicked] = useState(false);
    const [showRenewal, setShowRenewal] = useState(false);
    const setStatus = useContext(StatusContext);
    const navigate = useNavigate();

    const initialTermTotal =
        product.id === PRODUCTS.DOD.id
            ? orderDetails.InitialTerm1Value + orderDetails.InitialTerm2Value
            : orderDetails[CONTRACT_FIELD[product.id]] ===
              CONTRACT_TYPE.employeeExperience
            ? orderDetails.productPrice +
              orderDetails.headCountProductPrice *
                  orderDetails.headCountProductQyt
            : orderDetails.productPrice;

    const renewalTermTotal = orderDetails.RenewalTerm1Value;
    const statement =
        STATEMENT[product.id][orderDetails[CONTRACT_FIELD[product.id]]];

    const onCompleteOrder = () => {
        setClicked(true);

        const callback = (success, data) => {
            setClicked(false);
            if (success) {
                setStatus(PAGE_STATUS.success);
            } else {
                gotoError(navigate);
            }
        };

        completeOrder(opportunityId, callback);
    };

    useEffect(() => {
        if (product.id === PRODUCTS.DOD.id) {
            setShowRenewal(true);
        }
    }, [product]);
    return (
        <div className="round-card bg-black pa-0 p-v-24 p-b-38 fixed">
            <div className="flex-align-center m-h-24">
                <div className="circle-36">
                    <span className="appkiticon a-font-18 a-text-white icon-news-outline"></span>
                </div>
                <div className="m-l-15">
                    <h5 className="a-h5 clear-margin-bottom text-bold a-text-white a-font-22">
                        Order Summary
                    </h5>
                </div>
            </div>
            <div className="bg-white m-t-12 flex-center">
                <img
                    src={orderSummary}
                    alt="Order Summary"
                    className="img-order-summary"
                />
            </div>
            <div className="m-h-24">
                <div className="flex-justify-between m-t-18">
                    <PriceCom
                        desc="Initial Term total"
                        price={formatNumber(initialTermTotal)}
                    />
                </div>
                {renewalTermTotal && showRenewal ? (
                    <div className="flex-justify-between m-t-12">
                        <PriceCom
                            desc="Renewal Term total"
                            price={formatNumber(renewalTermTotal)}
                        />
                    </div>
                ) : null}
                <div className="seperate-line"></div>
                <div className="reset-checkbox">
                    <Checkbox
                        defaultChecked={checked}
                        onChange={() => setChecked(!checked)}
                    >
                        <p className="a-text-white clear-margin-bottom">
                            {statement.preText}
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={statement.url}
                                className="underline orange a-hover word-break"
                            >
                                {product.id === PRODUCTS.DOD.id
                                    ? product.label + "License Agreement"
                                    : statement.url}
                            </a>
                            {statement.lastText}
                        </p>
                    </Checkbox>
                </div>
                <div className="seperate-line"></div>
                <div className="text-right">
                    <Button
                        aria-label="Loading"
                        kind="primary"
                        size="lg"
                        className={`w-130 h-34 ${
                            clicked ? "a-no-interaction" : ""
                        }`}
                        disabled={!checked || clicked}
                        onClick={() => onCompleteOrder()}
                    >
                        {!clicked ? (
                            "COMPLETE ORDER"
                        ) : (
                            <span className="a-loading a-default a-loading-16"></span>
                        )}
                    </Button>
                </div>
            </div>
        </div>
    );
};
