import { CardTitle } from "./CardTitle";
import { formatDate } from "../../../common/Util";

const DetailItem = (props) => {
    const { title, desc } = props;
    return (
        <div className="m-t-16">
            <h4 className="text-14 half-bold">{title}</h4>
            {desc.map((item, index) => (
                <p
                    key={"detail-item-" + index}
                    className="text-12 gray half-bold text-justify"
                >
                    {item}
                </p>
            ))}
        </div>
    );
};

export const CardDetail = (props) => {
    const { effectiveDate, detailsProduct } = props;
    return (
        <div className="round-card bg-white m-b-24">
            <CardTitle iconClass="icon-purpose-outline" text="Details" />
            <DetailItem
                title="Order Effective Date"
                desc={[formatDate(effectiveDate)]}
            />
            {detailsProduct.map((item, index) => (
                <DetailItem
                    key={"detail-" + index}
                    title={item.title}
                    desc={item.text}
                />
            ))}
        </div>
    );
};
