import {
    INFORM_CONTENT,
    DISCLOSURE_CHECKLIST,
    CURRENCY_CODE_TO_SYMBOL,
    UNITED_STATES
} from "../Constants";

export const getTheatre = (country, setFormState) => {
    window.Visualforce.remoting.Manager.invokeAction(
        window.endpoints.getTheatre,
        country,
        (result, event) => {
            if (event.status) {
                setFormState({
                    ...result,
                    currencySymbol: CURRENCY_CODE_TO_SYMBOL[result.currencyCode]
                });
            }
        },
        { escape: false }
    );
};

export const submitForm = (formData, formState, setFormState, callback) => {
    const state =
        formData.country === UNITED_STATES ? formData.usState : formData.state;
    const leadDetails = {
        tAndCApproverEmail: formData.tcApproverEmail,
        tAndCApproverName: formData.tcApproverName,
        otherAuditor: formData.otherAuditor,
        defaultTheatre: formState.theatre,
        nameOfContact: formData.pwcContact,
        country: formData.country,
        orderType: "New",
        firstName: formData.firstName,
        lastName: formData.lastName,
        phone: formData.phone,
        email: formData.email,
        title: formData.title,
        company: formData.companyName,
        parentCompany: formData.parentCompany,
        address1: formData.addressLine1,
        address2: formData.addressLine2,
        city: formData.city,
        state: state,
        postalCode: formData.zip,
        externalAuditor: formData.externalAuditor,
        informReference: formData.hearAbout,
        organizationRules: formData.rules,
        companyDomainNames: formData.companyDomainNames,
        referralSource: formData.referralSource,
        pwcEngagementTeamMember: formData.engagementTeamManager,
        currencyCode: formState.currencyCode,
        defaultCurrency: formState.currencyName,
        billingTerritory: formState.billingTerritory,
        accessToAll: formData.accessToAll,
        legalNamesOfOtherEntities: formData.legalNamesOfOtherEntities
    };
    // the backend does not handle 0 quantities correctly, must pass null instead... *sigh*
    const leadProducts = {
        numberOfAdditionallicences:
            formData.additionalLicenses > 0
                ? formData.additionalLicenses
                : null,
        flatFeeForlicence: formState.firstLicense,
        totalAnnualCostChecklistlicences: formData.disclosureChecklistTotal,
        pricePerConcurrentUser: formData.viewpointTotal,
        totalAnnualCostForAlllicences: formData.viewpointTotal,
        additionallicenceCost: formState.firstLicense,
        numberOfUnitedStates:
            formData.usContent > 0 ? formData.usContent : null,
        numberOfUnitedStatesGASBOnly:
            formData.gasbContent > 0 ? formData.gasbContent : null,
        numberOfGlobalInternational:
            formData.globalContent > 0 ? formData.globalContent : null,
        numberOfCanada:
            formData.canadaContent > 0 ? formData.canadaContent : null,
        numberOfJapan: formData.japanContent > 0 ? formData.japanContent : null,
        numberOfUnitedKingdom: formData.ukContent ? formData.ukContent : null,
        numberOfUnitedKingdomGAAPOnly: formData.gaapContent
            ? formData.gaapContent
            : null,
        unitedStatesCost: formData.usContent,
        unitedStatesGASBOnlyCost: formData.gasbContentTotal,
        globalInternationalCost: formData.globalContentTotal,
        unitedKingdomCost: formData.ukContentTotal,
        unitedKingdomGAAPCost: formData.gaapContentTotal,
        canadaCost: formData.canadaContentTotal,
        japanCost: formData.japanContentTotal
    };

    const leadBooleans = {
        informContent: formData.orderViewpoint,
        disclosureChecklist: formData.orderDisclosureChecklist
    };

    window.Visualforce.remoting.Manager.invokeAction(
        window.endpoints.submitForm,
        leadDetails,
        leadProducts,
        leadBooleans,
        (result, event) => {
            if (event.status) {
                const names = [],
                    values = [];
                for (const lead of result) {
                    if (lead.RecordType.Name === DISCLOSURE_CHECKLIST) {
                        names.push("disclosureChecklistConfirmationNumber");
                        values.push(
                            lead.Disclosure_Checklist_Confirmation_Number__c
                        );
                    }
                    if (lead.RecordType.Name === INFORM_CONTENT) {
                        names.push("viewpointConfirmationNumber");
                        values.push(lead.Inform_Confirmation_Number__c);
                    }
                    for (let i = 0; i < names.length; ++i) {
                        formState[names[i]] = values[i];
                    }
                    setFormState({ ...formState });
                }
            }
            callback();
        },
        { escape: false }
    );
};
