import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { gotoError } from "../../../../../Routes";
import { PRODUCTS } from "../../../Config";
import { getPricingOptions } from "../../../service/External";

const usePricingOptions = (
    setFormState,
    product,
    pricingOptionsLoaded,
    setPricingOptionsLoaded
) => {
    const navigate = useNavigate();

    useEffect(() => {
        const callback = (success, data) => {
            if (success) {
                // POD does not have pricing options, but it does have contact options
                // use this hook to load them instead
                if (product.id === PRODUCTS.POD.id) {
                    setFormState((prior) => {
                        prior.contactOptions = data;
                        return { ...prior };
                    });
                } else {
                    setFormState((prior) => {
                        prior.pricingOptions = data;
                        return { ...prior };
                    });
                }
                setPricingOptionsLoaded(true);
            } else {
                gotoError(navigate);
            }
        };

        // load pricing options once
        if (!pricingOptionsLoaded && product) {
            getPricingOptions(product.id, callback);
        }
    }, [
        navigate,
        product,
        setFormState,
        pricingOptionsLoaded,
        setPricingOptionsLoaded
    ]);
};

export default usePricingOptions;
