const InitialTerm = ({ props, reviewPage }) => {
    return (
        <div className="round-card-black">
            <div className="round-card-black-top">
                <div className="p-b-16">
                    <span className="appkiticon icon-calendar-outline a-font-24 a-text-red p-r-16"></span>
                    <span className="text-16 bold">Initial Term</span>
                </div>
                <div className="row light-gray-text-12">
                    <div className="col">{props.product.label}</div>
                    <div className="col right">$0</div>
                </div>
                {reviewPage && (
                    <div className="gray-text-10">
                        <div className="col-9">
                            All PwC clients receive a complimentary subscription
                            with unlimited users.
                        </div>
                    </div>
                )}
            </div>
            <div className="black-background-rounded">
                <div className="row">
                    <span className="col">Subtotal before tax</span>
                    <span className="col right">$0 USD</span>
                </div>
            </div>
        </div>
    );
};

export default InitialTerm;
