import { CONTRACT_TYPE } from "../../Constants";
const WFO = {
    [CONTRACT_TYPE.frontlineWorkers]: [
        "Client will pay an annual per-User fee for each User in accordance with the rates set forth below:",
        "Front-line Workers@$$$@Volume Tier - PPY/User@$$$@• Up to 5,000 - $10@$$$@• 5,001-10,000  - $9@$$$@• 10,001-25,000 - $7@$$$@• 25,001-50,000 - $5@$$$@• 50,001-100,000 - $3",
        "For clarity, the rate applicable to a tier applies only to Users purchased in that tier (and not any lower tier).",
        "If at any time during the Initial Term the number of Users exceeds the Minimum Commitment for that year, PwC will invoice Client for each additional User in excess of the Minimum Commitment, pro rata for the remainder of that year of the Initial Term (“Additional User Fees”). PwC will not issue such invoices more than once per quarter during the Term. Payment of Additional User Fees will be due as provided in the Agreement. Payment of the Minimum Commitments and Additional User Fees are non-refundable."
    ],
    [CONTRACT_TYPE.officeWorkers]: [
        "Client will pay an annual per-User fee for each User in accordance with the rates set forth below:",
        "Office Workers@$$$@Volume Tier - PPY/User@$$$@• Up to 1,000 -  $50@$$$@• 1,001-2,500 -  $45@$$$@• 2,501-5,000 - $35@$$$@• 5,001-10,000 - $25@$$$@• 10,001-20,000 - $15",
        "For clarity, the rate applicable to a tier applies only to Users purchased in that tier (and not any lower tier).",
        "If at any time during the Initial Term the number of Users exceeds the Minimum Commitment for that year, PwC will invoice Client for each additional User in excess of the Minimum Commitment, pro rata for the remainder of that year of the Initial Term (“Additional User Fees”). PwC will not issue such invoices more than once per quarter during the Term. Payment of Additional User Fees will be due as provided in the Agreement. Payment of the Minimum Commitments and Additional User Fees are non-refundable."
    ]
};
export default WFO;
