import { useState } from "react";
import { Checkbox } from "appkit-react/lib/Checkbox";
import { InputNumber } from "appkit-react/lib/InputNumber";

import Select from "../../Select";
import {
    buildInitialValidationErrors,
    VALIDATION_TYPE
} from "../../../../common/Validation";
import ContractWrapper from "../ContractWrapper";
import InitialTerm from "./InitialTerm";
import { CONTRACT_TYPE } from "../../../../contract/Constants";
import { NEW_ORDER_DATA } from "../../../../../Constants";

const Contract = ({ props }) => {
    const {
        formData,
        formState,
        setFormState,
        handleChange,
        buttonActions,
        buttonLabels
    } = props;

    const validations = {
        contractType: {
            type: VALIDATION_TYPE.REQUIRED,
            errorMessage: "Please select a contract type."
        }
    };

    const [validationErrors, setValidationErrors] = useState(
        buildInitialValidationErrors(validations, NEW_ORDER_DATA)
    );

    const [loading, setLoading] = useState(false);

    const dependencies = [formData.contractType, formData.numberOfUsers];
    const haveData = !!formData.contractType;

    const getPricingParams = {
        productFamily: props.product.id,
        contractType: formData.contractType,
        numberOfUsers: formData.numberOfUsers
    };

    const setPricingOptions = (result) => {
        setFormState((current) => {
            return {
                ...current,
                customerExperienceProdIdFees:
                    result.customerExperienceProdIdFees,
                customerExperienceProdId: result.customerExperienceProdId,
                employeeExperienceProdIdFees:
                    result.employeeExperienceProdIdFees,
                employeeExperienceProdId: result.employeeExperienceProdId,
                headCountFeeProdId: result.headCountFeeProdId,
                headCountFeeProdFees: result.headCountFeeProdFees
            };
        });
    };

    // TODO : why do we have 2 versions of this form?
    const isCustomerExperience =
        formData.contractType === CONTRACT_TYPE.customerExperience;
    const isEmployeeExperience =
        formData.contractType === CONTRACT_TYPE.employeeExperience;
    const content = (
        <>
            <div>
                <Select
                    primary={true}
                    label="CONTRACT TYPE"
                    options={formState.pricingOptions.contractTypes}
                    value={formData.contractType}
                    onChange={handleChange("contractType")}
                    errorMessage={validationErrors.contractType.message}
                    hasError={validationErrors.contractType.error}
                />
                {isCustomerExperience && (
                    <div className="text-14 p-b-36">
                        <Checkbox
                            name="autoRenew"
                            checked={true}
                            disabled={true}
                        >
                            Renew automatically when the annual subscription
                            ends.
                        </Checkbox>
                    </div>
                )}
                {isEmployeeExperience && (
                    <div className="row p-b-32 g-0">
                        <div className="col-7 d-flex align-items-center">
                            <label className="clear-m-b a-form-label">
                                EMPLOYEE HEADCOUNT NUMBER
                            </label>
                        </div>
                        <div className="col-5">
                            <InputNumber
                                kind={"arrow"}
                                className={"hide-buttons input-number-center"}
                                min={1}
                                max={500000}
                                onChange={handleChange("numberOfUsers")}
                                value={formData.numberOfUsers}
                            />
                        </div>
                        <div className="text-12">
                            The total Employee Headcount Number cannot be less
                            than 1 or exceed 500,000.
                        </div>
                    </div>
                )}
            </div>
            {haveData && (
                <div>
                    <div className="gray-text-12 p-v-8">ORDER TERMS</div>
                    <div className="round-card-black">
                        <InitialTerm
                            props={props}
                            loading={loading}
                            isCustomerExperience={isCustomerExperience}
                            isEmployeeExperience={isEmployeeExperience}
                        />
                    </div>
                </div>
            )}
        </>
    );

    return (
        <ContractWrapper
            formData={formData}
            buttonActions={buttonActions}
            buttonLabels={buttonLabels}
            validations={validations}
            validationErrors={validationErrors}
            setValidationErrors={setValidationErrors}
            haveData={haveData}
            dependencies={dependencies}
            getPricingParams={getPricingParams}
            setPricingOptions={setPricingOptions}
            loading={loading}
            setLoading={setLoading}
            content={content}
        />
    );
};

export default Contract;
