import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "appkit-react/lib/Button/Button";
import Modal from "appkit-react/lib/Modal/Modal";
import ModalHeader from "appkit-react/lib/Modal/ModalHeader";
import ModalFooter from "appkit-react/lib/Modal/ModalFooter";

import useIsMounted from "../../../common/UseIsMounted";
import { renewOrder } from "../view/service/External";
import "./GeneralOrderModal.css";
import "../view/summary/Summary.css";
import { formatDate } from "../../../common/Util";
import { gotoError, gotoRenewedOrder } from "../../../../Routes";

const RenewSubscriptionModal = (props) => {
    const { isVisible, setIsVisible, product, opportunityId, client, endDate } =
        props;

    const isMounted = useIsMounted();
    const [submitting, setSubmitting] = useState(false);
    const navigate = useNavigate();

    const handleCancel = () => {
        if (isMounted()) setIsVisible(false);
    };

    const callback = (success, result) => {
        if (success) {
            if (isMounted()) setIsVisible(false);
            gotoRenewedOrder(
                navigate,
                product.id,
                opportunityId,
                client,
                endDate
            );
        } else {
            gotoError(navigate);
        }
    };

    const handleConfirm = () => {
        setSubmitting(true);
        renewOrder(opportunityId, endDate, "", callback);
    };

    return (
        <Modal
            className="withdraw-modal"
            visible={isVisible}
            onCancel={handleCancel}
        >
            <ModalHeader>
                <span>
                    <span>
                        <div className="modal-title">
                            Renew client subscription
                        </div>
                    </span>
                    <span>
                        <div className="modal-description">
                            {client}'s {product.label}'s subscription period
                            will be extended to {formatDate(endDate)}.
                        </div>
                    </span>
                </span>
            </ModalHeader>
            <ModalFooter>
                <div>
                    <Button
                        kind="secondary"
                        onClick={handleCancel}
                        size="lg"
                        disabled={submitting}
                    >
                        CANCEL
                    </Button>
                    <Button
                        kind="primary"
                        onClick={handleConfirm}
                        size="lg"
                        className="m-l-24"
                        isLoading={submitting}
                    >
                        RENEW
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    );
};
export default RenewSubscriptionModal;
