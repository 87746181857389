import "./Steps.css";

const Steps = (props) => {
    return (
        <div>
            <div className="steps">
                {props.steps.map((element, i) => {
                    const isActive = props.active >= i;
                    const isActiveLabel = props.active === i;
                    const stepHolderClasses = isActive
                        ? "step-holder-active"
                        : "step-holder";
                    const stepIconClasses = isActive
                        ? "step-icon active-icon"
                        : "step-icon";
                    const stepLabelClasses = isActiveLabel
                        ? "step-label text-14 active-label"
                        : "step-label text-14";

                    return (
                        <div className={stepHolderClasses} key={i}>
                            <div className={stepIconClasses}>
                                <img src={element.icon} alt={element.alt} />
                            </div>
                            <div className={stepLabelClasses}>
                                {element.label}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Steps;
