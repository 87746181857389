import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { gotoError } from "../../../../../Routes";
import { splitServiceModules } from "../../../../common/Util";
import { PRODUCTS } from "../../../Config";
import { viewOrder } from "../../view/service/External";

const useViewOrderInfo = (
    opportunityId,
    setSubmissionInfo,
    setProduct,
    setFormData,
    setFormState,
    setAdditionalContactCount,
    setLoading
) => {
    const navigate = useNavigate();

    useEffect(() => {
        // TODO : standardize these fields so that this mapping is not necessary
        const PRODUCT_FIELD_MAPPING = {
            [PRODUCTS.POD.id]: {
                contractType: (data) => "",
                annualFees: (data) => 0,
                serviceModule: (data) => "",
                numberOfUsers: (data) => 0
            },
            [PRODUCTS.DOD.id]: {
                contractType: (data) => data.contractType,
                annualFees: (data) => data.InitialTerm2Value,
                serviceModule: (data) => data.serviceModule,
                numberOfUsers: (data) => 0
            },
            [PRODUCTS.LISTEN.id]: {
                contractType: (data) =>
                    data.contractDetails.productContractType,
                annualFees: (data) => 0,
                serviceModule: (data) => "",
                numberOfUsers: (data) =>
                    data.contractDetails.headCountProductQyt ?? 1
            },
            [PRODUCTS.WFO.id]: {
                contractType: (data) =>
                    data.contractDetails.productContractType,
                annualFees: (data) => data.contractDetails.productPrice,
                serviceModule: (data) => {
                    return splitServiceModules(data.serviceModule);
                },
                numberOfUsers: (data) => data.numberOfUsers
            },
            [PRODUCTS.CN.id]: {
                contractType: (data) => "",
                annualFees: (data) => data.contractDetails.productPrice,
                serviceModule: (data) => "",
                numberOfUsers: (data) => 0
            }
        };

        const onError = () => {
            gotoError(navigate);
        };

        if (opportunityId === null) {
            onError();
        }
        // put all the contact data into the appropriate stucture for the UI
        const buildContacts = (contacts, data, required) => {
            const contactData = {};
            const contactSelect = [];
            for (let contact of contacts) {
                const hasViewField =
                    typeof contact.salesforceViewField !== "undefined";
                const field = hasViewField
                    ? contact.salesforceViewField
                    : contact.salesforceField;
                if (required || data[field]) {
                    contactData[contact.id] = data[field] ?? "";
                    contactSelect.push(contact.id);
                }
            }
            return [contactData, contactSelect];
        };

        const callback = (success, result) => {
            if (success) {
                const data = result.results[0];
                const product = PRODUCTS[data.ProductName];
                setProduct(product);
                setSubmissionInfo({
                    date: data.createdDate,
                    submitterName: data.SubmitterName,
                    submitterEmail: data.SubmitterEmail,
                    submittedDate: data.createdDate,
                    status: data.OrderStatus,
                    client: data.AccountName,
                    contractStartDate: data.ContractstartDate,
                    contractEndDate: data.ContractEndDate,
                    installBaseExpirationDate: data.InstallBaseExpirationDate,
                    signedBy: data.SignedByName,
                    fileName: data.filedName,
                    fileSize: data.fileSize,
                    fileLink: data.fileLink,
                    showSubmitNewAFS: data.showSubmitNewAFS,
                    oppName: data.oppName,
                    oppUniqueId: data.oppUniqueId,
                    afsRequired: data.afsRequired,
                    oppOwner: data.oppOwner,
                    displaySlpWarning: data.displaySlpWarning,
                    isTrial: data.renewTrial,
                    afsNumber: data.AFSNumber,
                    afsApprovalDate: data.AFSApprovalDate,
                    afsExpirationDate: data.AFSExpirationDate
                });
                setFormState((currentFormState) => {
                    return {
                        ...currentFormState,
                        clientAccount: {
                            id: data.AccountId,
                            prid: data.prid,
                            name: data.AccountName,
                            address: data.address,
                            city: data.city,
                            state: data.state,
                            country: data.country,
                            zip: data.zip
                        },
                        // TODO : standardize these fields across all products
                        annualFees:
                            PRODUCT_FIELD_MAPPING[product.id]["annualFees"](
                                data
                            ),
                        // DOD
                        oneTimeFees: data.InitialTerm1Value,
                        renewalAnnualFees: data.RenewalTerm1Value,
                        initialTermTotal:
                            data.InitialTerm1Value + data.InitialTerm2Value,
                        renewalTermTotal: data.RenewalTerm1Value,
                        // LISTEN
                        customerExperienceProdIdFees:
                            data?.contractDetails?.productPrice ?? 0,
                        employeeExperienceProdIdFees:
                            data?.contractDetails?.productPrice ?? 0,
                        headCountFeeProdFees:
                            data?.contractDetails?.headCountProductPrice ?? 0
                    };
                });
                const [requiredContacts] = buildContacts(
                    product.optionalComponent.contact.requiredContacts,
                    data,
                    true
                );
                const [nonEditableContacts] = buildContacts(
                    product.optionalComponent.contact.nonEditableContacts,
                    data,
                    true
                );
                const [additionalContacts, additionalContactsSelect] =
                    buildContacts(
                        product.optionalComponent.contact.additionalContacts,
                        data,
                        false
                    );
                if (setFormData) {
                    setFormData((currentFormData) => {
                        const formData = {
                            ...currentFormData,
                            // client
                            clientFirstName: [data.firstName],
                            clientLastName: [data.lastName],
                            clientEmail: [data.email],
                            clientTitle: [data.jobTitle],
                            clientPhone: [data.phoneNumber],
                            // order terms
                            contractType:
                                PRODUCT_FIELD_MAPPING[product.id][
                                    "contractType"
                                ](data),
                            serviceModule:
                                PRODUCT_FIELD_MAPPING[product.id][
                                    "serviceModule"
                                ](data),
                            clientTier: data.clientTier,
                            autoRenew: data.renewAnnual,
                            waiveEnablementFee: data.waiveEnablementFee,
                            numberOfUsers:
                                PRODUCT_FIELD_MAPPING[product.id][
                                    "numberOfUsers"
                                ](data),
                            subscriptionTier:
                                data?.contractDetails?.productTier ?? "",
                            subscriptionTerm:
                                data?.contractDetails?.productTerm ?? "",
                            communicationChannel:
                                data?.communicationChannel ?? "",
                            // contacts
                            requiredContacts: requiredContacts,
                            nonEditableContacts: nonEditableContacts,
                            additionalContacts: additionalContacts,
                            additionalContactsSelect: additionalContactsSelect
                        };
                        if (data.addiEmail) {
                            formData.clientEmail.push(data.addiEmail);
                        }
                        if (setAdditionalContactCount)
                            setAdditionalContactCount(
                                formData.clientEmail.length - 1
                            );
                        return formData;
                    });
                }
            } else {
                onError();
            }
            setLoading(false);
        };

        setLoading(true);
        viewOrder(opportunityId, callback);
    }, [
        navigate,
        opportunityId,
        setAdditionalContactCount,
        setFormData,
        setFormState,
        setLoading,
        setProduct,
        setSubmissionInfo
    ]);
};

export default useViewOrderInfo;
