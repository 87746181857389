import DOD from "./contract/dod/Content";
import CN from "./contract/cn/Content";
import WFO from "./contract/wfo/Content";
import LISTEN from "./contract/listen/Content";
import POD from "./contract/pod/Content";

import { ORDER_TERMS } from "../../../Constants";

const Terms = (props) => {
    const { product, pricingOptionsLoaded } = props;

    // TODO : figure out the best way to completely paramterize this page
    const content = {
        [ORDER_TERMS.DOD]: <DOD props={props} />,
        [ORDER_TERMS.CN]: <CN props={props} />,
        [ORDER_TERMS.WFO]: <WFO props={props} />,
        [ORDER_TERMS.LISTEN]: <LISTEN props={props} />,
        [ORDER_TERMS.POD]: <POD props={props} />
    };

    return (
        <div className="p-b-125">
            <div className="row">
                <div className="col-3"></div>
                <div className="col-6 flex-justify-center">
                    {pricingOptionsLoaded ? (
                        content[product.optionalComponent.order]
                    ) : (
                        <div className="p-t-32 center">
                            <span className="a-loading a-primary loading-large"></span>
                            <div>
                                Just a moment while we look up the order
                                terms...
                            </div>
                        </div>
                    )}
                </div>
                <div className="col-3"></div>
            </div>
        </div>
    );
};

export default Terms;
