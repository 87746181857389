import pwcLogo from "../../assets/pwc.svg";
import "./Header.css";

const Header = () => {
    return (
        <div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-1"></div>
                    <header className="col pad-header">
                        <img
                            src={pwcLogo}
                            className="pad-logo"
                            alt="PwC Logo"
                        ></img>
                        <h4>License Viewpoint / Disclosure Checklist</h4>
                    </header>
                    <div className="col-1"></div>
                </div>
            </div>
            <div className="border-bottom"></div>
        </div>
    );
};

export default Header;
