import { Banner } from "appkit-react";
import { LOOKUP_MESSAGE_STATES } from "../../../Constants";
import { EXTERNAL_URLS } from "../../../../common/Constants";
import "./Messaging.css";

const Messaging = ({ product, formState, setFormState }) => {
    const passMessage = (
        <div className="half-bold">
            {product.label} can be licensed to this client.
        </div>
    );

    const passBanner = (
        <Banner
            content={passMessage}
            closeable={true}
            status="success"
            className="toaster"
        />
    );

    const notFoundMessage = (
        <div className="half-bold">
            Sorry, we couldn't find the client opportunity you entered. Please
            make sure to copy the complete URL and try again.
        </div>
    );

    const notFoundBanner = (
        <Banner
            content={notFoundMessage}
            closeable={true}
            className="toaster toaster-red"
            onClose={() =>
                setFormState({
                    ...formState,
                    lookupMessage: ""
                })
            }
        />
    );

    const errorMessage = (
        <div className="half-bold">
            Digital Client Acceptance failed due to system errors. Please try
            again.
        </div>
    );

    const errorMessageBanner = (
        <Banner
            content={errorMessage}
            closeable={true}
            className="toaster toaster-red"
            onClose={() =>
                setFormState({
                    ...formState,
                    lookupMessage: ""
                })
            }
        />
    );

    const failMessage = (
        <div className="half-bold">
            PwC is currently unable to offer {product.label} to{" "}
            {formState.clientAccount?.name}.{" "}
            <a
                href={EXTERNAL_URLS.taFail}
                target="_blank"
                rel="noreferrer"
                className="banner-a"
            >
                Click here
            </a>{" "}
            for more information.
        </div>
    );

    const failBanner = (
        <Banner
            content={failMessage}
            closeable={true}
            className="toaster toaster-red"
        />
    );

    const secondaryMessage = (
        <div className="half-bold">
            Additional review is required to offer {product.label} to{" "}
            {formState.clientAccount?.name} due to incomplete or inconsistent
            information.{" "}
            <a
                href={EXTERNAL_URLS.taSecondary}
                target="_blank"
                rel="noreferrer"
                className="banner-a"
            >
                Click here
            </a>{" "}
            for more information or{" "}
            <a
                href={EXTERNAL_URLS.taCES}
                target="_blank"
                rel="noreferrer"
                className="banner-a"
            >
                review details in CES
            </a>
            .
        </div>
    );

    const secondaryBanner = (
        <Banner
            content={secondaryMessage}
            closeable={true}
            className="toaster toaster-red"
        />
    );

    const notSupportedMessage = (
        <div className="half-bold">
            The standard SLP order form is not supported for this client. Once
            the contract has been created, please complete it offline and upload
            the signed contract to SLP.{" "}
            <a
                href={EXTERNAL_URLS.taNotSupported}
                target="_blank"
                rel="noreferrer"
                className="banner-a"
            >
                Click here
            </a>{" "}
            for more information.
        </div>
    );

    const notSupportedBanner = (
        <Banner
            content={notSupportedMessage}
            closeable={true}
            status="warning"
            className="toaster"
        />
    );

    const afsNotificationMessage = (
        <div className="half-bold">
            Authorization for Services (AFS) notification is required.{" "}
            <a
                href={EXTERNAL_URLS.taAFSNotification}
                target="_blank"
                rel="noreferrer"
                className="banner-a"
            >
                Click here
            </a>{" "}
            for more information.
        </div>
    );

    const afsNotificationBanner = (
        <Banner
            content={afsNotificationMessage}
            closeable={true}
            status="warning"
            className="toaster"
        />
    );

    const afsInformationMessage = (
        <div className="half-bold">
            Authorization for Services (AFS) information is required.
        </div>
    );

    const afsInformationBanner = (
        <Banner
            content={afsInformationMessage}
            closeable={true}
            status="warning"
            className="toaster"
        />
    );

    const messageToBanner = {
        [LOOKUP_MESSAGE_STATES.NOT_FOUND]: notFoundBanner,
        [LOOKUP_MESSAGE_STATES.TA_FAIL]: failBanner,
        [LOOKUP_MESSAGE_STATES.TA_ERROR]: errorMessageBanner,
        [LOOKUP_MESSAGE_STATES.AFS_NOTIFICATION_REQUIRED]:
            afsNotificationBanner,
        [LOOKUP_MESSAGE_STATES.AFS_INFORMATION_REQUIRED]: afsInformationBanner,
        [LOOKUP_MESSAGE_STATES.PASS_NA]: passBanner,
        [LOOKUP_MESSAGE_STATES.SECONDARY_NA]: secondaryBanner,
        [LOOKUP_MESSAGE_STATES.NOT_SUPPORTED]: notSupportedBanner
    };

    return <>{messageToBanner[formState.lookupMessage]}</>;
};

export default Messaging;
