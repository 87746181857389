import { useState } from "react";
import { CardTitle } from "../../CardTitle";
import { ANNUAL_SUBSCRIPTION_FEE } from "./Constants";
import { formatNumber } from "../../../../../common/Util";
import { CONTRACT_FIELD, CONTRACT_TYPE } from "../../Constants";

const LISTEN = (props) => {
    const [detailShow, setDetailShow] = useState(false);
    const { orderDetails, productId } = props;

    const annualSubscriptionFee =
        ANNUAL_SUBSCRIPTION_FEE[orderDetails[CONTRACT_FIELD[productId]]];
    const productPrice =
        orderDetails[CONTRACT_FIELD[productId]] ===
        CONTRACT_TYPE.customerExperience
            ? orderDetails.productPrice
            : orderDetails.productPrice +
              orderDetails.headCountProductPrice *
                  orderDetails.headCountProductQyt;
    const responses = formatNumber(6 * orderDetails.headCountProductQyt, "");
    // prevent Initial term card bottom radius bug when there is no RenewalTerm1Value
    const borderBottomRadius = "border-radius-b";

    return (
        <div className="p-t-26 m-b-28">
            <div className="m-h-24 flex-justify-between">
                <CardTitle
                    iconClass="icon-calendar-outline"
                    text="Initial Term"
                />
                <div
                    onClick={() => setDetailShow(!detailShow)}
                    className="orange text-12 half-bold a-hover underline text-bold"
                >
                    {detailShow ? "Hide Details" : "View Details"}
                </div>
            </div>
            <ul className="m-h-24 ul-clear-left-p">
                {orderDetails.productPrice ? (
                    <li className="p-t-15">
                        <div className="flex-justify-between text-12 half-bold gray">
                            <h6 className="text-12 half-bold mb-0">
                                Annual Subscription Fee
                            </h6>
                            <span>
                                {formatNumber(orderDetails.productPrice)}
                            </span>
                        </div>
                        {detailShow ? (
                            <div>
                                {annualSubscriptionFee.map((item, key) => (
                                    <div
                                        className="gray-text-10 half-bold one-time-fee  w-percent-70"
                                        key={"asf-key-" + key}
                                    >
                                        <p className="mb-2">{item.title}</p>
                                        <ul>
                                            {item.text.map(
                                                (subitem, subkey) => (
                                                    <li
                                                        className="p-l-85-em"
                                                        key={
                                                            "asf-key-" +
                                                            key +
                                                            subkey
                                                        }
                                                    >
                                                        {subitem}
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    </div>
                                ))}
                            </div>
                        ) : null}
                    </li>
                ) : null}
                {orderDetails[CONTRACT_FIELD[productId]] ===
                    CONTRACT_TYPE.employeeExperience && (
                    <>
                        <li className="p-t-15">
                            <div className="flex-justify-between text-12 half-bold gray">
                                <h6 className="text-12 half-bold mb-0">
                                    Employee Headcount Number
                                </h6>
                                <span>
                                    {formatNumber(
                                        orderDetails.headCountProductQyt,
                                        ""
                                    )}
                                </span>
                            </div>
                        </li>

                        <li className="p-t-15">
                            <div className="flex-justify-between text-12 gray half-bold">
                                <h6 className="text-12 half-bold mb-0">
                                    Headcount Fee (
                                    {formatNumber(
                                        orderDetails.headCountProductPrice
                                    )}
                                    {".00 "}
                                    per employee)
                                </h6>
                                <span>
                                    {formatNumber(
                                        orderDetails.headCountProductPrice *
                                            orderDetails.headCountProductQyt
                                    )}
                                </span>
                            </div>
                            {detailShow ? (
                                <div>
                                    <div className="gray-text-10 half-bold one-time-fee  w-percent-70">
                                        <ul>
                                            <li>
                                                <p className="clear-margin-bottom">
                                                    Includes up to {responses}{" "}
                                                    responses per annual period
                                                    of the Term. Responses over
                                                    this number will be billed
                                                    at US$0.50 per response.
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            ) : null}
                        </li>
                    </>
                )}
            </ul>
            <div
                className={`bg-black white flex-justify-between p-h-24 p-v-15 m-t-15 text-bold ${borderBottomRadius}`}
            >
                <h3 className="text-16 clear-margin-bottom text-bold">
                    Subtotal before tax
                </h3>
                <span>{formatNumber(productPrice)} USD</span>
            </div>
        </div>
    );
};

export default LISTEN;
