import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Pagination } from "appkit-react";

import SortingTable from "./SortingTable";
import { getOrders } from "../../service/External";
import { gotoError } from "../../../../Routes";
import { ORDER_TABLE_DATA } from "./Constants";

const TableWrapper = (props) => {
    const { tabId, columns } = props;

    const [tableData, setTableData] = useState(ORDER_TABLE_DATA);
    const [ordersLoaded, setOrdersLoaded] = useState(false);

    const navigate = useNavigate();
    useEffect(() => {
        const callback = (success, result) => {
            if (success) {
                setTableData((current) => {
                    return {
                        ...current,
                        orders: result.orders ?? [],
                        total: result.totalNumberOfRecords
                    };
                });
                setOrdersLoaded(true);
            } else {
                gotoError(navigate);
            }
        };

        setOrdersLoaded(false);
        getOrders(
            props.product.id,
            tabId,
            // table component page starts at 1, back end index starts at 0
            tableData.pageNumber - 1,
            tableData.pageSize,
            callback
        );
    }, [
        navigate,
        tabId,
        tableData.pageNumber,
        tableData.pageSize,
        props.product.id
    ]);

    return (
        <div className="row">
            {ordersLoaded ? (
                <div>
                    <SortingTable
                        columns={columns}
                        data={tableData.orders}
                        tabId={tabId}
                        product={props.product}
                    />
                    <div className="d-flex justify-content-end p-b-185">
                        <Pagination
                            defaultPageSize={tableData.pageSize}
                            defaultCurrent={1}
                            total={tableData.total}
                            current={tableData.pageNumber}
                            onChange={(eventType, pageNumber) =>
                                setTableData((current) => {
                                    return {
                                        ...current,
                                        pageNumber: pageNumber
                                    };
                                })
                            }
                        />
                    </div>
                </div>
            ) : (
                <div className="d-flex justify-content-center p-v-128">
                    <div className="a-loading a-primary a-m-10"></div>
                </div>
            )}
        </div>
    );
};

export default TableWrapper;
