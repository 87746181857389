import "./Opportunity.css";
import { LOOKUP_MESSAGE_STATES } from "../../../Constants";
import { greenIcon, yellowIcon, redIcon } from "../../common/order/StatusIcons";
import ProductCard from "../../common/order/ProductCard";
import AccountInfo from "../../common/order/AccountInfo";
import { EXTERNAL_URLS } from "../../../../common/Constants";
import AFSSummary from "./AFSSummary";

const Opportunity = ({ props, hideWrongEntityLink, showAFSSummary }) => {
    const { formState, formData } = props;

    const messageToIcon = {
        [LOOKUP_MESSAGE_STATES.NOT_FOUND]: redIcon,
        [LOOKUP_MESSAGE_STATES.TA_FAIL]: redIcon,
        [LOOKUP_MESSAGE_STATES.TA_ERROR]: redIcon,
        [LOOKUP_MESSAGE_STATES.AFS_NOTIFICATION_REQUIRED]: yellowIcon,
        [LOOKUP_MESSAGE_STATES.AFS_INFORMATION_REQUIRED]: yellowIcon,
        [LOOKUP_MESSAGE_STATES.PASS_NA]: greenIcon,
        [LOOKUP_MESSAGE_STATES.PASS_NO_BANNER]: greenIcon,
        [LOOKUP_MESSAGE_STATES.SECONDARY_NA]: redIcon,
        [LOOKUP_MESSAGE_STATES.NOT_SUPPORTED]: yellowIcon
    };

    // redIcon => no continue button => need bottom padding
    const enclosingClasses =
        messageToIcon[props.formState.lookupMessage] === redIcon
            ? "row gray-background p-b-125"
            : "row gray-background";

    return (
        <div className={enclosingClasses}>
            <div className="col-3"></div>
            <div className="col-6 flex-justify-center">
                <div
                    className={
                        hideWrongEntityLink ? "content p-b-8" : "content"
                    }
                >
                    <ProductCard
                        icon={messageToIcon[formState.lookupMessage]}
                        label={props.product.label}
                    />
                    <div className="gray-text-12 m-b-12 m-t-32">
                        CLIENT OPPORTUNITY
                    </div>
                    <div className="round-card half-bold">
                        <a
                            href={
                                EXTERNAL_URLS.gCRMbaseURL +
                                formState.clientOpportunity.id
                            }
                            target="_blank"
                            rel="noreferrer"
                            className="orange text-12"
                        >
                            {formState.clientOpportunity.name}
                        </a>
                        <div className="separator-line"></div>
                        <AccountInfo clientAccount={formState.clientAccount} />
                        <div className="separator-line"></div>
                        <div className="light-gray-text-12">PRID</div>
                        <div className="gray-text-16">
                            {formState.clientAccount.prid}
                        </div>
                        <div className="separator-line"></div>
                        <div className="light-gray-text-12">
                            Opportunity Owner
                        </div>
                        <div className="gray-text-16">
                            {formState.clientOpportunity.owner}
                        </div>
                        <div className="text-12 orange p-v-8">
                            {formState.clientOpportunity.email}
                        </div>
                        <div className="text-12 orange">
                            {formState.clientOpportunity.phone}
                        </div>
                    </div>
                    {!hideWrongEntityLink && (
                        <div
                            className="right orange text-12 bold clickable underline m-v-8"
                            onClick={props.resetLookup}
                        >
                            Wrong entity?
                        </div>
                    )}
                    {showAFSSummary && <AFSSummary formData={formData} />}
                </div>
            </div>
            <div className="col-3"></div>
        </div>
    );
};

export default Opportunity;
