import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "appkit-react/lib/Button/Button";
import Modal from "appkit-react/lib/Modal/Modal";
import ModalHeader from "appkit-react/lib/Modal/ModalHeader";
import ModalBody from "appkit-react/lib/Modal/ModalBody";
import ModalFooter from "appkit-react/lib/Modal/ModalFooter";
import { CalendarPicker } from "appkit-react-datepicker";

import useIsMounted from "../../../common/UseIsMounted";
import { terminateOrder } from "../view/service/External";
import { newDateEST } from "./../../../common/Util";
import "./GeneralOrderModal.css";
import "../view/summary/Summary.css";
import { gotoError, gotoTerminatedOrder } from "../../../../Routes";

const TerminateOrderModal = (props) => {
    const { isVisible, setIsVisible, product, opportunityId, client, endDate } =
        props;

    const isMounted = useIsMounted();
    const [validDate, setValidDate] = useState(false);
    const [date, setDate] = useState();
    const [submitting, setSubmitting] = useState(false);
    const navigate = useNavigate();

    const handleCancel = () => {
        if (isMounted()) setIsVisible(false);
    };

    const callback = (success, result) => {
        if (success) {
            if (isMounted()) setIsVisible(false);
            gotoTerminatedOrder(
                navigate,
                product.id,
                opportunityId,
                client,
                date
            );
        } else {
            gotoError(navigate);
        }
    };
    const handleConfirm = () => {
        setSubmitting(true);
        terminateOrder(opportunityId, date, callback);
    };

    const nowEST = newDateEST();
    // set time to 00:00:00:000
    const from = new Date(
        nowEST.getFullYear(),
        nowEST.getMonth(),
        nowEST.getDate()
    );

    // set time to 00:00:00:001 as there is a bug in the CalendarPicker:
    //   the left / right arrows only work if the to date is > midnight
    //   on the first day of the month
    const end = new Date(endDate);
    const to = new Date(
        end.getFullYear(),
        end.getMonth(),
        end.getDate(),
        0,
        0,
        0,
        1
    );

    return (
        <Modal
            className="terminate-modal"
            visible={isVisible}
            onCancel={handleCancel}
        >
            <ModalHeader>
                <span>
                    <span>
                        <div className="modal-title">Terminate order</div>
                    </span>
                    <span>
                        <div className="modal-description">
                            Please select a date when the client subscription
                            should be terminated. Once the order is terminated,
                            please follow up with the internal product team to
                            complete the cancellation process
                        </div>
                    </span>
                </span>
            </ModalHeader>
            <ModalBody>
                <div className="gray-text-12">
                    <div className="modal-separator-line"></div>
                    <div className="p-b-10">TERMINATION DATE</div>
                    <CalendarPicker
                        value={date}
                        onValueChange={(isValid, value) => {
                            setValidDate(isValid && !!value);
                            setDate(new Date(value));
                        }}
                        mode="large"
                        editable={false}
                        format="mm/dd/yyyy"
                        minDate={from}
                        maxDate={to}
                        className="calendar-picker-w-100"
                    />
                    <div className="modal-separator-line"></div>
                </div>
            </ModalBody>
            <ModalFooter>
                <div>
                    <Button
                        kind="secondary"
                        onClick={handleCancel}
                        size="lg"
                        disabled={submitting}
                    >
                        CANCEL
                    </Button>
                    <Button
                        kind="primary"
                        onClick={handleConfirm}
                        size="lg"
                        className="m-l-24"
                        disabled={!validDate}
                        isLoading={submitting}
                    >
                        TERMINATE
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    );
};
export default TerminateOrderModal;
