// Order initial terms template
import DOD from "./DOD";
import WFO from "./WFO";
import LISTEN from "./LISTEN";
import CN from "./CN";

const InitialTermProduct = {
    DOD,
    WFO,
    LISTEN,
    CN
};
export default InitialTermProduct;
