import LabelErrorWrapper, {
    WRAPPED_COMPONENT_TYPE
} from "./LabelErrorWrapper.js";

const WrappedInput = (props) => {
    const wrappedInput = (
        <input
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
            placeholder={props.hint}
            name={props.name}
            aria-label={props.name}
            type={WRAPPED_COMPONENT_TYPE.INPUT}
            maxLength={props.maxLength}
            disabled={props.disabled}
        />
    );

    let hasError = props.hasError;
    let errorMessage = [props.errorMessage];

    // handle multiple validations / error messages per field
    if (Array.isArray(props.hasError) && Array.isArray(props.errorMessage)) {
        hasError = false;
        errorMessage = [];
        for (let i = 0; i < props.hasError.length; ++i) {
            if (props.hasError[i]) {
                hasError = true;
                errorMessage.push(props.errorMessage[i]);
            }
        }
    }

    return (
        <LabelErrorWrapper
            wrappedComponent={wrappedInput}
            label={props.label}
            hasError={hasError}
            errorMessage={errorMessage}
            className={props.className}
            primary={props.primary}
        />
    );
};

export default WrappedInput;
