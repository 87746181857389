// Order Payment Terms template
import DOD from "./DOD";
import WFO from "./WFO";
import LISTEN from "./LISTEN";
import CN from "./CN";
const PAYMENT_TERMS = {
    DOD,
    WFO,
    LISTEN,
    CN
};

export default PAYMENT_TERMS;
