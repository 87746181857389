import { useState } from "react";
import Button from "appkit-react/lib/Button/Button";
import FullScreenModal from "../../common/FullScreenModal";

const EditButtons = (props) => {
    const [submitting, setSubmitting] = useState(false);

    const handleSendContract = () => {
        setSubmitting(true);
        props.handleUpdateOrder();
    };

    return (
        <>
            <div className="row p-b-185">
                <div className="col-3"></div>
                <div className="col-6 flex-justify-center">
                    <div className="content">
                        <div className="nav-buttons m-t-36">
                            <Button
                                onClick={props.showDiscardAllModal}
                                kind={"secondary"}
                            >
                                DISCARD
                            </Button>
                            <Button onClick={handleSendContract}>
                                SEND CONTRACT
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="col-3"></div>
            </div>
            {submitting && (
                <FullScreenModal
                    content={"Just a moment while we submit the order..."}
                />
            )}
        </>
    );
};

export default EditButtons;
