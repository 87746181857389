import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Tab, Tabs } from "appkit-react/lib/Tabs";

import Header from "../../../Header";
import TableWrapper from "./TableWrapper";
import Footer from "../../../Footer";
import { gotoManage } from "../../../../Routes";
import useProduct from "../common/UseProduct";
import { COLUMNS, TAB } from "./Constants";
import "./ManageOrder.css";

const ManageOrder = (props) => {
    const product = useProduct();

    const { tabId } = useParams();
    const [activeTab, setActiveTab] = useState("");

    useEffect(() => {
        if (tabId != null) {
            setActiveTab(tabId);
        }
    }, [tabId]);

    const navigate = useNavigate();
    const changeTab = (name) => {
        setActiveTab(TAB[name].id);
        gotoManage(navigate, product.id, TAB[name].id);
    };

    const tabComponents = {
        [TAB.active.id]: (
            <Tab
                value={TAB.active.id}
                icon={TAB.active.label}
                key={TAB.active.id}
            >
                {activeTab === TAB.active.id && (
                    <TableWrapper
                        product={product}
                        tabId={TAB.active.id}
                        columns={COLUMNS.ACTIVE}
                    />
                )}
            </Tab>
        ),
        [TAB.pending.id]: (
            <Tab
                value={TAB.pending.id}
                icon={TAB.pending.label}
                key={TAB.pending.id}
            >
                {activeTab === TAB.pending.id && (
                    <TableWrapper
                        product={product}
                        tabId={TAB.pending.id}
                        columns={COLUMNS.PENDING}
                    />
                )}
            </Tab>
        ),
        [TAB.draft.id]: (
            <Tab value={TAB.draft.id} icon={TAB.draft.label} key={TAB.draft.id}>
                {activeTab === TAB.draft.id && (
                    <TableWrapper
                        product={product}
                        tabId={TAB.draft.id}
                        columns={COLUMNS.DRAFT}
                    />
                )}
            </Tab>
        ),
        [TAB.renewal.id]: (
            <Tab
                value={TAB.renewal.id}
                icon={TAB.renewal.label}
                key={TAB.renewal.id}
            >
                {activeTab === TAB.renewal.id && (
                    <TableWrapper
                        product={product}
                        tabId={TAB.renewal.id}
                        columns={COLUMNS.RENEWAL}
                    />
                )}
            </Tab>
        ),
        [TAB.afs.id]: (
            <Tab value={TAB.afs.id} icon={TAB.afs.label} key={TAB.afs.id}>
                {activeTab === TAB.afs.id && (
                    <TableWrapper
                        product={product}
                        tabId={TAB.afs.id}
                        columns={COLUMNS.AFS}
                    />
                )}
            </Tab>
        )
    };

    const tabs = [];
    for (const tab of product.tabs) {
        tabs.push(tabComponents[tab.id]);
    }

    return (
        <div>
            <Header />
            <div className="row g-0">
                <div className="col-1"></div>
                <div className="col-10">
                    <div className="p-b-16 p-t-32 text-22 half-bold">
                        All {product.label} orders
                    </div>
                    <div className="tabs-wrapper">
                        <Tabs
                            className="tabs-container"
                            defaultValue={activeTab}
                            value={activeTab}
                            onChange={(e, value) => changeTab(value)}
                            size="md"
                        >
                            {tabs}
                        </Tabs>
                    </div>
                </div>
                <div className="col-1"></div>
            </div>
            <Footer />
        </div>
    );
};
export default ManageOrder;
