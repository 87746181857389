import IndustrialRGBLogo from "../../assets/industrial.svg";
import Header from "../Header";
import { MESSAGES } from "../common/Constants";
import Footer from "../Footer";
import "./ScheduledMaintenance.css";

const ScheduledMaintenance = (props) => {
    return (
        <div>
            <Header />
            <div className="d-flex flex-center">
                <div className="col-5 column-pad text-center">
                    <div className="text-center a-font-32 text-pad text-32">
                        We are sorry...
                    </div>

                    <div className="text-center a-font-18 text-pad">
                        The system is currently down for maintenance.
                    </div>
                    <div className="text-center a-font-18">
                        Please try again later to submit your order request.
                    </div>

                    <div className="orange bold a-font-18 text-pad">
                        Estimated system recovery time:
                    </div>
                    <div className="bold">{MESSAGES.maintenance}</div>
                    <img
                        src={IndustrialRGBLogo}
                        className="responsive w-261 m-t-59 m-b-144"
                        alt="Scheduled Maintainance Logo"
                    />
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ScheduledMaintenance;
