import dollar from "../../../../../assets/dollar-sign.svg";

const OrderIconHeader = () => {
    return (
        <div className="review-icon-container col-6">
            <div className="step-icon active-icon review-icon">
                <img src={dollar} alt="Order Icon"></img>
            </div>
            <span className="p-l-8 text-22 half-bold">Order</span>
        </div>
    );
};

export default OrderIconHeader;
