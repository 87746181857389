import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import Button from "appkit-react/lib/Button/Button";
import Checkbox from "appkit-react/lib/Checkbox/Checkbox";
import Header from "../../../../Header";
import Menu, { MENU_LABELS } from "../common/Menu";
import SupportBanner from "../../SupportBanner";
import Footer from "../../../../Footer";
import Edit from "../edit/EditSummary";
import { NEW_ORDER_DATA, NEW_ORDER_STATE } from "../../../Constants";
import { resendContract } from "../service/External";
import useViewOrderInfo from "../../common/order/UseViewOrderInfo";
import "../summary/Summary.css";
import {
    gotoError,
    gotoManageDefault,
    gotoResentOrder,
    gotoViewOrder
} from "../../../../../Routes";

const Resend = (props) => {
    const { opportunityId } = useParams();

    const navigate = useNavigate();
    const [checked, setChecked] = useState(false);
    const [product, setProduct] = useState({});
    const [submissionInfo, setSubmissionInfo] = useState({});
    const [formData, setFormData] = useState(NEW_ORDER_DATA);
    const [formState, setFormState] = useState(NEW_ORDER_STATE);
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);

    useViewOrderInfo(
        opportunityId,
        setSubmissionInfo,
        setProduct,
        setFormData,
        setFormState,
        null,
        setLoading
    );

    const onViewOrder = () => {
        gotoViewOrder(navigate, product.id, opportunityId);
    };
    const gotoAllOrders = () => {
        gotoManageDefault(navigate, product);
    };
    const menuLabels = [
        MENU_LABELS.ALL_ORDERS,
        MENU_LABELS.VIEW_ORDER,
        MENU_LABELS.RESEND_ORDER
    ];
    const menuActions = [gotoAllOrders, onViewOrder];

    const callback = (success, result) => {
        if (success) {
            gotoResentOrder(
                navigate,
                product.id,
                opportunityId,
                submissionInfo.client
            );
        } else {
            gotoError(navigate);
        }
    };
    const handleResend = () => {
        setSubmitting(true);
        resendContract(opportunityId, callback);
    };

    return (
        <div>
            {loading ? (
                <div className="flex-justify-center vh-65">
                    <div className="center">
                        <div className="a-loading a-primary loading-large"></div>
                        <div>Please wait while the order is loaded...</div>
                    </div>
                </div>
            ) : (
                <div>
                    <Header />
                    {menuLabels && menuActions && (
                        <Menu labels={menuLabels} actions={menuActions} />
                    )}
                    <Edit
                        header={"Resend order to client"}
                        product={product}
                        formState={formState}
                        formData={formData}
                        user={props.user}
                    />
                    <div className="flex-justify-center flex-column content p-t-36 p-b-185">
                        <div className="p-b-45">
                            <Checkbox
                                checked={checked}
                                name="agreeToTC"
                                onChange={() => setChecked(!checked)}
                            >
                                I acknowledge the information entered is correct
                                and understand an email will be sent to the
                                client signatory.
                            </Checkbox>
                        </div>
                        <div className="nav-buttons">
                            <Button
                                aria-label="Loading"
                                disabled={submitting}
                                kind="secondary"
                                onClick={onViewOrder}
                            >
                                CANCEL
                            </Button>
                            <Button
                                aria-label="Loading"
                                disabled={!checked || submitting}
                                kind="primary"
                                onClick={handleResend}
                                isLoading={submitting}
                                isLoadingBlocking={true}
                            >
                                RESEND
                            </Button>
                        </div>
                    </div>
                    <SupportBanner />
                    <Footer />
                </div>
            )}
        </div>
    );
};

export default Resend;
