const ProductCard = (props) => {
    return (
        <>
            <div className="gray-text-12 m-b-12 m-t-32">PRODUCT</div>
            <div className="round-card">
                {props.icon}
                <span className="gray-text-16 bold align-middle">{props.label}</span>
            </div>
        </>
    );
};

export default ProductCard;
