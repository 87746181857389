export const ORDER_STATUS = {
    ACTIVE: "Active",
    PENDING: "Pending",
    DRAFT: "Draft",
    CANCELED: "Cancelled",
    EXPIRED: "Expired",
    CONFIRMED: "Confirmed",
    PENDING_RENEWAL: "Pending renewal",
    AUTO_RENEW: "Auto-renew"
};

export const BUTTONS = {
    TERMINATE_ORDER: 1,
    SUBMIT_NEW_AFS: 2,
    WITHDRAW_ORDER: 3,
    RESEND_TO_CLIENT: 4,
    EDIT_ORDER: 5,
    SUBMIT_TO_CLIENT: 6,
    CANCEL_RENEWAL: 7,
    RENEW_SUBSCRIPTION: 8,
    UPLOAD_CONTRACT: 9
};
