import ContactDetails from "../review/ContactDetails";

const NonEditableContacts = ({ formData, product }) => {
    const nonEditableContacts = [];
    let i = 0;
    for (const contact of product.optionalComponent.contact
        .nonEditableContacts) {
        nonEditableContacts.push(
            <div key={i}>
                <div className="separator-line"></div>
                <ContactDetails
                    key={i++}
                    label={contact.label.toUpperCase()}
                    email={formData.nonEditableContacts[contact.id].email}
                />
            </div>
        );
    }

    return <>{nonEditableContacts}</>;
};

export default NonEditableContacts;
