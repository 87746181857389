import { CONTRACT_TYPE } from "../../Constants";
const DOD = {
    [CONTRACT_TYPE.fourtyFiveDays]: [
        {
            title: "Term",
            text: [
                "The Agreement will commence on the Order Effective Date and will continue through the Initial Term End Date below (“Initial Term”), unless earlier terminated in accordance with the Agreement. Thereafter, it will automatically renew for one (1)-year renewal terms (each, a “Renewal Term”) until a party provides the other with notice of non-renewal prior to the end of the Initial Term or at least thirty (30) days prior to the end of the then-current Renewal Term (as applicable)."
            ]
        },
        {
            title: "Initial Term End Date",
            text: [
                "The date that is forty-five (45) days after the Activation Date below."
            ]
        },
        {
            title: "Activation Date",
            text: [
                "The earlier of the date of the Digital on Demand Onboarding Session or the date that is two (2) weeks after the Order Effective Date."
            ]
        },
        {
            title: "User Accounts",
            text: [
                "PwC will establish User Accounts as set forth in the Offering Overview."
            ]
        }
    ],
    [CONTRACT_TYPE.ninetyDays]: [
        {
            title: "Term",
            text: [
                "The Agreement will commence on the Order Effective Date and will continue through the Initial Term End Date below (“Initial Term”), unless earlier terminated in accordance with the Agreement. Thereafter, it will automatically renew for one (1)-year renewal terms (each, a “Renewal Term”) until a party provides the other with notice of non-renewal prior to the end of the Initial Term or at least thirty (30) days prior to the end of the then-current Renewal Term (as applicable)."
            ]
        },
        {
            title: "Initial Term End Date",
            text: [
                "The date that is ninety (90) days after the Activation Date below."
            ]
        },
        {
            title: "Activation Date",
            text: [
                "The earlier of the date of the Digital on Demand Onboarding Session or the date that is two (2) weeks after the Order Effective Date."
            ]
        },
        {
            title: "User Accounts",
            text: [
                "PwC will establish User Accounts as set forth in the Offering Overview."
            ]
        }
    ],
    [CONTRACT_TYPE.twelveMonths]: [
        {
            title: "Term",
            text: [
                "The Agreement will commence on the Order Effective Date and will continue through the Initial Term End Date below (“Initial Term”), unless earlier terminated in accordance with the Agreement. Thereafter, it will automatically renew for one (1)-year renewal terms (each, a “Renewal Term”) until a party provides the other with notice of non-renewal at least thirty (30) days prior to the end of the Initial Term or the then-current Renewal Term (as applicable)."
            ]
        },
        {
            title: "Initial Term End Date",
            text: [
                "The date that is one (1) year after the Activation Date below."
            ]
        },
        {
            title: "Activation Date",
            text: [
                "The earlier of the date of the Digital on Demand Onboarding Session or the date that is two (2) weeks after the Order Effective Date."
            ]
        },
        {
            title: "User Accounts",
            text: [
                "PwC will establish User Accounts as set forth in the Offering Overview."
            ]
        }
    ]
};
export default DOD;
