import { Banner } from "appkit-react";
import { formatDate } from "../../../../common/Util";

export const AFSBanner = (props) => {
    const message =
        `${props.client}'s restriction status has changed. Please submit the new AFS ` +
        `information to continue licensing ${props.product} to ${props.client}.`;

    return (
        <Banner
            content={message}
            closeable={true}
            status="warning"
            className="toaster"
        />
    );
};

export const RenewalBanner = (props) => {
    const message =
        `${props.client}'s ${props.product} subscription ends ` +
        `on ${formatDate(props.endDate)}.`;

    return (
        <Banner
            content={message}
            closeable={true}
            className="toaster toaster-red"
        />
    );
};

export const AutoRenewalBanner = (props) => {
    const message =
        `${props.client}'s ${props.product} subscription automatically renews ` +
        `on ${formatDate(props.endDate)}.`;

    return (
        <Banner
            content={message}
            closeable={true}
            className="toaster toaster-red"
        />
    );
};

export const ConfirmedBanner = (props) => {
    const message =
        `${props.client}'s ${props.product} subscription period will be extended to ` +
        `${formatDate(props.endDate)}.`;

    return (
        <Banner
            content={message}
            closeable={true}
            status="success"
            className="toaster"
        />
    );
};

export const CanceledBanner = (props) => {
    const message = `${props.client}'s ${props.product} subscription has been canceled.`;

    return (
        <Banner
            content={message}
            closeable={true}
            className="toaster toaster-red"
        />
    );
};

export const ExpiredBanner = (props) => {
    const message =
        `${props.client}'s ${props.product} subscription expired on ` +
        `${formatDate(props.endDate)}.`;

    return (
        <Banner
            content={message}
            closeable={true}
            className="toaster toaster-red"
        />
    );
};
