import LineItem from "../../common/LineItem";
import WrappedInput from "../../common/WrappedInput";
import { CURRENCY_CODE_TO_SYMBOL, UNITED_STATES } from "../Constants";
import TCApprover from "./TCApprover";

const DisclosureChecklistPricing = ({ props }) => {
    const currencySymbol = CURRENCY_CODE_TO_SYMBOL.USD;
    const firstLicense = props.formState.firstLicense;

    return (
        <div>
            <div className="text-16 orange p-v-32">
                Effective July 1, the annual license fee for Disclosure Checklist will increase to $2,000.
                Each license includes up to 50 inputters*.
            </div>
            <div className="text-16 orange p-b-32">
                *Inputters refer to all available roles within Disclosure
                Checklist.
            </div>
            <h5>Individual company use pricing:</h5>
            <div className="d-flex justify-content-end p-v-8">
                <label htmlFor="firstLicense" className="m-r-24">
                    Flat fee for first license:
                </label>
                <div className="subtotal-w right p-r-10">
                    {currencySymbol}
                    {firstLicense}
                </div>
            </div>
            <LineItem
                name={"additionalLicenses"}
                label={"Additional Licenses:"}
                onChange={props.handleChange("additionalLicenses")}
                value={props.formData.additionalLicenses}
                total={props.formData.additionalLicensesTotal}
                currency={currencySymbol}
            />
            <div className="separator-line m-t-40"></div>
            <div className="row gx-0 px-0">
                <span className="m-r-18 col"></span>
                <span className="subtotal-w col-3 ">
                    <div className="text-12 m-b-12">TOTAL:</div>
                </span>
            </div>
            <div className="row gx-0 px-0 align-items-center">
                <span className="light-gray-text-12 m-r-18 col">
                    Total annual cost for all Disclosure Checklist licenses:
                </span>
                <span className="subtotal-w col-3 d-flex justify-content-end">
                    <div className="subtotal">
                        {currencySymbol}
                        {props.formData.disclosureChecklistTotal}
                    </div>
                </span>
            </div>
            <div className="separator-line m-t-40"></div>
            <div className="text-16 m-b-16">Required Information</div>
            <WrappedInput
                name="companyDomainNames"
                onChange={props.handleChange("companyDomainNames")}
                value={props.formData.companyDomainNames}
                hasError={props.validationErrors.companyDomainNames.error}
                errorMessage={props.validationErrors.companyDomainNames.message}
                label={"* COMPANY DOMAIN NAMES"}
                className="p-h-3"
            />
            <div className="light-gray-text-12 m-b-24 p-h-3">
                Kindly provide all domain names for company users that will need
                access to Disclosure Checklist (ie. @pwc.com; @us.pwc.com)
            </div>
            <div className="light-gray-text-12 m-b-24 p-h-3">
                The following individuals cannot use the tool: (a) if individual
                does not have a Company-issued email address utilizing the
                Company's domain name or (b) if individual is an employee or
                contractor of any entity that is a competitor of PwC (including
                any entity affiliated with Deloitte Touche Tohmatsu Limited, EY
                (formerly Ernst & Young), or KPMG).
            </div>
            <WrappedInput
                name="engagementTeamManager"
                onChange={props.handleChange("engagementTeamManager")}
                value={props.formData.engagementTeamManager}
                hasError={props.validationErrors.engagementTeamManager.error}
                errorMessage={
                    props.validationErrors.engagementTeamManager.message
                }
                label={"* PWC ENGAGEMENT TEAM MANAGER"}
                className="p-h-3"
            />
            <div className="light-gray-text-12 m-b-24 p-h-3">
                If you are not a PwC client, insert N/A
            </div>
            {props.formState.billingTerritory !== UNITED_STATES && (
                <TCApprover
                    handleChange={props.handleChange}
                    formData={props.formData}
                    validationErrors={props.validationErrors}
                />
            )}
        </div>
    );
};

export default DisclosureChecklistPricing;
