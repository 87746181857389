import WrappedSelect from "../../common/WrappedSelect";
import SelectOption from "appkit-react/lib/Select/SelectOption";
import { AUDITORS } from "../Constants";

const StateSelect = (props) => {
    const options = [];
    for (let i = 0; i < AUDITORS.length; ++i) {
        const auditors = AUDITORS[i];
        options.push(<SelectOption key={i} value={auditors}>{auditors}</SelectOption>);
    }

    return (
        <WrappedSelect
            name={props.name}
            label={props.label}
            placeholder={props.placeholder}
            hasError={props.hasError}
            errorMessage={props.errorMessage}
            options={options}
            onChange={props.onChange}
            value={props.value}
        />
    );
}

export default StateSelect;