import SupportInfo from "./SupportInfo";
import { UNITED_STATES, OTHER, PWC_CONTACT } from "../Constants";

const LeftColumnSubmitted = (props) => {
    let referralInfo = props.formData.hearAbout;
    if (props.formData.hearAbout === PWC_CONTACT) {
        referralInfo = props.formData.pwcContact
    }
    if (props.formData.hearAbout === OTHER) {
        referralInfo = props.formData.referralSource
    }

    return (
        <div>
            <h5 className="orange m-t-38 m-b-16">Contact Details</h5>
            <div className="round-card">
                <div className="gray-text-18 bold">{props.formData.firstName} {props.formData.lastName}</div>
                <div className="separator-line m-t-12 m-b-8"></div>
                <div className="text-14">{props.formData.phone}</div>
                <div className="text-14 orange m-v-4 bold">{props.formData.email}</div>
                <div className="title m-v-4">{props.formData.title.toUpperCase()}</div>
                <div className="separator-line m-v-32"></div>
                <div className="gray-text-18 bold">{props.formData.companyName}</div>
                <div className="text-14 m-v-3">{props.formData.parentCompany}</div>
                <div className="text-14 m-v-3">{props.formData.country}</div>
                <div className="text-14 m-v-3">{props.formData.addressLine1}</div>
                <div className="text-14 m-v-3">{props.formData.addressLine2}</div>
                <div className="text-14 m-v-3">{props.formData.city}</div>
                <div className="text-14 m-v-3">
                    {(props.formData.country === UNITED_STATES)
                        ? props.formData.usState
                        : props.formData.state}
                </div>
                <div className="text-14 m-v-3">{props.formData.zip}</div>
                <div className="separator-line m-v-32"></div>
                <div className="text-14 m-v-3">External Auditor: {
                    (props.formData.externalAuditor === OTHER)
                        ? props.formData.otherAuditor
                        : props.formData.externalAuditor}
                </div>
                <div className="text-14 m-v-3">
                    Subject to US SEC or US GAO Rules: {props.formData.rules}
                </div>
                <div className="separator-line m-v-32"></div>
                <div className="gray-text-18 bold">Referral Information:</div>
                <div className="text-14 m-v-3">{referralInfo}</div>
            </div>
            <div className="separator-line m-v-48"></div>
            <SupportInfo />
            <div className="m-b-224">{"\u00A0"}</div>
        </div>
    );
};

export default LeftColumnSubmitted;