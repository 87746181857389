import { CONTRACT_TYPE } from "../../Constants";
const WFO = {
    [CONTRACT_TYPE.frontlineWorkers]: [
        {
            title: "Additional Service Description with Price",
            desc: [
                {
                    sub: "Standard Tableau dashboard (published on PwC’s Workbench)",
                    value: "$2,600"
                },
                {
                    sub: "Custom Tableau dashboard: PwC will, for the minimum fee set forth in the next column plus time and materials basis, develop and deploy a custom Tableau Dashboard (published on PwC’s Workbench), as described more fully in an SOW attached to this Order.  Such services will be billed at a rate of [US$200.00] per hour*.",
                    value: "$3,100 + T&M per customization"
                },
                {
                    sub: "Data extraction to csv",
                    value: "$330 per extract"
                },
                {
                    sub: "WO experience templates",
                    value: "$950 per template"
                },
                {
                    sub: "Additional Activation Management or Customer Success hours: PwC will, on a time and materials basis, provide additional Activation Management or Customer Success hours, as described more fully in an SOW attached to this Order.  Such services will be billed at a rate of [US$200.00] per hour.*",
                    value: "$200 per hour"
                },
                {
                    sub: "Product services: PwC will, on a time and materials basis, provide additional product services (e.g., lead experience design, custom reporting / analytics),  as described more fully in an SOW attached to this Order.  Such services will be billed at a rate of [US$200.00] per hour.*",
                    value: "$200 per hour"
                }
            ]
        },
        {
            title: "",
            desc: [
                {
                    sub: "",
                    value: "*May not exceed 40 hours in the aggregate. Additional hours would require separate agreement."
                }
            ]
        }
    ],
    [CONTRACT_TYPE.officeWorkers]: [
        {
            title: "Additional Service Description with Price",
            desc: [
                {
                    sub: "Standard Tableau dashboard (published on PwC’s Workbench)",
                    value: "$2,600"
                },
                {
                    sub: "Custom Tableau dashboard: PwC will, for the minimum fee set forth in the next column plus time and materials basis, develop and deploy a custom Tableau Dashboard (published on PwC’s Workbench), as described more fully in an SOW attached to this Order.  Such services will be billed at a rate of [US$200.00] per hour*.",
                    value: "$3,100 + T&M per customization"
                },
                {
                    sub: "Data extraction to csv",
                    value: "$330 per extract"
                },
                {
                    sub: "WO experience templates",
                    value: "$950 per template"
                },
                {
                    sub: "Additional Activation Management or Customer Success hours: PwC will, on a time and materials basis, provide additional Activation Management or Customer Success hours, as described more fully in an SOW attached to this Order.  Such services will be billed at a rate of [US$200.00] per hour.*",
                    value: "$200 per hour"
                },
                {
                    sub: "Product services: PwC will, on a time and materials basis, provide additional product services (e.g., lead experience design, custom reporting / analytics),  as described more fully in an SOW attached to this Order.  Such services will be billed at a rate of [US$200.00] per hour.*",
                    value: "$200 per hour"
                }
            ]
        },
        {
            title: "",
            desc: [
                {
                    sub: "",
                    value: "*May not exceed 40 hours in the aggregate. Additional hours would require separate agreement."
                }
            ]
        }
    ]
};
export default WFO;
