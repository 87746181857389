import lock from "../../../../../assets/lock-open.svg";

const AcceptanceHeader = ({ showEdit, props }) => {
    return (
        <div className="row gray-background">
            <div className="col-3"></div>
            <div className="col-6 flex-justify-center p-t-28">
                <div className="content">
                    <div>
                        <div className="row">
                            <div className="review-icon-container col-6">
                                <div className="step-icon active-icon review-icon">
                                    <img src={lock} alt="Acceptance Icon"></img>
                                </div>
                                <span className="p-l-8 text-22 half-bold">
                                    Acceptance
                                </span>
                            </div>
                            {showEdit && (
                                <div className="col-6 right">
                                    <div className="m-t-4">
                                        <span className="text-12 half-bold orange p-r-8">
                                            Edit
                                        </span>
                                        <span
                                            className="appkiticon icon-edit-outline a-font-24 a-text-red v-middle clickable"
                                            onClick={props?.resetLookup}
                                        ></span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-3"></div>
        </div>
    );
};

export default AcceptanceHeader;
