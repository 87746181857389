import { PRODUCT_STAKEHOLDERS } from "../../../Constants";
import ContactIconHeader from "../../common/order/ContactIconHeader";
import ContactDetails from "./ContactDetails";

const Contact = ({ props }) => {
    const buildContact = (i, stakeholder, contacts) => {
        return (
            <ContactDetails
                key={i}
                stakeholder={stakeholder}
                label={PRODUCT_STAKEHOLDERS[stakeholder].label}
                email={contacts[stakeholder].email}
                fullName={contacts[stakeholder].fullName}
                region={contacts[stakeholder].region}
                market={contacts[stakeholder].market}
            />
        );
    };

    const contacts = [];
    let i = 0;
    if (props.formData.nonEditableContacts) {
        for (const key in props.formData.nonEditableContacts) {
            contacts.push(
                buildContact(i++, key, props.formData.nonEditableContacts)
            );
        }
    }

    if (props.formData.requiredContacts) {
        for (const key in props.formData.requiredContacts) {
            contacts.push(
                buildContact(i++, key, props.formData.requiredContacts)
            );
        }
    }

    if (props.formData.additionalContacts) {
        for (const key in props.formData.additionalContacts) {
            contacts.push(
                buildContact(i++, key, props.formData.additionalContacts)
            );
        }
    }

    return (
        <div>
            <div className="row">
                <ContactIconHeader />
                {props.gotoContact && (
                    <div className="col-6 right">
                        <div className="m-t-4">
                            <span className="text-12 half-bold orange p-r-8">
                                Edit
                            </span>
                            <span
                                className="appkiticon icon-edit-outline a-font-24 a-text-red v-middle clickable"
                                onClick={props.gotoContact}
                            ></span>
                        </div>
                    </div>
                )}
            </div>

            <div className="gray-text-12 m-b-12 m-t-32">SUBMITTER</div>
            <div className="round-card">
                <div className="text-16 half-bold">{props.user.name}</div>
                <div className="text-12 orange half-bold">
                    {props.user.email}
                </div>
            </div>
            {contacts}
        </div>
    );
};

export default Contact;
