import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { getAccess } from "./components/landing/service/External";
import useProduct from "./components/products/layout/common/UseProduct";
import { gotoAcessDenied, ROUTES } from "./Routes";

export const PrivateRoute = ({ children, user, page }) => {
    const product = useProduct();
    const [hasAccess, setHasAccess] = useState(false);
    const [accessCheckCompleted, setAccessCheckCompleted] = useState(false);

    // re-run access check if product changes
    useEffect(() => {
        setHasAccess(false);
        setAccessCheckCompleted(false);
    }, [product]);

    const navigate = useNavigate();
    const callback = (success, result) => {
        if (success) {
            setHasAccess(result);
            setAccessCheckCompleted(true);
        } else {
            gotoAcessDenied(navigate);
        }
    };

    let content = <></>;
    if (!accessCheckCompleted) {
        if (user && user.guid && product && product.id && page) {
            getAccess(user.guid, product.id, page, callback);
        } else {
            content = <Navigate to={ROUTES.accessDenied} replace={true} />;
        }
    } else {
        if (hasAccess) {
            content = children;
        } else {
            content = <Navigate to={ROUTES.accessDenied} replace={true} />;
        }
    }
    return content;
};
