// Order Additional Terms template
import DOD from "./DOD";
import WFO from "./WFO";
import LISTEN from "./LISTEN";
import CN from "./CN";
const ADDITIONAL_TERMS = {
    DOD,
    WFO,
    LISTEN,
    CN
};

export default ADDITIONAL_TERMS;
