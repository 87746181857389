import WrappedSelect from "../../../../common/WrappedSelect";
import SelectOption from "appkit-react/lib/Select/SelectOption";

const Select = (props) => {
    const options = [];
    for (let i = 0; i < props.options?.length; ++i) {
        options.push(
            <SelectOption key={i} value={props.options[i].value}>
                {props.options[i].label}
            </SelectOption>
        );
    }

    return (
        <WrappedSelect
            name={props.name}
            label={props.label}
            placeholder={props.placeholder}
            hasError={props.hasError}
            errorMessage={props.errorMessage}
            options={options}
            onChange={props.onChange}
            value={props.value}
            primary={props.primary}
            multiple={props.multiple}
        />
    );
};

export default Select;
