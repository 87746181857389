import { CONTRACT_TYPE } from "../../Constants";
const WFO = {
    [CONTRACT_TYPE.frontlineWorkers]: {
        preText:
            "This Order is subject to, and herein incorporates by this reference, the PricewaterhouseCoopers Standard Product Terms (including the Offering Overview and other materials incorporated by reference therein) available at ",
        lastText:
            " (“Standard Terms”). Together, the Order and the Standard Terms constitute the “Agreement” for Transparency Hub. Capitalized terms used and not otherwise defined in this Order are defined elsewhere in the Agreement.",
        url: "https://productcentral.products.pwc.com/legal/Hosted-Software-Terms"
    },
    [CONTRACT_TYPE.officeWorkers]: {
        preText:
            "This Order is subject to, and herein incorporates by this reference, the PricewaterhouseCoopers Standard Product Terms (including the Offering Overview and other materials incorporated by reference therein) available at ",
        lastText:
            " (“Standard Terms”). Together, the Order and the Standard Terms constitute the “Agreement” for Transparency Hub. Capitalized terms used and not otherwise defined in this Order are defined elsewhere in the Agreement.",
        url: "https://productcentral.products.pwc.com/legal/Hosted-Software-Terms"
    }
};
export default WFO;
