import { useNavigate, useParams } from "react-router-dom";
import { Success } from "./Success";
import orderSubmitted from "../../../../../assets/order-submitted.svg";
import { gotoViewOrder } from "../../../../../Routes";
import useProduct from "../../common/UseProduct";

const ContractUploaded = () => {
    const product = useProduct();
    const { opportunityId, clientName } = useParams();

    const subtitle = `The signed ${
        product.label
    } contract for ${decodeURIComponent(
        clientName
    )} has successfully been uploaded.`;

    const image = { src: orderSubmitted, width: "517px" };

    const navigate = useNavigate();
    const action = {
        text: "VIEW ORDER",
        onClick: () => gotoViewOrder(navigate, product.id, opportunityId)
    };

    return (
        <Success
            title="Contract uploaded"
            subtitle={subtitle}
            description="You can access this order in “Active” status."
            image={image}
            action={action}
        />
    );
};

export default ContractUploaded;
