import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Menu from "../common/Menu";
import ButtonsWrapper from "./ButtonsWrapper";
import Content from "./Content";

import TerminateOrderModal from "../../common/TerminateOrderModal";
import WithdrawModal from "../../common/WithdrawModal";
import RenewSubscriptionModal from "../../common/RenewSubscriptionModal";
import CancelRenewalModal from "../../common/CancelRenewalModal";

import { MENU_LABELS } from "../common/Menu";
import { ORDER_STATUS, BUTTONS } from "./Constants";
import OfflineContractingModal from "../../common/OfflineContractingModal";
import { gotoResendOrder, gotoSubmitAFS } from "../../../../../Routes";
import { PRODUCTS } from "../../../Config";

const Summary = (props) => {
    const { opportunityId, product, submissionInfo, gotoAllOrders } = props;

    const navigate = useNavigate();
    const [isTerminateModalVisible, setIsTerminateModalVisible] =
        useState(false);
    const [isWithdrawModalVisible, setIsWithdrawModalVisible] = useState(false);
    const [isRenewModalVisible, setIsRenewModalVisible] = useState(false);
    const [isCancelRenewModalVisible, setIsCancelRenewModalVisible] =
        useState(false);
    const [
        isOfflineContractingModalVisible,
        setIsOfflineContractingModalVisible
    ] = useState(false);

    const buttons = {
        [BUTTONS.TERMINATE_ORDER]: {
            label: "TERMINATE ORDER",
            action: () => setIsTerminateModalVisible(true),
            kind: "secondary",
            icon: <span className="appkiticon icon-end-process-outline"></span>,
            display: (submissionInfo) => {
                return (
                    submissionInfo.status === ORDER_STATUS.ACTIVE &&
                    product.id !== PRODUCTS.POD.id
                );
            }
        },
        [BUTTONS.SUBMIT_NEW_AFS]: {
            label: "SUBMIT NEW AFS",
            action: () => gotoSubmitAFS(navigate, product.id, opportunityId),
            kind: "primary",
            icon: <span className="appkiticon icon-news-fill"></span>,
            display: (submissionInfo) => {
                return (
                    submissionInfo.status === ORDER_STATUS.ACTIVE &&
                    submissionInfo.showSubmitNewAFS
                );
            }
        },
        [BUTTONS.WITHDRAW_ORDER]: {
            label: "WITHDRAW ORDER",
            action: () => setIsWithdrawModalVisible(true),
            kind: "secondary",
            icon: <span className="appkiticon icon-log-out-outline"></span>,
            display: (submissionInfo) => {
                return (
                    submissionInfo.status === ORDER_STATUS.PENDING &&
                    product.id !== PRODUCTS.POD.id
                );
            }
        },
        [BUTTONS.RESEND_TO_CLIENT]: {
            label: "RESEND TO CLIENT",
            action: () => gotoResendOrder(navigate, product.id, opportunityId),
            kind: "primary",
            icon: <span className="appkiticon icon-email-fill"></span>,
            display: (submissionInfo) => {
                return (
                    submissionInfo.status === ORDER_STATUS.PENDING &&
                    product.id !== PRODUCTS.POD.id
                );
            }
        },
        [BUTTONS.EDIT_ORDER]: {
            label: "EDIT ORDER",
            action: props.gotoEdit,
            kind: "secondary",
            icon: <span className="appkiticon icon-edit-outline"></span>,
            display: (submissionInfo) => {
                return submissionInfo.status === ORDER_STATUS.DRAFT;
            }
        },
        [BUTTONS.SUBMIT_TO_CLIENT]: {
            label: "SUBMIT TO CLIENT",
            action: () => {
                if (submissionInfo.displaySlpWarning) {
                    setIsOfflineContractingModalVisible(true);
                } else {
                    props.gotoSubmit();
                }
            },
            kind: "primary",
            icon: <span className="appkiticon icon-email-fill"></span>,
            display: (submissionInfo) => {
                return submissionInfo.status === ORDER_STATUS.DRAFT;
            }
        },
        [BUTTONS.CANCEL_RENEWAL]: {
            label: "CANCEL RENEWAL",
            action: () => setIsCancelRenewModalVisible(true),
            kind: "secondary",
            icon: <span className="appkiticon icon-end-process-outline"></span>,
            display: (submissionInfo) => {
                return (
                    (submissionInfo.status === ORDER_STATUS.PENDING_RENEWAL ||
                        submissionInfo.status === ORDER_STATUS.AUTO_RENEW) &&
                    product.id !== PRODUCTS.POD.id
                );
            }
        },
        [BUTTONS.RENEW_SUBSCRIPTION]: {
            label: "RENEW CLIENT SUBSCRIPTION",
            action: () => setIsRenewModalVisible(true),
            kind: "primary",
            icon: <span className="appkiticon icon-transform-outline"></span>,
            display: (submissionInfo) => {
                return submissionInfo.status === ORDER_STATUS.PENDING_RENEWAL;
            }
        },
        [BUTTONS.UPLOAD_CONTRACT]: {
            label: "UPLOAD CONTRACT",
            action: props.gotoUpload,
            kind: "secondary",
            icon: <span className="appkiticon icon-upload-outline"></span>,
            display: (submissionInfo) => {
                return submissionInfo.status === ORDER_STATUS.DRAFT;
            },
            instructions: (
                <div className="content p-t-24 flex-justify-center">
                    <div className="light-gray-text-14">
                        If offline contracting is required or additional terms
                        were negotiated, please upload the signed contract
                        within 14 days after contract signing.
                    </div>
                </div>
            )
        }
    };

    const menuLabels = [MENU_LABELS.ALL_ORDERS, MENU_LABELS.VIEW_ORDER];
    const menuActions = [gotoAllOrders];

    return (
        <div className="p-b-185">
            <Menu labels={menuLabels} actions={menuActions} />
            <div className="white-background flex-justify-center p-b-15">
                <div className="marging w-384 gray-text-22">
                    {props.submissionInfo.client}
                </div>
            </div>
            <div className="row gray-background p-v-24">
                <Content product={product} submissionInfo={submissionInfo} />
            </div>
            <ButtonsWrapper submissionInfo={submissionInfo} buttons={buttons} />
            {isTerminateModalVisible && (
                <TerminateOrderModal
                    isVisible={isTerminateModalVisible}
                    setIsVisible={setIsTerminateModalVisible}
                    product={product}
                    opportunityId={opportunityId}
                    client={submissionInfo.client}
                    endDate={submissionInfo.contractEndDate}
                />
            )}
            {isWithdrawModalVisible && (
                <WithdrawModal
                    isVisible={isWithdrawModalVisible}
                    setIsVisible={setIsWithdrawModalVisible}
                    product={product}
                    opportunityId={opportunityId}
                    client={submissionInfo.client}
                />
            )}
            {isRenewModalVisible && (
                <RenewSubscriptionModal
                    isVisible={isRenewModalVisible}
                    setIsVisible={setIsRenewModalVisible}
                    product={product}
                    opportunityId={opportunityId}
                    client={submissionInfo.client}
                    endDate={submissionInfo.contractEndDate}
                />
            )}
            {isCancelRenewModalVisible && (
                <CancelRenewalModal
                    isVisible={isCancelRenewModalVisible}
                    setIsVisible={setIsCancelRenewModalVisible}
                    product={product}
                    opportunityId={opportunityId}
                    client={submissionInfo.client}
                    endDate={submissionInfo.installBaseExpirationDate}
                />
            )}
            {isOfflineContractingModalVisible && (
                <OfflineContractingModal
                    isVisible={isOfflineContractingModalVisible}
                    setIsVisible={setIsOfflineContractingModalVisible}
                />
            )}
        </div>
    );
};

export default Summary;
