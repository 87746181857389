import DETAILS from "./templates/details/Details";
import PAYMENT_TERMS from "./templates/paymentTerms/PaymentTerms";
import ADDITIONAL_TERMS from "./templates/additionalTerms/AdditionalTerms";
import ADDITIONAL_SERVICE_FEES from "./templates/additionalServiceFees/AdditionalServiceFees";

import { ClientInfo } from "./ClientInfo";
import { InitialTerm } from "./InitialTerm";
import { RenewalTerm } from "./RenewalTerm";
import { CardTitle } from "./CardTitle";
import { CardDetail } from "./CardDetail";
import { ContactUsLink } from "./ContactUsLink";
import { OrderSummary } from "./OrderSummary";
import { CONTRACT_FIELD } from "./Constants";
import { PRODUCTS } from "../../Config";

const CardProduct = (props) => {
    const { product } = props;
    return (
        <div className="dod-bottom-line m-h-24 p-t-26">
            <CardTitle
                iconClass="icon-digital-transformation-outline"
                text={product.label}
                link={product.URL}
            />
        </div>
    );
};

const CardTerm = (props) => {
    const { orderDetails, product } = props;
    return (
        <div className="round-card white-round-card m-b-24 pa-0">
            <CardProduct product={product} />
            <InitialTerm orderDetails={orderDetails} productId={product.id} />
            <RenewalTerm orderDetails={orderDetails} productId={product.id} />
        </div>
    );
};

const CardPaymentTerms = (props) => {
    const { paymentTerms, productId } = props;

    return (
        <div className="round-card bg-white m-b-24">
            <CardTitle iconClass="icon-wealth-outline" text="Payment Terms" />
            <div
                className={
                    "m-t-16 text-12 gray half-bold text-justify " +
                    (productId === PRODUCTS.WFO.id ? "" : "one-time-fee")
                }
            >
                <ul
                    className={
                        productId === PRODUCTS.WFO.id ? "ul-clear-left-p" : ""
                    }
                >
                    {paymentTerms.map((item, key) => {
                        let finalItem = item.split("@$$$@");
                        let fln = finalItem.length;
                        return (
                            <li
                                key={"payment-" + key}
                                className={
                                    key === 0
                                        ? ""
                                        : fln > 1
                                        ? "clear-li-style m-t-18"
                                        : "m-t-18"
                                }
                            >
                                {finalItem.map((sub, subkey) => (
                                    <span
                                        key={"key-pt-" + key + subkey}
                                        className={
                                            fln > 1
                                                ? "payment-terms-" + productId
                                                : ""
                                        }
                                    >
                                        {fln > 1 ? (
                                            <>&nbsp;&nbsp;&nbsp;&nbsp;{sub}</>
                                        ) : (
                                            sub
                                        )}
                                        <br />
                                    </span>
                                ))}
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

const CardAddtionalServiceFees = (props) => {
    const { additionalServiceFees } = props;
    return additionalServiceFees.length ? (
        <div className="round-card bg-white m-b-24">
            <CardTitle
                iconClass="icon-profitability-outline"
                text="Additional Service Fees"
            />
            {additionalServiceFees.map((item, key) => (
                <div
                    key={"key-adf-" + key}
                    className={
                        " m-t-16 text-12 gray half-bold text-justify " +
                        (key === 0 && "one-time-fee")
                    }
                >
                    {item.title && (
                        <h4 className="text-14 darker half-bold">
                            {item.title}
                        </h4>
                    )}
                    <ul
                        className={
                            "text-12 gray half-bold text-justify clear-margin-top " +
                            (key !== 0 && "ul-clear-left-p")
                        }
                    >
                        {item.desc.map((ditem, dkey) => (
                            <li key={"at-" + key + dkey}>
                                {ditem.sub && (
                                    <>
                                        <span>{ditem.sub}</span>
                                        <br />
                                    </>
                                )}

                                <span className="gray-text-10 half-bold p-b-10 transfer-to-block">
                                    {ditem.value}
                                </span>
                            </li>
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    ) : null;
};

const CardAddtionalTerms = (props) => {
    const { additionalTerms } = props;
    return additionalTerms.length ? (
        <div className="round-card bg-white m-b-24">
            <CardTitle
                iconClass="icon-stewardship-outline"
                text="Additional Terms"
            />
            {additionalTerms.map((item, key) => (
                <div
                    key={"key-at-p-" + key}
                    className="m-t-16 text-12 gray half-bold text-justify"
                >
                    {item.title && (
                        <h4 className="text-14 darker half-bold">
                            {item.title}
                        </h4>
                    )}

                    {item.showList ? (
                        <p className="text-12 gray half-bold text-justify clear-margin-top one-time-fee">
                            <ul>
                                {item.desc.map((ditem, dkey) => (
                                    <li key={"at-" + key + dkey}>
                                        <span>{ditem}</span>
                                    </li>
                                ))}
                            </ul>
                        </p>
                    ) : (
                        <p className="text-12 gray half-bold text-justify clear-margin-top">
                            {item.desc.map((ditem, dkey) => (
                                <span key={"at-" + key + dkey}>
                                    <span>{ditem}</span>
                                    <br />
                                </span>
                            ))}
                        </p>
                    )}
                </div>
            ))}
        </div>
    ) : null;
};

const CardPwCLLC = () => {
    return (
        <div className="round-card bg-white m-b-125">
            <CardTitle
                iconClass="icon-building-outline"
                text="PwC Product Sales LLC"
                colClass="col-12"
            />
            <p className="text-14 darker half-bold m-t-18">
                300 Madison Avenue
                <br />
                New York, NY 10017
                <br />
                United States
            </p>
        </div>
    );
};

const ContactUs = (props) => {
    const { email } = props;
    return (
        <div className="p-h-24 m-t-18">
            <p className="text-12 half-bold gray">
                If you have any questions, please{" "}
                <ContactUsLink email={email} />.
            </p>
        </div>
    );
};

export const OrderDetail = (props) => {
    const { orderDetails, opportunityId, product } = props;

    const contractTypeField = CONTRACT_FIELD[product.id];
    const detailsProduct = DETAILS[product.id][orderDetails[contractTypeField]];

    const paymentTerms =
        PAYMENT_TERMS[product.id][orderDetails[contractTypeField]];
    const additionalTerms =
        ADDITIONAL_TERMS[product.id][orderDetails[contractTypeField]];
    const additionalServiceFees =
        ADDITIONAL_SERVICE_FEES[product.id][orderDetails[contractTypeField]];
    return (
        <div>
            <div className="container-fluid">
                <div className="light-gray-text-18 m-v-24 col-12 flex-center">
                    Please review the details below to complete your order.
                </div>
            </div>
            <div className="p-v-48 bg-lighter flex-justify">
                <div className="col-5 min-w-left">
                    <ClientInfo orderDetails={orderDetails} />
                    <CardTerm orderDetails={orderDetails} product={product} />
                    <CardDetail
                        detailsProduct={detailsProduct}
                        effectiveDate={orderDetails.EffectiveDate}
                    />
                    <CardPaymentTerms
                        paymentTerms={paymentTerms}
                        productId={product.id}
                    />
                    <CardAddtionalServiceFees
                        additionalServiceFees={additionalServiceFees}
                    />
                    <CardAddtionalTerms additionalTerms={additionalTerms} />
                    <CardPwCLLC />
                </div>
                <div className="col-3 m-l-24 min-w-right">
                    <OrderSummary
                        opportunityId={opportunityId}
                        orderDetails={orderDetails}
                        product={product}
                    />
                    <ContactUs
                        orderDetails={orderDetails}
                        email={product.email}
                    />
                </div>
            </div>
        </div>
    );
};
