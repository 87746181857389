import { EMAIL_REGEX } from "../common/Util";
import {
    INITIAL_FORM_DATA,
    NO,
    OTHER,
    PWC_CONTACT,
    UNITED_STATES,
} from "./Constants";

const VALIDATION_TYPE = Object.freeze({
    REQUIRED: "REQUIRED",
    PATTERN: "PATTERN",
    CONDITIONAL_REQUIRED: "CONDITIONAL_REQUIRED",
    CONDITIONAL_EXCLUDED: "CONDITIONAL_EXCLUDED",
    CONDITIONAL_PATTERN: "CONDITIONAL_PATTERN",
    CUSTOM: "CUSTOM"
});

// only use underscores to denote multiple validations for a single field
// firstName_1, firstName_2, etc
const validations = {
    firstName: {
        type: VALIDATION_TYPE.REQUIRED,
        errorMessage: "Please enter your First Name."
    },
    lastName: {
        type: VALIDATION_TYPE.REQUIRED,
        errorMessage: "Please enter your Last Name."
    },
    phone: {
        type: VALIDATION_TYPE.REQUIRED,
        errorMessage: "Please enter a Phone Number."
    },
    email: {
        type: VALIDATION_TYPE.PATTERN,
        pattern: EMAIL_REGEX,
        errorMessage: "Please enter an Email."
    },
    companyName: {
        type: VALIDATION_TYPE.REQUIRED,
        errorMessage: "Please enter your Company name."
    },
    parentCompany: {
        type: VALIDATION_TYPE.REQUIRED,
        errorMessage: "Please enter your Parent Company name."
    },
    country: {
        type: VALIDATION_TYPE.REQUIRED,
        errorMessage: "Please enter your Country."
    },
    addressLine1: {
        type: VALIDATION_TYPE.REQUIRED,
        errorMessage: "Please enter your Address."
    },
    city: {
        type: VALIDATION_TYPE.REQUIRED,
        errorMessage: "Please enter your City."
    },
    usState: {
        type: VALIDATION_TYPE.CONDITIONAL_REQUIRED,
        otherField: "country",
        otherFieldValue: UNITED_STATES,
        errorMessage: "Please select your State."
    },
    state: {
        type: VALIDATION_TYPE.CONDITIONAL_EXCLUDED,
        otherField: "country",
        otherFieldValue: UNITED_STATES,
        errorMessage: "Please select your State."
    },
    zip: {
        type: VALIDATION_TYPE.REQUIRED,
        errorMessage: "Please enter your Zip/Postal Code."
    },
    externalAuditor: {
        type: VALIDATION_TYPE.REQUIRED,
        errorMessage: "Please select an External Auditor."
    },
    otherAuditor: {
        type: VALIDATION_TYPE.CONDITIONAL_REQUIRED,
        otherField: "externalAuditor",
        otherFieldValue: OTHER,
        errorMessage: "Please enter an External Auditor."
    },
    rules: {
        type: VALIDATION_TYPE.REQUIRED,
        errorMessage:
            "Please select if your organization is subject to United States SEC or U.S. GAO rules."
    },
    hearAbout: {
        type: VALIDATION_TYPE.REQUIRED,
        errorMessage: "Please select Referral Information."
    },
    referralSource: {
        type: VALIDATION_TYPE.CONDITIONAL_REQUIRED,
        otherField: "hearAbout",
        otherFieldValue: OTHER,
        errorMessage: "Please enter a referral source."
    },
    pwcContact: {
        type: VALIDATION_TYPE.CONDITIONAL_REQUIRED,
        otherField: "hearAbout",
        otherFieldValue: PWC_CONTACT,
        errorMessage: "Please enter a PwC professional name."
    },
    companyDomainNames: {
        type: VALIDATION_TYPE.CONDITIONAL_REQUIRED,
        otherField: "orderDisclosureChecklist",
        otherFieldValue: true,
        errorMessage: "Please enter a Company Domain Name(s)."
    },
    engagementTeamManager: {
        type: VALIDATION_TYPE.CONDITIONAL_REQUIRED,
        otherField: "orderDisclosureChecklist",
        otherFieldValue: true,
        errorMessage: "Please enter an Engagement Team Manager."
    },
    tcApproverName: {
        type: VALIDATION_TYPE.CUSTOM,
        errorMessage: "Please enter a T&C Approver.",
        callback: (formData, formState) => {
            if (
                formState.billingTerritory === UNITED_STATES ||
                formData.orderDisclosureChecklist
            ) {
                return !formData.tcApproverName;
            } else {
                return false;
            }
        }
    },
    tcApproverEmail: {
        type: VALIDATION_TYPE.CUSTOM,
        errorMessage: "Please enter a T&C Approver Email.",
        callback: (formData, formState) => {
            if (
                formState.billingTerritory === UNITED_STATES ||
                formData.orderDisclosureChecklist
            ) {
                return !EMAIL_REGEX.test(formData.tcApproverEmail);
            } else {
                return false;
            }
        }
    },
    legalNamesOfOtherEntities: {
        type: VALIDATION_TYPE.CUSTOM,
        errorMessage: "Please enter the legal name(s) of the other entity(ies).",
        callback: (formData, formState) => {
            if (
                (formState.billingTerritory === UNITED_STATES || formData.orderDisclosureChecklist) && formData.accessToAll === NO
            ) {
                return !formData.legalNamesOfOtherEntities;
            } else {
                return false;
            }
        }
    },
    agreeToTC: {
        type: VALIDATION_TYPE.CUSTOM,
        errorMessage:
            "Please agree to the terms and conditions before submitting.",
        callback: (formData, formState) => {
            if (
                formData.orderViewpoint &&
                formState.billingTerritory !== UNITED_STATES
            ) {
                return !formData.agreeToTC;
            } else {
                return false;
            }
        }
    },
    accessToAll: {
        type: VALIDATION_TYPE.CUSTOM,
        errorMessage:
            "Please select Yes/No.",
        callback: (formData, formState) => {
            if (
                formState.billingTerritory === UNITED_STATES || formData.orderDisclosureChecklist
            ) {
                return !formData.accessToAll;
            } else {
                return false;
            }
        }
    },
    nonZeroOrder: {
        type: VALIDATION_TYPE.CUSTOM,
        errorMessage:
            "At least one subscription must be selected to proceed with your order.  " +
            "If you do not wish to order Viewpoint, uncheck the box above.",
        callback: (formData) => {
            if (formData.orderViewpoint) {
                const total =
                    formData.usContent +
                    formData.globalContent +
                    formData.canadaContent +
                    formData.ukContent +
                    formData.gaapContent +
                    formData.japanContent +
                    formData.gasbContent;
                return total <= 0;
            }
        }
    }
};

// copy initialFormData:
//   for each field, replace value="" with { error: undefined, message: validations.<>.errorMessage }
// note that error has 3 states:
//   {undefined, true, false} => {hide error message, fade in error message, fade out error message}
export const initialValidationErrors = Object.fromEntries(
    Object.entries(INITIAL_FORM_DATA).map(([key, value]) => [
        key,
        { error: undefined, message: validations[key]?.errorMessage }
    ])
);

export const validate = (
    formData,
    formState,
    validationErrors,
    setValidationErrors
) => {
    let hasError = false;
    for (const field in validations) {
        const result = validationErrors[field];
        const fieldValue = formData[field];
        switch (validations[field].type) {
            // field must be truthy
            case VALIDATION_TYPE.REQUIRED: {
                result.error = fieldValue ? false : true;
                break;
            }
            // field is required to match a pattern
            case VALIDATION_TYPE.PATTERN: {
                const pattern = validations[field].pattern;
                const hasError = !pattern.test(fieldValue);
                result.error = hasError ? true : false;
                break;
            }
            // field is required when the value of otherField equals otherFieldValue
            case VALIDATION_TYPE.CONDITIONAL_REQUIRED: {
                const otherField = validations[field].otherField;
                const otherFieldValue = validations[field].otherFieldValue;
                if (otherFieldValue === formData[otherField]) {
                    result.error = fieldValue ? false : true;
                } else {
                    result.error = false;
                }
                break;
            }
            // field is required when the value of otherField does not equal otherFieldValue
            case VALIDATION_TYPE.CONDITIONAL_EXCLUDED: {
                const otherField = validations[field].otherField;
                const otherFieldValue = validations[field].otherFieldValue;
                if (otherFieldValue !== formData[otherField]) {
                    result.error = fieldValue ? false : true;
                } else {
                    result.error = false;
                }
                break;
            }
            // field is required to match a pattern when the value of otherField equals otherFieldValue
            case VALIDATION_TYPE.CONDITIONAL_PATTERN: {
                const otherField = validations[field].otherField;
                const otherFieldValue = validations[field].otherFieldValue;
                if (otherFieldValue === formData[otherField]) {
                    const pattern = validations[field].pattern;
                    const hasError = !pattern.test(fieldValue);
                    result.error = hasError ? true : false;
                } else {
                    result.error = false;
                }
                break;
            }
            // run the callback function to determine if there is a validation error
            case VALIDATION_TYPE.CUSTOM: {
                result.error = validations[field].callback(formData, formState);
                break;
            }

            default:
                break;
        }
        hasError = hasError || result.error;
    }
    // setValidationErrors(validationErrors) does NOT rerender Input errors
    // must pass a new copy of validationErrors so that the validationErrors reference changes and triggers react to rerender
    // ¯\_(ツ)_/¯
    setValidationErrors({ ...validationErrors });
    // TODO : can't call this here... maybe in the component with the error?
    //document.querySelector('.a-input-text\.a-input-error').focus()
    return !hasError;
};
