import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Checkbox,
    Button
} from "appkit-react";

const WaiveModal = (props) => {
    return (
        <div>
            <Modal
                visible={props.visible}
                onCancel={props.hideWaiveModal}
                className="waive-modal"
            >
                <ModalHeader closable={true}>
                    <div className="text-22 half-bold">
                        One-time Enablement Fee
                    </div>
                </ModalHeader>
                <ModalBody>
                    <div className="text-14">
                        The only time an Enablement Fee will not be charged is
                        if the client has already received the below services
                        prior to subscribing to {props.product.label} and does
                        not require the delivery of any Enablement activities.
                    </div>
                    <div className="separator-line"></div>
                    <div className="text-16 bold">
                        {props.product.label} enablement services
                    </div>
                    <div className="light-gray-text-12 m-v-16">
                        Targeted training to engage users and accelerate the
                        impact of {props.product.label}. Includes the following
                        and can be repeated one time at Client's option upon
                        renewal of the Agreement:
                    </div>
                    <div className="light-gray-text-12 list">
                        <ul>
                            <li>
                                {props.product.label} Onboarding Session - a
                                facilitated session (up to 90 minutes) to
                                onboard Users and introduce the platform's
                                functionality.
                            </li>
                            <li>
                                Digital Academy - a facilitated session (max 4
                                hours) to deliver targeted training on a
                                specific technology tool (e.g., Alteryx, UiPath)
                            </li>
                            <li>
                                Digital Champions playbook - a playbook to
                                assist Client with creating a digital champion
                                network.
                            </li>
                            <li>
                                Automation Discovery sessions - a facilitated
                                session (up to 2 hours) to walk Users through
                                process flows and relevant assets currently
                                available.
                            </li>
                        </ul>
                    </div>
                    <div className="separator-line"></div>

                    <Checkbox
                        name="waiveEnablementFee"
                        checked={props.formData.waiveEnablementFee}
                        onChange={(e) =>
                            props.handleChange("waiveEnablementFee")(
                                e.target.checked
                            )
                        }
                    >
                        <div className="gray-text-14">
                            I confirm this client has already received the{" "}
                            {props.product.label} enablement services and the
                            one-time Enablement Fee can be waived.
                        </div>
                    </Checkbox>
                </ModalBody>
                <ModalFooter>
                    <div>
                        <Button onClick={props.hideWaiveModal}>CLOSE</Button>
                    </div>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default WaiveModal;
