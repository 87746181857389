import { CONTRACT_TYPE } from "../../Constants";
const CN = {
    [CONTRACT_TYPE.v1ContractCN]: [
        {
            title: "Term",
            text: [
                "The “Agreement” between Client and PwC means the Order and the documents incorporated by reference and described above; if there is a conflict among the provisions, this Order will prevail. The “Effective Date” of the Agreement is the date set forth above. Capitalized terms used but not defined in this Order are defined elsewhere in the Agreement.",
                "Upon expiration of the Initial Term, this Order will automatically renew for additional successive Renewal Terms of one (1) year each until a party provides the other with notice of its election not to renew at least thirty (30) days prior to the end of the then-current Initial Term or Renewal Term (as applicable). The Term consists of the Initial Term and all Renewal Terms (if any)."
            ]
        },
        {
            title: "Initial Term End Date",
            text: [
                "11:59pm Eastern time on the day prior to the Effective Date plus the number of years of above mentioned term of the contract."
            ]
        },
        {
            title: "User Accounts",
            text: [
                "The maximum number of individual User Accounts that Client is permitted to issue and manage is the User Account Limit set forth in the table below, in accordance with the applicable Subscription Tier to which Client subscribes. PwC will establish one (1) Client administrator account to enable Client to issue and manage individual User Accounts."
            ]
        }
    ],
    [CONTRACT_TYPE.v2ContractCN]: [
        {
            title: "Term",
            text: [
                "The “Agreement” between Client and PwC means the Order and the documents incorporated by reference and described above; if there is a conflict among the provisions, this Order will prevail. The “Effective Date” of the Agreement is the date set forth above. Capitalized terms used but not defined in this Order are defined elsewhere in the Agreement.",
                "Upon expiration of the Initial Term, this Order will automatically renew for additional successive Renewal Terms of one (1) year each until a party provides the other with notice of its election not to renew at least thirty (30) days prior to the end of the then-current Initial Term or Renewal Term (as applicable). The Term consists of the Initial Term and all Renewal Terms (if any)."
            ]
        },
        {
            title: "Initial Term End Date",
            text: [
                "11:59pm Eastern time on the day prior to the Effective Date plus the number of years of above mentioned term of the contract."
            ]
        },
        {
            title: "User Accounts",
            text: [
                "The maximum number of individual User Accounts that Client is permitted to issue and manage is the User Account Limit set forth in the table below, in accordance with the applicable Subscription Tier to which Client subscribes. PwC will establish one (1) Client administrator account to enable Client to issue and manage individual User Accounts."
            ]
        }
    ],
    [CONTRACT_TYPE.v3ContractCN]: [
        {
            title: "Term",
            text: [
                "The “Agreement” between Client and PwC means the Order and the documents incorporated by reference and described above; if there is a conflict among the provisions, this Order will prevail. The “Effective Date” of the Agreement is the date set forth above. Capitalized terms used but not defined in this Order are defined elsewhere in the Agreement.",
                "Upon expiration of the Initial Term, this Order will automatically renew for additional successive Renewal Terms of one (1) year each until a party provides the other with notice of its election not to renew at least thirty (30) days prior to the end of the then-current Initial Term or Renewal Term (as applicable). The Term consists of the Initial Term and all Renewal Terms (if any)."
            ]
        },
        {
            title: "Initial Term End Date",
            text: [
                "11:59pm Eastern time on the day prior to the Effective Date plus the number of years of above mentioned term of the contract."
            ]
        },
        {
            title: "User Accounts",
            text: [
                "The maximum number of individual User Accounts that Client is permitted to issue and manage is the User Account Limit set forth in the table below, in accordance with the applicable Subscription Tier to which Client subscribes. PwC will establish one (1) Client administrator account to enable Client to issue and manage individual User Accounts."
            ]
        }
    ],
    [CONTRACT_TYPE.v4ContractCN]: [
        {
            title: "Term",
            text: [
                "The “Agreement” between Client and PwC means the Order and the documents incorporated by reference and described above; if there is a conflict among the provisions, this Order will prevail. The “Effective Date” of the Agreement is the date set forth above. Capitalized terms used but not defined in this Order are defined elsewhere in the Agreement.",
                "Upon expiration of the Initial Term, this Order will automatically renew for additional successive Renewal Terms of one (1) year each until a party provides the other with notice of its election not to renew at least thirty (30) days prior to the end of the then-current Initial Term or Renewal Term (as applicable). The Term consists of the Initial Term and all Renewal Terms (if any)."
            ]
        },
        {
            title: "Initial Term End Date",
            text: [
                "11:59pm Eastern time on the day prior to the Effective Date plus the number of years of above mentioned term of the contract."
            ]
        },
        {
            title: "User Accounts",
            text: [
                "The maximum number of individual User Accounts that Client is permitted to issue and manage is the User Account Limit set forth in the table below, in accordance with the applicable Subscription Tier to which Client subscribes. PwC will establish one (1) Client administrator account to enable Client to issue and manage individual User Accounts."
            ]
        }
    ],
    [CONTRACT_TYPE.v5ContractCN]: [
        {
            title: "Term",
            text: [
                "The “Agreement” between Client and PwC means the Order and the documents incorporated by reference and described above; if there is a conflict among the provisions, this Order will prevail. The “Effective Date” of the Agreement is the date set forth above. Capitalized terms used but not defined in this Order are defined elsewhere in the Agreement.",
                "Upon expiration of the Initial Term, this Order will automatically renew for additional successive Renewal Terms of one (1) year each until a party provides the other with notice of its election not to renew at least thirty (30) days prior to the end of the then-current Initial Term or Renewal Term (as applicable). The Term consists of the Initial Term and all Renewal Terms (if any)."
            ]
        },
        {
            title: "Initial Term End Date",
            text: [
                "11:59pm Eastern time on the day prior to the Effective Date plus the number of years of above mentioned term of the contract."
            ]
        },
        {
            title: "User Accounts",
            text: [
                "The maximum number of individual User Accounts that Client is permitted to issue and manage is the User Account Limit set forth in the table below, in accordance with the applicable Subscription Tier to which Client subscribes. PwC will establish one (1) Client administrator account to enable Client to issue and manage individual User Accounts."
            ]
        }
    ]
};
export default CN;
