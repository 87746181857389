import { useNavigate, useParams } from "react-router-dom";
import { Success } from "./Success";
import watchTechnology from "../../../../../assets/watchTechnology.svg";
import { gotoViewOrder } from "../../../../../Routes";
import useProduct from "../../common/UseProduct";

const WithdrawnOrder = () => {
    const product = useProduct();
    const { opportunityId, clientName } = useParams();

    const subtitle = `The ${product.label} order for ${decodeURIComponent(
        clientName
    )} has successfully been withdrawn. The client will no longer be able to view the original order.`;

    const image = { src: watchTechnology, width: "246px" };

    const navigate = useNavigate();
    const action = {
        text: "VIEW ORDER",
        onClick: () => gotoViewOrder(navigate, product.id, opportunityId)
    };

    return (
        <Success
            title="Order withdrawn"
            subtitle={subtitle}
            description={`You can still access this order in "Draft" status.`}
            image={image}
            action={action}
        />
    );
};

export default WithdrawnOrder;
