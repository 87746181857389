import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../../../../Footer";
import Header from "../../../../Header";
import SupportBanner from "../../SupportBanner";
import useViewOrderInfo from "../../common/order/UseViewOrderInfo";
import EditSummary from "../edit/EditSummary";
import SubmitButtons from "./SubmitButtons";
import { NEW_ORDER_DATA, NEW_ORDER_STATE } from "../../../Constants";
import { MENU_LABELS } from "../common/Menu";
import { gotoManageDefault, gotoViewOrder } from "../../../../../Routes";

const SubmitOrder = () => {
    const { opportunityId } = useParams();

    const [product, setProduct] = useState();
    const [submissionInfo, setSubmissionInfo] = useState({});
    const [formData, setFormData] = useState(NEW_ORDER_DATA);
    const [formState, setFormState] = useState(NEW_ORDER_STATE);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    // order terms page //
    useViewOrderInfo(
        opportunityId,
        setSubmissionInfo,
        setProduct,
        setFormData,
        setFormState,
        null,
        setLoading
    );

    const submitter = {
        name: submissionInfo.submitterName,
        email: submissionInfo.submitterEmail
    };

    const menuLabels = [
        MENU_LABELS.ALL_ORDERS,
        MENU_LABELS.VIEW_ORDER,
        MENU_LABELS.SUBMIT_TO_CLIENT
    ];

    const gotoSummary = () => {
        gotoViewOrder(navigate, product.id, opportunityId);
    };
    const gotoAllOrders = () => {
        gotoManageDefault(navigate, product);
    };
    const menuActions = [gotoAllOrders, gotoSummary];
    const header = "Submit order to client";
    const description =
        "Please review the order summary below. An email to complete the order will be sent to the client signatory upon submission.";

    return (
        <div>
            <Header />
            {loading ? (
                <div className="flex-justify-center vh-65">
                    <div className="center">
                        <div className="a-loading a-primary loading-large"></div>
                        <div>Please wait while the order is loaded...</div>
                    </div>
                </div>
            ) : (
                <div className="container-fluid">
                    <EditSummary
                        header={header}
                        description={description}
                        user={submitter}
                        product={product}
                        formState={formState}
                        formData={formData}
                        menuLabels={menuLabels}
                        menuActions={menuActions}
                    />
                    <SubmitButtons
                        product={product}
                        opportunityId={opportunityId}
                        gotoSummary={gotoSummary}
                    />
                </div>
            )}
            <SupportBanner />
            <Footer />
        </div>
    );
};

export default SubmitOrder;
