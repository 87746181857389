import Contact from "./Contact";
import { removeElement } from "../../../../common/Util";
import { EXTERNAL_URLS, NUMBER_TO_WORD } from "../../../../common/Constants";
import { PRODUCTS } from "../../../Config";

const Contacts = ({ props, validationErrors }) => {
    const {
        product,
        formData,
        setFormData,
        additionalContactCount,
        setAdditionalContactCount
    } = props;

    const remove = (index) => {
        formData.clientFirstName = removeElement(
            formData.clientFirstName,
            index
        );
        formData.clientLastName = removeElement(formData.clientLastName, index);
        formData.clientEmail = removeElement(formData.clientEmail, index);
        formData.clientTitle = removeElement(formData.clientTitle, index);
        formData.clientPhone = removeElement(formData.clientPhone, index);
        setFormData({ ...formData });
        setAdditionalContactCount(additionalContactCount - 1);
    };

    const additionalContacts = [];
    // index === 0 is the signatory contact
    for (let i = 1; i < additionalContactCount + 1; ++i) {
        additionalContacts.push(
            <div key={i}>
                <div className={i === 1 ? "p-t-24" : ""}>
                    {i > 1 && <div className="gray-separator-line m-t-0"></div>}
                    <span
                        className="appkiticon icon-circle-minus-fill a-font-16 a-text-red v-middle clickable"
                        onClick={() => remove(i)}
                    ></span>
                    <span className="orange v-middle p-l-8 text-12">
                        Delete contact
                    </span>
                </div>
                <Contact
                    props={props}
                    validationErrors={validationErrors}
                    index={i}
                    emailOnly={
                        product.optionalComponent.client
                            .additionalContactsEmailOnly
                    }
                />
            </div>
        );
    }

    const singleAdditionalContact =
        product.optionalComponent.client.maxAdditionalContacts === 1;
    const maxAdditionalContacts =
        NUMBER_TO_WORD[product.optionalComponent.client.maxAdditionalContacts];
    const plural = product.optionalComponent.client.maxAdditionalContacts !== 1;

    const canAdd =
        additionalContactCount <
        product.optionalComponent.client.maxAdditionalContacts;
    const add = () => {
        if (canAdd) {
            setAdditionalContactCount(additionalContactCount + 1);
        }
    };

    return (
        <div>
            <div className="text-18 bold">
                Additional contact{plural ? "s" : ""} (optional)
            </div>
            <div className="light-gray-text-16">
                {singleAdditionalContact
                    ? "This person receives a copy of the order and will get notified once the order has been completed by the client signatory."
                    : `You can add up to ${maxAdditionalContacts} additional client contacts to receive contract details.`}
                {product.id === PRODUCTS.POD.id && (
                    <>
                        {" "}If you need to add more than {maxAdditionalContacts}{" "}
                        users please{" "}
                        <a
                            href={EXTERNAL_URLS.addMoreUsers}
                            target="_blank"
                            rel="noreferrer"
                            className="light-orange"
                        >
                            use this form
                        </a>
                        .
                    </>
                )}
            </div>
            {additionalContacts}
            {canAdd && (
                <div className="p-t-8">
                    <span
                        className="appkiticon icon-circle-plus-fill a-font-24 a-text-red v-middle clickable"
                        onClick={add}
                    ></span>
                    <span className="orange v-middle p-l-8 text-12">
                        Add contact
                    </span>
                </div>
            )}
        </div>
    );
};

export default Contacts;
