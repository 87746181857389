import unavailable from "../../../../assets/unavailable.svg";
import success from "../../../../assets/success.svg";
import lookingOptimistic from "../../../../assets/lookingOptimistic.svg";
import { ContactUsLink } from "./ContactUsLink";

export const UnavailablePage = (props) => {
    const { productName, email } = props;
    return (
        <div className="flex-column m-b-300 darker">
            <h3 className="m-t-80 m-b-30 text-32 third-bold">
                This order is unavailable
            </h3>
            <p className="m-b-30 text-18 font-normal">
                {" "}
                The {productName} order you are attempting to access is no
                longer available.
            </p>
            <p className="text-18 font-normal m-b-58">
                If you have any questions, please{" "}
                <ContactUsLink email={email} />
            </p>
            <img src={unavailable} alt="Unavailable" className="w-589" />
        </div>
    );
};

export const SuccessPage = () => {
    return (
        <div className="flex-column m-b-300 darker">
            <h3 className="m-t-80 m-b-30 text-32 third-bold">
                <span className="appkiticon a-font-32 green icon-confirmed-fill m-r-6"></span>
                Your order is complete!
            </h3>
            <p className="m-b-7 text-18 font-normal">
                Thank you for submitting the order.
            </p>
            <p className="m-b-58 text-18 font-normal">
                You will receive emails to proceed with the payment and
                onboarding.
            </p>
            <img src={success} alt="Success" className="w-342" />
        </div>
    );
};

export const WrongPage = () => {
    return (
        <div className="flex-column">
            <h3 className="m-t-80 m-b-30 text-32 third-bold">
                Something went wrong
            </h3>
            <p className="clear-m-b clear-m-t text-18 font-normal">
                Sorry for the inconvenience. We encountered an error.
            </p>
            <p className="m-b-30 text-18 font-normal clear-m-t">
                Please try again later.
            </p>
            <img
                src={lookingOptimistic}
                alt="Looking Optimistic"
                className="w-589 m-t-59 m-b-197"
            />
        </div>
    );
};

export const NotSupportedPage = () => {
    return (
        <div className="flex-column">
            <h3 className="m-t-80 m-b-30 text-32 third-bold">
                No longer supported
            </h3>
            <p className="clear-m-b clear-m-t text-18 font-normal">
                Policy on Demand is no longer licensed via SLP.
            </p>
            <p className="m-b-30 text-18 font-normal clear-m-t">
                Please contact the Policy on Demand{" "}
                <a
                    href="mailto:US_policyondemand@pwc.com"
                    className="underline orange"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    support team
                </a>{" "}
                for assistance.
            </p>
            <img
                src={lookingOptimistic}
                alt="Looking Optimistic"
                className="w-589 m-t-59 m-b-197"
            />
        </div>
    );
};
