import ClientIconHeader from "../../common/order/ClientIconHeader";
import ClientDetails from "./ClientDetails";

const Client = ({ props }) => {
    const additionalContacts = [];
    for (let i = 1; i < props.formData.clientEmail.length; ++i) {
        const isLast = props.formData.clientEmail.length === i + 1;
        additionalContacts.push(
            <ClientDetails
                id={i}
                key={i}
                firstName={props.formData.clientFirstName[i]}
                lastName={props.formData.clientLastName[i]}
                title={props.formData.clientTitle[i]}
                email={props.formData.clientEmail[i]}
                phone={props.formData.clientPhone[i]}
                isLast={isLast}
            />
        );
    }

    const domains = [];
    for (let i = 0; i < props.formData.clientDomainName.length; ++i) {
        domains.push(
            <div className="gray-text-14" key={i}>
                {props.formData.clientDomainName[i]}
            </div>
        );
    }

    return (
        <div>
            <div className="row">
                <ClientIconHeader />
                {props.gotoClient && (
                    <div className="col-6 right">
                        <div className="m-t-4">
                            <span className="text-12 half-bold orange p-r-8">
                                Edit
                            </span>
                            <span
                                className="appkiticon icon-edit-outline a-font-24 a-text-red v-middle clickable"
                                onClick={props.gotoClient}
                            ></span>
                        </div>
                    </div>
                )}
            </div>
            <div className="gray-text-12 m-b-12 m-t-32">CONTRACT SIGNATORY</div>
            <div className="round-card">
                <ClientDetails
                    id={0}
                    firstName={props.formData.clientFirstName[0]}
                    lastName={props.formData.clientLastName[0]}
                    title={props.formData.clientTitle[0]}
                    email={props.formData.clientEmail[0]}
                    phone={props.formData.clientPhone[0]}
                    isLast={true}
                />
            </div>
            {props.formData.clientEmail.length > 1 && (
                <>
                    <div className="gray-text-12 m-b-12 m-t-32">
                        ADDITIONAL CONTACTS
                    </div>
                    <div className="round-card">{additionalContacts}</div>
                </>
            )}
            {domains.length > 0 && (
                <>
                    <div className="gray-text-12 m-b-12 m-t-32">
                        ALLOWED DOMAINS
                    </div>
                    <div className="round-card">{domains}</div>
                </>
            )}
        </div>
    );
};

export default Client;
