import { CONTRACT_TYPE } from "../../Constants";
const LISTEN = {
    [CONTRACT_TYPE.employeeExperience]: [
        "The annual fee for the Initial Term will be invoiced on the Effective Date.",
        "Thereafter, subsequent annual fees will be invoiced on each anniversary of the Effective Date.",
        "The annual fee includes the number of responses set forth in the table above. Additional fees for responses above the included number of responses will be invoiced quarterly.",
        "Client will pay PwC amounts due within thirty (30) days after the date of invoice."
    ],
    [CONTRACT_TYPE.customerExperience]: [
        "The annual fee for the Initial Term will be invoiced on the Effective Date.",
        "Thereafter, subsequent annual fees will be invoiced on each anniversary of the Effective Date.",
        "In addition, a use fee for the number of responses is payable by Client as set forth in the table above, to be invoiced quarterly.",
        "Client will pay PwC amounts due within thirty (30) days after the date of invoice."
    ]
};
export default LISTEN;
