import { useNavigate, useParams } from "react-router-dom";
import { Success } from "./Success";
import isoEmailChecklist from "../../../../../assets/isoEmailChecklist.svg";
import { gotoViewOrder } from "../../../../../Routes";
import useProduct from "../../common/UseProduct";

const ResentOrder = () => {
    const product = useProduct();
    const { opportunityId, clientName } = useParams();

    const subtitle = `The order for ${product.label} has been resent to ${clientName}.`;

    const image = { src: isoEmailChecklist, width: "517px" };

    const navigate = useNavigate();
    const action = {
        text: "VIEW ORDER",
        onClick: () => gotoViewOrder(navigate, product.id, opportunityId)
    };

    return (
        <Success
            title="Order resent to client"
            subtitle={subtitle}
            description={`You will get notified via email when the client has completed the order.`}
            image={image}
            action={action}
        />
    );
};

export default ResentOrder;
