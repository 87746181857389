import { EMAIL_REGEX, PWC_EMAIL_REGEX } from "../../../../common/Util";
import { PRODUCT_STAKEHOLDERS } from "../../../Constants";

export const initialValidationErrors = Object.fromEntries(
    Object.entries(PRODUCT_STAKEHOLDERS).map(([key, value]) => [
        key,
        {
            email: {
                error: undefined,
                message: "Please enter a valid email address."
            },
            fullName: {
                error: undefined,
                message: "Please enter a valid name."
            },
            region: {
                error: undefined,
                message: "Please select a region."
            },
            market: {
                error: undefined,
                message: "Please select a market."
            }
        }
    ])
);

const _validate = (toValidate, validationErrors, fieldValues) => {
    let hasError = false;

    toValidate.forEach((field) => {
        const emailValidation = (fieldValue) => {
            return !EMAIL_REGEX.test(fieldValue);
        };
        const pwcEmailValidation = (fieldValue) => {
            return !PWC_EMAIL_REGEX.test(fieldValue);
        };
        const requiredValidation = (fieldValue) => {
            return fieldValue ? false : true;
        };
        // for LEAD_TAX_PARTNER the form collects fullName, email, region and market
        // for LEAD_TAX_DIRECTOR the form collects fullName and email
        // for all other PRODUCT_STAKEHOLDERS the form collects email
        const subFields =
            field === PRODUCT_STAKEHOLDERS.LEAD_TAX_PARTNER.id
                ? {
                      fullName: requiredValidation,
                      email: pwcEmailValidation,
                      region: requiredValidation,
                      market: requiredValidation
                  }
                : field === PRODUCT_STAKEHOLDERS.LEAD_TAX_DIRECTOR.id
                ? {
                      fullName: requiredValidation,
                      email: pwcEmailValidation
                  }
                : {
                      email: emailValidation
                  };

        Object.entries(subFields).forEach((key) => {
            const subField = key[0];
            const validation = key[1];
            const result = validationErrors[field][subField];
            const fieldValue = fieldValues[field]?.[subField];
            result.error = validation(fieldValue);

            hasError = hasError || result.error;
        });
    });

    return hasError;
};

export const validate = (
    formData,
    validationErrors,
    setValidationErrors,
    requiredContacts,
    hasAdditionalCcRecipient
) => {
    // requiredContacts is an array of PRODUCT_STAKEHOLDERS
    const requiredContactIds = [];
    requiredContacts.forEach((contact) => {
        requiredContactIds.push(contact.id);
    });

    const hasErrorRequired = _validate(
        requiredContactIds,
        validationErrors,
        formData.requiredContacts
    );
    const hasErrorAdditional = hasAdditionalCcRecipient
        ? // special case, validate single field
          _validate(
              [PRODUCT_STAKEHOLDERS.CC_RECIPIENT.id],
              validationErrors,
              formData.additionalContacts
          )
        : // validate all selected additional fields
          _validate(
              // additionalContactsSelect is an array of PRODUCT_STAKEHOLDERS keys that is dynamically created via ContactSelect
              formData.additionalContactsSelect,
              validationErrors,
              formData.additionalContacts
          );
    setValidationErrors({ ...validationErrors });
    return !(hasErrorAdditional || hasErrorRequired);
};
