import Button from "appkit-react/lib/Button/Button";
import Modal from "appkit-react/lib/Modal/Modal";
import ModalHeader from "appkit-react/lib/Modal/ModalHeader";
import ModalBody from "appkit-react/lib/Modal/ModalBody";
import ModalFooter from "appkit-react/lib/Modal/ModalFooter";

export const DiscardChangesModal = (props) => {
    return (
        <Modal
            className="discard-modal w-588"
            visible={props.visible}
            onCancel={props.handleCancel}
        >
            <ModalHeader className="clear-border-bottom clear-p-v">
                <h3 className="text-22 clear-m-b">Discard Changes?</h3>
            </ModalHeader>
            <ModalBody>
                <p className="gray-text-14">
                    Any changes you've made will be lost. The order information
                    will reset to its original values.
                </p>
            </ModalBody>
            <ModalFooter>
                <div>
                    <Button
                        kind="secondary"
                        onClick={props.handleCancel}
                        size="lg"
                    >
                        CANCEL
                    </Button>
                    <Button
                        kind="primary"
                        onClick={
                            props.discardAll
                                ? props.handleDiscardAll
                                : props.handleDiscard
                        }
                        size="lg"
                        className="m-l-24"
                    >
                        DISCARD
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default DiscardChangesModal;
