import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Checkbox } from "appkit-react";
import Opportunity from "../acceptance/Opportunity";
import Client from "./Client";
import Order from "./Order";
import Contact from "./Contact";
import FullScreenModal from "../../common/FullScreenModal";
import { submitNewOrder } from "../../../service/External";
import AcceptanceHeader from "../../common/order/AcceptanceHeader";
import { LOOKUP_MESSAGE_STATES } from "../../../Constants";
import { ACKNOWLEDGE_TEXT } from "../../../../common/Constants";
import { gotoCreatedOrder, gotoError } from "../../../../../Routes";

const Review = (props) => {
    const { product, formState, formData, user, backward } = props;

    const [acknowledged, setAcknowledged] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const navigate = useNavigate();

    const handleSubmitCallback = (success, opportunityId) => {
        setSubmitting(false);
        if (success) {
            gotoCreatedOrder(
                navigate,
                product.id,
                opportunityId,
                formState.clientAccount.name,
                formState.lookupMessage === LOOKUP_MESSAGE_STATES.NOT_SUPPORTED
            );
        } else {
            gotoError(navigate);
        }
    };

    const handleSubmit = () => {
        setSubmitting(true);
        submitNewOrder(
            formData,
            formState,
            product,
            user,
            handleSubmitCallback
        );
    };

    return (
        <div>
            <div className="p-b-125">
                <div className="row">
                    <div className="col-3"></div>
                    <div className="col-6 flex-justify-center">
                        <div className="content">
                            <div className="text-18 bold p-b-28">
                                Review & Submit
                            </div>
                        </div>
                    </div>
                    <div className="col-3"></div>
                </div>
                <AcceptanceHeader showEdit={true} props={props} />
                <Opportunity
                    props={props}
                    hideWrongEntityLink={true}
                    showAFSSummary={
                        formState.lookupMessage ===
                        LOOKUP_MESSAGE_STATES.AFS_INFORMATION_REQUIRED
                    }
                />
                <div className="row gray-background p-b-32">
                    <div className="col-3"></div>
                    <div className="col-6 flex-justify-center">
                        <div className="content">
                            <div className="red-separator-line"></div>
                            <Client props={props} />
                            <div className="red-separator-line"></div>
                            <Order props={props} reviewPage={true} />
                            <div className="red-separator-line"></div>
                            <Contact props={props} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-3"></div>
                    <div className="col-6 flex-justify-center">
                        <div className="content p-t-36">
                            <div>
                                <Checkbox
                                    onClick={(e) => {
                                        setAcknowledged(e.target.checked);
                                    }}
                                    disabled={submitting}
                                >
                                    {ACKNOWLEDGE_TEXT[product.id]}
                                </Checkbox>
                            </div>
                            <div className="nav-buttons m-t-48">
                                <Button onClick={backward} kind={"secondary"}>
                                    BACK
                                </Button>
                                <Button
                                    onClick={() => handleSubmit()}
                                    disabled={!acknowledged}
                                >
                                    SUBMIT
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="col-3"></div>
                </div>
            </div>
            {submitting && (
                <FullScreenModal
                    content={"Hold tight, we are submitting your order..."}
                />
            )}
        </div>
    );
};

export default Review;
