import { ORDER_TERMS } from "../../../Constants";
import OrderIconHeader from "../../common/order/OrderIconHeader";
import InitialTermDOD from "../order/contract/dod/InitialTerm";
import RenewalTermDOD from "../order/contract/dod/RenewalTerm";
import InitialTermWFO from "../order/contract/wfo/InitialTerm";
import InitialTermCN from "../order/contract/cn/InitialTerm";
import InitialTermListen from "../order/contract/listen/InitialTerm";
import InitialTermPOD from "../order/contract/pod/InitialTerm";

const Order = ({ props, reviewPage }) => {
    const { product } = props;

    const showRenewalTerm = props.formState.renewalTermTotal > 0;
    // TODO : figure out the best way to completely paramterize this page
    const content = {
        [ORDER_TERMS.DOD]: (
            <>
                <InitialTermDOD
                    props={props}
                    showRenewalTerm={showRenewalTerm}
                />
                {showRenewalTerm && <RenewalTermDOD props={props} />}
            </>
        ),
        [ORDER_TERMS.CN]: <InitialTermCN props={props} />,
        [ORDER_TERMS.WFO]: <InitialTermWFO props={props} />,
        [ORDER_TERMS.LISTEN]: <InitialTermListen props={props} />,
        [ORDER_TERMS.POD]: (
            <InitialTermPOD props={props} reviewPage={reviewPage} />
        )
    };

    return (
        <div>
            <div className="row">
                <OrderIconHeader />
                {props.gotoTerms && (
                    <div className="col-6 right">
                        <div className="m-t-4">
                            <span className="text-12 half-bold orange p-r-8">
                                Edit
                            </span>
                            <span
                                className="appkiticon icon-edit-outline a-font-24 a-text-red v-middle clickable"
                                onClick={props.gotoTerms}
                            ></span>
                        </div>
                    </div>
                )}
            </div>
            <div className="gray-text-12 m-b-12 m-t-32">ORDER TERMS</div>
            <div className="round-card-black">
                {content[product.optionalComponent.order]}
            </div>
        </div>
    );
};

export default Order;
