const TermsConditionsUS = () => {
    return (
        <div className="us-t-c-content text-14">
            <div className="right small-caps bold">PwC Confidential</div>
            <div className="center small-caps bold">Terms and Conditions for Viewpoint</div>
            <p>These Terms and Conditions for Viewpoint (“<strong>Agreement</strong>”) are
                between you (“<strong>Client</strong>”) and PwC Product Sales
                LLC (“<strong>PwC</strong>”) concerning PwC’s provision to Client
                of PwC’s online offering Viewpoint (as defined below), as more fully described
                in this Agreement.  This Agreement will be effective as of the date on which
                Client indicated its assent to this Agreement, for example, by indicating
                that Client read and agreed to this Agreement (such date,
                “<strong>Effective Date</strong>”).  Client’s access or use of Viewpoint
                constitutes Client’s consent to this Agreement.</p>
            <p>If you are an individual accessing or using Viewpoint on behalf of, or
                for the benefit of, any corporation, partnership or other entity with which you
                are associated (“<strong>Organization</strong>”), then you are agreeing to
                this Agreement on behalf of yourself and such Organization, and you represent
                and warrant that you have the legal authority to bind such Organization to this Agreement.</p>
            <p className="small-caps">This Agreement contains a mandatory arbitration provision that,
                as further set forth in <span className="text-underline">Section 7</span> below,
                requires the use of arbitration on an individual
                basis to resolve disputes, rather than jury trials or any other court
                proceedings, or class actions of any kind.</p>
            <ol>
                <li>
                    <strong className="text-underline">Viewpoint</strong>
                    <ol>
                        <li><strong>Generally.</strong> By submission of Client’s completed
                            ordering form (the “<strong>Order</strong>”; a blank version of
                            which is available at <a href="https://pwc-ptp.secure.force.com/viewpointorderform" target="_blank" rel="noreferrer">
                                https://pwc-ptp.secure.force.com/viewpointorderform</a>),
                            Client has indicated that it wishes to receive Viewpoint. “<strong>Viewpoint</strong>” is
                            PwC’s online offering currently known as “Viewpoint” that provides
                            a research and reference library of accounting, financial reporting, and auditing literature
                            and is accessible (via access credentials provided as set forth
                            in <span className="text-underline">Section 1.2</span>) at https://viewpoint.pwc.com
                            (or any successor website designated by PwC), together with any materials available
                            on or through such website (and applicable portions, extracts, or components thereof).</li>
                        <li><strong>Client Users.</strong> “<strong>Client Users</strong>” means any individual
                            whom Client authorizes to access and use Viewpoint on Client’s behalf. PwC will establish
                            individual user accounts for the number of Client Users indicated by Client on the Order.
                            PwC will enable one or more Client User administrators to issue access
                            credentials (user name and password) for each of these user accounts; however, unless
                            agreed to otherwise by PwC, all Client Users using the user accounts must
                            use an email address within the same email domain. Access credentials are unique to
                            each Client User and must not be shared, even between Client Users. Client
                            may not exceed any limitations or restrictions on the number of Client Users without
                            PwC’s prior written consent and payment of any applicable fees. Client
                            will, and will cause each Client User to, access and use Viewpoint in compliance
                            with all applicable laws, rules, regulations, obligations to third parties,
                            and this Agreement. Client will remain responsible for all use and access of
                            Viewpoint by Client Users.</li>
                        <li><strong>Limited Right to Use.</strong> Subject to the terms and conditions
                            hereof, PwC will make Viewpoint available to Client and Client Users (a) during
                            the twelve (12) month period commencing on the Effective Date or (b) if the
                            Order indicates that Viewpoint is provided on a “trial,” “evaluation,” or other
                            non-production basis (“<strong>Trial License</strong>”), during the Trial
                            License period indicated on the Order. Client (and any authorized Client Users)
                            will access and use Viewpoint only via the URL provided by PwC and only
                            during the applicable twelve (12) month period (or Trial License period
                            indicated on the Order, as the case may be). Client (and any authorized Client
                            Users) may use Viewpoint only for the non-commercial, internal business
                            purposes of Client (and if the Order indicates that Viewpoint is provided
                            on a Trial License basis, only for the purpose of evaluating whether
                            Client wishes to obtain from PwC the right to use Viewpoint for production,
                            non-evaluation use). Viewpoint is not designed or intended to form part of
                            Client’s permanent records. PwC reserves the right, at its discretion, to
                            change or discontinue the offerings, content, information, functionality
                            and availability of Viewpoint.</li>
                        <li><strong>Restrictions.</strong> Client will not (and will not permit
                            any Client User to) use Viewpoint except as expressly permitted in this Agreement.
                            Client will not (and will not permit any other party to): (a) use Viewpoint
                            in a way prohibited by law, regulation, governmental order or decree, or for
                            any fraudulent or unlawful purpose, or to create a product or service similar
                            to Viewpoint, or in any manner that would violate the legal rights of any
                            third party; (b) alter, adapt, modify, translate, or create derivative works
                            of Viewpoint; (c) rent, lend, loan, lease, sell, distribute, or sublicense
                            Viewpoint; (d) remove, alter, or obscure any disclaimer, copyright, trademark,
                            confidentiality, or other legal notice from any part of Viewpoint; (e)
                            provide any means of access to, or otherwise display or transmit, Viewpoint
                            from or through any website (including by “framing” or “mirroring”) other than
                            the URL designated by PwC for such purposes; (f) interfere with or disrupt (or
                            otherwise use Viewpoint in a way that could harm or impair) Viewpoint or
                            the servers, software, devices, systems, networks, accounts, or data used to
                            make Viewpoint available, or violate any requirements, policies, or terms or
                            conditions of any providers thereof; (g) use Viewpoint to distribute “spam” or
                            transmit or otherwise make available in connection with Viewpoint any virus,
                            worm, Trojan horse, time bomb, spyware, ransomware, malware or other harmful or
                            potentially harmful code or materials; (h) circumvent, bypass or breach any
                            technological controls or security device or protection used by any part of
                            Viewpoint; or (i) use Viewpoint in any application or situation where failure of
                            Viewpoint could lead to the death or serious bodily injury of any person, or
                            to severe physical or environmental damage (“<strong>High-Risk Activities</strong>”).</li>
                        <li><strong>Ownership.</strong> Except for the express rights granted in
                            <span className="text-underline">Section 1.3</span>, as between the parties,
                            PwC owns and will retain all right, title, and
                            interest (including all intellectual property rights) in and to Viewpoint.
                            Client does not and will not acquire under this Agreement any right, title or
                            interest in or to Viewpoint (or any intellectual property rights related thereto),
                            whether by implication, estoppel or otherwise, except for the limited
                            right to access and use Viewpoint as expressly permitted under this Agreement.
                            Viewpoint is licensed, not sold. There are no implied licenses hereunder.
                            PwC and its suppliers reserve all rights not expressly granted hereunder.</li>
                    </ol>
                </li>
                <li>
                    <strong><span className="text-underline">Fees</span>.</strong> For each twelve (12)
                    month period of the Term (as defined below) (or for the Trial License period
                    indicated on the Order, as the case may be), Client will pay PwC the total fees
                    (consisting of the cost per Client User multiplied by the number of Client
                    Users) indicated on the Order (as such fees may be updated for any subsequent
                    Renewal Term (as defined below) by prior written notice from PwC, “<strong>Fees</strong>”),
                    as well as any applicable sales, use, value added or other similar tax. If Client is
                    exempt from state sales tax, Client must provide PwC a copy of Client’s exemption
                    certificate. Fees for Renewal Terms will be billed on an annual basis in advance of
                    the commencement of the applicable Renewal Term. Fees are nonrefundable. Client will
                    pay PwC all amounts due within 30 days after the date of invoice therefor.
                    All payments will be made in U.S. dollars.</li>
                <li>
                    <strong className="text-underline">Term and Termination</strong>
                    <ol>
                        <li><strong>Term; Termination.</strong> Unless earlier terminated in accordance
                            with this Agreement, this Agreement will commence on the Effective Date (a) for an
                            initial term of twelve (12) months (“<strong>Initial Term</strong>”) and will
                            automatically renew for successive periods of twelve (12) months each (each,
                            a “<strong>Renewal Term</strong>” and collectively with the Initial Term,
                            the “<strong>Term</strong>”), unless either party provides the other party
                            with written notice of its election not to renew at least ninety (90) days
                            prior to the end of the then-current Initial Term or Renewal Term (as applicable);
                            or (b) if the Order indicates that Viewpoint is provided on a Trial License basis,
                            for the Trial License period indicated on the Order. Client may terminate this Agreement by providing
                            at least thirty (30) days’ written notice to PwC. Either party may terminate
                            this Agreement by providing written notice to the other party: (x) if the other party
                            materially breaches its obligations hereunder and does not cure such breach within
                            fifteen (15) days after receipt of written notice; or (y) in the event of the
                            dissolution, termination of existence, liquidation, filing for bankruptcy or similar
                            protection, assignment for the benefit of creditors, or insolvency of the
                            other party. Because PwC is subject to certain regulatory obligations (including
                            those relating to “independence”) under which PwC must maintain a relationship
                            that is independent of certain entities, PwC may immediately terminate this Agreement
                            without penalty or liability if continuing to perform hereunder could result
                            in PwC’s noncompliance with any applicable law, rule or regulation, or any regulatory
                            guidance, independence rule, professional standard, or self-regulatory rule or
                            policy.  In addition, PwC may terminate this Agreement on ninety (90) days’ notice
                            in connection with a general discontinuance of Viewpoint to its client base.</li>
                        <li><strong>Effect of Termination.</strong> Upon termination of this Agreement: (a)
                            Client’s rights under <span className="text-underline">Section 1.3</span> will
                            immediately terminate and Client will (and will cause Client Users to) immediately
                            cease all use of Viewpoint; and (b) except to the extent necessary for each party to
                            continue to exercise its surviving rights, each party will return or destroy (or
                            in the case of electronic materials, delete) all documents and other embodiments
                            (in any form) that contain or constitute the other party’s Confidential Information.
                            The following Sections will survive any termination of this Agreement:
                            <span className="text-underline">1.4</span>, <span className="text-underline">1.5</span>,
                            <span className="text-underline">2</span>, <span className="text-underline">3.2</span>,
                            <span className="text-underline">4</span>, <span className="text-underline">5</span>,
                            <span className="text-underline">6</span>, and <span className="text-underline">7</span>.</li>
                    </ol>
                </li>
                <li>
                    <strong className="text-underline">Confidentiality</strong>
                    <ol>
                        <li>“<strong>Confidential Information</strong>” means non-public information or
                            materials disclosed by one party to the other party that is marked or described as,
                            contains content that would reasonably be considered to be, or is provided under
                            circumstances reasonably indicating it is, confidential or proprietary. PwC’s
                            Confidential Information includes this Agreement, Viewpoint, and non-public
                            information and materials related to it. Confidential Information does not include any
                            information or material which (a) is or becomes generally available to the public
                            through no fault of the receiving party; (b) the receiving party already rightfully
                            possesses at the time of disclosure without restriction on disclosure or use; or (c)
                            is rightfully received from a third party without restriction on disclosure or
                            use.  Each party will use and disclose the Confidential Information that it receives
                            only to exercise its rights and perform its obligations under this Agreement, and
                            each party will protect the confidentiality of Confidential Information that it
                            receives; in each case, except as required by applicable law, statute, rule, regulation
                            or professional standard. Each party may disclose the other party’s Confidential
                            Information to its employees, and PwC may also disclose Client’s Confidential
                            Information to any other firm in the global network of separate and independent
                            PricewaterhouseCoopers firms (“<strong>Network Firm</strong>”) and PwC’s service
                            providers and designees; in each case where such parties have a legitimate need to
                            know such information in furtherance of this Agreement. In addition, the receiving
                            party may disclose the disclosing party’s Confidential Information to the extent
                            required by applicable law, professional standards, or any government authority
                            (subject to providing the disclosing party prompt notice (to the extent permitted
                            by applicable law) and cooperating with the disclosing party’s attempts to limit
                            the disclosure). Any actual or threatened breach of the confidentiality obligations
                            hereunder may result in irreparable harm to the disclosing party with no adequate
                            remedy at law. The disclosing party will be entitled to seek appropriate equitable
                            relief (in addition to other remedies) in such event.</li>
                        <li><strong>Additional Understandings.</strong> Notwithstanding the above restrictions
                            on disclosure, but otherwise subject to the confidentiality provisions herein,
                            Client may discuss information (including PwC’s Confidential Information) relating
                            to Client’s use of Viewpoint with Professional Advisors with the understanding
                            that PwC provides no services and prepares no work product or other materials for
                            their use, benefit, or reliance, and PwC assumes no duty, liability, or responsibility
                            with respect to such parties, or any use or reliance by them. Professional Advisors
                            may not further disclose such materials to any third party.
                            “<strong>Professional Advisors</strong>” means Client’s third-party professional
                            advisors who (a) are acting solely for Client’s benefit and on Client’s behalf;
                            (b) have a need to know certain information to provide advice or services to Client;
                            and (c) who are subject to written obligations with Client regarding the
                            protection of PwC’s Confidential Information and intellectual property rights that
                            are materially consistent with those herein. “Professional Advisors” may include
                            auditors, attorneys, and financial and other similar advisors, but excludes parties
                            that provide insurance, financing, capital in any form, or fairness opinions;
                            parties that sell or underwrite securities in connection with a transaction relating
                            to the subject matter hereof; any counterparty to an anticipated transaction
                            or dispute; parties that have or may obtain a financial interest in Client; or parties
                            to an anticipated transaction with Client.</li>
                        <li><strong>Feedback.</strong> Client, on behalf of itself and Client Users, hereby grants
                            to PwC a worldwide, nonexclusive, royalty-free, transferable, sublicenseable
                            (through one or more tiers), perpetual, and irrevocable license to use and otherwise
                            exploit any suggestions, comments, ideas, test results, and other feedback that
                            is provided to PwC regarding Viewpoint in any manner that PwC deems necessary or desirable.
                            In addition, PwC may use and otherwise exploit, in anonymized and aggregated
                            form, usage data generated by Client Users’ use of Viewpoint for analytic, statistical,
                            security, quality control, and similar purposes, including for purposes of
                            developing, testing, improving and providing Viewpoint and other products and services
                            of PwC or any Network Firm.</li>
                    </ol>
                </li>
                <li>
                    <strong className="text-underline">Representations and Warranties; Indemnity; Disclaimer</strong>
                    <ol>
                        <li><strong>By Client.</strong> Client hereby represents and warrants to PwC that: (a)
                            it is a corporation or organization duly organized and validly existing under the
                            laws of the jurisdiction in which it was incorporated or organized; (b) it has all
                            requisite corporate power and authority to execute and deliver this Agreement and
                            perform its obligations under this Agreement; and (c) it has the authority to bind
                            Client Users and make statements on their behalf as provided in this Agreement.
                            Client will defend, indemnify and hold harmless PwC, its affiliates, the Network
                            Firms, and their respective partners, principals, directors, officers, employees,
                            successors, assigns, licensors and service providers from and against any and all
                            claims, actions, and causes of action (including any inquiries, investigations,
                            enforcement actions and other actions by governmental or regulatory entities and
                            claims of any nature, whether statutory or common law, and whether sounding in
                            negligence, strict liability or otherwise) brought by a third party, and pay any
                            damages, fines, penalties, liabilities, obligations, judgments, decrees, settlements,
                            costs, and expenses (including reasonable attorneys’ fees) arising out of any of the
                            foregoing, to the extent arising out of or related to (i) any breach, or a claim
                            that, if true, would be a breach, by Client or any Client User of this Agreement
                            (including any applicable Client User limitations and restrictions); or (ii) the use
                            or disclosure by Client and Client Users of Viewpoint.</li>
                        <li><strong>Disclaimer.</strong> TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, PWC
                            MAKES NO (AND, ON BEHALF OF ITSELF, ITS AFFILIATES, THE NETWORK FIRMS, AND THEIR
                            RESPECTIVE LICENSORS AND SERVICE PROVIDERS, HEREBY DISCLAIMS ALL) REPRESENTATIONS OR
                            WARRANTIES TO CLIENT, CLIENT USERS OR ANY OTHER PARTY, WHETHER EXPRESS OR IMPLIED,
                            INCLUDING ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                            TITLE, NON-INFRINGEMENT OR ACCURACY, AND ANY WARRANTIES THAT MAY ARISE FROM COURSE
                            OF DEALING, COURSE OF PERFORMANCE OR USAGE OF TRADE. WITHOUT LIMITING THE FOREGOING,
                            VIEWPOINT IS PROVIDED “AS IS” AND PWC AND ITS LICENSORS (A) DO NOT GUARANTEE THE
                            COMPLETENESS OF THE DATA, INFORMATION AND OTHER CONTENT IN VIEWPOINT AND (B) DO NOT
                            REPRESENT OR WARRANT THAT ACCESS TO OR USE OF VIEWPOINT WILL BE UNINTERRUPTED,
                            ERROR-FREE OR SECURE. VIEWPOINT IS NOT DESIGNED, MANUFACTURED, OR INTENDED FOR HIGH-RISK
                            ACTIVITIES. Client may not make any representations, warranties, covenants,
                            or other statements regarding Viewpoint to any Client User or other party, and if
                            Client does so, PwC expressly disclaims them. Viewpoint is provided for general
                            information purposes only and should not be used as a substitute for consultation
                            with professional advisors.  This Agreement is not an offer of any financial, accounting,
                            or other professional goods or services.</li>
                    </ol>
                </li>
                <li><strong>Limitation of Liability.</strong> TO THE FULLEST EXTENT ALLOWED BY APPLICABLE
                    LAW, IN NO EVENT WILL PWC BE LIABLE FOR ANY LOSS OF PROFITS, LOSS OF BUSINESS,
                    LOSS OF USE OR DATA, INTERRUPTION OF BUSINESS, OR FOR ANY INDIRECT, SPECIAL, INCIDENTAL,
                    PUNITIVE OR CONSEQUENTIAL DAMAGES OF ANY KIND, EVEN IF ADVISED OF THE POSSIBILITY
                    OF SUCH DAMAGES. TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, PWC’S ENTIRE LIABILITY
                    TO CLIENT OR ANY OTHER PARTY IN ANY WAY RELATED TO THIS AGREEMENT, VIEWPOINT,
                    AND ANY OTHER SUBJECT MATTER OF THIS AGREEMENT, AND REGARDLESS OF THE FORM OF ANY CLAIM
                    OR ACTION (WHETHER STATUTORY OR COMMON LAW, AND WHETHER SOUNDING IN NEGLIGENCE,
                    STRICT LIABILITY, OR OTHERWISE), WILL NOT EXCEED AN AMOUNT EQUAL TO THE GREATER OF $100
                    AND THE AGGREGATE AMOUNTS PAID BY CLIENT PURSUANT TO
                    <span className="text-underline">SECTION 2 (FEES)</span> FOR THE THEN-CURRENT INITIAL TERM
                    OR RENEWAL TERM (AS APPLICABLE).</li>
                <li>
                    <strong className="text-underline">Miscellaneous</strong>
                    <ol>
                        <li><strong>Governing Law and Dispute Resolution.</strong> This Agreement and any
                            dispute relating to it, including any claims or defenses asserted, will be governed
                            by and construed, interpreted, and enforced in accordance with the domestic laws of
                            the State of New York, without giving effect to any provisions that would require
                            the laws of another jurisdiction to apply. The Uniform Computer Information Transactions
                            Act, the Uniform Commercial Code, and the United Nations Convention on
                            Contracts for the International Sale of Goods will not apply to this Agreement. Any
                            unresolved dispute relating to or arising out of this Agreement (including
                            Viewpoint and the use thereof) will be resolved by arbitration, except that either party
                            is free to seek equitable relief in a court having jurisdiction in the event
                            of a breach or threatened breach of a party’s obligations with respect to Confidential
                            Information or intellectual property rights. The arbitration will be conducted
                            in accordance with the Rules for Non-Administered Arbitration of the International
                            Institute for Conflict Prevention and Resolution then in effect
                            (“<strong>CPR Rules</strong>”). The arbitration will be conducted before a panel of three
                            (3) arbitrators selected using the screened process set forth in the CPR
                            Rules. The arbitration will be seated and take place in New York, New York. The arbitration
                            panel (and not any court or agency) will have exclusive authority to
                            resolve any dispute relating to the interpretation, applicability, enforceability, or
                            formation of, or defenses with respect to, this Agreement, including issues of
                            arbitrability. The arbitration panel will have no power to award non-monetary or
                            equitable relief of any sort, or to award damages inconsistent with this Agreement
                            (including <span className="text-underline">Section 6</span>). Judgment on any arbitration
                            award may be entered in any court having jurisdiction. All aspects of the
                            arbitration will be treated as confidential. Any demand by Client for arbitration must
                            be issued within one (1) year from the date that Client became aware or
                            should reasonably have become aware of the facts that give rise to PwC’s alleged liability
                            and in any event no later than two (2) years after the cause of action
                            accrued. Client agrees to: (a) bring any claim or other legal proceeding of any nature
                            arising from or related to this Agreement or its subject matter against PwC
                            and not against PwC’s Affiliates, the Network Firms, or their respective partners,
                            principals, members, employees, agents, licensors and service providers; (b)
                            ensure that Client’s Affiliates and Client Users do not assert any claim arising
                            from or related to this Agreement or its subject matter against any of the foregoing
                            or PwC; and (c) accept responsibility and liability if any such party pursues such
                            a claim. This Section is also intended for the benefit of the entities and
                            individuals set forth in the foregoing clause (a).</li>
                        <li><strong>General.</strong> This Agreement is the entire agreement between the
                            parties concerning the subject matter hereof and supersedes all prior or
                            contemporaneous representations, discussions, proposals, negotiations, conditions
                            and agreements, whether oral or written, and all communications between
                            the parties relating to the subject matter of this Agreement and all past courses
                            of dealing or industry custom. No amendment or modification of any provision
                            of this Agreement will be effective unless in writing and signed by a duly authorized
                            signatory of both parties. The terms and conditions of this Agreement will
                            supersede any different or additional terms in any order forms, purchase orders,
                            order acknowledgements, invoices or other similar ordering documents, which
                            additional or different terms are expressly rejected. PwC objects to any such
                            additional or different terms contained in any of the foregoing documents. PwC’s
                            acceptance of any offer is expressly made conditional on assent to the terms of
                            this Agreement. Nothing contained in Viewpoint or in this Agreement grants to
                            Client any right to use any trade names, trademarks, service marks, logos or designs
                            without the express prior written consent of PwC or the applicable rights
                            holder. PwC may use Client’s names, trademarks, and logos in experience citations,
                            case studies, and recruiting materials and on public-facing customer lists
                            and reference materials for Viewpoint, in tangible, electronic, or digital form.
                            Each provision of this Agreement is separable and if for any reason any provision
                            or provisions hereof are determined to be invalid and contrary to any existing or
                            future law, such invalidity will not impair the operation of or affect those
                            portions of this Agreement that are valid, but in such case the parties will endeavor
                            to amend or modify this Agreement to achieve to the extent reasonably
                            practicable the purpose of the invalid provision. The headings of sections of this
                            Agreement are for convenience and will not be interpreted to limit or amplify
                            the provisions of this Agreement. This Agreement will be construed as if drafted
                            jointly by the parties and no presumption or burden of proof will arise favoring
                            or disfavoring any party by virtue of the authorship of any of the provisions of
                            this Agreement. No failure by a party to insist upon the strict performance of any
                            term or condition of this Agreement or to exercise any right or remedy will operate
                            as a waiver. The rights and remedies provided by this Agreement are cumulative
                            and the exercise of any one right or remedy by a party will not preclude or waive
                            its right to exercise any or all other rights or remedies. This Agreement does
                            not create an agency, partnership, joint venture or any other form of association,
                            for tax purposes or otherwise, between the parties; the parties will at all
                            times be and remain independent contractors and neither party nor its agents have
                            any authority to bind the other party. Nothing in this Agreement will be
                            construed to confer upon any third party other than the parties to this Agreement
                            a right of action under this Agreement.</li>
                        <li><strong>Assignment.</strong> Neither party will assign, transfer, delegate or
                            otherwise dispose of, whether voluntarily or involuntarily, by operation of law
                            or otherwise, this Agreement or any of its rights or obligations under this Agreement,
                            without the other party’s prior written consent. Notwithstanding the
                            foregoing, PwC may assign, transfer, delegate or otherwise dispose of, whether
                            voluntarily or involuntarily, by operation of law or otherwise, this Agreement
                            or any of its rights or obligations under this Agreement, without Client’s prior
                            written consent (a) to any Network Firm or (b) in connection with any
                            acquisition, merger, consolidation, reorganization, or similar transaction, or any
                            spin-off, divestiture, or other separation of a PwC business or portion
                            of a PwC business. Any attempted assignment, transfer, delegation or disposal in
                            violation of the foregoing will be void and will be a material breach of
                            this Agreement. Subject to the foregoing, this Agreement will be binding upon and
                            inure to the benefit of the parties and their successors and permitted assigns.</li>
                        <li><strong>CPA Notice; Nature of Arrangement.</strong> PwC is owned by professionals
                            who hold CPA licenses as well as by professionals who are not licensed CPAs.
                            Depending on the nature of the products and services provided to Client under this
                            Agreement (including Viewpoint), non-CPA owners may be involved in providing
                            such products and services.  Client agrees that Client will be solely responsible
                            for any and all communications required with its regulators and that PwC is not
                            being engaged to interact or meet with third parties, including Client’s regulators.
                            If PwC is requested or authorized by Client or required by government
                            regulation, regulatory agency, subpoena, or other legal process to produce materials
                            or personnel for testimony or interview with respect to Viewpoint or any
                            other products or services to be provided by PwC to Client under this Agreement,
                            Client will reimburse PwC for PwC’s and its counsels’ reasonable expenses and
                            professional time incurred in responding to such a request.</li>
                    </ol>
                </li>
            </ol>
        </div>
    );
}

export default TermsConditionsUS;