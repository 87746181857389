import { useState } from "react";
import { CardTitle } from "./CardTitle";
import { PriceCom } from "./PriceCom";
import { formatNumber } from "../../../common/Util";

export const RenewalTerm = (props) => {
    const [detailShow, setDetailShow] = useState(false);
    const { orderDetails } = props;

    return orderDetails.RenewalTerm1Value ? (
        <div className="">
            <div className="m-h-24 flex-justify-between">
                <CardTitle
                    iconClass="icon-transform-outline"
                    text="Renewal Term"
                />
                <div
                    onClick={() => setDetailShow(!detailShow)}
                    className="orange text-12 half-bold a-hover underline text-bold"
                >
                    {detailShow ? "Hide Details" : "View Details"}
                </div>
            </div>
            <ul className="m-h-24 ul-clear-left-p">
                <li className="p-t-15">
                    <div className="flex-justify-between text-12 half-bold gray">
                        <h6 className="text-12 half-bold">
                            {"Downloadable Assets - " +
                                orderDetails.ServiceModule +
                                " Module"}
                        </h6>
                        <span>
                            {formatNumber(orderDetails.RenewalTerm1Value)}
                        </span>
                    </div>
                    {detailShow ? (
                        <div>
                            <div className="text-12 half-bold orange">
                                Annual Fee | Due as of renewal effective date
                            </div>
                            <div className="gray-text-10 half-bold">
                                <p className="clear-margin-bottom">
                                    {orderDetails.ServiceModule ===
                                    "Finance Function"
                                        ? "Access to the Finance Function collection of Downloadable Assets"
                                        : "Access to Downloadable Assets tagged as " +
                                          orderDetails.ServiceModule}
                                </p>
                            </div>
                        </div>
                    ) : null}
                </li>
                <li className="p-t-15">
                    <div className="flex-justify-between text-12 gray half-bold">
                        <h6 className="text-12 half-bold">
                            Downloadable Assets - General Utility
                        </h6>
                        <span>Included</span>
                    </div>
                    {detailShow ? (
                        <div>
                            <div className="gray-text-10 half-bold">
                                <p className="clear-margin-bottom">
                                    Access to Downloadable Assets tagged as
                                    General Utility
                                </p>
                            </div>
                        </div>
                    ) : null}
                </li>
                <li className="p-t-15">
                    <div className="flex-justify-between text-12 gray half-bold">
                        <h6 className="text-12 half-bold">
                            {"Cloud-Based Assets - " +
                                orderDetails.ServiceModule}
                        </h6>
                        <span>Included</span>
                    </div>
                    {detailShow ? (
                        <div>
                            <div className="gray-text-10 half-bold">
                                <p className="clear-margin-bottom">
                                    Access to Cloud-Based Assets that are part
                                    of the {orderDetails.ServiceModule}{" "}
                                    collection. There is no cap on usage of
                                    assets tagged both as{" "}
                                    {orderDetails.ServiceModule} and General
                                    Utility.
                                </p>
                            </div>
                        </div>
                    ) : null}
                </li>
            </ul>
            <div className="bg-black white flex-justify-between p-h-24 p-v-15 m-t-15 text-bold border-radius-b">
                <PriceCom
                    desc="Subtotal before tax"
                    price={formatNumber(orderDetails.RenewalTerm1Value)}
                />
            </div>
        </div>
    ) : null;
};
