// Order statement template
import DOD from "./DOD";
import WFO from "./WFO";
import LISTEN from "./LISTEN";
import CN from "./CN";
const STATEMENT = {
    DOD,
    WFO,
    LISTEN,
    CN
};

export default STATEMENT;
