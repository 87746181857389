const TermsConditionsAgreeUS = ({ props }) => {
    return (
        <div className="text-14 p-t-10">
            <div>
                Upon approval of this request, the provision of Viewpoint and/or
                Disclosure Checklist will be subject to the respective product's
                Terms & Conditions, available at:
            </div>
            <div className="p-t-10 p-b-10">
                <span className="gray-text-14 bold">Viewpoint: </span>
                <a
                    href="https://productcentral.products.pwc.com/product/Viewpoint"
                    target="_blank"
                    rel="noreferrer"
                    className="orange bold"
                >
                    https://productcentral.products.pwc.com/product/Viewpoint
                </a>
            </div>
            <div className="p-b-10">
                <span className="gray-text-14 bold">
                    Disclosure Checklist:{" "}
                </span>
                <a
                    href="https://productcentral.products.pwc.com/product/Disclosure-Checklist"
                    target="_blank"
                    rel="noreferrer"
                    className="orange bold"
                >
                    https://productcentral.products.pwc.com/product/Disclosure-Checklist
                </a>
            </div>
            <div className="p-b-10">
                These Terms & Conditions include (a) the PricewaterhouseCoopers
                Standard Hosted Product License Terms and Conditions at{" "}
                <a
                    href="https://productcentral.products.pwc.com/legal/Hosted-Software-Terms"
                    target="_blank"
                    rel="noreferrer"
                    className="orange bold"
                >
                    https://productcentral.products.pwc.com/legal/Hosted-Software-Terms
                </a>
                {" "}(the “Standard Terms”); and (b) documents incorporated into the
                Standard Terms by reference, including the respective product's
                Offering Overview, Maintenance and Support terms, and Data
                Processing Agreement, each available as described in the
                Standard Terms.
            </div>
            <div>
                Once approved by PwC, your company's T&C approver will receive a
                final Order electronically that will require acknowledgement of
                these Terms & Conditions. Only upon PwC's receipt of an Order
                signed by your company's T&C approver can access to the
                respective product(s) be granted to your company.
            </div>
        </div>
    );
};

export default TermsConditionsAgreeUS;
