import { ORDER_STATUS } from "./Constants";
import {
    RenewalBanner,
    AutoRenewalBanner,
    ConfirmedBanner,
    CanceledBanner,
    ExpiredBanner
} from "./Messaging";
import { formatDate } from "../../../../common/Util";
import "./Summary.css";
import { PRODUCTS } from "../../../Config";

const Content = (props) => {
    const { product, submissionInfo } = props;

    const checkMarkIcon = (
        <span className="appkiticon icon-circle-checkmark-outline a-font-32 renewal-green"></span>
    );
    const endProcessIcon = (
        <span className="appkiticon icon-end-process-outline a-font-32 renewal-red"></span>
    );
    const archiveIcon = (
        <span className="appkiticon icon-archive-outline a-font-32 light-gray"></span>
    );
    const timeIcon = (
        <span className="appkiticon icon-time-outline a-font-32 light-gray"></span>
    );
    const transformIcon = (
        <span className="appkiticon icon-transform-outline a-font-32 light-gray"></span>
    );
    const icons = {
        [ORDER_STATUS.ACTIVE]: checkMarkIcon,
        [ORDER_STATUS.PENDING]: timeIcon,
        [ORDER_STATUS.DRAFT]: archiveIcon,
        [ORDER_STATUS.CANCELED]: endProcessIcon,
        [ORDER_STATUS.EXPIRED]: endProcessIcon,
        [ORDER_STATUS.CONFIRMED]: checkMarkIcon,
        [ORDER_STATUS.PENDING_RENEWAL]: transformIcon,
        [ORDER_STATUS.AUTO_RENEW]: transformIcon
    };

    const grayFont = "m-l-8 a-font-22 light-gray";
    const redFont = "m-l-8 a-font-22 renewal-red";
    const greenFont = "m-l-8 a-font-22 renewal-green";
    const fonts = {
        [ORDER_STATUS.ACTIVE]: greenFont,
        [ORDER_STATUS.PENDING]: grayFont,
        [ORDER_STATUS.DRAFT]: grayFont,
        [ORDER_STATUS.CANCELED]: redFont,
        [ORDER_STATUS.EXPIRED]: redFont,
        [ORDER_STATUS.CONFIRMED]: greenFont,
        [ORDER_STATUS.PENDING_RENEWAL]: grayFont,
        [ORDER_STATUS.AUTO_RENEW]: grayFont
    };

    const isDraftOrPending =
        submissionInfo.status === ORDER_STATUS.DRAFT ||
        submissionInfo.status === ORDER_STATUS.PENDING;

    const isActive = submissionInfo.status === ORDER_STATUS.ACTIVE;
    const isTrial = submissionInfo.isTrial;

    // the logic to show the banners does not match the buttons or order status
    const banners = (submissionInfo) => {
        if (submissionInfo.status === ORDER_STATUS.PENDING_RENEWAL) {
            return (
                <RenewalBanner
                    client={props.submissionInfo.client}
                    product={product.label}
                    endDate={submissionInfo.installBaseExpirationDate}
                />
            );
        }

        if (submissionInfo.status === ORDER_STATUS.AUTO_RENEW) {
            return (
                <AutoRenewalBanner
                    client={props.submissionInfo.client}
                    product={product.label}
                    endDate={submissionInfo.installBaseExpirationDate}
                />
            );
        }

        if (submissionInfo.status === ORDER_STATUS.CONFIRMED) {
            return (
                <ConfirmedBanner
                    client={props.submissionInfo.client}
                    product={product.label}
                    endDate={submissionInfo.contractEndDate}
                />
            );
        }

        if (submissionInfo.status === ORDER_STATUS.CANCELED) {
            return (
                <CanceledBanner
                    client={props.submissionInfo.client}
                    product={product.label}
                />
            );
        }

        if (submissionInfo.status === ORDER_STATUS.EXPIRED) {
            return (
                <ExpiredBanner
                    client={props.submissionInfo.client}
                    product={product.label}
                    endDate={submissionInfo.installBaseExpirationDate}
                />
            );
        }

        return <></>;
    };

    return (
        <div className="flex-justify-center">
            <div className="content m-v-10">
                <div className="round-card">
                    <div className="flex-start">
                        <div className="flex-column">
                            {icons[submissionInfo.status]}
                            {isActive && isTrial && (
                                <div className="a-font-10 renewal-green m-v-5">
                                    Trial
                                </div>
                            )}
                        </div>
                        <span className={fonts[submissionInfo.status]}>
                            {submissionInfo.status}
                        </span>
                    </div>
                    <div
                        className={
                            isActive && isTrial
                                ? "separator-line-b-m-24"
                                : "separator-line"
                        }
                    ></div>
                    <div className="flex-align-center p-l-12 m-t-27">
                        <span className="appkiticon icon-digital-transformation-outline a-font-24 a-text-orange location-icon rigth"></span>
                        <div className="text-16 m-l-14">
                            <div className="product-name">{product.label}</div>
                        </div>
                    </div>
                    <div className="flex-align-center p-l-12 m-t-27">
                        <span className="appkiticon icon-calendar-outline a-font-24 a-text-yellow location-icon"></span>
                        <div className="text-16 m-l-14">
                            <div className="light-gray-text-12">
                                {isDraftOrPending
                                    ? "Submitted on"
                                    : "Subscription Period"}
                            </div>
                            <div className="gray-text-14 half-bold">
                                {isDraftOrPending
                                    ? formatDate(submissionInfo.submittedDate)
                                    : `${formatDate(
                                          submissionInfo.contractStartDate
                                      )} - ${formatDate(
                                          submissionInfo.contractEndDate
                                      )}`}
                            </div>
                        </div>
                    </div>

                    {isActive && submissionInfo.showSubmitNewAFS && (
                        <div className="flex-align-center p-l-12 m-t-27">
                            <span className="appkiticon icon-calendar-accepted-outline a-font-24 a-text-yellow location-icon"></span>
                            <div className="text-16 m-l-14">
                                <div className="light-gray-text-12">
                                    AFS Expiration Date
                                </div>
                                <div className="gray-text-14 half-bold">
                                    {submissionInfo.afsExpirationDate
                                        ? formatDate(
                                              submissionInfo.afsExpirationDate
                                          )
                                        : "Missing"}
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="flex-align-center p-l-12 m-t-27">
                        <span className="appkiticon icon-leader-outline a-font-24 a-text-yellow location-icon"></span>
                        <div className="text-16 m-l-14">
                            <div className="light-gray-text-12">Submitter</div>
                            <div className="gray-text-14 half-bold">
                                {submissionInfo.submitterName}
                            </div>
                        </div>
                    </div>

                    {(!isDraftOrPending || product.id === PRODUCTS.POD.id) && (
                        <div className="flex-align-center p-l-12 m-t-27">
                            <span className="appkiticon icon-pencil-outline a-font-24 a-text-yellow location-icon"></span>
                            <div className="text-16 m-l-14">
                                <div className="light-gray-text-12">
                                    Contract Signatory
                                </div>
                                <div className="gray-text-14 half-bold">
                                    {submissionInfo.signedBy}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {banners(submissionInfo)}
        </div>
    );
};

export default Content;
