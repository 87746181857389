export const VALIDATION_TYPE = Object.freeze({
    REQUIRED: "REQUIRED",
    REQUIRED_ARRAY: "REQUIRED_ARRAY",
    PATTERN: "PATTERN",
    CONDITIONAL_REQUIRED: "CONDITIONAL_REQUIRED",
    CONDITIONAL_EXCLUDED: "CONDITIONAL_EXCLUDED",
    CONDITIONAL_PATTERN: "CONDITIONAL_PATTERN",
    CUSTOM: "CUSTOM"
});

// copy initialFormData:
//   for each field, replace value="" with { error: undefined, message: validations.<>.errorMessage }
// note that error has 3 states:
//   {undefined, true, false} => {hide error message, fade in error message, fade out error message}
export const buildInitialValidationErrors = (validations, formData) => {
    return Object.fromEntries(
        Object.entries(formData).map(([key, value]) => [
            key,
            { error: undefined, message: validations[key]?.errorMessage }
        ])
    );
};

export const validate = (
    validations,
    formData,
    validationErrors,
    setValidationErrors
) => {
    let hasError = false;

    for (const field in validations) {
        const result = validationErrors[field];
        const fieldValue = formData[field];
        switch (validations[field].type) {
            // field must be truthy
            case VALIDATION_TYPE.REQUIRED: {
                result.error = fieldValue ? false : true;
                break;
            }
            // fied is a non-empty array
            case VALIDATION_TYPE.REQUIRED_ARRAY: {
                result.error = fieldValue?.length > 0 ? false: true;
                break;
            }
            // field is required to match a pattern
            case VALIDATION_TYPE.PATTERN: {
                const pattern = validations[field].pattern;
                const hasError = !pattern.test(fieldValue);
                result.error = hasError ? true : false;
                break;
            }
            // field is required when the value of otherField equals otherFieldValue
            case VALIDATION_TYPE.CONDITIONAL_REQUIRED: {
                const otherField = validations[field].otherField;
                const otherFieldValue = validations[field].otherFieldValue;
                if (otherFieldValue === formData[otherField]) {
                    result.error = fieldValue ? false : true;
                } else {
                    result.error = false;
                }
                break;
            }
            // field is required when the value of otherField does not equal otherFieldValue
            case VALIDATION_TYPE.CONDITIONAL_EXCLUDED: {
                const otherField = validations[field].otherField;
                const otherFieldValue = validations[field].otherFieldValue;
                if (otherFieldValue !== formData[otherField]) {
                    result.error = fieldValue ? false : true;
                } else {
                    result.error = false;
                }
                break;
            }
            // field is required to match a pattern when the value of otherField equals otherFieldValue
            case VALIDATION_TYPE.CONDITIONAL_PATTERN: {
                const otherField = validations[field].otherField;
                const otherFieldValue = validations[field].otherFieldValue;
                if (otherFieldValue === formData[otherField]) {
                    const pattern = validations[field].pattern;
                    const hasError = !pattern.test(fieldValue);
                    result.error = hasError ? true : false;
                } else {
                    result.error = false;
                }
                break;
            }
            // run the callback function to determine if there is a validation error
            case VALIDATION_TYPE.CUSTOM: {
                result.error = validations[field].callback(formData);
                break;
            }

            default:
                break;
        }
        hasError = hasError || result.error;
    }

    setValidationErrors({ ...validationErrors });
    return !hasError;
};
