import { useState } from "react";
import { createHashHistory } from "history";
import { useNavigate, useParams } from "react-router-dom";

import Header from "../../../Header";
import Footer from "../../../Footer";
import SupportBanner from "../SupportBanner";

import Summary from "./summary/Summary";
import AFS from "./afs/AFS";
import Resend from "./resend/Resend";
import EditManager from "./edit/EditManager";
import UploadContract from "./upload/UploadContract";
import Renew from "./renew/Renew";

import { NEW_ORDER_DATA, NEW_ORDER_STATE } from "../../Constants";
import "./ViewOrder.css";
import useViewOrderInfo from "../common/order/UseViewOrderInfo";
import {
    gotoManageDefault,
    gotoSubmitOrder,
    redirectToViewOrder
} from "../../../../Routes";

const ViewOrder = (props) => {
    const { opportunityId } = useParams();

    const [product, setProduct] = useState({});
    const [submissionInfo, setSubmissionInfo] = useState({});
    const [formData, setFormData] = useState(NEW_ORDER_DATA);
    const [formState, setFormState] = useState(NEW_ORDER_STATE);
    const [additionalContactCount, setAdditionalContactCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    // order terms page //
    useViewOrderInfo(
        opportunityId,
        setSubmissionInfo,
        setProduct,
        setFormData,
        setFormState,
        setAdditionalContactCount,
        setLoading
    );

    const PAGES = {
        SUMMARY: 0,
        AFS: 1,
        RESEND: 2,
        EDIT: 4,
        UPLOAD: 5,
        RENEW: 6
    };

    const [page, setPage] = useState(PAGES.SUMMARY);
    const gotoSubmit = () => {
        gotoSubmitOrder(navigate, product.id, opportunityId);
    };
    const gotoEdit = () => {
        setPage(PAGES.EDIT);
    };
    const gotoUpload = () => {
        setPage(PAGES.UPLOAD);
    };

    // common menu actions
    const gotoAllOrders = () => {
        gotoManageDefault(navigate, product);
    };
    const history = createHashHistory();
    const gotoViewOrder = () => {
        // go back to the view page which reloads the order
        redirectToViewOrder(history, product.id, opportunityId);
    };

    const pageComponents = [];
    pageComponents[PAGES.SUMMARY] = (
        <Summary
            opportunityId={opportunityId}
            gotoSubmit={gotoSubmit}
            gotoEdit={gotoEdit}
            gotoUpload={gotoUpload}
            product={product}
            submissionInfo={submissionInfo}
            gotoAllOrders={gotoAllOrders}
        />
    );
    pageComponents[PAGES.AFS] = <AFS />;
    pageComponents[PAGES.RESEND] = <Resend />;
    pageComponents[PAGES.EDIT] = (
        <EditManager
            opportunityId={opportunityId}
            product={product}
            formState={formState}
            setFormState={setFormState}
            formData={formData}
            setFormData={setFormData}
            additionalContactCount={additionalContactCount}
            setAdditionalContactCount={setAdditionalContactCount}
            submissionInfo={submissionInfo}
            gotoAllOrders={gotoAllOrders}
            gotoViewOrder={gotoViewOrder}
        />
    );
    pageComponents[PAGES.UPLOAD] = (
        <UploadContract
            opportunityId={opportunityId}
            gotoAllOrders={gotoAllOrders}
            gotoViewOrder={gotoViewOrder}
            product={product}
            formState={formState}
            formData={formData}
            submissionInfo={submissionInfo}
        />
    );
    pageComponents[PAGES.RENEW] = <Renew />;

    return (
        <>
            <Header />
            {loading ? (
                <div className="flex-justify-center vh-65">
                    <div className="center">
                        <div className="a-loading a-primary loading-large"></div>
                        <div>Please wait while the order is loaded...</div>
                    </div>
                </div>
            ) : (
                <div className="container-fluid">{pageComponents[page]}</div>
            )}
            <SupportBanner />
            <Footer />
        </>
    );
};
export default ViewOrder;
