import LoadingOrDisplay from "../../../../../../common/LoadingOrDisplay";
import { formatNumber } from "../../../../../../common/Util";
import { CONTRACT_TYPE } from "../../../../contract/Constants";

// TODO : stadardize these fields? so that this object is not necessary
export const LISTEN_OPTION_TO_FIELD = {
    [CONTRACT_TYPE.customerExperience]: {
        id: "customerExperienceProdId"
    },
    [CONTRACT_TYPE.employeeExperience]: {
        id: "employeeExperienceProdId"
    }
};

const InitialTerm = ({ props, loading }) => {
    const { formState, formData } = props;
    // this is duplicated in ../Content.js and could be passed in as a prop,
    // but this component is also used on the review page without ../Content.js
    // TODO : find a better solution?  don't have 2 versions of the same page?
    const isCustomerExperience =
        formData.contractType === CONTRACT_TYPE.customerExperience;
    const isEmployeeExperience =
        formData.contractType === CONTRACT_TYPE.employeeExperience;

    // TODO : all of this logic / fee calculation should be in the backend
    const subscriptionFee = isCustomerExperience
        ? formState.customerExperienceProdIdFees
        : isEmployeeExperience
        ? formState.employeeExperienceProdIdFees
        : 0;
    const headcountFee =
        formData.numberOfUsers * formState.headCountFeeProdFees;
    const total = isEmployeeExperience
        ? subscriptionFee + headcountFee
        : subscriptionFee;
    return (
        <div>
            <div className="round-card-black-top">
                <div className="p-b-16">
                    <span className="appkiticon icon-calendar-outline a-font-24 a-text-red p-r-16"></span>
                    <span className="text-16 bold">Initial Term</span>
                </div>
                <div className="light-gray-text-12">
                    <div className="row g-0">
                        <div className="col-9 m-v-8 gy-0">
                            Annual Subscription Fee
                        </div>
                        <div className="col-3 m-v-8 gy-0 right">
                            <LoadingOrDisplay
                                loading={loading}
                                display={formatNumber(subscriptionFee)}
                            />
                        </div>
                    </div>
                </div>
                {isEmployeeExperience && (
                    <>
                        <div className="light-gray-text-12">
                            <div className="row g-0">
                                <div className="col-9 m-v-8 gy-0">
                                    Employee Headcount Number
                                </div>
                                <div className="col-3 m-v-8 gy-0 right">
                                    {formatNumber(
                                        props.formData.numberOfUsers,
                                        ""
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="light-gray-text-12">
                            <div className="row g-0">
                                <div className="col-9 m-v-8 gy-0">
                                    Headcount Fee (
                                    {formatNumber(
                                        formState.headCountFeeProdFees
                                    )}
                                    .00 per employee)
                                </div>
                                <div className="col-3 m-v-8 gy-0 right">
                                    <LoadingOrDisplay
                                        loading={loading}
                                        display={formatNumber(headcountFee)}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
            <div className="black-background-rounded">
                <div className="row">
                    <span className="col">Subtotal before tax</span>
                    <span className="col right">
                        <LoadingOrDisplay
                            loading={loading}
                            display={formatNumber(total)}
                        />{" "}
                        USD
                    </span>
                </div>
            </div>
        </div>
    );
};

export default InitialTerm;
