const SupportInfo = () => {
    return (
        <div className="light-gray-text-14">
            Licensing Support
            <div>
                <span>Americas support: </span>
                <span className="orange">
                    <a
                        href="mailto:us_product-licensing-support@pwc.com"
                        target="_blank"
                        rel="noreferrer"
                    >
                        us_product-licensing-support@pwc.com
                    </a>
                </span>
            </div>
            <div>
                <span>
                    UK, Europe, Middle East, Africa, Mainland China, Hong Kong
                    SAR and Macau SAR support:{" "}
                </span>
                <span className="orange">
                    <a
                        href="mailto:uk_viewpoint@pwc.com"
                        target="_blank"
                        rel="noreferrer"
                    >
                        uk_viewpoint@pwc.com
                    </a>
                </span>
            </div>
            <div>
                <span>Canada support: </span>
                <span className="orange">
                    <a
                        href="mailto:CA_Viewpoint@pwc.com"
                        target="_blank"
                        rel="noreferrer"
                    >
                        CA_Viewpoint@pwc.com
                    </a>
                </span>
            </div>
            <div>
                <span>Japan support: </span>
                <span className="orange">
                    <a
                        href="mailto:jp_aarata_viewpoint-mbx@pwc.com"
                        target="_blank"
                        rel="noreferrer"
                    >
                        jp_aarata_viewpoint-mbx@pwc.com
                    </a>
                </span>
            </div>
        </div>
    );
};

export default SupportInfo;
