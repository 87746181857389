export const PriceCom = (props) => {
    const { desc, price } = props;
    return (
        <>
            <h3 className="text-16 clear-margin-bottom text-bold a-text-white">
                {desc}
            </h3>
            <span className="a-text-white">{price} USD</span>
        </>
    );
};
