import { useState } from "react";
import { Input, Button, Tooltip } from "appkit-react";
import { getClientOpportunity } from "../../../service/External";
import { LOOKUP_MESSAGE_STATES } from "../../../Constants";
import useIsMounted from "../../../../common/UseIsMounted";
import FullScreenModal from "../../common/FullScreenModal";

const Lookup = ({ props }) => {
    const isMounted = useIsMounted();
    const [looking, setLooking] = useState(false);
    const callback = () => {
        if (isMounted()) {
            setLooking(false);
        }
    };

    const lookup = () => {
        const matches = props.formData.salesforceURL.match(
            /\/r(|\/Opportunity)\/([A-Za-z0-9]{18}|[A-Za-z0-9]{15})/
        );
        if (matches?.length > 2) {
            setLooking(true);
            const opportunityId = matches[2];
            getClientOpportunity(
                opportunityId,
                props.product.id,
                props.formState,
                props.setFormState,
                callback
            );
        } else {
            props.setFormState({
                ...props.formState,
                lookupMessage: LOOKUP_MESSAGE_STATES.NOT_FOUND
            });
        }
    };

    const tooltipText =
        "Copy your client's Salesforce opportunity URL in the browser's address bar, then paste it in the field below.";

    return (
        <div className="p-b-125">
            <div className="gray-text-12 m-b-12">PRODUCT</div>
            <div className="round-card bold">{props.product.label}</div>
            <div className="separator-line"></div>
            <span className="tooltip-label">
                <label className="gray-text-12">
                    SALESFORCE OPPORTUNITY URL
                </label>
                <Tooltip
                    content={tooltipText}
                    placement="top"
                    trigger="hover"
                    className="tooltip-content"
                >
                    <span className="appkiticon icon-information-outline a-text-red"></span>
                </Tooltip>
            </span>
            <Input
                name="salesforceURL"
                onChange={props.handleChange("salesforceURL")}
                value={props.formData.salesforceURL}
                hasError={props.formState.notFound || props.formState.taError}
                onEnterPress={lookup}
            />
            <div className="nav-buttons m-t-48">
                <Button onClick={lookup}>LOOK UP CLIENT OPPORTUNITY</Button>
            </div>
            {looking && (
                <FullScreenModal
                    content={
                        "Just a moment while we look up the client opportunity and confirm client status..."
                    }
                />
            )}
        </div>
    );
};

export default Lookup;
