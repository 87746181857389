// Order details template
import DOD from "./DOD";
import WFO from "./WFO";
import LISTEN from "./LISTEN";
import CN from "./CN";
const DETAILS = {
    DOD,
    WFO,
    LISTEN,
    CN
};

export default DETAILS;
