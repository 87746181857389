import { PRODUCT_STAKEHOLDERS } from "../../../Constants";

const ContactDetails = (props) => {
    const { label, email, fullName, region, market, stakeholder } = props;

    const isLeadTaxPartner =
        stakeholder === PRODUCT_STAKEHOLDERS.LEAD_TAX_PARTNER.id;
    const isLeadTaxDirector =
        stakeholder === PRODUCT_STAKEHOLDERS.LEAD_TAX_DIRECTOR.id;

    return (
        <>
            <div className="gray-text-12 m-b-12 m-t-32">
                {isLeadTaxDirector
                    ? "ADDITIONAL CONTACTS"
                    : label.toUpperCase()}
            </div>
            <div className="round-card">
                {isLeadTaxPartner && (
                    <>
                        <div className="gray-text-16">{fullName}</div>
                        <div className="light-gray-text-12 p-t-4">{label}</div>
                        <div className="light-gray-text-12">
                            Region: {region}
                        </div>
                        <div className="light-gray-text-12 p-b-8">
                            Market: {market}
                        </div>
                    </>
                )}
                {isLeadTaxDirector && (
                    <>
                        <div className="gray-text-16">{fullName}</div>
                        <div className="light-gray-text-12 p-t-4 p-b-8">
                            {label}
                        </div>
                    </>
                )}
                <div className="text-12 orange half-bold">{email}</div>
            </div>
        </>
    );
};

export default ContactDetails;
