import Messaging from "./Messaging";
import Opportunity from "./Opportunity";
import Lookup from "./Lookup";
import AFS, { NO, YES } from "./AFS";
import Continue from "./Continue";
import { LOOKUP_MESSAGE_STATES, NEW_ORDER_DATA } from "../../../Constants";
import "./Acceptance.css";
import {
    validate,
    buildInitialValidationErrors,
    VALIDATION_TYPE
} from "../../common/Validation";
import { useState } from "react";
import { PRODUCTS } from "../../../Config";
import { EXTERNAL_URLS } from "../../../../common/Constants";
import { AFS_NUMBER_REGEX } from "../../../../common/Util";

const Acceptance = (props) => {
    const { product, formState, setFormState, formData, forward } = props;

    const [checked, setChecked] = useState(false);

    const message = {
        [LOOKUP_MESSAGE_STATES.NOT_FOUND]: {
            showOpportunity: false,
            showContinue: false,
            showLookup: true,
            showAFS: false
        },
        [LOOKUP_MESSAGE_STATES.TA_FAIL]: {
            showOpportunity: true,
            showContinue: false,
            showLookup: false,
            showAFS: false
        },
        [LOOKUP_MESSAGE_STATES.TA_ERROR]: {
            showOpportunity: false,
            showContinue: false,
            showLookup: true,
            showAFS: false
        },
        [LOOKUP_MESSAGE_STATES.AFS_NOTIFICATION_REQUIRED]: {
            showOpportunity: true,
            showContinue: true,
            showLookup: false,
            showAFS: false
        },
        [LOOKUP_MESSAGE_STATES.AFS_INFORMATION_REQUIRED]: {
            showOpportunity: true,
            showContinue: true,
            showLookup: false,
            showAFS: true
        },
        [LOOKUP_MESSAGE_STATES.PASS_NA]: {
            showOpportunity: true,
            showContinue: true,
            showLookup: false,
            showAFS: false
        },
        [LOOKUP_MESSAGE_STATES.PASS_NO_BANNER]: {
            showOpportunity: true,
            showContinue: true,
            showLookup: false,
            showAFS: false
        },
        [LOOKUP_MESSAGE_STATES.SECONDARY_NA]: {
            showOpportunity: true,
            showContinue: false,
            showLookup: false,
            showAFS: false
        },
        [LOOKUP_MESSAGE_STATES.NOT_SUPPORTED]: {
            showOpportunity: true,
            showContinue: true,
            showLookup: false,
            showAFS: false
        }
    };

    const validations = {
        afsCompleted: {
            type: VALIDATION_TYPE.REQUIRED,
            errorMessage:
                "Please indicate if an AFS Request was completed and approved."
        },
        afsNumber: {
            type: VALIDATION_TYPE.PATTERN,
            pattern: AFS_NUMBER_REGEX,
            errorMessage: "Please enter a valid AFS number, e.g. AFS-123456"
        },
        afsApprovalDate: {
            type: VALIDATION_TYPE.REQUIRED,
            errorMessage: "Please select a valid date."
        }
    };

    const [validationErrors, setValidationErrors] = useState(
        buildInitialValidationErrors(validations, NEW_ORDER_DATA)
    );

    const handleContinue = () => {
        if (showAFS && afsWasCompleted) {
            if (
                validate(
                    validations,
                    formData,
                    validationErrors,
                    setValidationErrors
                )
            ) {
                forward();
            }
        } else if (showAFS && afsWasNotCompleted) {
            window.open(EXTERNAL_URLS.gotoAFS);
        } else {
            forward();
        }
    };

    const showAFS =
        message[formState.lookupMessage]?.showAFS &&
        product.id === PRODUCTS.POD.id;

    // formData.afsCompleted has 3 states: {YES, NO, ""}
    const afsWasCompleted = formData.afsCompleted === YES;
    const afsWasNotCompleted = formData.afsCompleted === NO;

    return (
        <div>
            <div className="row">
                <div className="col-3"></div>
                <div className="col-6 flex-justify-center">
                    <div className="content">
                        <h4>Digital Client Acceptance</h4>
                        <h4 className="m-b-12">(C1, C2 or New Client)</h4>
                        {product.optionalComponent.acceptance
                            .showAFSInstructions ? (
                            <div className="orange m-b-38">
                                For C1 or restricted clients,{" "}
                                <span className="bold">
                                    Authorization for Services (AFS)
                                </span>{" "}
                                must be completed and approved before
                                proceeding.
                            </div>
                        ) : (
                            <div className="m-b-38"></div>
                        )}
                        {(formState.lookupMessage === "" ||
                            message[formState.lookupMessage]?.showLookup) && (
                            <Lookup props={props} />
                        )}
                    </div>
                </div>
                <div className="col-3"></div>
            </div>
            {message[formState.lookupMessage]?.showOpportunity && (
                <Opportunity props={props} />
            )}
            {showAFS && (
                <AFS
                    props={props}
                    validationErrors={validationErrors}
                    checked={checked}
                    setChecked={setChecked}
                    afsWasCompleted={afsWasCompleted}
                    afsWasNotCompleted={afsWasNotCompleted}
                />
            )}
            {message[formState.lookupMessage]?.showContinue && (
                <Continue
                    disabled={
                        (showAFS && afsWasCompleted && !checked) ||
                        (showAFS && !afsWasCompleted && !afsWasNotCompleted)
                    }
                    gray={!showAFS}
                    onContinue={handleContinue}
                    label={
                        showAFS && afsWasNotCompleted ? "GO TO AFS" : "CONTINUE"
                    }
                />
            )}
            <Messaging
                product={product}
                formState={formState}
                setFormState={setFormState}
            />
        </div>
    );
};

export default Acceptance;
