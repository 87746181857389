import { ORDER_TERMS, PRODUCT_STAKEHOLDERS } from "../products/Constants";
import { TAB } from "./layout/manage/Constants";

export const PRODUCTS = {
    CN: {
        id: "CN",
        label: "Change Navigator",
        URL: "https://productcentral.products.pwc.com/product/Change-Navigator",
        email: "US_Change-Nav-Support@pwc.com",
        optionalComponent: {
            acceptance: {
                showAFSInstructions: false
            },
            client: {
                additionalContacts: true,
                maxAdditionalContacts: 1,
                additionalContactsEmailOnly: true,
                domainNames: false,
                maxDomainNames: 0
            },
            order: ORDER_TERMS.CN,
            contact: {
                nonEditableContacts: [PRODUCT_STAKEHOLDERS.CUSTOMER_SUCCESS],
                requiredContacts: [
                    PRODUCT_STAKEHOLDERS.LEAD_CLIENT_PARTNER,
                    PRODUCT_STAKEHOLDERS.ENGAGEMENT_LEADER
                ],
                additionalContacts: [
                    PRODUCT_STAKEHOLDERS.CLIENT_RELATIONSHIP_EXECUTIVE,
                    PRODUCT_STAKEHOLDERS.CC_RECIPIENT
                ]
            }
        },
        defaultValue: {
            contact: {
                nonEditableContacts: {
                    CUSTOMER_SUCCESS: "us_slp_dev+customer_success@pwc.com"
                    // PROD \\
                    // CUSTOMER_SUCCESS: "us_change-nav-support@pwc.com"
                }
            }
        },
        tabs: [TAB.active, TAB.pending, TAB.draft, TAB.renewal],
        defaultTab: TAB.active
    },
    DOD: {
        id: "DOD",
        label: "Digital on Demand",
        URL: "https://digitalondemand.pwc.com/",
        email: "us_dod_csms@pwc.com",
        optionalComponent: {
            acceptance: {
                showAFSInstructions: true
            },
            client: {
                additionalContacts: true,
                maxAdditionalContacts: 1,
                additionalContactsEmailOnly: true,
                domainNames: false,
                maxDomainNames: 0
            },
            order: ORDER_TERMS.DOD,
            contact: {
                nonEditableContacts: [
                    PRODUCT_STAKEHOLDERS.PRODUCT_LEADER,
                    PRODUCT_STAKEHOLDERS.DEALS_DESK,
                    PRODUCT_STAKEHOLDERS.CUSTOMER_SUCCESS,
                    PRODUCT_STAKEHOLDERS.CENTRALIZED_BILLING_TEAM
                ],
                requiredContacts: [
                    PRODUCT_STAKEHOLDERS.GLOBAL_RELATIONSHIP_PARTNER,
                    PRODUCT_STAKEHOLDERS.ENGAGEMENT_LEADER,
                    PRODUCT_STAKEHOLDERS.SALES_AMBASSADOR
                ],
                additionalContacts: [PRODUCT_STAKEHOLDERS.CC_RECIPIENT]
            }
        },
        defaultValue: {
            contact: {
                nonEditableContacts: {
                    PRODUCT_LEADER: "us_slp_dev+deals_desk@pwc.com",
                    DEALS_DESK: "us_slp_dev+product_leader@pwc.com",
                    CUSTOMER_SUCCESS: "us_slp_dev+customer_success@pwc.com",
                    CENTRALIZED_BILLING_TEAM:
                        "us_slp_dev+centralized_billing_team@pwc.com"
                    // PROD \\
                    // PRODUCT_LEADER: "jeff.lower@pwc.com",
                    // DEALS_DESK: "kimberly.w.wilson@pwc.com",
                    // CUSTOMER_SUCCESS: "us_dod_csms@pwc.com",
                    // CENTRALIZED_BILLING_TEAM: "us_pt_product_commercial_operations@pwc.com"
                }
            }
        },
        tabs: [TAB.active, TAB.pending, TAB.draft, TAB.renewal],
        defaultTab: TAB.active
    },
    LISTEN: {
        id: "LISTEN",
        label: "Listen",
        URL: "https://productcentral.products.pwc.com/product/Listen-Platform",
        email: "gbl_listen_platform_support@pwc.com",
        optionalComponent: {
            acceptance: {
                showAFSInstructions: false
            },
            client: {
                additionalContacts: true,
                maxAdditionalContacts: 1,
                additionalContactsEmailOnly: true,
                domainNames: false,
                maxDomainNames: 0
            },
            order: ORDER_TERMS.LISTEN,
            contact: {
                nonEditableContacts: [PRODUCT_STAKEHOLDERS.CUSTOMER_SUCCESS],
                requiredContacts: [
                    PRODUCT_STAKEHOLDERS.LEAD_CLIENT_PARTNER,
                    PRODUCT_STAKEHOLDERS.ENGAGEMENT_LEADER,
                    PRODUCT_STAKEHOLDERS.ENGAGEMENT_MANAGER
                ],
                additionalContacts: [
                    PRODUCT_STAKEHOLDERS.CLIENT_RELATIONSHIP_EXECUTIVE,
                    PRODUCT_STAKEHOLDERS.CC_RECIPIENT
                ]
            }
        },
        defaultValue: {
            contact: {
                nonEditableContacts: {
                    CUSTOMER_SUCCESS: "us_slp_dev+customer_success@pwc.com"
                    // PROD \\
                    // CUSTOMER_SUCCESS: "us_listen_sales@pwc.com"
                }
            }
        },
        tabs: [TAB.active, TAB.pending, TAB.draft, TAB.renewal],
        defaultTab: TAB.active
    },
    POD: {
        id: "POD",
        label: "Policy on Demand",
        URL: "https://TODO.pwc.com/",
        email: "TODO@pwc.com",
        optionalComponent: {
            acceptance: {
                showAFSInstructions: true
            },
            client: {
                additionalContacts: true,
                maxAdditionalContacts: 3,
                additionalContactsEmailOnly: false,
                domainNames: true,
                maxDomainNames: 50
            },
            order: ORDER_TERMS.POD,
            contact: {
                nonEditableContacts: [],
                requiredContacts: [PRODUCT_STAKEHOLDERS.LEAD_TAX_PARTNER],
                additionalContacts: [PRODUCT_STAKEHOLDERS.LEAD_TAX_DIRECTOR]
            }
        },
        defaultValue: {
            contact: {
                nonEditableContacts: {}
            }
        },
        tabs: [TAB.active, TAB.pending, TAB.afs],
        defaultTab: TAB.afs
    },
    WFO: {
        id: "WFO",
        label: "Workforce Orchestrator",
        URL: "https://productcentral.products.pwc.com/product/Workforce-Orchestrator",
        email: "US_WorkforceOrchestratorSupport@pwc.com",
        optionalComponent: {
            acceptance: {
                showAFSInstructions: false
            },
            client: {
                additionalContacts: true,
                maxAdditionalContacts: 1,
                additionalContactsEmailOnly: true,
                domainNames: false,
                maxDomainNames: 0
            },
            order: ORDER_TERMS.WFO,
            contact: {
                nonEditableContacts: [
                    PRODUCT_STAKEHOLDERS.CUSTOMER_SUCCESS,
                    PRODUCT_STAKEHOLDERS.GENERAL_MANAGER
                ],
                requiredContacts: [
                    PRODUCT_STAKEHOLDERS.LEAD_CLIENT_PARTNER,
                    PRODUCT_STAKEHOLDERS.ENGAGEMENT_LEADER
                ],
                additionalContacts: [
                    PRODUCT_STAKEHOLDERS.CLIENT_RELATIONSHIP_EXECUTIVE,
                    PRODUCT_STAKEHOLDERS.CC_RECIPIENT
                ]
            }
        },
        defaultValue: {
            contact: {
                nonEditableContacts: {
                    CUSTOMER_SUCCESS: "us_slp_dev+customer_success@pwc.com",
                    GENERAL_MANAGER: "us_slp_dev+general_manager@pwc.com"
                    // PROD \\
                    // CUSTOMER_SUCCESS: "us_workforceorchestratorsupport@pwc.com",
                    // GENERAL_MANAGER: "andrew.d.martin@pwc.com"
                }
            }
        },
        tabs: [TAB.active, TAB.pending, TAB.draft, TAB.renewal],
        defaultTab: TAB.active
    }
};
