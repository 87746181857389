import { getDomain, isEmail } from "../../../../common/Util";
import WrappedInput from "../../../../common/WrappedInput";

const Contact = ({
    props,
    validationErrors,
    index,
    emailOnly,
    onEmailBlur
}) => {
    const handleEmailChange = (name, index) => (value) => {
        const valueLower = value.toLowerCase();
        props.handleIndexedChange("clientEmail", index)(valueLower);
        // auto-populate domain 0 with clientEmail 0's domain
        if (index === 0) {
            const domain = isEmail(valueLower) ? getDomain(valueLower) : "";
            props.handleIndexedChange("clientDomainName", index)(domain);
        }
    };
    // Use the ?? "" pattern for values here.  These fields are arrays of length 1 intially.
    // Thus, when index > 0, the value is initially undefined and generates a react error:
    //   Warning: A component is changing an uncontrolled input to be controlled. This is likely
    //   caused by the value changing from undefined to a defined value, which should not happen.
    return (
        <>
            <div className="row g-0">
                {!emailOnly && (
                    <>
                        <div className="col p-r-24">
                            <WrappedInput
                                name="clientFirstName"
                                label="*FIRST NAME"
                                value={
                                    props.formData.clientFirstName[index] ?? ""
                                }
                                onChange={props.handleIndexedChange(
                                    "clientFirstName",
                                    index
                                )}
                                hasError={
                                    validationErrors.clientFirstName.error[
                                        index
                                    ]
                                }
                                errorMessage={
                                    validationErrors.clientFirstName.message
                                }
                                primary={true}
                            />
                        </div>
                        <div className="col">
                            <WrappedInput
                                name="clientLastName"
                                label="*LAST NAME"
                                value={
                                    props.formData.clientLastName[index] ?? ""
                                }
                                onChange={props.handleIndexedChange(
                                    "clientLastName",
                                    index
                                )}
                                hasError={
                                    validationErrors.clientLastName.error[index]
                                }
                                errorMessage={
                                    validationErrors.clientLastName.message
                                }
                                primary={true}
                            />
                        </div>
                    </>
                )}
                <WrappedInput
                    name="clientEmail"
                    label="*WORK EMAIL"
                    value={props.formData.clientEmail[index] ?? ""}
                    onChange={handleEmailChange("clientEmail", index)}
                    hasError={[
                        validationErrors.clientEmail.error[index],
                        validationErrors.clientEmail_1.error[index]
                    ]}
                    errorMessage={[
                        validationErrors.clientEmail.message,
                        validationErrors.clientEmail_1.message
                    ]}
                    primary={true}
                />
                {!emailOnly && (
                    <>
                        <WrappedInput
                            name="clientTitle"
                            label={index === 0 ? "*JOB TITLE" : "JOB TITLE"}
                            value={props.formData.clientTitle[index] ?? ""}
                            onChange={props.handleIndexedChange(
                                "clientTitle",
                                index
                            )}
                            hasError={validationErrors.clientTitle_1.error[index]}
                            errorMessage={validationErrors.clientTitle_1.message}
                            primary={true}
                        />
                        <WrappedInput
                            name="clientPhone"
                            label="PHONE"
                            value={props.formData.clientPhone[index] ?? ""}
                            onChange={props.handleIndexedChange(
                                "clientPhone",
                                index
                            )}
                            hasError={validationErrors.clientPhone.error[index]}
                            errorMessage={validationErrors.clientPhone.message}
                            primary={true}
                        />
                    </>
                )}
            </div>
        </>
    );
};

export default Contact;
