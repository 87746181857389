export const ClientInfo = (props) => {
    const { orderDetails } = props;
    return (
        <div className="round-card left-round-card m-b-24 flex">
            <div className="col-6 flex-start clear-padding">
                <span className="appkiticon icon-location-outline a-font-32 a-text-tangerine"></span>
                <div className="m-l-15">
                    <h5 className="a-h5">{orderDetails.AccountName}</h5>
                    <p className="text-12 gray half-bold">
                        {orderDetails.address}
                        <br />
                        {orderDetails.city}, {orderDetails.state}{" "}
                        {orderDetails.zip}
                        <br />
                        {orderDetails.country}
                    </p>
                </div>
            </div>
            <div className="col-6 flex-start m-l-12">
                <span className="appkiticon icon-avatar-outline a-font-32 a-text-tangerine"></span>
                <div className="m-l-15">
                    <h5 className="a-h5">
                        {orderDetails.firstName + " " + orderDetails.lastName}
                    </h5>
                    <ul className="text-12 orange half-bold pa-0">
                        <li className="m-b-7 a-hover">{orderDetails.email}</li>
                        {orderDetails.phoneNumber ? (
                            <li>{orderDetails.phoneNumber}</li>
                        ) : null}
                    </ul>
                </div>
            </div>
        </div>
    );
};
