import { useState } from "react";
import { Table } from "appkit-react";
import ViewOrderLink from "./ViewOrderLink";
import OrderOptionsButton from "./OrderOptionsButton";
import OfflineContractingModal from "../common/OfflineContractingModal";
import RenewSubscriptionModal from "../common/RenewSubscriptionModal";
import { RENEW_MODAL_DATA, TAB } from "./Constants";
import "./SortingTable.css";

const SortingTable = (props) => {
    const { product, tabId } = props;

    const [renewModalData, setRenewModalData] = useState(RENEW_MODAL_DATA);
    const [isRenewModalVisible, setIsRenewModalVisible] = useState(false);
    const [
        isOfflineContractingModalVisible,
        setIsOfflineContractingModalVisible
    ] = useState(false);

    const rows = [];
    function buildRows(order) {
        if (tabId === TAB.draft.id || order.showButton) {
            order.actions = (
                <div className="w-100">
                    <div className="right">
                        <OrderOptionsButton
                            tabId={tabId}
                            opportunityId={order.id}
                            client={order.accountName}
                            product={product}
                            endDate={
                                tabId === TAB.renewal.id
                                    ? order.contractEndDate
                                    : order.endDate
                            }
                            useSecondaryAction={order.showButton}
                            setRenewModalData={setRenewModalData}
                            setIsRenewModalVisible={setIsRenewModalVisible}
                            setIsOfflineContractingModalVisible={
                                setIsOfflineContractingModalVisible
                            }
                        />
                    </div>
                    <div className="right p-t-10">
                        <ViewOrderLink
                            opportunityId={order.id}
                            productId={product.id}
                        />
                    </div>
                </div>
            );
        } else {
            order.actions = (
                <div className="w-100">
                    <div className="right">
                        <ViewOrderLink
                            opportunityId={order.id}
                            productId={product.id}
                        />
                    </div>
                </div>
            );
        }

        order.clientName = order.accountName;

        rows.push(order);
    }

    Object.values(props.data).forEach((order) => buildRows(order));

    return (
        <div className="management-table">
            <Table
                data={rows}
                columns={props.columns}
                defaultPageSize={10}
                showPagination={false}
            />
            {isRenewModalVisible && (
                <RenewSubscriptionModal
                    isVisible={isRenewModalVisible}
                    setIsVisible={setIsRenewModalVisible}
                    product={renewModalData.product}
                    client={renewModalData.client}
                    opportunityId={renewModalData.opportunityId}
                    endDate={renewModalData.endDate}
                />
            )}
            {isOfflineContractingModalVisible && (
                <OfflineContractingModal
                    isVisible={isOfflineContractingModalVisible}
                    setIsVisible={setIsOfflineContractingModalVisible}
                />
            )}
        </div>
    );
};
export default SortingTable;
