import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "appkit-react/lib/Button/Button";
import Modal from "appkit-react/lib/Modal/Modal";
import ModalHeader from "appkit-react/lib/Modal/ModalHeader";
import ModalFooter from "appkit-react/lib/Modal/ModalFooter";

import useIsMounted from "../../../common/UseIsMounted";
import { withdrawContract } from "../view/service/External";
import "./GeneralOrderModal.css";
import "../view/summary/Summary.css";
import { gotoError, gotoWithdrawnOrder } from "../../../../Routes";

const WithdrawModal = (props) => {
    const { isVisible, setIsVisible, product, opportunityId, client } = props;

    const isMounted = useIsMounted();
    const [submitting, setSubmitting] = useState(false);
    const navigate = useNavigate();

    const handleCancel = () => {
        if (isMounted()) setIsVisible(false);
    };

    const callback = (success, result) => {
        if (success) {
            if (isMounted()) setIsVisible(false);
            gotoWithdrawnOrder(navigate, product.id, opportunityId, client);
        } else {
            gotoError(navigate);
        }
    };

    const handleConfirm = () => {
        setSubmitting(true);
        withdrawContract(opportunityId, callback);
    };

    return (
        <Modal
            className="action-modal-showcase withdraw-modal"
            visible={isVisible}
            onCancel={handleCancel}
        >
            <ModalHeader>
                <span>
                    <span>
                        <div className="modal-title">Withdraw this order?</div>
                    </span>
                    <span>
                        <div className="modal-description">
                            This order will be moved back to "Draft" status. The
                            client will no longer be able to view the original
                            order.
                        </div>
                    </span>
                </span>
            </ModalHeader>
            <ModalFooter>
                <div>
                    <Button
                        kind="secondary"
                        className="modal-button"
                        onClick={handleCancel}
                        size="lg"
                        disabled={submitting}
                    >
                        CANCEL
                    </Button>
                    <Button
                        kind="primary"
                        onClick={handleConfirm}
                        size="lg"
                        className="m-l-24 modal-button"
                        isLoading={submitting}
                    >
                        WITHDRAW
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    );
};
export default WithdrawModal;
