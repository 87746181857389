import { useState } from "react";
import { Checkbox } from "appkit-react/lib/Checkbox";
import { InputNumber } from "appkit-react/lib/InputNumber";

import Select from "../../Select";
import {
    buildInitialValidationErrors,
    VALIDATION_TYPE
} from "../../../../common/Validation";
import ContractWrapper from "../ContractWrapper";
import InitialTerm from "./InitialTerm";
import { CONTRACT_TYPE } from "../../../../contract/Constants";
import { NEW_ORDER_DATA } from "../../../../../Constants";

const Contract = ({ props }) => {
    const {
        formData,
        formState,
        setFormState,
        handleChange,
        buttonActions,
        buttonLabels
    } = props;

    const validations = {
        contractType: {
            type: VALIDATION_TYPE.REQUIRED,
            errorMessage: "Please select a contract type."
        },
        communicationChannel: {
            type: VALIDATION_TYPE.REQUIRED,
            errorMessage: "Please select a communication channel."
        }
    };

    const [validationErrors, setValidationErrors] = useState(
        buildInitialValidationErrors(validations, NEW_ORDER_DATA)
    );

    const [loading, setLoading] = useState(false);

    const dependencies = [formData.contractType, formData.numberOfUsers];
    // these conditions are "normally" the same...
    // however, we have fields that are not necessary for the getPricing call
    // but are required to show the term
    const haveData = !!formData.contractType;
    const showTerm = formData.contractType && formData.communicationChannel;

    const getPricingParams = {
        productFamily: props.product.id,
        contractType: formData.contractType,
        numberOfUsers: formData.numberOfUsers
    };

    const setPricingOptions = (result) => {
        setFormState((current) => {
            return {
                ...current,
                annualFees: result.productFees,
                selectedProductId: result.productId,
                serviceAAMCSHProdFees: result.serviceAAMCSHProdFees,
                serviceAAMCSHProdId: result.serviceAAMCSHProdId,
                serviceCTDProdFees: result.serviceCTDProdFees,
                serviceCTDProdId: result.serviceCTDProdId,
                serviceDECProdFees: result.serviceDECProdFees,
                serviceDECProdId: result.serviceDECProdId,
                servicePSProdFees: result.servicePSProdFees,
                servicePSProdId: result.servicePSProdId,
                serviceSTDProdFees: result.serviceSTDProdFees,
                serviceSTDProdId: result.serviceSTDProdId,
                serviceWETProdFees: result.serviceWETProdFees,
                serviceWETProdId: result.serviceWETProdId
            };
        });
    };

    // TODO : why do we have 2 versions of this form?
    const MAX_OFFICE_WORKERS = 20000;
    const MAX_FRONT_LINE_WORKERS = 100000;
    const handleContractTypeChagne = (value) => {
        if (
            value === CONTRACT_TYPE.officeWorkers &&
            formData.numberOfUsers > MAX_OFFICE_WORKERS
        ) {
            handleChange("numberOfUsers")(MAX_OFFICE_WORKERS);
        }
        handleChange("contractType")(value);
    };

    const isFrontlineWorkers =
        formData.contractType === CONTRACT_TYPE.frontlineWorkers;
    const isOfficeWorkers =
        formData.contractType === CONTRACT_TYPE.officeWorkers;
    const maxNumberOfUsersUsers = isFrontlineWorkers
        ? MAX_FRONT_LINE_WORKERS
        : MAX_OFFICE_WORKERS;
    const tooltipText = isFrontlineWorkers
        ? "Total number of users cannot be less than 1 or exceed 100,000. For orders over 100,000 users please contact the "
        : "Total number of users cannot be less than 1 or exceed 20,000. For orders over 20,000 users please contact the ";

    const content = (
        <>
            <div>
                <Select
                    primary={true}
                    label="CONTRACT TYPE"
                    options={formState.pricingOptions.contractTypes}
                    value={formData.contractType}
                    onChange={handleContractTypeChagne}
                    errorMessage={validationErrors.contractType.message}
                    hasError={validationErrors.contractType.error}
                />
                {(isFrontlineWorkers || isOfficeWorkers) && (
                    <>
                        <div className="text-14 p-b-36">
                            <Checkbox
                                name="autoRenew"
                                checked={formData.autoRenew}
                                onChange={(e) =>
                                    handleChange("autoRenew")(e.target.checked)
                                }
                            >
                                Renew automatically when the annual subscription
                                ends.
                            </Checkbox>
                        </div>
                        <div className="row p-b-32 g-0">
                            <div className="col-6 d-flex align-items-center">
                                <label className="clear-m-b a-form-label">
                                    TOTAL NUMBER OF USERS
                                </label>
                            </div>
                            <div className="col-6">
                                <InputNumber
                                    kind={"arrow"}
                                    className={
                                        "hide-buttons input-number-center"
                                    }
                                    min={1}
                                    max={maxNumberOfUsersUsers}
                                    onChange={handleChange("numberOfUsers")}
                                    value={formData.numberOfUsers}
                                />
                            </div>
                            <div className="text-12">
                                {tooltipText}
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    className="orange underline"
                                    href="mailto:US_WorkforceOrchestratorSupport@pwc.com"
                                >
                                    Product Team
                                </a>
                                .
                            </div>
                        </div>
                        <Select
                            primary={true}
                            label="SERVICE MODULE"
                            options={formState.pricingOptions.serviceModules}
                            value={formData.serviceModule}
                            onChange={(values) => {
                                // why is there always an empty string in the returned array?
                                const filtered = values.filter(
                                    (option) => option
                                );
                                handleChange("serviceModule")(filtered);
                            }}
                            multiple={true}
                        />
                        <Select
                            primary={true}
                            label="COMMUNICATION CHANNEL"
                            options={
                                formState.pricingOptions.communicationChannel
                            }
                            value={formData.communicationChannel}
                            onChange={handleChange("communicationChannel")}
                            errorMessage={
                                validationErrors.communicationChannel.message
                            }
                            hasError={
                                validationErrors.communicationChannel.error
                            }
                        />
                    </>
                )}
            </div>
            {showTerm && (
                <div>
                    <div className="gray-text-12 p-v-8">ORDER TERMS</div>
                    <div className="round-card-black">
                        <InitialTerm props={props} loading={loading} />
                    </div>
                </div>
            )}
        </>
    );

    return (
        <ContractWrapper
            formData={formData}
            buttonActions={buttonActions}
            buttonLabels={buttonLabels}
            validations={validations}
            validationErrors={validationErrors}
            setValidationErrors={setValidationErrors}
            haveData={haveData}
            dependencies={dependencies}
            getPricingParams={getPricingParams}
            setPricingOptions={setPricingOptions}
            loading={loading}
            setLoading={setLoading}
            content={content}
        />
    );
};

export default Contract;
