import WrappedInput from "../../common/WrappedInput";
import CountrySelect from "../select/CountrySelect";
import StateSelect from "../select/StateSelect";
import AuditorSelect from "../select/AuditorSelect";
import RuleSelect from "../select/RulesSelect";
import { UNITED_STATES, OTHER } from "../Constants";

const CompanyInfo = ({ props }) => {
    return (
        <div>
            <WrappedInput
                name="companyName"
                onChange={props.handleChange("companyName")}
                value={props.formData.companyName}
                hasError={props.validationErrors.companyName.error}
                errorMessage={props.validationErrors.companyName.message}
                label={"* COMPANY NAME (OFFICIAL LEGAL ENTITY NAME)"}
            />
            <WrappedInput
                name="parentCompany"
                onChange={props.handleChange("parentCompany")}
                value={props.formData.parentCompany}
                hasError={props.validationErrors.parentCompany.error}
                errorMessage={props.validationErrors.parentCompany.message}
                label={"* PARENT COMPANY (OFFICIAL LEGAL PARENT ENTITY NAME)"}
            />
            <CountrySelect
                name="country"
                onChange={props.handleChange("country")}
                value={props.formData.country}
                hasError={props.validationErrors.country.error}
                errorMessage={props.validationErrors.country.message}
                label={"* COUNTRY OR REGION"}
            />
            <WrappedInput
                name="addressLine1"
                onChange={props.handleChange("addressLine1")}
                value={props.formData.addressLine1}
                hasError={props.validationErrors.addressLine1.error}
                errorMessage={props.validationErrors.addressLine1.message}
                label={"* ADDRESS"}
            />
            <WrappedInput
                name="addressLine2"
                onChange={props.handleChange("addressLine2")}
                value={props.formData.addressLine2}
                hasError={props.validationErrors.addressLine2.error}
                errorMessage={props.validationErrors.addressLine2.message}
                label={"ADDRESS LINE 2"}
            />
            <WrappedInput
                name="city"
                onChange={props.handleChange("city")}
                value={props.formData.city}
                hasError={props.validationErrors.city.error}
                errorMessage={props.validationErrors.city.message}
                label={"* CITY"}
            />
            {(props.formData.country === UNITED_STATES)
                ? <StateSelect
                    name="usState"
                    onChange={props.handleChange("usState")}
                    value={props.formData.usState}
                    hasError={props.validationErrors.usState.error}
                    errorMessage={props.validationErrors.usState.message}
                    label={"* STATE"}
                />
                : <WrappedInput
                    name="state"
                    onChange={props.handleChange("state")}
                    value={props.formData.state}
                    hasError={props.validationErrors.state.error}
                    errorMessage={props.validationErrors.state.message}
                    label={"* STATE/PROVINCE"}
                />}
            <WrappedInput
                name="zip"
                onChange={props.handleChange("zip")}
                value={props.formData.zip}
                hasError={props.validationErrors.zip.error}
                errorMessage={props.validationErrors.zip.message}
                label={"* ZIP/POSTAL CODE"}
            />
            <AuditorSelect
                name="externalAuditor"
                onChange={props.handleChange("externalAuditor")}
                value={props.formData.externalAuditor}
                hasError={props.validationErrors.externalAuditor.error}
                errorMessage={props.validationErrors.externalAuditor.message}
                label={"* EXTERNAL AUDITOR"}
            />
            <div className={props.formData.externalAuditor === OTHER ? "fade-height expanded" : "fade-height collapsed"}>
                <WrappedInput
                    name="otherAuditor"
                    onChange={props.handleChange("otherAuditor")}
                    value={props.formData.otherAuditor}
                    hasError={props.validationErrors.otherAuditor.error}
                    errorMessage={props.validationErrors.otherAuditor.message}
                    label={"IF OTHER, PLEASE DENOTE"}
                />
            </div>
            <RuleSelect
                name="rules"
                onChange={props.handleChange("rules")}
                value={props.formData.rules}
                hasError={props.validationErrors.rules.error}
                errorMessage={props.validationErrors.rules.message}
                label={"* IS YOUR ORGANIZATION SUBJECT TO UNITED STATES SEC OR US GAO RULES?"}
            />
        </div>
    );
}

export default CompanyInfo;
