import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { gotoError } from "../../../../../../Routes";
import { getPricing } from "../../../../service/External";

const useUpdatePricing = (
    setLoading,
    isMounted,
    haveData,
    getPricingParams,
    setPricingOptions,
    dependencies
) => {
    const navigate = useNavigate();
    // the dependency array is dynamic; eslint does not handle that
    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        const updatePricing = () => {
            if (haveData) {
                const callback = (success, result) => {
                    if (success) {
                        setPricingOptions(result);
                        setLoading(false);
                    } else {
                        gotoError(navigate);
                    }
                };

                setLoading(true);
                getPricing(getPricingParams, callback);
            }
        };
        // don't updatePricing() on the initial mount of the component
        // (the edit page already has the result of this call so it's not necessary)
        if (isMounted.current) {
            updatePricing();
        } else {
            isMounted.current = true;
        }
    }, dependencies);
};

export default useUpdatePricing;
