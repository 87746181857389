import { useState } from "react";
import { Button } from "appkit-react";
import ContactSelect from "./ContactSelect";
import AdditionalContacts from "./AdditionalContacts";
import { removeElement } from "../../../../common/Util";
import { validate, initialValidationErrors } from "./Validation";
import RequiredContacts from "./RequiredContacts";
import NonEditableContacts from "./NonEditableContacts";
import ContactIconHeader from "../../common/order/ContactIconHeader";
import { PRODUCT_STAKEHOLDERS } from "../../../Constants";

const Contact = (props) => {
    const {
        formData,
        formState,
        setFormData,
        product,
        buttonLabels,
        buttonActions
    } = props;

    const [validationErrors, setValidationErrors] = useState(
        initialValidationErrors
    );

    const handleRequiredContactChange = (index) => (name, value) => {
        if (!formData.requiredContacts[index]) {
            formData.requiredContacts[index] = {};
        }
        formData.requiredContacts[index][name] = value;
        setFormData({ ...formData });
    };

    const handleContactSelectChange = (selectdContacts) => {
        let additionalContacts = {};
        for (let i = 0; i < selectdContacts.length; ++i) {
            additionalContacts[selectdContacts[i]] =
                formData?.additionalContacts?.[selectdContacts[i]];
        }
        formData.additionalContacts = additionalContacts;
        formData.additionalContactsSelect = selectdContacts;
        setFormData({ ...formData });
    };

    const handleAddCCRecipient = () => {
        formData.additionalContacts = {
            [PRODUCT_STAKEHOLDERS.CC_RECIPIENT.id]: undefined
        };
        setFormData({ ...formData });
    };

    const handleAdditionalContactChange = (index) => (name, value) => {
        if (!formData.additionalContacts[index]) {
            formData.additionalContacts[index] = {};
        }
        formData.additionalContacts[index][name] = value;
        setFormData({ ...formData });
    };

    const handleRemove = (value) => {
        // remove from object
        delete formData.additionalContacts[value];
        // remove from array
        formData.additionalContactsSelect = removeElement(
            formData.additionalContactsSelect,
            formData.additionalContactsSelect.indexOf(value)
        );
        setFormData({ ...formData });
    };

    // having a single optional component === additional cc recipient is a special case...
    const hasAdditionalCcRecipient =
        product.optionalComponent.contact.additionalContacts.length === 1 &&
        product.optionalComponent.contact.additionalContacts.includes(
            PRODUCT_STAKEHOLDERS.CC_RECIPIENT
        );
    const showAddCcRecipient =
        hasAdditionalCcRecipient && // only show the add button...
        Object.keys(formData.additionalContacts).length === 0; // if the <input/> has not already been added to the form
    const validateAdditionalCcRecipient =
        hasAdditionalCcRecipient && // only validate the field...
        Object.keys(formData.additionalContacts).length === 1; // if the <input/> has already been added to the form

    const onContinue = () => {
        if (
            validate(
                formData,
                validationErrors,
                setValidationErrors,
                product.optionalComponent.contact.requiredContacts,
                validateAdditionalCcRecipient
            )
        ) {
            buttonActions[1]();
        }
    };

    const showNonEditableContacts =
        product.optionalComponent.contact.nonEditableContacts.length > 0;
    const showRequiredContacts =
        product.optionalComponent.contact.requiredContacts.length > 0;
    const showAdditionalContactsSelect =
        product.optionalComponent.contact.additionalContacts.length > 1 ||
        product.optionalComponent.contact.additionalContacts.includes(
            PRODUCT_STAKEHOLDERS.LEAD_TAX_DIRECTOR
        );
    const showAdditionalContacts = formData.additionalContacts;

    return (
        <div className="p-b-125">
            <div className="row">
                <div className="col-3"></div>
                <div className="col-6 flex-justify-center">
                    <div className="content">
                        {props.useIconHeader ? (
                            <div className="m-b-28">
                                <ContactIconHeader />
                            </div>
                        ) : (
                            <div className="text-18 bold p-b-24">
                                PwC contacts
                            </div>
                        )}
                        <div className="col-6 gray-text-12 m-b-12">
                            SUBMITTER
                        </div>
                        <div className="round-card">
                            <div className="text-16 half-bold">
                                {props.user.name}
                            </div>
                            <div className="text-12 orange half-bold">
                                {props.user.email}
                            </div>
                        </div>
                        {showNonEditableContacts && (
                            <NonEditableContacts
                                formData={formData}
                                product={product}
                            />
                        )}
                        {showRequiredContacts && (
                            <RequiredContacts
                                formState={formState}
                                formData={formData}
                                product={product}
                                handleRequiredContactChange={
                                    handleRequiredContactChange
                                }
                                validationErrors={validationErrors}
                            />
                        )}
                        <div className="red-separator-line"></div>
                        {showAddCcRecipient && (
                            <div>
                                <span
                                    className="appkiticon icon-circle-plus-fill a-font-24 a-text-red v-middle clickable"
                                    onClick={handleAddCCRecipient}
                                ></span>
                                <span className="orange v-middle p-l-8 text-12 bold">
                                    Add Cc recipient
                                </span>
                            </div>
                        )}
                        {showAdditionalContactsSelect && (
                            <div>
                                <div className="text-18 bold">
                                    Additional contacts (optional)
                                </div>
                                <div className="light-gray-text-16 p-b-24">
                                    Please choose the stakeholders that should
                                    be copied on contract details from the list
                                    below.
                                </div>
                                <ContactSelect
                                    name={"additionalContacts"}
                                    value={formData.additionalContactsSelect}
                                    onChange={handleContactSelectChange}
                                    options={
                                        product.optionalComponent.contact
                                            .additionalContacts
                                    }
                                    label={"CONTACTS TO INCLUDE"}
                                />
                            </div>
                        )}
                        {showAdditionalContacts && (
                            <AdditionalContacts
                                formData={formData}
                                handleRemove={handleRemove}
                                handleAdditionalContactChange={
                                    handleAdditionalContactChange
                                }
                                validationErrors={validationErrors}
                                hideTopSeparator={hasAdditionalCcRecipient}
                            />
                        )}
                        <div className="red-separator-line"></div>
                        <div className="nav-buttons m-t-48">
                            <Button
                                onClick={buttonActions[0]}
                                kind={"secondary"}
                            >
                                {buttonLabels[0]}
                            </Button>
                            <Button onClick={onContinue}>
                                {buttonLabels[1]}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="col-3"></div>
            </div>
        </div>
    );
};

export default Contact;
