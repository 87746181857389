import ContactInfo from "./ContactInfo";
import CompanyInfo from "./CompanyInfo";
import ReferralInfo from "./ReferralInfo";
import SupportInfo from "./SupportInfo";

import "./LeftColumn.css"

const LeftColumn = (props) => {
    return (
        <div>
            <div className="m-t-40">
                Please provide us with your contact information. We respect your
                privacy and will use this information only in relation to this order.
            </div>
            <div className="m-v-32">
                Please note that asterisk (*) denotes a required field.
            </div>
            <div className="separator-line"></div>
            <h5>Contact information:</h5>
            <ContactInfo props={props} />
            <div className="separator-line"></div>
            <h5>Company information:</h5>
            <CompanyInfo props={props} />
            <div className="separator-line"></div>
            <h5>Referral information:</h5>
            <ReferralInfo props={props} />
            <div className="light-gray-text-14">
                Thank you for your interest in Viewpoint and Disclosure Checklist.
            </div>
            <div className="separator-line m-t-32"></div>
            <SupportInfo />
            <div className="spacer-64">{"\u00A0"}</div>
        </div>
    );
};

export default LeftColumn;