import { UNITED_STATES } from "../Constants";

const RightColumnSubmitted = (props) => {
    let confirmMessage = "Your confirmation number is ";
    let confirmMessageDetail =
        "You will receive an order confirmation shortly by email. We will review and process your request within 5-10 business days, however access may be delayed while we obtain any necessary pre-approvals. Once your license has been approved, your Viewpoint and/or Disclosure Checklist access instructions and your invoice will be sent to your email address provided ";

    if (props.formState.billingTerritory === UNITED_STATES) {
        confirmMessage = "Your tracking number is ";
        confirmMessageDetail =
            "You will receive an order confirmation shortly by email. We will review and process your request within 5-10 business days, however access may be delayed while we obtain any necessary pre-approvals. Once your license has been approved by PwC, your company's T&C approver will receive a final Order electronically that will require acknowledgement of the Terms & Conditions. Only upon PwC's receipt of an Order signed by your company's T&C approver can access to the respective product(s) be granted to your company. After that is received, your Viewpoint and/or Disclosure Checklist access instructions and your invoice will be sent to your email address provided ";
    }

    if (
        props.formData.orderViewpoint &&
        props.formData.orderDisclosureChecklist
    ) {
        confirmMessage +=
            props.formState.viewpointConfirmationNumber +
            " for your Viewpoint Order and " +
            props.formState.disclosureChecklistConfirmationNumber +
            " for your Disclosure Checklist Order.";
    } else if (props.formData.orderDisclosureChecklist) {
        confirmMessage +=
            props.formState.disclosureChecklistConfirmationNumber +
            " for your Disclosure Checklist Order.";
    } else if (props.formData.orderViewpoint) {
        confirmMessage +=
            props.formState.viewpointConfirmationNumber +
            " for your Viewpoint Order.";
    }

    const currencySymbol = props.formState.currencySymbol;
    const currencyCode = props.formState.currencyCode;

    return (
        <div>
            <h5 className="orange m-t-38 m-b-16">Confirmation:</h5>
            <div className="text-16 m-v-32">
                Your license request has been received.
            </div>
            <div className="orange bold">{confirmMessage}</div>
            <div className="m-v-32">
                {confirmMessageDetail}
                <span className="orange bold">{props.formData.email}</span>.
            </div>
            <div className="">Thank you for your order.</div>
            <div className="red m-v-32">
                (Please close this window for security reasons)
            </div>
            <div className="separator-line m-v-32"></div>
            <h5 className="orange m-t-38 m-b-16">Order Details</h5>
            {props.formData.orderViewpoint && (
                <div className="round-card text-14 m-b-36">
                    <div className="gray-text-18 bold">Viewpoint</div>
                    <div className="separator-line m-v-18"></div>
                    <div className="light-gray-text-14 bold">
                        Territory Content:
                    </div>
                    <div className="row m-v-3">
                        <div className="col-sm-7 right">US Content:</div>
                        <div className="col-sm-5">
                            {currencySymbol}
                            {props.formData.usContentTotal}
                        </div>
                    </div>
                    <div className="row m-v-3">
                        <div className="col-sm-7 right">
                            Global/International Content:
                        </div>
                        <div className="col-sm-5">
                            {currencySymbol}
                            {props.formData.globalContentTotal}
                        </div>
                    </div>
                    <div className="row m-v-3">
                        <div className="col-sm-7 right">Canada Content:</div>
                        <div className="col-sm-5">
                            {currencySymbol}
                            {props.formData.canadaContentTotal}
                        </div>
                    </div>
                    <div className="row m-v-3">
                        <div className="col-sm-7 right">
                            UK (including UK GAAP) Content:
                        </div>
                        <div className="col-sm-5">
                            {currencySymbol}
                            {props.formData.ukContentTotal}
                        </div>
                    </div>
                    <div className="row m-v-3">
                        <div className="col-sm-7 right">UK GAAP Only:</div>
                        <div className="col-sm-5">
                            {currencySymbol}
                            {props.formData.gaapContentTotal}
                        </div>
                    </div>
                    <div className="row m-v-3">
                        <div className="col-sm-7 right">Japan Content:</div>
                        <div className="col-sm-5">
                            {currencySymbol}
                            {props.formData.japanContentTotal}
                        </div>
                    </div>
                    <div className="row m-v-3">
                        <div className="col-sm-7 right">US GASB Only:</div>
                        <div className="col-sm-5">
                            {currencySymbol}
                            {props.formData.gasbContentTotal}
                        </div>
                    </div>
                    <div className="separator-line m-v-18"></div>
                    <div className="row m-v-3">
                        <div className="col-sm-7 right orange bold">
                            Total annual pre-tax cost for all Viewpoint
                            licenses**:
                        </div>
                        <div className="col-sm-5 orange bold">
                            {currencyCode} {currencySymbol}
                            {props.formData.viewpointTotal}
                        </div>
                    </div>
                    <div className="separator-line m-v-18"></div>
                    <div className="light-gray-text-14">
                        ** Any applicable taxes will be added to your invoice.
                    </div>
                </div>
            )}
            {props.formData.orderDisclosureChecklist && (
                <div className="round-card text-14 m-b-36">
                    <div className="gray-text-18 bold m-b-24">
                        Disclosure Checklist
                    </div>
                    <div className="separator-line m-v-18"></div>
                    <div className="row m-v-3">
                        <div className="col-sm-7 right">
                            Total number of licenses:
                        </div>
                        <div className="col-sm-5">
                            {props.formData.additionalLicenses + 1}
                        </div>
                    </div>
                    <div className="separator-line m-v-18"></div>
                    <div className="row m-v-3">
                        <div className="col-sm-7 right orange bold">
                            Total annual pre-tax cost for Disclosure
                            Checklist**:
                        </div>
                        <div className="col-sm-5 orange bold">
                            USD ${props.formData.disclosureChecklistTotal}
                        </div>
                    </div>
                    <div className="separator-line m-v-18"></div>
                    <div className="light-gray-text-14">
                        ** Any applicable taxes will be added to your invoice.
                    </div>
                </div>
            )}
            <div className="m-b-224">{"\u00A0"}</div>
        </div>
    );
};

export default RightColumnSubmitted;
