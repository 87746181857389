import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Input } from "appkit-react";
import Checkbox from "appkit-react/lib/Checkbox/Checkbox";
import Button from "appkit-react/lib/Button/Button";

import WrappedInput from "../../../../common/WrappedInput";
import WrappedCalendar from "../../../../common/WrappedCalendar";
import { WRAPPED_COMPONENT_TYPE } from "../../../../common/LabelErrorWrapper";
import Header from "../../../../Header";
import Menu, { MENU_LABELS } from "../common/Menu";
import SupportBanner from "../../SupportBanner";
import Footer from "../../../../Footer";
import AccountInfo from "../../common/order/AccountInfo";
import {
    gotoError,
    gotoManageDefault,
    gotoSubmittedAFS,
    gotoViewOrder
} from "../../../../../Routes";
import useViewOrderInfo from "../../common/order/UseViewOrderInfo";
import { AFS_NUMBER_REGEX, newDateEST } from "../../../../common/Util";
import { submitAFS } from "./../service/External";
import {
    buildInitialValidationErrors,
    VALIDATION_TYPE,
    validate
} from "../../common/Validation";
import { AFS_DATA } from "../../../Constants";

const AFS = (props) => {
    const { opportunityId } = useParams();
    const [afsData, setAFSData] = useState(AFS_DATA);
    const [checked, setChecked] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const validations = {
        afsNumber: {
            type: VALIDATION_TYPE.PATTERN,
            pattern: AFS_NUMBER_REGEX,
            errorMessage: "Please enter a valid AFS number, e.g. AFS-123456"
        },
        afsApprovalDate: {
            type: VALIDATION_TYPE.REQUIRED,
            errorMessage: "Please select a valid date."
        }
    };
    const [validationErrors, setValidationErrors] = useState(
        buildInitialValidationErrors(validations, AFS_DATA)
    );

    const [product, setProduct] = useState({});
    const [submissionInfo, setSubmissionInfo] = useState({});
    const [formState, setFormState] = useState({});
    const [loading, setLoading] = useState(true);
    useViewOrderInfo(
        opportunityId,
        setSubmissionInfo,
        setProduct,
        null,
        setFormState,
        null,
        setLoading
    );

    const navigate = useNavigate();

    const gotoOrder = () => {
        gotoViewOrder(navigate, product.id, opportunityId);
    };
    const gotoAllOrders = () => {
        gotoManageDefault(navigate, product);
    };

    const handleChange = (name) => (value) => {
        setAFSData((current) => {
            return { ...current, [name]: value };
        });
    };

    const handleCalendarChange = (isValid, value) => {
        if (isValid && !!value) {
            handleChange("afsApprovalDate")(new Date(value));
        }
    };

    const callback = (success, result) => {
        if (success) {
            gotoSubmittedAFS(
                navigate,
                product.id,
                opportunityId,
                submissionInfo.client
            );
        } else {
            gotoError(navigate);
        }
    };

    const handleSubmit = (value) => {
        if (
            validate(
                validations,
                afsData,
                validationErrors,
                setValidationErrors
            )
        ) {
            setSubmitting(true);
            submitAFS(
                {
                    oppId: opportunityId,
                    AFSReferenceNo: afsData.afsNumber,
                    AFSApprovalDate: afsData.afsApprovalDate,
                    contactName: props.user.name,
                    contactEmail: props.user.email
                },
                callback
            );
        }
    };

    const menuLabels = [
        MENU_LABELS.ALL_ORDERS,
        MENU_LABELS.VIEW_ORDER,
        MENU_LABELS.SUBMIT_AFS
    ];
    const menuActions = [gotoAllOrders, gotoOrder];

    const nowEST = newDateEST();
    const aYearAgo = new Date(
        nowEST.getFullYear() - 1,
        nowEST.getMonth(),
        nowEST.getDate() + 1
    );
    // set time to 00:00:00:001 as there is a bug in the CalendarPicker:
    //   the left / right arrows only work if the to date is > midnight
    //   on the first day of the month
    const today = new Date(
        nowEST.getFullYear(),
        nowEST.getMonth(),
        nowEST.getDate(),
        0,
        0,
        0,
        1
    );

    return (
        <>
            <Header />
            {loading ? (
                <div className="flex-justify-center vh-65">
                    <div className="center">
                        <div className="a-loading a-primary loading-large"></div>
                        <div>Please wait while the order is loaded...</div>
                    </div>
                </div>
            ) : (
                <>
                    <Menu labels={menuLabels} actions={menuActions} />
                    <div className="white-background flex-justify-center p-b-15">
                        <div className="marging w-384 gray-text-22 half-bold">
                            Submit new AFS
                        </div>
                    </div>
                    <div className="row gray-background g-0">
                        <div className="col-3"></div>
                        <div className="col-6 flex-justify-center">
                            <div className="content m-t-20">
                                <div className="text-12 p-b-10">
                                    LEGAL ENTITY
                                </div>
                                <div className="round-card m-b-24">
                                    <AccountInfo
                                        clientAccount={formState.clientAccount}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-3"></div>
                    </div>
                    <div className="row white-background g-0">
                        <div className="col-3"></div>
                        <div className="col-6 flex-justify-center content">
                            <div className="p-t-20">
                                <WrappedInput
                                    value={afsData.afsNumber}
                                    name="afsNumber"
                                    label="*NEW AFS REFERENCE NUMBER"
                                    maxLength={11}
                                    primary={true}
                                    onChange={handleChange("afsNumber")}
                                    hasError={validationErrors.afsNumber.error}
                                    errorMessage={
                                        validationErrors.afsNumber.message
                                    }
                                    hint="AFS-123456"
                                />
                                <WrappedCalendar
                                    className="wrapped-calendar-picker-w-100 calendar-right-align"
                                    value={afsData.afsApprovalDate}
                                    type={WRAPPED_COMPONENT_TYPE.CALENDAR}
                                    editable={false}
                                    format="mm/dd/yyyy"
                                    minDate={aYearAgo}
                                    maxDate={today}
                                    primary={true}
                                    name="date"
                                    label="*NEW AFS APPROVAL DATE"
                                    hasError={
                                        validationErrors.afsApprovalDate.error
                                    }
                                    errorMessage={
                                        validationErrors.afsApprovalDate.message
                                    }
                                    onValueChange={handleCalendarChange}
                                />
                                <Input
                                    className="p-b-29"
                                    name="name"
                                    label="FULL NAME"
                                    disabled={true}
                                    value={props.user.name}
                                />
                                <Input
                                    className="p-b-29"
                                    name="email"
                                    label="WORK EMAIL"
                                    disabled={true}
                                    value={props.user.email}
                                />
                                <Checkbox
                                    checked={checked}
                                    name="agreeToTC"
                                    onChange={() => setChecked(!checked)}
                                    className="p-b-45 flex-align-center"
                                >
                                    I acknowledge the Authorization For Services
                                    (AFS) is approved and the information
                                    entered above is accurate.
                                </Checkbox>
                                <div className="nav-buttons p-b-185">
                                    <Button
                                        kind="secondary"
                                        onClick={gotoOrder}
                                        disabled={submitting}
                                    >
                                        CANCEL
                                    </Button>
                                    <Button
                                        disabled={!checked || submitting}
                                        kind="primary"
                                        onClick={handleSubmit}
                                        isLoading={submitting}
                                    >
                                        SUBMIT
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className="col-3"></div>
                    </div>
                </>
            )}
            <SupportBanner />
            <Footer />
        </>
    );
};

export default AFS;
