import WrappedInput from "../../common/WrappedInput";
import AccessSelect from "../select/AccessSelect";
import {
    NO,
    UNITED_STATES
} from "../Constants";

const TCApprover = (props) => {
    return (
        <>
            <WrappedInput
                name="tcApproverName"
                onChange={props.handleChange("tcApproverName")}
                value={props.formData.tcApproverName}
                hasError={props.validationErrors.tcApproverName.error}
                errorMessage={props.validationErrors.tcApproverName.message}
                label={"* COMPANY'S T&C APPROVER"}
                className="p-h-3"
            />
            <div className="light-gray-text-12 m-b-24 p-h-3">
                Kindly provide the name of the company representative who has
                sufficient authority to agree to the license terms and
                conditions on behalf of the company.
            </div>
            <WrappedInput
                name="tcApproverEmail"
                onChange={props.handleChange("tcApproverEmail")}
                value={props.formData.tcApproverEmail}
                hasError={props.validationErrors.tcApproverEmail.error}
                errorMessage={props.validationErrors.tcApproverEmail.message}
                label={"* COMPANY'S T&C APPROVER EMAIL"}
                className="p-h-3"
            />
            <div className="light-gray-text-12 m-b-24 p-h-3">
                Kindly provide the email address of the company representative
                who has sufficient authority to agree to the license terms and
                conditions on behalf of the company.
            </div>

            <AccessSelect
                name="accessToAll"
                onChange={props.handleChange("accessToAll")}
                value={props.formData.accessToAll}
                hasError={props.validationErrors.accessToAll.error}
                errorMessage={props.validationErrors.accessToAll.message}
                label={"* Are all of the users that will access this license employed by the legal entity that will be the licensee?"}
            />

            {props.formData.accessToAll === NO && <WrappedInput
                maxLength={200}
                name="legalNamesOfOtherEntities"
                onChange={props.handleChange("legalNamesOfOtherEntities")}
                value={props.formData.legalNamesOfOtherEntities}
                hasError={props.validationErrors.legalNamesOfOtherEntities.error}
                errorMessage={props.validationErrors.legalNamesOfOtherEntities.message}
                label={"* What is(are) the legal name(s) of the other entity(ies) associated with all users of the license?"}
                className="p-h-3"
            />}
        </>
    );
};

export default TCApprover;
