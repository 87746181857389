import Checkbox from "appkit-react/lib/Checkbox/Checkbox";
import { EXTERNAL_URLS } from "../../../../common/Constants";
import { WRAPPED_COMPONENT_TYPE } from "../../../../common/LabelErrorWrapper";
import { newDateEST } from "../../../../common/Util";
import WrappedCalendar from "../../../../common/WrappedCalendar";
import WrappedInput from "../../../../common/WrappedInput";
import Select from "../order/Select";

export const YES = "Yes";
export const NO = "No";

const AFS = ({
    props,
    validationErrors,
    checked,
    setChecked,
    afsWasCompleted,
    afsWasNotCompleted
}) => {
    const { formData, handleChange } = props;

    const handleCalendarChange = (isValid, value) => {
        if (isValid && !!value) {
            handleChange("afsApprovalDate")(new Date(value));
        }
    };

    const options = [
        { label: YES, value: YES },
        { label: NO, value: NO }
    ];

    const nowEST = newDateEST();
    const aYearAgo = new Date(
        nowEST.getFullYear() - 1,
        nowEST.getMonth(),
        nowEST.getDate() + 1
    );
    // set time to 00:00:00:001 as there is a bug in the CalendarPicker:
    //   the left / right arrows only work if the to date is > midnight
    //   on the first day of the month
    const today = new Date(
        nowEST.getFullYear(),
        nowEST.getMonth(),
        nowEST.getDate(),
        0,
        0,
        0,
        1
    );

    return (
        <>
            <div className="row gray-background p-v-12">&nbsp;</div>
            <div className="row">
                <div className="col-3"></div>
                <div className="col-6 flex-justify-center">
                    <div className="content">
                        <div className="m-t-48">
                            <Select
                                primary={true}
                                label="WAS AN AFS REQUEST COMPLETED AND APPROVED?"
                                options={options}
                                value={formData.afsCompleted}
                                onChange={handleChange("afsCompleted")}
                                hasError={validationErrors.afsCompleted.error}
                                errorMessage={
                                    validationErrors.afsCompleted.message
                                }
                            />
                            {afsWasCompleted && (
                                <>
                                    <WrappedInput
                                        value={formData.afsNumber}
                                        name="afsNumber"
                                        label="AFS REFERENCE NUMBER"
                                        maxLength={11}
                                        primary={true}
                                        onChange={handleChange("afsNumber")}
                                        hasError={
                                            validationErrors.afsNumber.error
                                        }
                                        errorMessage={
                                            validationErrors.afsNumber.message
                                        }
                                        hint="AFS-123456"
                                    />
                                    <WrappedCalendar
                                        className="wrapped-calendar-picker-w-100 calendar-right-align"
                                        value={formData.afsApprovalDate}
                                        type={WRAPPED_COMPONENT_TYPE.CALENDAR}
                                        editable={false}
                                        format="mm/dd/yyyy"
                                        minDate={aYearAgo}
                                        maxDate={today}
                                        primary={true}
                                        name="date"
                                        label="AFS APPROVAL DATE"
                                        hasError={
                                            validationErrors.afsApprovalDate
                                                .error
                                        }
                                        errorMessage={
                                            validationErrors.afsApprovalDate
                                                .message
                                        }
                                        onValueChange={handleCalendarChange}
                                    />
                                    <Checkbox
                                        checked={checked}
                                        name="agreeToTC"
                                        onChange={() => setChecked(!checked)}
                                        className="flex-align-center"
                                    >
                                        I acknowledge the Authorization For
                                        Services (AFS) is approved and the
                                        information entered above is accurate.
                                    </Checkbox>
                                </>
                            )}
                            {afsWasNotCompleted && (
                                <div className="a-bg-status-red a-text-white afs-error">
                                    <div className="row g-0">
                                        <span className="col-afs-icon d-flex justify-content-center align-items-start p-t-6">
                                            <span className="appkiticon icon-end-process-fill a-font-24"></span>
                                        </span>
                                        <span className="col-afs-text text-14 bold p-l-12">
                                            Authorization for Services (AFS)
                                            request is required. Please create
                                            an AFS request and try again, once
                                            your AFS is approved.
                                            <div>
                                                <a
                                                    href={
                                                        EXTERNAL_URLS.afsMoreInformation
                                                    }
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="underline"
                                                >
                                                    Click here
                                                </a>{" "}
                                                for more information.
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-3"></div>
            </div>
        </>
    );
};

export default AFS;
