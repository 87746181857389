const SUCCESS = "Success";
export const terminateOrder = (opportunityId, date, callback) => {
    window.Visualforce.remoting.Manager.invokeAction(
        window.endpoints.terminateOrder,
        opportunityId,
        date,
        (result, event) => {
            if (event.status && result.flag === SUCCESS) {
                callback(true, event.result);
            } else {
                callback(false);
            }
        },
        { escape: false }
    );
};

export const submitAFS = (afsData, callback) => {
    window.Visualforce.remoting.Manager.invokeAction(
        window.endpoints.submitAFS,
        afsData,
        (result, event) => {
            if (event.status && result.flag === SUCCESS) {
                callback(true, event.result);
            } else {
                callback(false);
            }
        },
        { escape: false }
    );
};

export const viewOrder = (opportunityId, callback) => {
    window.Visualforce.remoting.Manager.invokeAction(
        window.endpoints.viewOrder,
        opportunityId,
        (result, event) => {
            if (event.status && result.flag === SUCCESS) {
                callback(true, result);
            } else {
                callback(false);
            }
        },
        { escape: false }
    );
};

export const withdrawContract = (opportunityId, callback) => {
    window.Visualforce.remoting.Manager.invokeAction(
        window.endpoints.withdrawContract,
        opportunityId,
        (result, event) => {
            if (event.status) {
                callback(true, event.result);
            } else {
                callback(false);
            }
        },
        { escape: false }
    );
};

export const resendContract = (opportunityId, callback) => {
    window.Visualforce.remoting.Manager.invokeAction(
        window.endpoints.resendContract,
        opportunityId,
        (result, event) => {
            if (event.status) {
                callback(true, event.result);
            } else {
                callback(false);
            }
        },
        { escape: false }
    );
};

export const renewOrder = (
    opportunityId,
    approvalDate,
    afsNumber,
    callback
) => {
    window.Visualforce.remoting.Manager.invokeAction(
        window.endpoints.renewOrder,
        opportunityId,
        approvalDate,
        afsNumber,
        (result, event) => {
            if (event.status) {
                callback(true, event.result);
            } else {
                callback(false);
            }
        },
        { escape: false }
    );
};

export const cancelOrder = (opportunityId, callback) => {
    window.Visualforce.remoting.Manager.invokeAction(
        window.endpoints.cancelOrder,
        opportunityId,
        (result, event) => {
            if (event.status) {
                callback(true, event.result);
            } else {
                callback(false);
            }
        },
        { escape: false }
    );
};

export const uploadContract = (
    opportunityId,
    contractSignedDate,
    file,
    callback
) => {
    window.Visualforce.remoting.Manager.invokeAction(
        window.endpoints.uploadContract,
        opportunityId,
        contractSignedDate,
        file,
        function (result, event) {
            if (event.status && result.flag === SUCCESS) {
                callback(true, event.result);
            } else {
                callback(false);
            }
        },
        { escape: true }
    );
};
