// use this hook to determine if a component is mounted / unmounted

// when used appropriately, it circumvents the following error:
//   Warning: Can't perform a React state update on an unmounted component.
//   This is a no-op, but it indicates a memory leak in your application.
//   To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.

import { useCallback, useEffect, useRef } from "react";

const useIsMounted = () => {
    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true;

        return () => {
            isMounted.current = false;
        };
    }, []);

    return useCallback(() => isMounted.current, []);
};

export default useIsMounted;
