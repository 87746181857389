import InputNumber from "appkit-react/lib/InputNumber/InputNumber";

import "./LineItem.css";

const LineItem = (props) => {
    return (
        <div className="d-flex justify-content-end p-v-8">
            <label htmlFor={props.name} className="line-item-label align-self-center">
                {props.label}
            </label>
            <InputNumber
                name={props.name}
                className="arrow-input arrow-input-p"
                min={props.min}
                max={props.max}
                step={1}
                kind={"arrow"}
                onChange={props.onChange}
                value={props.value}
            />
            <span className="subtotal m-l-12">{props.currency}{props.total}</span>
        </div>
    );
}

export default LineItem;