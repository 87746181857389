import WrappedInput from "../../common/WrappedInput";

const ContactInfo = ({props}) => {
    return (
        <div>
            <WrappedInput
                name="firstName"
                onChange={props.handleChange("firstName")}
                value={props.formData.firstName}
                hasError={props.validationErrors.firstName.error}
                errorMessage={props.validationErrors.firstName.message}
                label={"* FIRST NAME"}
            />
            <WrappedInput
                name="lastName"
                onChange={props.handleChange("lastName")}
                value={props.formData.lastName}
                hasError={props.validationErrors.lastName.error}
                errorMessage={props.validationErrors.lastName.message}
                label={"* LAST NAME"}
            />
            <WrappedInput
                name="phone"
                onChange={props.handleChange("phone")}
                value={props.formData.phone}
                hasError={props.validationErrors.phone.error}
                errorMessage={props.validationErrors.phone.message}
                label={"* PHONE"}
            />
            <WrappedInput
                name="email"
                onChange={props.handleChange("email")}
                value={props.formData.email}
                hasError={props.validationErrors.email.error}
                errorMessage={props.validationErrors.email.message}
                label={"* EMAIL"}
            />
            <WrappedInput
                name="title"
                onChange={props.handleChange("title")}
                value={props.formData.title}
                hasError={props.validationErrors.title.error}
                errorMessage={props.validationErrors.title.message}
                label={"TITLE"}
            />
        </div>
    );
}

export default ContactInfo;