import WrappedInput from "../../common/WrappedInput";
import HearAboutSelect from "../select/HearAboutSelect";
import { OTHER, PWC_CONTACT } from "../Constants";

const ReferralInfo = ({ props }) => {
    return (
        <div>
            <HearAboutSelect
                name="hearAbout"
                onChange={props.handleChange("hearAbout")}
                value={props.formData.hearAbout}
                hasError={props.validationErrors.hearAbout.error}
                errorMessage={props.validationErrors.hearAbout.message}
                label={"* HOW DID YOU HEAR ABOUT VIEWPOINT / DISCLOSURE CHECKLIST?"}
            />
            <div className={props.formData.hearAbout === PWC_CONTACT ? "fade-height expanded" : "fade-height collapsed"}>
                <WrappedInput
                    name="referralSource"
                    onChange={props.handleChange("pwcContact")}
                    value={props.formData.pwcContact}
                    hasError={props.validationErrors.pwcContact.error}
                    errorMessage={props.validationErrors.pwcContact.message}
                    label={"NAME OF CONTACT"}
                />
            </div>
            <div className={props.formData.hearAbout === OTHER ? "fade-height expanded" : "fade-height collapsed"}>
                <WrappedInput
                    name="referralSource"
                    onChange={props.handleChange("referralSource")}
                    value={props.formData.referralSource}
                    hasError={props.validationErrors.referralSource.error}
                    errorMessage={props.validationErrors.referralSource.message}
                    label={"REFERRAL SOURCE"}
                />
            </div>
        </div>
    );
}

export default ReferralInfo;