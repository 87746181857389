import { calculate } from "./PricingCalculator";

export const calculateDisclosureChecklistPricing = (formData, formState) => {
    formData.additionalLicensesTotal = formData.additionalLicenses * formState.additionalLicense;
    formData.disclosureChecklistTotal = formData.additionalLicensesTotal + formState.firstLicense;
}

export const calculateViewpointPricing = (formData, formState) => {
    formData.usContentTotal = calculate(formData.usContent, formState.contentAreaPrices?.usContent);
    formData.globalContentTotal = calculate(formData.globalContent, formState.contentAreaPrices?.globalContent);
    formData.canadaContentTotal = calculate(formData.canadaContent, formState.contentAreaPrices?.canadaContent);
    formData.ukContentTotal = calculate(formData.ukContent, formState.contentAreaPrices?.ukContent);
    formData.gaapContentTotal = calculate(formData.gaapContent, formState.contentAreaPrices?.gaapContent);
    formData.japanContentTotal = calculate(formData.japanContent, formState.contentAreaPrices?.japanContent);
    formData.gasbContentTotal = calculate(formData.gasbContent, formState.contentAreaPrices?.gasbContent);

    formData.viewpointTotal = formData.usContentTotal
        + formData.globalContentTotal
        + formData.canadaContentTotal
        + formData.ukContentTotal
        + formData.gaapContentTotal
        + formData.japanContentTotal
        + formData.gasbContentTotal;
}

export const resetPricing = (formData) => {
    // viewpoint
    formData.orderViewpoint = false;
    formData.usContent = 0;
    formData.usContentTotal = 0;
    formData.globalContent = 0;
    formData.globalContentTotal = 0;
    formData.canadaContent = 0;
    formData.canadaContentTotal = 0;
    formData.ukContent = 0;
    formData.ukContentTotal = 0;
    formData.gaapContent = 0;
    formData.gaapContentTotal = 0;
    formData.japanContent = 0;
    formData.japanContentTotal = 0;
    formData.gasbContent = 0;
    formData.gasbContentTotal = 0;
    formData.viewpointTotal = 0;
    // disclosure checklist
    formData.orderDisclosureChecklist = false;
    formData.additionalLicenses = 0;
    formData.additionalLicensesTotal = 0;
    formData.disclosureChecklistTotal = 0;
    formData.companyDomainNames = "";
    formData.engagementTeamManager = "";
    formData.tcApproverName = "";
    formData.tcApproverEmail = "";
    formData.agreeToTC = false
}

