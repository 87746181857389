import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "appkit-react";

import { validate, initialValidationErrors } from "./Validation";
import Contacts from "./Contacts";
import Contact from "./Contact";
import DomainNames from "./DomainNames";
import ClientIconHeader from "../../common/order/ClientIconHeader";
import { gotoError } from "../../../../../Routes";
import "./Client.css";

const Client = (props) => {
    const { product, buttonActions, buttonLabels, formData, domainNameCount } =
        props;
    const [validationErrors, setValidationErrors] = useState(
        initialValidationErrors
    );
    const [domainValidating, setDomainValidating] = useState(false);

    const navigate = useNavigate();
    const handleContinue = () => {
        validate(
            product.id,
            formData,
            validationErrors,
            setValidationErrors,
            props.additionalContactCount + 1, // this includes a non-additional contact that must be validated
            domainNameCount,
            product.optionalComponent.client.additionalContactsEmailOnly,
            setDomainValidating,
            () => buttonActions[1](),
            () => gotoError(navigate)
        );
    };

    return (
        <div className="p-b-125">
            <div className="row">
                <div className="col-3"></div>
                <div className="col-6 flex-justify-center">
                    <div className="content">
                        {props.useIconHeader ? (
                            <div className="m-b-28">
                                <ClientIconHeader />
                            </div>
                        ) : (
                            <div className="text-18 bold">
                                Client information for contract
                            </div>
                        )}
                        <div className="light-gray-text-16 m-b-28">
                            Contract Signatory
                        </div>
                        <Contact
                            props={props}
                            validationErrors={validationErrors}
                            index={0}
                        />
                        {(product.optionalComponent.client.additionalContacts ||
                            product.optionalComponent.client.domainNames) && (
                            <div className="red-separator-line"></div>
                        )}
                        {product.optionalComponent.client
                            .additionalContacts && (
                            <div>
                                <Contacts
                                    props={props}
                                    validationErrors={validationErrors}
                                />
                                <div className="red-separator-line"></div>
                            </div>
                        )}
                        {product.optionalComponent.client.domainNames && (
                            <DomainNames
                                props={props}
                                validationErrors={validationErrors}
                            />
                        )}
                        <div className="nav-buttons m-t-48">
                            <Button
                                onClick={buttonActions[0]}
                                kind={"secondary"}
                            >
                                {buttonLabels[0]}
                            </Button>
                            <Button
                                onClick={handleContinue}
                                disabled={domainValidating}
                                isLoading={domainValidating}
                            >
                                {buttonLabels[1]}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="col-3"></div>
            </div>
        </div>
    );
};

export default Client;
