import { useState } from "react";
import Button from "appkit-react/lib/Button/Button";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "appkit-react";
import TermsConditionsUS from "./TermsConditionsUS";
import TermsConditionsUK from "./TermsConditionsUK";
import TermsConditionsJapan from "./TermsConditionsJapan";
import TermsConditionsIreland from "./TermsConditionsIreland";
import { UNITED_STATES, IRELAND, JAPAN } from "../Constants";
import "./TermsConditionsModal.css";

const TermsConditionsModal = (props) => {
    const [visible, setVisible] = useState(false);

    let modalContent = <TermsConditionsUK />;
    switch (props.country) {
        case UNITED_STATES:
            modalContent = <TermsConditionsUS />;
            break;

        case IRELAND:
            modalContent = <TermsConditionsIreland />;
            break;

        case JAPAN:
            modalContent = <TermsConditionsJapan />;
            break;

        default:
            break;
    }

    return (
        <span>
            <span
                className="orange text-14 bold pointer"
                onClick={() => setVisible(true)}
            >
                terms and conditions.
            </span>
            <Modal
                className="t-c-modal"
                visible={visible}
                onCancel={() => setVisible(false)}
            >
                <ModalHeader className="t-c-modal-title text-18">
                    Viewpoint Terms and Conditions
                </ModalHeader>
                <ModalBody className="t-c-modal-body">{modalContent}</ModalBody>
                <ModalFooter className="m-b-16">
                    <Button
                        onClick={() => setVisible(false)}
                        gray={true}
                        size={"xl"}
                        kind={"primary"}
                    >
                        CLOSE
                    </Button>
                </ModalFooter>
            </Modal>
        </span>
    );
};

export default TermsConditionsModal;
