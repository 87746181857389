import { useState } from "react";
import { CardTitle } from "../../CardTitle";
import { formatNumber } from "../../../../../common/Util";

const CN = (props) => {
    const [detailShow, setDetailShow] = useState(false);
    const { orderDetails } = props;

    // prevent Initial term card bottom radius bug when there is no RenewalTerm1Value

    const productDescription = orderDetails.productDescription.split("\n");
    const borderBottomRadius = "border-radius-b";
    return (
        <div className="p-t-26 m-b-28">
            <div className="m-h-24 flex-justify-between">
                <CardTitle
                    iconClass="icon-calendar-outline"
                    text="Initial Term"
                />
                <div
                    onClick={() => setDetailShow(!detailShow)}
                    className="orange text-12 half-bold a-hover underline text-bold"
                >
                    {detailShow ? "Hide Details" : "View Details"}
                </div>
            </div>
            <ul className="m-h-24 ul-clear-left-p">
                {orderDetails.productPrice ? (
                    <li className="p-t-15">
                        <div className="flex-justify-between text-12 half-bold gray">
                            <h6 className="text-12 half-bold mb-0">
                                Annual Subscription Fee
                            </h6>
                            <span>
                                {formatNumber(orderDetails.productPrice)}
                            </span>
                        </div>
                        {detailShow ? (
                            <div>
                                <div className="gray-text-10 half-bold one-time-fee w-percent-70">
                                    <p className="mb-2 mt-2">
                                        Subscription Tier:
                                    </p>
                                    <ul>
                                        <li className="p-l-85-em">
                                            {orderDetails.productTier}
                                        </li>
                                    </ul>
                                </div>
                                <div className="gray-text-10 half-bold one-time-fee w-percent-70">
                                    <p className="mb-2 mt-2">
                                        Subscription Term:
                                    </p>
                                    <ul>
                                        <li className="p-l-85-em">
                                            {orderDetails.productTerm}
                                        </li>
                                    </ul>
                                </div>
                                <div className="gray-text-10 half-bold one-time-fee w-percent-70">
                                    <p className="mb-2 mt-2">
                                        Product Features:
                                    </p>
                                    <ul>
                                        {productDescription.map((item) => (
                                            <li className="p-l-85-em">
                                                {item}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ) : null}
                    </li>
                ) : null}
            </ul>
            <div
                className={`bg-black white flex-justify-between p-h-24 p-v-15 m-t-15 text-bold ${borderBottomRadius}`}
            >
                <h3 className="text-16 clear-margin-bottom text-bold">
                    Subtotal before tax
                </h3>
                <span>{formatNumber(orderDetails.productPrice)} USD</span>
            </div>
        </div>
    );
};

export default CN;
