import "./Footer.css";

function Footer() {
    return (
        <footer>
            <div className="footer-text">
            <span>
            © 2017 - 2024 PwC. All rights reserved. PwC refers to the PwC network and/or one or more of its member firms, each of which is a separate legal entity. 
            Please see{" "}
                <a
                    href="https://www.pwc.com/structure"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    www.pwc.com/structure
                </a>{" "}
                for further details.
            </span>
                <a
                    href="https://www.pwc.com/us/en/site/privacy.html"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Privacy Statement
                </a>
            </div>
        </footer>
    );
}

export default Footer;
