import { CONTRACT_TYPE } from "../../Constants";
const WFO = {
    [CONTRACT_TYPE.frontlineWorkers]: [
        {
            title: "Term",
            text: [
                "Upon expiration of the Initial Term, this Order will automatically renew for additional successive Renewal Terms of one (1) year each until a party provides the other with notice of its election not to renew at least forty-five (45) days prior to the end of the then-current Initial Term or Renewal Term (as applicable)."
            ]
        },
        {
            title: "Initial Term End Date",
            text: [
                "11:59pm Eastern time on the day prior to the first anniversary of the Effective Date"
            ]
        },
        {
            title: "Activation Date",
            text: ["Effective Date"]
        },
        {
            title: "User Accounts",
            text: [
                "Client has limited number of Users. The number of allowed Users is defined above.",
                "If at any time during the Initial Term the number of Users exceeds the Minimum Commitment for that year, PwC will invoice Client for each additional User in excess of the Minimum Commitment, pro rata for the remainder of that year of the Initial Term (“Additional User Fees”). PwC will not issue such invoices more than once per quarter during the Term. Payment of Additional User Fees will be due as provided in the Agreement. Payment of the Minimum Commitments and Additional User Fees are non-refundable."
            ]
        },
        {
            title: "Authorized Contractor(s)",
            text: [
                "All Authorized Contractors subject to all other restrictions in the Agreement."
            ]
        },
        {
            title: "Included Affiliate(s)",
            text: [
                "All Affiliates subject to all other restrictions in the Agreement."
            ]
        },
        {
            title: "Additional terms",
            text: [
                "For each year (or partial year) of the Initial Term and each Renewal Term (if any), Client will purchase a minimum of 500 End User seats (each a “Minimum Commitment”)."
            ]
        }
    ],
    [CONTRACT_TYPE.officeWorkers]: [
        {
            title: "Term",
            text: [
                "Upon expiration of the Initial Term, this Order will automatically renew for additional successive Renewal Terms of one (1) year each until a party provides the other with notice of its election not to renew at least forty-five (45) days prior to the end of the then-current Initial Term or Renewal Term (as applicable)."
            ]
        },
        {
            title: "Initial Term End Date",
            text: [
                "11:59pm Eastern time on the day prior to the first anniversary of the Effective Date"
            ]
        },
        {
            title: "Activation Date",
            text: ["Effective Date"]
        },
        {
            title: "User Accounts",
            text: [
                "Client has limited number of Users. The number of allowed Users is defined above.",
                "If at any time during the Initial Term the number of Users exceeds the Minimum Commitment for that year, PwC will invoice Client for each additional User in excess of the Minimum Commitment, pro rata for the remainder of that year of the Initial Term (“Additional User Fees”). PwC will not issue such invoices more than once per quarter during the Term. Payment of Additional User Fees will be due as provided in the Agreement. Payment of the Minimum Commitments and Additional User Fees are non-refundable."
            ]
        },
        {
            title: "Authorized Contractor(s)",
            text: [
                "All Authorized Contractors subject to all other restrictions in the Agreement."
            ]
        },
        {
            title: "Included Affiliate(s)",
            text: [
                "All Affiliates subject to all other restrictions in the Agreement."
            ]
        },
        {
            title: "Additional terms",
            text: [
                "For each year (or partial year) of the Initial Term and each Renewal Term (if any), Client will purchase a minimum of 500 End User seats (each a “Minimum Commitment”)."
            ]
        }
    ]
};
export default WFO;
