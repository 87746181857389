import { useState, useEffect } from "react";
import LeftColumn from "./layout/LeftColumn";
import RightColumn from "./layout/RightColumn";
import LeftColumnSubmitted from "./layout/LeftColumnSubmitted";
import RightColumnSubmitted from "./layout/RightColumnSubmitted";
import { initialValidationErrors, validate } from "./Validation";
import {
    getTheatre,
    submitForm
} from "./service/External";
import {
    calculateViewpointPricing,
    calculateDisclosureChecklistPricing,
    resetPricing
} from "./service/Pricing";
import { INITIAL_FORM_DATA, INITIAL_FORM_STATE } from "./Constants";

import "./Viewpoint.css";

const Viewpoint = () => {
    const [formData, setFormData] = useState(INITIAL_FORM_DATA);
    const [validationErrors, setValidationErrors] = useState(
        initialValidationErrors
    );
    const [formState, setFormState] = useState(INITIAL_FORM_STATE);
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    // when these fields change, update Disclosure Checklist pricing
    const disclosureChecklistPricingFields = ["additionalLicenses"];
    // update formData with a new value
    const handleChange = (name) => (value) => {
        formData[name] = value;
        if (
            disclosureChecklistPricingFields.includes(name) ||
            (name === "orderDisclosureChecklist" && value) // first Disclosure Checklist license
        ) {
            calculateDisclosureChecklistPricing(formData, formState);
        }
        // changing the country changes the pricing model
        if (name === "country") {
            getTheatre(value, setFormState);
            resetPricing(formData);
        }
        setFormData({ ...formData });
    };

    // when these fields change, update Viewpoint pricing
    const viewpointPricingFields = [
        "usContent",
        "globalContent",
        "canadaContent",
        "ukContent",
        "gaapContent",
        "japanContent",
        "gasbContent"
    ];
    // update formData with multiple new values
    const handleChanges = (names) => (values) => {
        if (names.length !== values.length) {
            console.error(
                "Names and values must have the same length.",
                names,
                values
            );
            return;
        }
        for (let i = 0; i < names.length; ++i) {
            formData[names[i]] = values[i];
        }
        const intersection = viewpointPricingFields.filter((x) =>
            names.includes(x)
        );
        if (intersection.length > 0) {
            calculateViewpointPricing(formData, formState);
        }
        setFormData({ ...formData });
    };

    const submit = () => {
        setSubmitting(true);
        if (validate(formData, formState, validationErrors, setValidationErrors)) {
            // TODO : handle remote error?
            const callback = () => {
                setSubmitting(false);
                setSubmitted(true);
            };
            submitForm(formData, formState, setFormState, callback);
        } else {
            setSubmitting(false);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [submitted]);

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-1 gray-background"></div>
                <div className="col-sm-4 gray-background">
                    {!submitted ? (
                        <LeftColumn
                            handleChange={handleChange}
                            formData={formData}
                            validationErrors={validationErrors}
                        />
                    ) : (
                        <LeftColumnSubmitted formData={formData} />
                    )}
                </div>
                <div className="col-sm-1 gray-background"></div>
                <div className="col-sm-1"></div>
                <div className="col-sm-4">
                    {!submitted ? (
                        <RightColumn
                            handleChange={handleChange}
                            handleChanges={handleChanges}
                            formData={formData}
                            formState={formState}
                            validationErrors={validationErrors}
                            submit={submit}
                            submitting={submitting}
                        />
                    ) : (
                        <RightColumnSubmitted
                            formData={formData}
                            formState={formState}
                        />
                    )}
                </div>
                <div className="col-sm-1"></div>
            </div>
        </div>
    );
};

export default Viewpoint;
