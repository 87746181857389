export const CardTitle = (props) => {
    const { iconClass, text, link, colClass } = props;
    let col = colClass ?? "col-6";
    let flexClass = link ? "flex-start" : "flex-align-center";
    return (
        <div className={`${col} ${flexClass}`}>
            <span
                className={`appkiticon a-font-32 a-text-orange ${iconClass}`}
            ></span>
            <div className="m-l-15">
                <h5 className="a-h5 clear-margin-bottom text-bold">{text}</h5>
                {link ? (
                    <div className="dod-card-title">
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            className="a-hover orange text-12 half-bold nowrap"
                            href={link}
                        >
                            {link}
                        </a>
                    </div>
                ) : null}
            </div>
        </div>
    );
};
