import { CONTRACT_TYPE } from "../../Constants";
const DOD = {
    [CONTRACT_TYPE.fourtyFiveDays]: [
        {
            title: "User Limitation during Initial Term (Trial Starter Period):",
            desc: [
                "Notwithstanding anything to the contrary in the Agreement, the Initial Term shall be considered a “Trial Starter Period”. During the Trial Starter Period, no fees will be payable by Client for use of the Offering and Client agrees that it will not permit more than ten (10) Users to access and use the Offering (the “Trial Users”). This limitation on the number of Users will be removed upon the commencement of the first Renewal Term subject to payment by Client of fees due at that time."
            ]
        }
    ],
    [CONTRACT_TYPE.ninetyDays]: [],
    [CONTRACT_TYPE.twelveMonths]: []
};
export default DOD;
