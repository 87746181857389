import WrappedInput from "../../../../common/WrappedInput";
import { removeElement } from "../../../../common/Util";

const DomainNames = ({ props, validationErrors }) => {
    const remove = (index) => {
        props.formData.clientDomainName = removeElement(
            props.formData.clientDomainName,
            index
        );
        props.setFormData({ ...props.formData });
        props.setDomainNameCount(props.domainNameCount - 1);
    };

    const additionalDomainNames = [];
    for (let i = 1; i < props.domainNameCount; ++i) {
        additionalDomainNames.push(
            <div key={i}>
                <div className="gray-separator-line"></div>
                <span
                    className="appkiticon icon-circle-minus-fill a-font-16 a-text-red v-middle clickable"
                    onClick={() => remove(i)}
                ></span>
                <span className="orange v-middle p-l-8 text-12">
                    Delete domain name
                </span>
                <WrappedInput
                    name="domain"
                    label="DOMAIN NAME"
                    value={props.formData.clientDomainName[i] ?? ""}
                    onChange={(value) => props.handleIndexedChange("clientDomainName", i)(value.toLowerCase())}
                    hasError={[
                        validationErrors.clientDomainName.error[i],
                        validationErrors.clientDomainName_1.error[i],
                        validationErrors.clientDomainName_2.error[i]
                    ]}
                    errorMessage={[
                        validationErrors.clientDomainName.message,
                        validationErrors.clientDomainName_1.message,
                        validationErrors.clientDomainName_2.message
                    ]}
                    primary={true}
                />
            </div>
        );
    }

    const canAdd =
        props.domainNameCount <
        props.product.optionalComponent.client.maxDomainNames;
    const add = () => {
        if (canAdd) {
            props.setDomainNameCount(props.domainNameCount + 1);
        }
    };

    return (
        <div>
            <div className="text-18 bold">Allowed domain names</div>
            <div className="light-gray-text-16 p-b-24">
                Only email addresses that contain the submitted domain name(s)
                will be allowed to access the product. This is to control and
                set security.
            </div>
            <WrappedInput
                name="domain"
                label="DOMAIN NAME"
                value={props.formData.clientDomainName[0] ?? ""}
                hasError={[
                    validationErrors.clientDomainName.error[0],
                    validationErrors.clientDomainName_1.error[0],
                    validationErrors.clientDomainName_2.error[0]
                ]}
                errorMessage={[
                    validationErrors.clientDomainName.message,
                    validationErrors.clientDomainName_1.message,
                    validationErrors.clientDomainName_2.message
                ]}
                primary={true}
                disabled={true}
            />
            {additionalDomainNames}
            {canAdd && (
                <div className="p-t-8">
                    <span
                        className="appkiticon icon-circle-plus-fill a-font-24 a-text-red v-middle clickable"
                        onClick={add}
                    ></span>
                    <span className="orange v-middle p-l-8 text-12">
                        Add more domain names
                    </span>
                </div>
            )}
        </div>
    );
};

export default DomainNames;
