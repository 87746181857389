import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "appkit-react/lib/Button/Button";
import fintechDigitalLogo from "../../assets/fintechDigital.svg";
import Header from "../Header";
import Footer from "../Footer";
import Banner from "./Banner";
import { ORDER_LIST } from "./Constants";
import { gotoError, gotoLanding, gotoManage, gotoNewOrder } from "../../Routes";
import { getAccess } from "./service/External";
import ProductSelect from "./ProductSelect";
import useProduct from "../products/layout/common/UseProduct";

const Landing = (props) => {
    const product = useProduct(true);
    const [canCreate, setCanCreate] = useState(false);
    const [canManage, setCanManage] = useState(false);
    const navigate = useNavigate();

    // when the product derived from the URI changes
    useEffect(() => {
        setCanCreate(false);
        setCanManage(false);
        // perform an access check for the new product
        if (product) {
            const getAccessCallback = (success, result) => {
                if (success) {
                    setCanCreate(true);
                    setCanManage(result);
                } else {
                    gotoError(navigate);
                }
            };

            getAccess(
                props.user.guid,
                product.id,
                ORDER_LIST,
                getAccessCallback
            );
        }
    }, [navigate, props.user.guid, product]);

    const createNewOrder = () => {
        gotoNewOrder(navigate, product.id);
    };

    const manageOrders = () => {
        gotoManage(navigate, product.id, product.defaultTab.id);
    };

    // when the product selection changes, update the URI
    const handleSelection = (productId) => {
        gotoLanding(navigate, productId);
    };

    return (
        <div>
            <Header notClickable={true} />
            <div>
                <div className="m-t-104 m-b-52">
                    <div className="row flex-justify g-0">
                        <div className="col-5 max-w-588">
                            <div className="gray-text-45 half-bold">
                                License and deliver products to your clients
                            </div>
                            <div className="text-18 p-t-44 p-b-48">
                                Software Licensing Platform (SLP) accelerates
                                the PwC product licensing process by offering
                                automated capabilities, controls and flexibility
                                to drive higher product sales volume with less
                                overhead.
                            </div>
                            <div className="text-18 orange p-b-32">
                                Select a product below to get started.
                            </div>
                            <ProductSelect
                                value={product?.id}
                                name="product"
                                onChange={handleSelection}
                                label={"PRODUCT"}
                            />
                            <div className="p-t-32">
                                <div>
                                    <Button
                                        size={"lg"}
                                        kind={"primary"}
                                        onClick={createNewOrder}
                                        disabled={!canCreate}
                                        className="m-r-16"
                                    >
                                        CREATE NEW ORDER
                                    </Button>
                                    <Button
                                        size={"lg"}
                                        kind={
                                            !canManage ? "primary" : "secondary"
                                        }
                                        onClick={manageOrders}
                                        disabled={!canManage}
                                    >
                                        MANAGE ORDERS
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className="col-5 max-w-588">
                            <div className="d-flex justify-content-start">
                                <div className="col image-pad">
                                    <img
                                        src={fintechDigitalLogo}
                                        className="right-icon"
                                        alt="Fintech Digital Logo"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Banner />
            </div>
            <Footer />
        </div>
    );
};

export default Landing;
