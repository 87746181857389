export const getAccess = (guid, productId, page, callback) => {
    window.Visualforce.remoting.Manager.invokeAction(
        window.endpoints.getAccess,
        guid,
        productId,
        page,
        (result, event) => {
            if (event.status) {
                callback(true, event.result);
            } else {
                callback(false);
            }
        },
        { escape: false }
    );
};
