import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import Header from "../../../Header";
import Footer from "../../../Footer";
import SupportBanner from "../SupportBanner";
import Steps from "./Steps";
import Acceptance from "./acceptance/Acceptance";
import Client from "./client/Client";
import Terms from "./order/Terms";
import Contact from "./contact/Contact";
import Review from "./review/Review";
import useProduct from "../common/UseProduct";
import usePricingOptions from "../common/order/UsePricingOptions";

import {
    NEW_ORDER_DATA,
    NEW_ORDER_STATE
} from "../../Constants";
import lock from "../../../../assets/lock-open.svg";
import client from "../../../../assets/client.svg";
import dollar from "../../../../assets/dollar-sign.svg";
import envelope from "../../../../assets/envelope.svg";
import document from "../../../../assets/document.svg";
import "./NewOrder.css";
import { PRODUCTS } from "../../Config";

const NewOrder = (props) => {
    const product = useProduct();
    // all pages \\
    const PAGES = { ACCEPTANCE: 0, CLIENT: 1, TERMS: 2, CONTACT: 3, REVIEW: 4 };
    const [page, setPage] = useState(PAGES.ACCEPTANCE);
    // populate default non-editable data
    NEW_ORDER_DATA.nonEditableContacts = {};
    for (let key in product.defaultValue.contact.nonEditableContacts) {
        NEW_ORDER_DATA.nonEditableContacts[key] = {
            email: product.defaultValue.contact.nonEditableContacts[key]
        };
    }
    const [formData, setFormData] = useState(NEW_ORDER_DATA);
    const [formState, setFormState] = useState(NEW_ORDER_STATE);

    // order terms page \\
    const [pricingOptionsLoaded, setPricingOptionsLoaded] = useState(false);
    usePricingOptions(
        setFormState,
        product,
        pricingOptionsLoaded,
        setPricingOptionsLoaded
    );
    // client page \\
    const [additionalContactCount, setAdditionalContactCount] = useState(0);
    // the DomainNames component has a non-optional domain name input
    const [domainNameCount, setDomainNameCount] = useState(
        product?.optionalComponent?.client?.domainNames ? 1 : 0
    );

    const reset = () => {
        // For each object or array in NEW_ORDER_DATA, a new object or array MUST be created here.
        // Otherwise, the reference to the object or array does not change.  Thus, react does not
        // detect the change and uses the old values.
        setFormData({
            ...NEW_ORDER_DATA,
            clientFirstName: [],
            clientLastName: [],
            clientEmail: [],
            clientTitle: [],
            clientPhone: [],
            clientDomainName: [],
            requiredContacts: {},
            additionalContacts: {},
            additionalContactsSelect: []
        });
        setFormState({ ...NEW_ORDER_STATE });
        setPricingOptionsLoaded(false);
    };

    // reset the form when the user naviagtes elsewhere
    const location = useLocation();
    useEffect(() => {
        reset();
        setPage(PAGES.ACCEPTANCE);
    }, [PAGES.ACCEPTANCE, location]);

    const resetLookup = () => {
        formState.lookupMessage = "";
        formState.clientAccount = null;
        formState.clientOpportunity = null;
        setFormState({ ...formState });
        formData.afsCompleted = "";
        formData.afsNumber = "";
        formData.afsApprovalDate = null;
        setFormData({ ...formData });
        setPage(PAGES.ACCEPTANCE);
    };

    const handleChange = (name) => (value) => {
        formData[name] = value;
        setFormData({ ...formData });
    };

    // treat each field in formData as an array and update according to index
    const handleIndexedChange = (name, index) => (value) => {
        formData[name][index] = value;
        setFormData({ ...formData });
    };

    const forward = () => {
        if (page < steps.length - 1) {
            setPage(page + 1);
        }
    };

    const backward = () => {
        if (page > 0) {
            setPage(page - 1);
        }
    };

    const gotoClient = () => {
        setPage(PAGES.CLIENT);
    };

    const gotoTerms = () => {
        setPage(PAGES.TERMS);
    };

    const gotoContact = () => {
        setPage(PAGES.CONTACT);
    };

    const steps = [];
    steps[PAGES.ACCEPTANCE] = {
        label: "Acceptance",
        icon: lock,
        alt: "Acceptance Icon",
        view: (
            <Acceptance
                product={product}
                handleChange={handleChange}
                formData={formData}
                forward={forward}
                formState={formState}
                setFormState={setFormState}
                resetLookup={resetLookup}
            />
        )
    };
    steps[PAGES.CLIENT] = {
        label: "Client",
        icon: client,
        alt: "Client Icon",
        view: (
            <Client
                product={product}
                handleIndexedChange={handleIndexedChange}
                formData={formData}
                setFormData={setFormData}
                buttonLabels={["BACK", "CONTINUE"]}
                buttonActions={[backward, forward]}
                additionalContactCount={additionalContactCount}
                setAdditionalContactCount={setAdditionalContactCount}
                domainNameCount={domainNameCount}
                setDomainNameCount={setDomainNameCount}
                useIconHeader={false}
            />
        )
    };
    steps[PAGES.TERMS] = {
        label: "Order",
        icon: dollar,
        alt: "Order Icon",
        view: (
            <Terms
                product={product}
                handleChange={handleChange}
                formData={formData}
                formState={formState}
                setFormState={setFormState}
                buttonLabels={["BACK", "CONTINUE"]}
                buttonActions={[backward, forward]}
                useIconHeader={false}
                pricingOptionsLoaded={pricingOptionsLoaded}
            />
        )
    };
    steps[PAGES.CONTACT] = {
        label: "Contact",
        icon: envelope,
        alt: "Contact Icon",
        view: (
            <Contact
                product={product}
                formData={formData}
                formState={formState}
                setFormData={setFormData}
                buttonLabels={["BACK", "CONTINUE"]}
                buttonActions={[backward, forward]}
                user={props.user}
                useIconHeader={false}
            />
        )
    };
    steps[PAGES.REVIEW] = {
        label: "Review",
        icon: document,
        alt: "Review Icon",
        view: (
            <Review
                product={product}
                formData={formData}
                formState={formState}
                forward={forward}
                backward={backward}
                resetLookup={resetLookup}
                gotoClient={gotoClient}
                gotoTerms={gotoTerms}
                gotoContact={gotoContact}
                user={props.user}
            />
        )
    };

    const isPODOrderStep =
        product.id === PRODUCTS.POD.id && page === PAGES.TERMS;

    return (
        <div>
            {product !== undefined ? (
                <>
                    <Header />
                    <div className="container-fluid p-t-24">
                        <div className="row">
                            <div className="light-gray-text-18 m-b-24 col-12 flex-justify-center">
                                Please {isPODOrderStep ? "review" : "provide"}{" "}
                                the details below to license products to your
                                clients.
                            </div>
                        </div>
                        <div className="row">
                            <Steps active={page} steps={steps} />
                        </div>
                        {steps[page].view}
                    </div>
                    <SupportBanner />
                    <Footer />
                </>
            ) : (
                <></>
            )}
        </div>
    );
};

export default NewOrder;
