const TermsConditionsUS = () => {
    return (
        <div>
            <p>
                <strong>本利用規約は、PwC（お客様に本サイトを提供する、PwCネットワークのメンバーファームをいいます）が登録を承認した事業主様にViewpoint Webサイト（以下、「本サイト」といいます）のアクセスおよび利用を許諾することについて定めるものです。お申込み時に「利用規約に同意する」のチェックボックスにチェックを入れて申込みをされることにより、本サイトの登録フォームにおいて特定される法⼈（以下、「お客様」といいます）は、本利用規約において参照する規約を含めて、下記に規定する条項に同意するものとします。お客様は、「利用規約に同意する」のチェックボックスにチェックを入れて申し込んだ時点で、PwCと契約を締結することとなります。</strong>
            </p>
            <p>PwCは、改訂した条項を本サイトに掲載することにより、いつでも本利用規約を変更することができます。新しい条項は、本サイトに初めて掲載されてから15日後に⾃動的に発効し、お客様に対して契約の拘束力を有します。お客様が本利用規約の改訂を承認することを希望なさらない場合、お客様は第12.3項に基づいて本利用規約を解除することができます。上記以外の⽅法によって本利用規約は改訂されません。</p>
            <ol>
                <li class="list-item-bold">
                    <strong>免責および責任制限</strong>
                    <ol>
                        <li>
                            <strong>本項は、本サイトのあらゆる部分、本サイトで利用可能な情報およびサービスならびにこれらの使用に適用します。</strong>
                            <ol>
                                <li>本サイトに含まれる情報は、ご関⼼事項に関する⼀般的なガイダンスに限定されます。
                                    法律の適用およびその影響は、個別の事実関係によって⼤幅に異なることが想定されます。
                                    法律、政令、省令、諸規則等は、常に変更が加えられること、および電⼦的通信⼿段に内在する危
                                    険性や問題点を踏まえ、本サイトに掲載される情報は、その情報提供が遅れたり、⽋落したり、
                                    または不正確である可能性もあります。したがって、法務、会計、税務、規制またはその他の専
                                    ⾨的なアドバイスもしくはサービスをPwCまたは著者によって提供しているものではないという認
                                    識のもと、本サイトの情報は提供されています。</li>
                                <li>本サイト上の情報交換によって、いかなる性質の職務上の関係も成⽴しません。</li>
                                <li>ガイダンスの注釈を含めて、本サイト上の情報は、専⾨的な会計、税務、法務、⾦融、またはその
                                    他の適格なアドバイザーとの相談の代替に使用されるべきものではありません。</li>
                                <li>お客様は、本サイトから⼊⼿した情報に基づいて⾏動したり、またはそれらの情報に依
                                    拠したりするべきではありません。お客様は、意思決定をする前または何らかの⾏動をする前
                                    に、PwCの専⾨家に相談することが肝要です。</li>
                                <li>PwCは、第三者から⼊⼿して本サイトで掲載した情報およびコンテンツの正確性を確保
                                    するためにあらゆる努⼒を⾏っておりますが、PwCも当該第三者も、掲載された情報の正
                                    確性または内容に対して⼀切の責任を負わないものとします。</li>
                                <li>
                                    <strong>お客様は、PwC、PwC加盟法人、その関連するパートナーシップ、法⼈、パートナー、代理⼈、役員、または従業員が、いかなる状況においても本サイトに含まれる情報に責任を負わないことに同意することとします。</strong>
                                </li>
                                <li>
                                    <strong>PwCは、当該情報については、契約、不法⾏為（過失を含む）を含む法律上、またはその他の如何を問わず、明⽰または暗⽰の保証をするものではなく、⼀切の責任を負わないこととします（ここでいう保証には、相当なスキルまたは注意をもって本サイト上の情報が提供もしくは作成されるという保証も含まれますが、それらに限定されるものではありません）。</strong>
                                </li>
                            </ol>
                        </li>
                        <li>PwCは、本サイトへの継続的で断絶のない安全なアクセスを保証いたしかねます。お客様は、
                            インターネットの信頼性、可用性、パフォーマンス、およびその他の側⾯がPwCの合理的な管
                            理能⼒の範囲外であることを承諾することとします。
                            <ol>
                                <li>PwCは、本サイトの機能がお客様の要求事項を満たすことを表明保証いたしかねます。</li>
                                <li>PwCは、本サイトからまたは本サイトを経由してお客様に配信される情報が第三者によ
                                    り記録もしくは監視されないこと、または当該情報がお客様のサイトへ到達することを保
                                    証いたしかねます。お客様は、本サイトに掲載される、または電⼦メールによりお客様に送
                                    信される情報が、安全であるまたは機密として保持されることをPwCが保証できないことに
                                    同意することとします。</li>
                                <li>PwCは、お客様の送信機器またはデータへの不正アクセスもしくはこれらの改変、
                                    送受信されたまたは送受信されなかった資料またはデータについて責任を負いません。</li>
                                <li>本サイトは、お客様のブラウザと本サイトの間のトラフィックを現在利用可能な最
                                    ⾼レベルで暗号化することができますが、お客様のブラウザがサポートする暗号化
                                    レベルに依存するため、PwCはお客様が当該セキュリティ・レベルを得ることを保証
                                    いたしかねます。PwCは、低い暗号化レベルで本サイトを使用することによって増加
                                    するリスクをお引き受けいたしません。</li>
                            </ol>
                        </li>
                        <li>PwCは、本サイトに関連してお客様に送信する可能性がある⽂書またはファイルに標準的な
                            市販のウイルス・チェック・ソフトウェアを使用しますが、本サイトまたは本サイトからダウ
                            ンロードされた⽂書もしくはファイルにエラーがなく、ウイルスまたはその他の有害な要素
                            がないことを表明保証いたしかねます。</li>
                        <li>お客様は、お客様がPwCとの間にのみ関係が成立することに同意することとします。PwCは、
                            本サイトおよび本サイト上で提供されるサービスに関する下請業者の⾏為に責任を負うこととします。</li>
                        <li>本サイトの特定のリンクは、第三者が管理するその他のサイトに接続しておりますが、
                            当該サイトが、本サイトのフレーム内に表⽰されるとは限らないこととします。PwCは、
                            当該第三者のサイトのコンテンツを検証しておらず、当該サイトまたは当該サイトを提供する
                            可能性がある機関もしくは⼈物から提供され、またはアクセス可能ないかなる情報、
                            サービス、または製品について⽀持、保証、促進、または推奨いたしません。</li>
                    </ol>
                </li>
                <li class="list-item-bold">
                    <strong>損害補償</strong>
                    <ol>
                        <li>第1.4項の条項を有効なものとするため、お客様は、お客様が直接的または間接的
                            に利害関係を有している企業もしくはお客様グループのメンバーが、下請業者、
                            そのパートナー、当該下請業者またはPwCの役員もしくは従業員に対して、本サイトに関
                            連する法的責任について請求または執⾏を⾏わないことに同意し、⾏わないようにさせることとします。
                            下請業者、そのパートナー、またはPwCの役員もしくは従業員が、お客様企業またはお客様
                            グループのメンバーによるあらゆる請求や執行によって負担するいかなる損失、責任および費用
                            についても、お客様は補償しなければならないものとします。なお、当該請求や執行をした企業が、
                            現にお客様グループのメンバーである期間またはそれ以降の期間のいずれにおいて請求や執
                            行を実施したかを問いません。</li>
                        <li>お客様は、お客様のユーザー管理者または認定ユーザー用のユーザーネームおよびパスワードを
                            使用して本サイトを使用するもしくは本サイトにアクセスするそれぞれの⼈物が、本利用規約を
                            遵守していることを確保  するものとします。お客様は、お客様のユーザー管理者、認定ユーザー、
                            その他のお客様のユーザー管理者または認定されたユーザーのユーザーネームおよびパスワードを
                            使用してWebサイトにアクセスする者もしくは本サイトから⼊⼿した情報をお客様が配布した者が、
                            本利用規約に違反したことによりPwC、PwCの取締役、役員、および従業員が合理的に負担
                            するいかなる責任、損失、経費、またはその他の費用も補償することを確保します。</li>
                        <li>第2.1項および第2.2項で規定されている損害賠償に関する条項は、当該請求または執
                            ⾏について、下請業者、そのパートナー、当該下請業者またはPwCの役員もしくは従業
                            員が負担する社内外問わず、訴訟等およびその他の費用ならびに経費にも適用することとします。</li>
                    </ol>
                </li>
                <li class="list-item-bold">
                    <strong>適格性</strong>
                    <ol>
                        <li>
                            <p><span class="bg-color-yellow">本サイトは、有料会員については、適用される法律
                                の下で法的拘束⼒のある契約を締結することができる法⼈、政府機関、一部の個人事
                                業主、ビジネスパートナーシップのみが使用することができるものとします。</span></p>
                        </li>
                        <li>次のいずれかに該当する場合、お客様は、本サイトへアクセスするまたは本サイトを利用する資格がありません。
                            <ol>
                                <li>
                                    <p><span class="bg-color-yellow">お客様が適用される法令における「消費者」または⾃然⼈である場合（一
                                        部の個人事業主又は個人の専門職を除きます）（有料会員についてのみ）</span></p>
                                </li>
                                <li>
                                    <p><span class="bg-color-yellow">お客様がお客様のビジネスのために本サイトを利用しない場合（有料会員についてのみ）</span></p>
                                </li>
                                <li>お客様が登録フォームに登録していない管轄地域の法律の下で設立、登録、もしくは組成されており、
                                    または、当該地域から本サイトを利用している場合</li>
                                <li>お客様が適用される法律または諸規則の下で、本サイトにアクセスすることが許可されていない場合</li>
                            </ol>
                            <p>上記の適格性の要件を満たしていない場合、お客様は、本サイトを利用する資格を有しないこととします。
                                お客様が、本サイトを利用する場合は、お客様は本サイトを利用する資格を有しており、上記に規定する
                                者ではないことを確認し、それを表明したものとします。</p>
                        </li>
                        <li>上記に規定している適格性の要件を満たしていないにもかかわらず登録し本サイトを利用する場合、
                            当該不適格者は、本契約の当事者である適格者と同⼀のあらゆる義務、責任、および債務を有することとします。</li>
                    </ol>
                </li>
                <li class="list-item-bold">
                    <strong>本サイトへの登録</strong>
                    <ol>
                        <li>お客様は、登録フォームに表⽰されているすべての必須項⽬に情報を⼊⼒する必要があります。</li>
                        <li>お客様が会員サービスに登録申込みをした場合は、PwCは、（１）お客様の登録申込みを承認するか否か、および（２）登録に対して適用される初期料金を確認するために、ご登録の連絡先にご連絡いたします（PwCがお客様と初期料金の合意をしていない場合）。PwCがお客様の登録申込みを承認する場合、お客様は、お客様のために本サイトにアクセスすることができる適格個人の⽒名および本サイトにおけるお客様の管理者として⾏動する⼈物（お客様の「<span>ユーザー管理者</span>」）の⽒名をPwCにお知らせください。ユーザー管理者用のアカウント有効化のためのURLリンクを電⼦メールでお客様の電子メールアドレス宛にご連絡いたします。登録申込前にPwCとお客様との間で初期料金の合意をしている場合、PwCがお客様の不備のない登録申込みを受領し、承認した時にユーザー管理者用のアカウント有効化のためのURLリンクを電⼦メールでお客様の電子メールアドレス宛にご連絡いたします。</li>
                        <li>ユーザー管理者は、本サイトで規定している⼿続きに従って認定ユーザー（適格個人である必
                            要があり、お客様のために本サイトにアクセスすることをお客様によって承認されている⼈物です）
                            を本サイトのユーザーとして登録することができます。</li>
                        <li>認定ユーザーが、適格個人またはお客様のために本サイトにアクセスすることをお客様に承認
                            されている⼈物でなくなる場合、ユーザー管理者は、本サイトで規定している⼿続きに従って認定
                            ユーザーの登録を無効にする必要があります。</li>
                        <li>ユーザー管理者が適格個人またはお客様のために本サイトにアクセスしユーザー管理者として行動
                            することをお客様に承認されている⼈物でなくなる場合、お客様はPwCの電⼦メールアドレス宛に電⼦
                            メールを送信し、その旨を遅滞なくPwCに通知する必要があります。PwCは、当該ユーザー管理者
                            のアカウントを無効にする権利を有します。お客様は同時に、本サイトで規定している⼿続きに従って後
                            任のユーザー管理者（適格個人でなければなりません）を指名する必要があります。</li>
                        <li>PwCは、その独自の裁量により、本サイトへのお客様の登録申込みを承認または拒否する権利を有します。</li>
                    </ol>
                </li>
                <li class="list-item-bold">
                    <strong>利用期間</strong>
                    <ol>
                        <li>PwCが有料の会員サービスに対するお客様の登録申込みを承認する場合は、PwCは初期料金の請求
                            書をお客様に送付し、お客様は、請求書で指定されているいずれかの⽅法により、請求書
                            記載の期限までに当該⾦額を⽀払う必要があります。</li>
                        <li>PwCがお客様の登録申込みを承認した時点より当初の契約期間が開始し、本利用規約の条項に従って
                            初回の更新日より前に契約が終了しない限り、登録承認日から1年後の応当日に当初の契約期間が終
                            了することとします。上記に規定している日付までにお客様が初期料金を⽀払っていない場合は、
                            本利用規約および本サイトへのアクセスを当該日付をもって終了することとします。</li>
                        <li>お客様が有料の会員サービスを登録している場合は、PwCは、当該更新日から12カ⽉間の利用料⾦（以下「<strong>更新料金</strong>」といいます）の請求書をお客様に送付いたします。お客様が更新料金（適用ある税⾦を含みます）を請求書記載の期限までに⽀払っていない場合は、本利用規約（および本サイトへのアクセス）は当該更新日をもって終了することとします。</li>
                        <li>記載される料金はすべて税抜き金額です。お客様は、本契約により生じ、法令上お客様に課
                            せられる税金（消費税および地⽅消費税など）を所定の税率により⽀払う責任を負います。</li>
                    </ol>
                </li>
                <li class="list-item-bold">
                    <strong>本サイトで利用可能なサービス</strong>
                    <ol>
                        <li>PwCは、本利用規約の条項に従って本サイトをご提供いたします。</li>
                        <li>お客様の本サイトの登録により、ユーザー管理者および認定ユーザーに、
                            本サイトにおいてお客様が登録しているサービスレベルで利用可能と特定
                            されている本サイトの領域の利用が許可されます。</li>
                        <li>PwCが、本サイトで追加料⾦が必要となるサービスであると表示している場合、当該
                            サービスの利用は本サイトで規定している当該料金の支払いを条件とします。</li>
                        <li>PwCは、本サイト上でソフトウェアを提供する場合がありますが、その場合
                            のソフトウェアへのアクセスについては、本サイトで規定している追加的な利用
                            規約への同意を条件といたします。</li>
                        <li>PwCが適切であると判断する場合に、PwCは独自の裁量により本サイトのいかなる
                            部分から、いかなる資料をも随時削除する権利を有します。</li>
                    </ol>
                </li>
                <li class="list-item-bold">
                    <strong>研修資料&ndash;CPEポイント記録</strong>
                    <ol>
                        <li>本第7項は、ユーザー管理者または認定ユーザーが、本サイト上もしくは本サイトを経由
                            して取得できる研修資料、クイズ、ビデオ、その他の情報、またはダウンロードしたものを
                            利用する場合に適用することとします。</li>
                        <li>お客様、ユーザー管理者、または認定ユーザーは、本サイト上もしくは本サイトを経由
                            して取得できる研修モジュール、クイズ、ビデオ、その他の研修情報、またはダウンロー
                            ドしたものに関連する、自身の継続的専⾨研修制度のCPE単位記録を⾃⼰管理する責任
                            を負うこととします。</li>
                    </ol>
                </li>
                <li class="list-item-bold">
                    <strong>お客様の義務</strong>
                    <ol>
                        <li>本サイトを利用する場合、お客様は、本サイトに掲載されている手続きおよび要求事
                            項を遵守することとします。</li>
                        <li>お客様は、下記の事項のすべてに同意することとします。
                            <ol>
                                <li>本サイトおよび本サイトを利用して⼊⼿した情報を使用する場合に適用されるあらゆる
                                    法律、政令、省令、諸規則等および義務を遵守すること</li>
                                <li>本サイトのいずれの部分にもアプリケーション・プログラムまたはダウンロード可能
                                    なファイルを掲載しないこと</li>
                                <li>ウイルス、ワーム、有害な要素、または破損したデータが含まれているファイル、
                                    もしくはソフトウェアを本サイトにアップロードまたは掲載しないこと、本サイト上の
                                    正常な通信の流れを妨害しないこと、または他のお客様、個人、もしくは法⼈に悪
                                    影響を及ぼす⾏動をとらないこと</li>
                                <li>事前にPwCの書⾯による許可または関連する寄稿者もしくは他のお客様の書⾯による
                                    許可を得ずに、本サイトを経由して⼊⼿またはアクセスしたソフトウェア、ファイル、
                                    情報、コミュニケーション、またはその他コンテンツを、複製、配布、送信、公開、
                                    移転、もしくは商業的に利用しないこと</li>
                                <li>本サイトのいずれの情報または資料もマーケティング⽬的に使用しないこと</li>
                                <li>本サイトの正常な動作を妨害し、もしくは妨害を試みる⽬的または本サイト⼜はその⼀
                                    部のセキュリティ機能を回避する⽬的で、いかなる機器、ソフトウェア、またはルーティンをも
                                    使用しないこと</li>
                                <li>本サイトのインフラに不合理なまたは過剰な負荷をかける⾏為をしないこと</li>
                                <li>事前の書⾯によるPwCの同意を得ずに、本サイトのいずれの部分に対してのハイパーリンクをも含めないこと</li>
                                <li>ユーザー管理者または認定ユーザー用のユーザーネームおよびパスワードを第三者（ユーザー
                                    管理者または認定ユーザーを除く）に開⽰もしくは共有しないこと、また、本利用規約で許可
                                    されていない⽬的のためにユーザーネームもしくはパスワードを使用しないこと</li>
                                <li>安全な接続およびコンピューター・システムを活用して本サイトにすべての登録情報を提出すること</li>
                                <li>ユーザーアカウント、ユーザーネーム、またはパスワードの不正使用、
                                    もしくはセキュリティ違反が発⽣した場合、直ちにPwCに通知すること</li>
                                <li>お客様のすべてのプロフィール情報を最新のものに維持すること</li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li class="list-item-bold">
                    <strong>許容される利用</strong>
                    <ol>
                        <li>本条項において明⽰的に規定している場合を除いて、本サイトから⼊⼿した情報は、
                            ユーザー管理者および認定ユーザーのみが利用するためのものです。本利用規約において
                            明⽰的な記載がない限り、お客様、ユーザー管理者、または認定ユーザーは、PwCの書⾯
                            による許可なく当該情報を配布する、または他の⼈物もしくは法⼈に当該情報を利用
                            させることはできないこととします。</li>
                        <li>ユーザー管理者および認定ユーザーは、第9.3項および本サイトに表⽰されている特定
                            の情報のための追加的な制限に従って、本サイトから⼊⼿した情報を自身で利用するために1
                            個のハードコピーを作成することができます。ただし、当該複製は<strong>非恒常的</strong>になされるもので、
                            一度の利用において一回限りの抽出とします。お客様は、PwCまたは当該資料の所有者・権限者
                            の書⾯による明示的な許可なく、本サイト上でまたは本サイトを経由してアクセスした資料について、
                            派生物の作成、改変、公開、もしくは配布できないとします。</li>
                        <li>本サイトから⼊⼿した記事または抜粋を本条項に従って複製した場合、お客様は、
                            適切な著作権または所有権表示を当該記事もしくは抜粋の複製物に必ず行うこととします。</li>
                    </ol>
                </li>
                <li class="list-item-bold">
                    <strong>知的財産権</strong>
                    <ol>
                        <li>PwCおよびPwCのサプライヤーは、本サイト（本サイト上のまたは本サイトを経由して
                            提供されるソフトウェアおよびコンテンツを含みます）に対する財産権を保有することとします。
                            本サイトまたはコンテンツに含まれるいかなるものも、お客様に本サイトの財産権の保有
                            を与えるものではないこととします。</li>
                        <li>PwCまたはPwCのサプライヤーは、本サイトのために開発されたあらゆるシステム、技術、
                            技法、アイデア、コンセプト、情報、コンピレーション、およびノウハウの知的財産権を保
                            有することとし（著作権およびデータベースの権利を含みますがこれに限定されません）、
                            PwCは当該システム、技術、技法、アイデア、コンセプト、情報、コンピレーション、
                            およびノウハウを他のクライアントまたはお客様のために開発もしくは使用できることとします。</li>
                        <li>本利用規約で定めるとおり、PwCは本サイトにアクセスする権限をお客様に付与しますが、
                            PwCの商標、著作権、特許権、またはその他の財産権に基づくライセンスもしくは権利をお
                            客様に許諾するものではありません。本利用規約において明⽰的に記載されている場合を
                            除いて、お客様は本サイトのいずれかの部分（本サイト上でまたは本サイトにより提供
                            されるソフトウェアおよびコンテンツを含みます）をコピー、ダウンロード、不適切な引
                            用または無断転載することができません。</li>
                    </ol>
                </li>
                <li class="list-item-bold">
                    <strong>守秘義務</strong>
                    <ol>
                        <li>
                            お客様は、ユーザー管理者および認定ユーザー用のあらゆるユーザーネームおよびパスワードの
                            秘密保持に関する全責任を負うこととします。
                        </li>
                        <li>お客様は、お客様がお客様⾃⾝の最も機密性の⾼い情報の秘密保持をする場合と同等
                            またはそれ以上の注意をもって、本サイトの秘密保持をすることに同意することとします。
                            上記の守秘義務は、次の情報には適用されません。
                            <p>(1) 情報開⽰時点またはそれ以降にお客様以外の発信元を通して公知の情報の⼀部となるもの、
                                (2) 情報開⽰時点でお客様が既に知っていたもの、(3) 機密情報を利用または参照することなく
                                お客様が独⾃に開発するもの、(4) PwCまたはPwCのライセンサーに対する守秘義務の対象となら
                                ない第三者から⼊⼿したもの、(5) 裁判所命令、召喚状、または政府の権限に従って情報開⽰する
                                必要があり、お客様がPwCに情報開⽰前に通知するもの</p>
                        </li>
                    </ol>
                </li>
                <li class="list-item-bold">
                    <strong>利用停止および終了</strong>
                    <ol>
                        <li>
                            <p>PwCは、以下の場合に、本利用規約を終了させることなく、追加的な通知を⾏うまで本サイトの
                                全部または⼀部の提供を即時に停⽌することができます。</p>
                            <ol>
                                <li>本サイトを修復、メンテナンス、または改良するため</li>
                                <li>政府機関、緊急対策機関、またはその他の管轄権を有する司法、⾏政、規制当局の
                                    命令、指⽰、もしくは要求に従うため</li>
                                <li>本サイトが不正なまたは本契約のいずれかの条項を違反するような⽅法で使用
                                    されているとPwCが信じる合理的な理由がある場合</li>
                            </ol>
                        </li>
                        <li>
                            <p>PwCは、以下の場合に、お客様の電⼦メールアドレスに通知することにより、
                                いつでも直ちに本利用規約を終了することができます。</p>
                            <ol>
                                <li>お客様が本利用規約に違反しており、是正可能なものである場合に、是正
                                    を求めるお客様の電⼦メールアドレス宛の通知の日付から14日以内に是正がされなかった場合</li>
                                <li>お客様が破産、特別清算、⺠事再⽣⼿続、会社更⽣⼿続のいずれかの申⽴を受けるか、
                                    ⼜は⾃らこれらの申⽴を⾏った場合</li>
                                <li>お客様の電⼦メールアドレスに2日前までに契約終了を通知する場合</li>
                            </ol>
                        </li>
                        <li>お客様はPwCの電⼦メールアドレスに契約終了の効力が生じる日を通知
                            することによりいつでも本利用規約を終了することができます。</li>
                        <li>PwCが本利用規約の第12.2.3項に従って本利用規約を終了する場合は、PwCは、
                            お客様がすでに⽀払った料金のうち、当該終了の効⼒発⽣日から次回の更新日までの
                            期間に対する按分額をご返金いたします。その他の理由で契約を終了する場合、
                            お客様は料金（またはその⼀部）の返金を受けることはできません。</li>
                        <li>第12.6項に従って、各当事者の権利および義務は本利用規約の終了をもって直ちに終了しますが、
                            当該終了は契約終了日までに生じていた当事者の権利および義務に影響を与えないこととします。</li>
                        <li>いかなる理由による本利用規約の終了にもかかわらず（お客様またはPwCによる
                            本利用規約の根本的な違反を含みますがこれに限定されません）、本利用規約の第1
                            項、第9.2項、第9.3項、第10項、第11項、および第13項は引き続き効⼒を有することとします。</li>
                    </ol>
                </li>
                <li class="list-item-bold">
                    <strong>準拠法</strong>
                    <ol>
                        <li><strong>本利用規約および本利用規約から⽣じる当事者間のあらゆる関係（不法⾏為
                            またはそれに相当する関係を含みます）は、日本法に準拠することとします。</strong></li>
                        <li><strong>本利用規約に起因するまたは関連する紛争を解決するために、東京地⽅裁判
                            所を第一審の専属的合意管轄裁判所とします。</strong></li>
                    </ol>
                </li>
                <li class="list-item-bold">
                    <strong>PwCに関する情報</strong>
                    <ol>
                        <li>PwC Japanグループの各法人は、<a href="https://www.pwc.com/jp/ja/about-us/member.html" target="_blank" rel="noreferrer">
                            https://www.pwc.com/jp/ja/about-us/member.html</a>に掲載されています。
                            PwCグローバルネットワークについては<a href="https://www.pwc.com/jp/ja/about-us/global.html" target="_blank" rel="noreferrer">
                                https://www.pwc.com/jp/ja/about-us/global.html</a>に掲載されています。</li>
                        <li>
                            お客様からPwCへのご連絡は、<a href="mailto:jp_aarata_viewpoint-mbx@pwc.com" target="_blank" rel="noreferrer">
                                jp_aarata_viewpoint-mbx@pwc.com</a>宛に電子メールにより行っていただくことが可能です。</li>
                    </ol>
                </li>
                <li class="list-item-bold">
                    <strong>本利用規約の承継</strong>
                    <p>PwCが本利用規約に基づくPwCの権利および義務を移転を第三者に譲渡する場合、PwCは、
                        PwCが所有する本利用規約およびサービスに関連するあらゆる情報、記録、およびその他のデ
                        ータを被譲渡者に引き渡す権利および義務を有していますが、適用される守秘義務に従って記
                        録保持の⽬的のためコピーを1部保持することができることとします。</p>
                </li>
                <li class="list-item-bold">
                    <strong>一般条項</strong>
                    <ol>
                        <li>
                            <p>本利用規約とその他の文書との間に⽭盾抵触が⽣じた場合は、それぞれ以下のとおりといたします。</p>
                            <ol>
                                <li>本サイトにおいて規定している手続きまたは要求事項と本利用規約の条項との間に⽭盾抵
                                    触があった場合においては、本利用規約の条項が優先されることとします。</li>
                                <li>個⼈情報保護⽅針の条項と本利用規約の条項との間に⽭盾抵触があった場合においては、
                                    本利用規約の条項が優先されることとします。</li>
                                <li>第1項と本利用規約のその他の条項との間に⽭盾抵触があった場合においては、
                                    第1項が優先されることとしま  す。</li>
                            </ol>
                        </li>
                        <li>両当事者は、両当事者の責めに帰すことができない理由による遅延または不履行（システム障
                            害、⽕災、洪⽔、天災、政府または超国家的権⼒の⾏為もしくは規制、戦争、暴動、ストライキ、
                            ロックアウト、および労使紛争を含みますがこれらに限定されません）において、本利用規約に基づく
                            両当事者の義務の履⾏の遅延または不履行について相手方当事者への責任を負わないこととします。</li>
                        <li>&lt;意図的に削除&gt;</li>
                        <li>お客様は、事前の書⾯によるPwCの同意なしに、本利用規約に基づく権利または義務を譲渡、
                            移譲、委託、または依頼をできないこととします。PwCは、お客様の電⼦メールアドレスに本利
                            用規約の承継を通知する電⼦メールを送信することにより、いつでもPwC加盟法人に本利用規約
                            を譲渡または承継することができることとします。お客様が、当該承継または譲渡を承認しない
                            場合は、第12項に従って本利用規約を終了することができます。</li>
                        <li>文書によるものでない限り、本利用規約のいずれの条項の権利放棄も有効とはならないこととします。</li>
                        <li>本利用規約に明⽰的に規定されている場合を除いて、通知は、直接⼿渡し、または、相手方
                            当事者の住所（PwC宛てに送信する通知の場合）または登録申込みで指定された住所（お客様
                            宛てに送信する通知の場合）に対して、郵便もしくはファックスによる送付のいずれかの⽅法
                            で⾏うこととします。通知を郵便で送付する場合は、送付後48時間後に到達したものとみなします。
                            ファックスで送信するまたは手渡しにより通知する場合は、発送後の最初の営業日に到達したものとみなします。</li>
                        <li>明⽰的または黙示的に本利用規約終了後又は満了後も存続することが意図された本利用規約の条項は、
                            有効に存続し両当事者を拘束します。</li>
                        <li>本利用規約の条項の全部または⼀部が無効であると判断された場合は、当該条項は本利用規約
                            の⼀部を構成しないものとみなします。いかなる場合も、本利用規約の残りの部分の効⼒は影響
                            を受けないものとします。</li>
                        <li>本サイトの参照⽂書またはページを含む本利用規約は、本サイトに関連する両当事者の完全なる合意
                            を構成しています。本利用規約は、文書または⼝頭による従前の提案、やり取り、理解、もしくはその
                            他のコミュニケーションから置き換わり、これらに優先することとします（PwCが⾏った詐欺的な契約締
                            結前の不当表⽰についてお客様が信頼していたことを⽰すことが可能なものに対する責任を負うことを除く）。
                            本利用規約には便宜的に⾒出しおよびタイトルを使用していますが、これらは本利用規約の⼀部を形成
                            するものではありません。</li>
                    </ol>
                </li>
                <li class="list-item-bold">
                    <strong>定義</strong>
                    <p>本利用規約においては次のように定義することとします。</p>
                    <ol>
                        <li class="no-list-item">
                            <ol>
                                <li>「<strong>ユーザー管理者</strong>」は、第4.2項で規定している意味を有します。</li>
                                <li>「<strong>認定ユーザー</strong>」は、第4.2項または第4.3項に従ってお客様のために本サ
                                    イトへアクセスすることを承認されている、適格個人を意味します。
                                </li>
                                <li>「<strong>電⼦メールアドレス</strong>」は、次の意味を有します。
                                    <p>(1) PwCについては、本サイトで随時指定される電⼦メールアドレス</p>
                                    <p>(2) お客様については、本サイトの登録情報の⼀部として表⽰される電⼦メールアドレス</p>
                                </li>
                                <li>「<strong>料金</strong>」は、初期料金または更新料金を意味します。</li>
                                <li>「<strong>グループ</strong>」はお客様、お客様の親会社、および親会社の⼦会社および関連
                                    会社を随時意味します（この定義で用いられている「親会社」および「関連会社」は、会社法第
                                    2条第3号および第4号で規定されている意味を有します）。</li>
                                <li>「<strong>初期料金</strong>」は、お客様との間で、(1)第4.2項に従い、または(2)お客様
                                    の登録以前に合意している料金を意味します。
                                </li>
                                <li>「<strong>PwC加盟法人</strong>」は、PwCおよびPricewaterhouseCoopersの名称の全部
                                    または⼀部を含む名称の下で事業を⾏うその他の企業（法⼈化されているかどうかにかかわらず）
                                    もしくはPricewaterhouseCoopersネットワーク内の企業（または提携もしくは関連企業）を意味します。
                                </li>
                                <li>「<strong>適格個人</strong>」は、次の意味を有します。
                                    <p>(1) ユーザー管理者については、お客様の従業員、取締役、役員、パートナー、または社⻑
                                    </p>
                                    <p>(2) 認定ユーザーについては、お客様のグループの従業員、取締役、役員、パートナー、または社⻑
                                    </p>
                                    <p>それぞれの場合において、第3項に従って本サイトを使用する資格を有する⼈物とします。</p>
                                </li>
                                <li>「<strong>更新日</strong>」は、登録承認日の日付から1年後の応当日を意味します。
                                </li>
                                <li>「<strong>更新料金</strong>」は、第5.3項で規定されている意味を有します。</li>
                                <li>「<strong>会員サービス</strong>」は、本サイトで随時会員向けサービス（無料登録会員および有
                                    料登録会員）として説明されているサービスを意味します。</li>
                                <li>「<strong>下請業者</strong>」は、PwCがサービスを外注する専⾨的なサービス企業を意味
                                    します（PwC加盟法人を含みますがこれに限定されません）。</li>
                                <li>「<strong>登録承認日</strong>」は、PwCが会員サービスへのお客様の登録を承認した日を意味します。</li>
                                <li>「<strong>システム障害</strong>」は、本サイトの全部または⼀部の損失が発⽣する、
                                    もしくは本サイトを利用できない状況、本サイトの通常の動作、タスク・パフォーマンス、
                                    または機能を妨げる状況、またはPwCに同様の影響を与える事象もしくは状況を意味します。</li>
                                <li>「<strong>本サイト</strong>」は、ViewpointのURLであるviewpoint.pwc.com（日本
                                    サイトは<a href="https://viewpoint.pwc.com/jp/ja.html" target="_blank" rel="noreferrer">
                                        https://viewpoint.pwc.com/jp/ja.html</a>）または随時それに置き換えられる
                                    可能性があるURLを意味します。お申込み時に「利用規約に同意する」のチェックボックスにチェックを入れることにより、
                                    お客様は本利用規約に拘束されることに同意し、個⼈情報保護⽅針に規定されている⽬的のための個⼈情報の収集、処理、
                                    使用、および開⽰（国際移転を含みます）を承諾することとします。本サイトのフィードバック機能を使
                                    用して電⼦メールでメッセージを送信することにより、いつでもPwCからの営業のご案内の受信の登
                                    録および同意を撤回することができます。
                                </li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li class="list-item-bold">
                    <p><span class="bg-color-yellow"><strong>個人情報保護方針</strong></span></p>
                    <p><span class="bg-color-yellow">PwCへご提供いただいたお客様の個⼈情報は、個人情報保護方針に従って適切に保護されます。</span></p>
                </li>
            </ol>
        </div>
    );
}
export default TermsConditionsUS;