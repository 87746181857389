import Button from "appkit-react/lib/Button/Button";
import Header from "../../../../Header";
import Footer from "../../../../Footer";
import SupportBanner from "../../SupportBanner";
import "./Success.css";

export const Success = (props) => {
    const { title, subtitle, description, action, image } = props;

    const buttonClasses = `${
        action.wide ? "wide-success-button" : "success-button"
    } m-b-80 m-t-15`;

    return (
        <>
            <Header />
            <div className="flex-column m-b-300 darker">
                <div className="m-t-80 m-b-30 gray-text-32 third-bold">
                    <span className="appkiticon a-font-32 green-fix icon-confirmed-fill m-r-6"></span>
                    {title}
                </div>
                {subtitle && (
                    <div className="m-b-20 text-18 font-normal w-588 center">
                        {subtitle}
                    </div>
                )}
                {description && (
                    <div className="m-b-58 text-18 font-normal w-588 center">
                        {description}
                    </div>
                )}
                {action && (
                    <Button
                        aria-label="Loading"
                        kind="primary"
                        size="lg"
                        className={buttonClasses}
                        onClick={action.onClick}
                    >
                        {action.text}
                    </Button>
                )}
                {image && (
                    <img
                        src={image.src}
                        alt="Success"
                        className="p-b-75"
                        style={{ width: image.width }}
                    />
                )}
            </div>
            <SupportBanner />
            <Footer />
        </>
    );
};
