import { useNavigate } from "react-router-dom";
import pwcLogo from "../assets/pwc.svg";
import verticalLine from "../assets/vertical-line.svg";
import { gotoRoot } from "../Routes";
import "./Header.css";

const Header = ({ text, notClickable }) => {
    const titleText = text ? text : "Software Licensing Platform";
    const classes = notClickable
        ? "responsive pwc-logo"
        : "responsive pwc-logo pointer";
    const navigate = useNavigate();
    const gotoLanding = notClickable
        ? null
        : () => {
              gotoRoot(navigate);
          };

    return (
        <div className="container-fluid">
            <div className="flex-center p-t-32">
                <img
                    src={pwcLogo}
                    className={classes}
                    alt="PwC Logo"
                    onClick={gotoLanding}
                />
                <img
                    src={verticalLine}
                    className="m-h-16"
                    alt="Vertical Line"
                />
                <div className="font-header">{titleText}</div>
            </div>
        </div>
    );
};

export default Header;
