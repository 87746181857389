import Button from "appkit-react/lib/Button/Button";
import { useNavigate } from "react-router-dom";
import { gotoSubmitAFS, gotoSubmitOrder } from "../../../../Routes";
import { TAB } from "./Constants";

const OrderOptionsButton = (props) => {
    const {
        product,
        opportunityId,
        client,
        endDate,
        useSecondaryAction,
        tabId,
        setRenewModalData,
        setIsRenewModalVisible,
        setIsOfflineContractingModalVisible
    } = props;

    const navigate = useNavigate();

    const afsButton = {
        label: "SUBMIT NEW AFS",
        className: "submit-afs-button",
        action: () => gotoSubmitAFS(navigate, product.id, opportunityId)
    };

    const buttons = {
        [TAB.active.id]: afsButton,
        [TAB.pending.id]: afsButton,
        [TAB.afs.id]: afsButton,
        [TAB.draft.id]: {
            label: "SUBMIT ORDER TO CLIENT",
            className: "submit-to-client-button",
            // we use the same button for two different actions:
            //   primary action - submit the order to the client
            //   secondary action - show an offline contrctacting requrired modal
            action: (useSecondaryAction) => {
                if (useSecondaryAction) {
                    setIsOfflineContractingModalVisible(true);
                } else {
                    gotoSubmitOrder(navigate, product.id, opportunityId);
                }
            }
        },
        [TAB.renewal.id]: {
            label: "RENEW SUBSCRIPTION",
            className: "renew-button",
            action: () => {
                setRenewModalData({
                    product: product,
                    client: client,
                    opportunityId: opportunityId,
                    endDate: endDate
                });
                setIsRenewModalVisible(true);
            }
        }
    };

    return (
        <div>
            <Button
                className={buttons[tabId].className}
                size="sm"
                onClick={() => buttons[tabId].action(useSecondaryAction)}
            >
                {buttons[tabId].label}
            </Button>
        </div>
    );
};

export default OrderOptionsButton;
