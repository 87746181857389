import { BrowserRouter as Router } from "react-router-dom";
import "./SomethingWentWrong.css";
import Header from "../Header";
import { NotSupportedPage } from "../products/layout/contract/StatusPage";
import SupportBanner from "../products/layout/SupportBanner";
import Footer from "../Footer";

const NotSupportedApp = (props) => {
    return (
        <Router>
            <Header />
            <NotSupportedPage />
            <SupportBanner />
            <Footer />
        </Router>
    );
};

export default NotSupportedApp;
