import { CONTRACT_TYPE } from "../../Constants";
const CN = {
    [CONTRACT_TYPE.v1ContractCN]: [
        "The subscription tiers available for the Offering and the applicable fees are set forth in the table below, with the subscription tier applicable to the provision of the Offering under this Order set forth in the Subscription Tier.",
        "Starter@$$$@•　　Number of Projects Permitted Per Year: Up to 3@$$$@•　　User Account Limit: Up to 50@$$$@•　　Number of permitted custom exportable reports: 0@$$$@•　　Annual Fee: $100,000",
        "Medium@$$$@•　　Number of Projects Permitted Per Year: Up to 5@$$$@•　　User Account Limit: Up to 50@$$$@•　　Number of permitted custom exportable reports: 1@$$$@•　　Annual Fee: $125,000",
        "Large@$$$@•　　Number of Projects Permitted Per Year: Up to 10@$$$@•　　User Account Limit: Up to 100@$$$@•　　Number of permitted custom exportable reports: 2@$$$@•　　Annual Fee: $150,000",
        "Deluxe@$$$@•　　Number of Projects Permitted Per Year: Up to 20@$$$@•　　User Account Limit: Up to 100@$$$@•　　Number of permitted custom exportable reports: 3@$$$@•　　Annual Fee: $200,000",
        "Enterprise@$$$@•　　Number of Projects Permitted Per Year: Unlimited@$$$@•　　User Account Limit: Up to 200@$$$@•　　Number of permitted custom exportable reports: 4@$$$@•　　Annual Fee: $250,000",
        "Client will pay the applicable annual fee for the Subscription Tier.",
        "During the Initial Term or a given Renewal Term, as applicable, Client may not decrease the applicable Subscription Tier. In the event that Client increases its Subscription Tier, additional fees (as set forth in the above table) for the applicable quarter will be pro-rated as of the date of increase.",
        "If Client exceeds the applicable User Account Limit in a given month, then PwC will invoice Client for each fifty (50) additional User Accounts (or fraction thereof) in excess of such limit at the rate of $20,000/year for the remainder of the Term (with such additional User Accounts then added to the User Account Limit). Client may request removal of any User Accounts in excess of the then-current User Account Limit no later than five (5) days prior to the end of a given month to avoid being charged for such excess User Accounts.  Notwithstanding the foregoing, as between PwC and Client, it is Client’s sole responsibility to not exceed the then-current User Account Limit during the Term.",
        "Fees for the Offering will be invoiced quarterly in advance, provided that to the extent that Client increases its Subscription Tier or maximum number of permitted User Accounts during the Initial Term or a Renewal Term, as applicable, PwC will issue additional invoices for any applicable amounts due based on any such increase.",
        "Notwithstanding anything to the contrary in this Order, fees for the Offering after the Initial Term will be at PwC’s then-current pricing. PwC will notify Client of any changes to such fees for an upcoming Renewal Term at least sixty (60) days prior to the start of such Renewal Term.",
        "Client will pay PwC amounts due within thirty (30) days after the date of invoice."
    ],
    [CONTRACT_TYPE.v2ContractCN]: [
        "The subscription tiers available for the Offering and the applicable fees are set forth in the table below, with the subscription tier applicable to the provision of the Offering under this Order set forth in the Subscription Tier.",
        "Starter@$$$@•　　Number of Projects Permitted Per Year: Up to 3@$$$@•　　User Account Limit: Up to 50@$$$@•　　Number of permitted custom exportable reports: 0@$$$@•　　Annual Fee: $100,000",
        "Medium@$$$@•　　Number of Projects Permitted Per Year: Up to 5@$$$@•　　User Account Limit: Up to 50@$$$@•　　Number of permitted custom exportable reports: 1@$$$@•　　Annual Fee: $125,000",
        "Large@$$$@•　　Number of Projects Permitted Per Year: Up to 10@$$$@•　　User Account Limit: Up to 100@$$$@•　　Number of permitted custom exportable reports: 2@$$$@•　　Annual Fee: $150,000",
        "Deluxe@$$$@•　　Number of Projects Permitted Per Year: Up to 20@$$$@•　　User Account Limit: Up to 100@$$$@•　　Number of permitted custom exportable reports: 3@$$$@•　　Annual Fee: $200,000",
        "Enterprise@$$$@•　　Number of Projects Permitted Per Year: Unlimited@$$$@•　　User Account Limit: Up to 200@$$$@•　　Number of permitted custom exportable reports: 4@$$$@•　　Annual Fee: $250,000",
        "Client will pay the applicable annual fee for the Subscription Tier.",
        "During the Initial Term or a given Renewal Term, as applicable, Client may not decrease the applicable Subscription Tier. In the event that Client increases its Subscription Tier, additional fees (as set forth in the above table) for the applicable quarter will be pro-rated as of the date of increase.",
        "If Client exceeds the applicable User Account Limit in a given month, then PwC will invoice Client for each fifty (50) additional User Accounts (or fraction thereof) in excess of such limit at the rate of $20,000/year for the remainder of the Term (with such additional User Accounts then added to the User Account Limit). Client may request removal of any User Accounts in excess of the then-current User Account Limit no later than five (5) days prior to the end of a given month to avoid being charged for such excess User Accounts.  Notwithstanding the foregoing, as between PwC and Client, it is Client’s sole responsibility to not exceed the then-current User Account Limit during the Term.",
        "Fees for the Offering will be invoiced quarterly in advance, provided that to the extent that Client increases its Subscription Tier or maximum number of permitted User Accounts during the Initial Term or a Renewal Term, as applicable, PwC will issue additional invoices for any applicable amounts due based on any such increase.",
        "Notwithstanding anything to the contrary in this Order, fees for the Offering after the Initial Term will be at PwC’s then-current pricing. PwC will notify Client of any changes to such fees for an upcoming Renewal Term at least sixty (60) days prior to the start of such Renewal Term.",
        "Client will pay PwC amounts due within thirty (30) days after the date of invoice."
    ],
    [CONTRACT_TYPE.v3ContractCN]: [
        "The subscription tiers available for the Offering and the applicable fees are set forth in the table below, with the subscription tier applicable to the provision of the Offering under this Order set forth in the Subscription Tier.",
        "Starter@$$$@•　　Number of Projects Permitted Per Year: Up to 3@$$$@•　　User Account Limit: Up to 50@$$$@•　　Number of permitted custom exportable reports: 0@$$$@•　　Annual Fee: $100,000",
        "Medium@$$$@•　　Number of Projects Permitted Per Year: Up to 5@$$$@•　　User Account Limit: Up to 50@$$$@•　　Number of permitted custom exportable reports: 1@$$$@•　　Annual Fee: $125,000",
        "Large@$$$@•　　Number of Projects Permitted Per Year: Up to 10@$$$@•　　User Account Limit: Up to 100@$$$@•　　Number of permitted custom exportable reports: 2@$$$@•　　Annual Fee: $150,000",
        "Deluxe@$$$@•　　Number of Projects Permitted Per Year: Up to 20@$$$@•　　User Account Limit: Up to 100@$$$@•　　Number of permitted custom exportable reports: 3@$$$@•　　Annual Fee: $200,000",
        "Enterprise@$$$@•　　Number of Projects Permitted Per Year: Unlimited@$$$@•　　User Account Limit: Up to 200@$$$@•　　Number of permitted custom exportable reports: 4@$$$@•　　Annual Fee: $250,000",
        "Client will pay the applicable annual fee for the Subscription Tier.",
        "During the Initial Term or a given Renewal Term, as applicable, Client may not decrease the applicable Subscription Tier. In the event that Client increases its Subscription Tier, additional fees (as set forth in the above table) for the applicable quarter will be pro-rated as of the date of increase.",
        "If Client exceeds the applicable User Account Limit in a given month, then PwC will invoice Client for each fifty (50) additional User Accounts (or fraction thereof) in excess of such limit at the rate of $20,000/year for the remainder of the Term (with such additional User Accounts then added to the User Account Limit). Client may request removal of any User Accounts in excess of the then-current User Account Limit no later than five (5) days prior to the end of a given month to avoid being charged for such excess User Accounts.  Notwithstanding the foregoing, as between PwC and Client, it is Client’s sole responsibility to not exceed the then-current User Account Limit during the Term.",
        "Fees for the Offering will be invoiced quarterly in advance, provided that to the extent that Client increases its Subscription Tier or maximum number of permitted User Accounts during the Initial Term or a Renewal Term, as applicable, PwC will issue additional invoices for any applicable amounts due based on any such increase.",
        "Notwithstanding anything to the contrary in this Order, fees for the Offering after the Initial Term will be at PwC’s then-current pricing. PwC will notify Client of any changes to such fees for an upcoming Renewal Term at least sixty (60) days prior to the start of such Renewal Term.",
        "Client will pay PwC amounts due within thirty (30) days after the date of invoice."
    ],
    [CONTRACT_TYPE.v4ContractCN]: [
        "The subscription tiers available for the Offering and the applicable fees are set forth in the table below, with the subscription tier applicable to the provision of the Offering under this Order set forth in the Subscription Tier.",
        "Starter@$$$@•　　Number of Projects Permitted Per Year: Up to 3@$$$@•　　User Account Limit: Up to 50@$$$@•　　Number of permitted custom exportable reports: 0@$$$@•　　Annual Fee: $100,000",
        "Medium@$$$@•　　Number of Projects Permitted Per Year: Up to 5@$$$@•　　User Account Limit: Up to 50@$$$@•　　Number of permitted custom exportable reports: 1@$$$@•　　Annual Fee: $125,000",
        "Large@$$$@•　　Number of Projects Permitted Per Year: Up to 10@$$$@•　　User Account Limit: Up to 100@$$$@•　　Number of permitted custom exportable reports: 2@$$$@•　　Annual Fee: $150,000",
        "Deluxe@$$$@•　　Number of Projects Permitted Per Year: Up to 20@$$$@•　　User Account Limit: Up to 100@$$$@•　　Number of permitted custom exportable reports: 3@$$$@•　　Annual Fee: $200,000",
        "Enterprise@$$$@•　　Number of Projects Permitted Per Year: Unlimited@$$$@•　　User Account Limit: Up to 200@$$$@•　　Number of permitted custom exportable reports: 4@$$$@•　　Annual Fee: $250,000",
        "Client will pay the applicable annual fee for the Subscription Tier.",
        "During the Initial Term or a given Renewal Term, as applicable, Client may not decrease the applicable Subscription Tier. In the event that Client increases its Subscription Tier, additional fees (as set forth in the above table) for the applicable quarter will be pro-rated as of the date of increase.",
        "If Client exceeds the applicable User Account Limit in a given month, then PwC will invoice Client for each fifty (50) additional User Accounts (or fraction thereof) in excess of such limit at the rate of $20,000/year for the remainder of the Term (with such additional User Accounts then added to the User Account Limit). Client may request removal of any User Accounts in excess of the then-current User Account Limit no later than five (5) days prior to the end of a given month to avoid being charged for such excess User Accounts.  Notwithstanding the foregoing, as between PwC and Client, it is Client’s sole responsibility to not exceed the then-current User Account Limit during the Term.",
        "Fees for the Offering will be invoiced quarterly in advance, provided that to the extent that Client increases its Subscription Tier or maximum number of permitted User Accounts during the Initial Term or a Renewal Term, as applicable, PwC will issue additional invoices for any applicable amounts due based on any such increase.",
        "Notwithstanding anything to the contrary in this Order, fees for the Offering after the Initial Term will be at PwC’s then-current pricing. PwC will notify Client of any changes to such fees for an upcoming Renewal Term at least sixty (60) days prior to the start of such Renewal Term.",
        "Client will pay PwC amounts due within thirty (30) days after the date of invoice."
    ],
    [CONTRACT_TYPE.v5ContractCN]: [
        "The subscription tiers available for the Offering and the applicable fees are set forth in the table below, with the subscription tier applicable to the provision of the Offering under this Order set forth in the Subscription Tier.",
        "Starter@$$$@•　　Number of Projects Permitted Per Year: Up to 3@$$$@•　　User Account Limit: Up to 50@$$$@•　　Number of permitted custom exportable reports: 0@$$$@•　　Annual Fee: $100,000",
        "Medium@$$$@•　　Number of Projects Permitted Per Year: Up to 5@$$$@•　　User Account Limit: Up to 50@$$$@•　　Number of permitted custom exportable reports: 1@$$$@•　　Annual Fee: $125,000",
        "Large@$$$@•　　Number of Projects Permitted Per Year: Up to 10@$$$@•　　User Account Limit: Up to 100@$$$@•　　Number of permitted custom exportable reports: 2@$$$@•　　Annual Fee: $150,000",
        "Deluxe@$$$@•　　Number of Projects Permitted Per Year: Up to 20@$$$@•　　User Account Limit: Up to 100@$$$@•　　Number of permitted custom exportable reports: 3@$$$@•　　Annual Fee: $200,000",
        "Enterprise@$$$@•　　Number of Projects Permitted Per Year: Unlimited@$$$@•　　User Account Limit: Up to 200@$$$@•　　Number of permitted custom exportable reports: 4@$$$@•　　Annual Fee: $250,000",
        "Client will pay the applicable annual fee for the Subscription Tier.",
        "During the Initial Term or a given Renewal Term, as applicable, Client may not decrease the applicable Subscription Tier. In the event that Client increases its Subscription Tier, additional fees (as set forth in the above table) for the applicable quarter will be pro-rated as of the date of increase.",
        "If Client exceeds the applicable User Account Limit in a given month, then PwC will invoice Client for each fifty (50) additional User Accounts (or fraction thereof) in excess of such limit at the rate of $20,000/year for the remainder of the Term (with such additional User Accounts then added to the User Account Limit). Client may request removal of any User Accounts in excess of the then-current User Account Limit no later than five (5) days prior to the end of a given month to avoid being charged for such excess User Accounts.  Notwithstanding the foregoing, as between PwC and Client, it is Client’s sole responsibility to not exceed the then-current User Account Limit during the Term.",
        "Fees for the Offering will be invoiced quarterly in advance, provided that to the extent that Client increases its Subscription Tier or maximum number of permitted User Accounts during the Initial Term or a Renewal Term, as applicable, PwC will issue additional invoices for any applicable amounts due based on any such increase.",
        "Notwithstanding anything to the contrary in this Order, fees for the Offering after the Initial Term will be at PwC’s then-current pricing. PwC will notify Client of any changes to such fees for an upcoming Renewal Term at least sixty (60) days prior to the start of such Renewal Term.",
        "Client will pay PwC amounts due within thirty (30) days after the date of invoice."
    ]
};
export default CN;
