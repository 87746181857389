import Select from "appkit-react/lib/Select/Select";
import LabelErrorWrapper, {
    WRAPPED_COMPONENT_TYPE
} from "./LabelErrorWrapper.js";
import "./WrappedSelect.css";

const WrappedSelect = (props) => {
    const wrappedSelect = (
        <Select
            name={props.name}
            onSelect={props.onChange}
            placeholder={props.placeholder ? props.placeholder : "SELECT"}
            type={WRAPPED_COMPONENT_TYPE.SELECT}
            value={props.value}
            multiple={props.multiple}
        >
            {props.options}
        </Select>
    );

    return (
        <LabelErrorWrapper
            name={props.name}
            wrappedComponent={wrappedSelect}
            label={props.label}
            hasError={props.hasError}
            errorMessage={props.errorMessage}
            primary={props.primary}
        />
    );
};

export default WrappedSelect;
