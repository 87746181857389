import { useNavigate, useParams } from "react-router-dom";
import { Success } from "./Success";
import teamWork from "../../../../../assets/teamWork.svg";
import { formatDate } from "../../../../common/Util";
import { gotoViewOrder } from "../../../../../Routes";
import useProduct from "../../common/UseProduct";

const CancelledRenewal = () => {
    const product = useProduct();
    const { opportunityId, clientName, cancellationDate } = useParams();

    const subtitle = `The ${
        product.label
    } renewal for  ${clientName} has been cancelled. 
    The client subscription will end on ${formatDate(
        decodeURIComponent(cancellationDate)
    )}.`;

    const image = { src: teamWork, width: "246px" };

    const navigate = useNavigate();
    const action = {
        text: "VIEW ORDER",
        onClick: () => gotoViewOrder(navigate, product.id, opportunityId)
    };

    return (
        <Success
            title="Renewal cancelled"
            subtitle={subtitle}
            description={``}
            image={image}
            action={action}
        />
    );
};

export default CancelledRenewal;
