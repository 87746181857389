import LoadingOrDisplay from "../../../../../../common/LoadingOrDisplay";
import { formatNumber } from "../../../../../../common/Util";

// TODO : stadardize these fields? so that this object is not necessary
export const WFO_OPTION_TO_FIELD = {
    "Activation Management/Customer Success": {
        id: "serviceAAMCSHProdId"
    },
    "Standard Tableau Dashboard": {
        id: "serviceSTDProdId"
    },
    "Custom Tableau Dashboard": {
        id: "serviceCTDProdId"
    },
    "Data Extraction to CSV": {
        id: "serviceDECProdId"
    },
    "Product Services": {
        id: "servicePSProdId"
    },
    "WO Experience Templates": {
        id: "serviceWETProdId"
    }
};

const InitialTerm = ({ props, loading }) => {
    const { formState, formData } = props;

    const selectedModules = [];
    let i = 0;
    for (const module of formData.serviceModule) {
        selectedModules.push(
            <li key={i++} className="gray-text-10 half-bold">
                {module}
            </li>
        );
    }

    return (
        <div>
            <div className="round-card-black-top">
                <div className="p-b-16">
                    <span className="appkiticon icon-calendar-outline a-font-24 a-text-red p-r-16"></span>
                    <span className="text-16 bold">Initial Term</span>
                </div>
                <div className="light-gray-text-12">
                    <div className="row g-0">
                        <div className="col-9 m-v-8 gy-0">
                            Annual Subscription Fee
                        </div>
                        <div className="col-3 m-v-8 gy-0 right">
                            <LoadingOrDisplay
                                loading={loading}
                                display={formatNumber(formState.annualFees)}
                            />
                        </div>
                    </div>
                </div>
                <div className="light-gray-text-12">
                    <div className="row g-0">
                        <div className="col-9 m-v-8 gy-0">
                            Total Number of Users
                        </div>
                        <div className="col-3 m-v-8 gy-0 right">
                            {formatNumber(formData.numberOfUsers, "")}
                        </div>
                    </div>
                </div>
                {selectedModules.length > 0 && (
                    <>
                        <div className="light-gray-text-12">
                            <div className="row g-0">
                                <div className="col-9 gy-0">
                                    Service Module(s):
                                </div>
                                <div className="col-3 gy-0 right">-</div>
                            </div>
                        </div>
                        <ul className="module-list">{selectedModules}</ul>
                        <div className="orange text-10 col-9 p-b-8">
                            Service Module fees will be calculated and billed
                            separately on a quarterly basis.
                        </div>
                    </>
                )}
                <div className="light-gray-text-12">
                    <div className="row g-0">
                        <div className="col-9 m-v-8 gy-0">
                            Communication Channel:
                            <div className="gray-text-10 half-bold">
                                {formData.communicationChannel}
                            </div>
                        </div>
                        <div className="col-3 m-v-8 gy-0 right">Included</div>
                    </div>
                </div>
            </div>
            <div className="black-background-rounded">
                <div className="row">
                    <span className="col">Subtotal before tax</span>
                    <span className="col right">
                        <LoadingOrDisplay
                            loading={loading}
                            display={formatNumber(formState.annualFees)}
                        />{" "}
                        USD
                    </span>
                </div>
            </div>
        </div>
    );
};

export default InitialTerm;
