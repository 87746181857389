import Header from "./Header";
import Viewpoint from "./Viewpoint";
import Footer from "./Footer";

const ViewpointApp = () => {
    return (
        <div>
            <Header />
            <Viewpoint />
            <Footer />
        </div>
    );
}

export default ViewpointApp;