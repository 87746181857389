import client from "../../../../../assets/client.svg";

const ClientIconHeader = () => {
    return (
        <div className="review-icon-container col-6">
            <div className="step-icon active-icon review-icon">
                <img src={client} alt="Client Icon"></img>
            </div>
            <span className="p-l-8 text-22 half-bold">Client</span>
        </div>
    );
};

export default ClientIconHeader;
