import { useParams } from "react-router";
import { useEffect, useState, createContext } from "react";

import useProduct from "../common/UseProduct";

import Header from "../../../Header";
import Footer from "../../../Footer";

import { getOrderDetails } from "../../service/External";
import { PAGE_STATUS } from "./Constants";
import "./Contract.css";

import { UnavailablePage, SuccessPage, WrongPage } from "./StatusPage";
import { OrderDetail } from "./OrderDetail";
// set global page status
export const StatusContext = createContext();

const Contract = (props) => {
    const [orderDetails, setOrderDetails] = useState({});
    const [status, setStatus] = useState("");
    const { opportunityId } = useParams();
    const product = useProduct();

    useEffect(() => {
        getOrderDetails(opportunityId, setOrderDetails, setStatus);
    }, [opportunityId]);

    return (
        <div>
            <Header
                text={status === PAGE_STATUS.order ? product.label : ""}
                notClickable={true}
            />
            {status === PAGE_STATUS.order ? (
                <StatusContext.Provider value={setStatus}>
                    <OrderDetail
                        orderDetails={orderDetails}
                        opportunityId={opportunityId}
                        product={product}
                    />
                </StatusContext.Provider>
            ) : null}
            {status === PAGE_STATUS.unavailable ? (
                <UnavailablePage
                    productName={product.label}
                    email={product.email}
                />
            ) : null}
            {status === PAGE_STATUS.success ? (
                <SuccessPage productName={product.label} />
            ) : null}
            {status === PAGE_STATUS.wrong ? <WrongPage /> : null}
            <Footer />
        </div>
    );
};

export default Contract;
