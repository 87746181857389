import { useNavigate } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";
import SupportBanner from "../products/layout/SupportBanner";
import unavailable from "../../assets/unavailable.svg";
import { gotoRoot } from "../../Routes";
import { Button } from "appkit-react";

const AccessDenied = (props) => {
    const navigate = useNavigate();

    return (
        <div>
            <Header />
            <div className="flex-column g-0">
                <h3 className="m-t-80 m-b-30 gray-text-32 third-bold">
                    <span className="appkiticon icon-end-process-fill a-font-32 a-text-red m-r-6"></span>
                    Access denied
                </h3>
                <div className="w-500 center gray-text-18">
                    Sorry, you do not have the permission to access this page.
                </div>
                <div className="w-500 center gray-text-18 m-b-38">
                    Please contact the product team if you would like to update
                    your permission.
                </div>
                <Button
                    className="m-b-80"
                    size="xl"
                    onClick={() => gotoRoot(navigate)}
                >
                    BACK TO HOMEPAGE
                </Button>
                <img
                    src={unavailable}
                    alt="unavailable"
                    className="w-558 m-b-197"
                />
            </div>
            <Footer />
            <SupportBanner />
        </div>
    );
};

export default AccessDenied;
