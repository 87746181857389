import { useState } from "react";
import { CardTitle } from "../../CardTitle";
import { ONE_TIME_FEE } from "./Constants";
import { formatNumber } from "../../../../../common/Util";
import { CONTRACT_FIELD } from "../../Constants";

const DOD = (props) => {
    const [detailShow, setDetailShow] = useState(false);
    const { orderDetails, productId } = props;

    const oneTimeFee = ONE_TIME_FEE[orderDetails[CONTRACT_FIELD[productId]]];

    // prevent Initial term card bottom radius bug when there is no RenewalTerm1Value
    const borderBottomRadius = orderDetails.RenewalTerm1Value
        ? ""
        : "border-radius-b";
    return (
        <div className="p-t-26 m-b-28">
            <div className="m-h-24 flex-justify-between">
                <CardTitle
                    iconClass="icon-calendar-outline"
                    text="Initial Term"
                />
                <div
                    onClick={() => setDetailShow(!detailShow)}
                    className="orange text-12 half-bold a-hover underline text-bold"
                >
                    {detailShow ? "Hide Details" : "View Details"}
                </div>
            </div>
            <ul className="m-h-24 ul-clear-left-p">
                {orderDetails.InitialTerm1Value ? (
                    <li className="p-t-15">
                        <div className="flex-justify-between text-12 half-bold gray">
                            <h6 className="text-12 half-bold">
                                {orderDetails.InitialTerm1}
                            </h6>
                            <span>
                                {formatNumber(orderDetails.InitialTerm1Value)}
                            </span>
                        </div>
                        {detailShow ? (
                            <div>
                                <div className="text-12 half-bold orange">
                                    {oneTimeFee.title}
                                </div>
                                <div className="gray-text-10 half-bold one-time-fee">
                                    <p className="mb-7">
                                        {oneTimeFee.subTitle}
                                    </p>
                                    <ul>
                                        {oneTimeFee.desc.map((item, key) => (
                                            <li key={"one-time-fee-" + key}>
                                                {item}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ) : null}
                    </li>
                ) : null}
                <li className="p-t-15">
                    <div className="flex-justify-between text-12 half-bold gray">
                        <h6 className="text-12 half-bold">
                            {orderDetails.InitialTerm2}
                        </h6>
                        <span>
                            {formatNumber(orderDetails.InitialTerm2Value)}
                        </span>
                    </div>
                    {detailShow ? (
                        <div>
                            <div className="text-12 half-bold orange">
                                Annual Fee{" "}
                                {orderDetails[CONTRACT_FIELD[productId]] ===
                                "12 months"
                                    ? "| Invoiced as of Order Effective Date"
                                    : "| Waived during Initial Term (Trial Starter Period)"}
                            </div>
                            <div className="gray-text-10 half-bold">
                                <p className="clear-margin-bottom">
                                    {orderDetails.ServiceModule ===
                                    "Finance Function"
                                        ? "Access to the Finance Function collection of Downloadable Assets"
                                        : "Access to Downloadable Assets tagged as " +
                                          orderDetails.ServiceModule}
                                </p>
                            </div>
                        </div>
                    ) : null}
                </li>
                <li className="p-t-15">
                    <div className="flex-justify-between text-12 half-bold gray">
                        <h6 className="text-12 half-bold">
                            Downloadable Assets - General Utility
                        </h6>
                        <span>Included</span>
                    </div>
                    {detailShow ? (
                        <div>
                            <div className="gray-text-10 half-bold">
                                <p className="clear-margin-bottom">
                                    Access to Downloadable Assets tagged as
                                    General Utility
                                </p>
                            </div>
                        </div>
                    ) : null}
                </li>
                <li className="p-t-15">
                    <div className="flex-justify-between text-12 gray half-bold">
                        <h6 className="text-12 half-bold">
                            {"Cloud-Based Assets - " +
                                orderDetails.ServiceModule}
                        </h6>
                        <span>Included</span>
                    </div>
                    {detailShow ? (
                        <div>
                            <div className="gray-text-10 half-bold">
                                <p className="clear-margin-bottom">
                                    Access to Cloud-Based Assets that are part
                                    of the {orderDetails.ServiceModule}{" "}
                                    collection. There is no cap on usage of
                                    assets tagged both as{" "}
                                    {orderDetails.ServiceModule} and General
                                    Utility.
                                </p>
                            </div>
                        </div>
                    ) : null}
                </li>
            </ul>
            <div
                className={`bg-black white flex-justify-between p-h-24 p-v-15 m-t-15 text-bold ${borderBottomRadius}`}
            >
                <h3 className="text-16 clear-margin-bottom text-bold">
                    Subtotal before tax
                </h3>
                <span>
                    {formatNumber(
                        orderDetails.InitialTerm1Value +
                            orderDetails.InitialTerm2Value
                    )}{" "}
                    USD
                </span>
            </div>
        </div>
    );
};

export default DOD;
