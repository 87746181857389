import { useState } from "react";

import InitialTerm from "./InitialTerm";
import Select from "../../Select";
import { buildInitialValidationErrors, VALIDATION_TYPE } from "../../../../common/Validation";
import ContractWrapper from "../ContractWrapper";
import { NEW_ORDER_DATA } from "../../../../../Constants";

const Contract = ({ props }) => {
    const {
        formData,
        formState,
        setFormState,
        handleChange,
        buttonActions,
        buttonLabels
    } = props;

    const validations = {
        subscriptionTier: {
            type: VALIDATION_TYPE.REQUIRED,
            errorMessage: "Please select a subscription tier."
        },
        subscriptionTerm: {
            type: VALIDATION_TYPE.REQUIRED,
            errorMessage: "Please select a subscription term."
        }
    };

    const [validationErrors, setValidationErrors] = useState(
        buildInitialValidationErrors(validations, NEW_ORDER_DATA)
    );

    const [loading, setLoading] = useState(false);

    const dependencies = [formData.subscriptionTier, formData.subscriptionTerm];
    const haveData = formData.subscriptionTier && formData.subscriptionTerm;

    const getPricingParams = {
        productFamily: props.product.id,
        term: formData.subscriptionTerm,
        tier: formData.subscriptionTier
    };

    const setPricingOptions = (result) => {
        setFormState((current) => {
            return {
                ...current,
                annualFees: result.productFees,
                selectedProductId: result.productId
            };
        });
    };

    const content = (
        <>
            <div>
                <Select
                    primary={true}
                    label="SUBSCRIPTION TIER"
                    options={formState.pricingOptions.clientTiers}
                    value={formData.subscriptionTier}
                    onChange={handleChange("subscriptionTier")}
                    errorMessage={validationErrors.subscriptionTier.message}
                    hasError={validationErrors.subscriptionTier.error}
                />
                <Select
                    primary={true}
                    label="SUBSCRIPTION TERM"
                    options={formState.pricingOptions.terms}
                    value={formData.subscriptionTerm}
                    onChange={handleChange("subscriptionTerm")}
                    errorMessage={validationErrors.subscriptionTerm.message}
                    hasError={validationErrors.subscriptionTerm.error}
                />
            </div>
            {haveData && (
                <div>
                    <div className="gray-text-12 p-v-8">ORDER TERMS</div>
                    <div className="round-card-black">
                        <InitialTerm props={props} loading={loading} />
                    </div>
                </div>
            )}
        </>
    );

    return (
        <ContractWrapper
            formData={formData}
            buttonActions={buttonActions}
            buttonLabels={buttonLabels}
            validations={validations}
            validationErrors={validationErrors}
            setValidationErrors={setValidationErrors}
            haveData={haveData}
            dependencies={dependencies}
            getPricingParams={getPricingParams}
            setPricingOptions={setPricingOptions}
            loading={loading}
            setLoading={setLoading}
            content={content}
        />
    );
};

export default Contract;
