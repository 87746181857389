import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Checkbox from "appkit-react/lib/Checkbox/Checkbox";
import Button from "appkit-react/lib/Button/Button";
import { Upload } from "appkit-react-upload";
import { CalendarPicker } from "appkit-react-datepicker";
import Menu, { MENU_LABELS } from "../common/Menu";
import EditSummary from "../edit/EditSummary";
import FullScreenModal from "../../common/FullScreenModal";
import { newDateEST } from "../../../../common/Util";
import { uploadContract } from "../service/External";
import { gotoError, gotoUploadedContract } from "../../../../../Routes";

const UploadContract = (props) => {
    const {
        opportunityId,
        gotoAllOrders,
        gotoViewOrder,
        product,
        formState,
        formData,
        submissionInfo
    } = props;
    const [uploadRef, setUploadRef] = useState();
    const [submitting, setSubmitting] = useState(false);
    const [acknowledged, setAcknowledged] = useState(false);
    const [date, setDate] = useState();
    const [validDate, setValidDate] = useState(false);
    const [hasFile, setHasFile] = useState(false);

    const handleCheck = (e) => {
        setAcknowledged(e.target.checked);
    };

    const navigate = useNavigate();
    const externalUploadCallback = (success, result) => {
        if (success) {
            gotoUploadedContract(
                navigate,
                product.id,
                opportunityId,
                props.formState.clientAccount.name
            );
        } else {
            gotoError(navigate);
        }
    };

    const handleAccept = (files) => {
        return files.filter((file) => {
            if (file.name.toLowerCase().endsWith("pdf")) {
                return true;
            } else {
                return false;
            }
        });
    };

    const handleExternalUpload = (uploadInfo, file) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            // strip everything before search from the string
            const search = ";base64,";
            var index = reader.result.indexOf(search);
            const base64data = reader.result.substring(index + search.length);
            const fileData = {
                name: file.name,
                size: file.size,
                base64: base64data
            };
            uploadContract(
                opportunityId,
                date,
                fileData,
                externalUploadCallback
            );
        };
        reader.readAsDataURL(file.originFile);
    };

    const handleUploadChange = (file, fileList) => {
        if (fileList.length > 0) {
            setHasFile(true);
        } else {
            setHasFile(false);
        }
    };

    const handleUpload = () => {
        setSubmitting(true);
        uploadRef.submit();
    };

    const nowEST = newDateEST();
    const from = new Date(
        nowEST.getFullYear(),
        nowEST.getMonth(),
        nowEST.getDate() - 13
    );
    const to = new Date(
        nowEST.getFullYear(),
        nowEST.getMonth(),
        nowEST.getDate(),
        0,
        0,
        0,
        1
    );

    const submitter = {
        name: submissionInfo.submitterName,
        email: submissionInfo.submitterEmail
    };

    const menuLabels = [
        MENU_LABELS.ALL_ORDERS,
        MENU_LABELS.VIEW_ORDER,
        MENU_LABELS.UPLOAD_CONTRACT
    ];
    const menuActions = [gotoAllOrders, gotoViewOrder];
    return (
        <>
            <div>
                <Menu labels={menuLabels} actions={menuActions} />
                <div className="flex-justify-center">
                    <div className="content">
                        <div className="text-22 half-bold">Upload Contract</div>
                        <div className="light-gray-text-16">
                            The signed contract must be uploaded within 14 days
                            after contract signing. The order will be moved to
                            “Active” status once uploaded.{" "}
                            <a
                                href="https://docs.google.com/document/d/1Zi-ZXLGWePLCr7-0e4sazq231oLOeDX7O5R437fXNNk/edit#"
                                target="_blank"
                                rel="noreferrer"
                                className="orange underline"
                            >
                                Click here
                            </a>{" "}
                            for more information.
                        </div>
                        <Upload
                            action={"external"}
                            autoUpload={false}
                            ref={(ref) => setUploadRef(ref)}
                            externalUpload={handleExternalUpload}
                            onChange={handleUploadChange}
                            accept={handleAccept}
                            className="p-v-18"
                        >
                            <div className="upload-drop a-apply-theme-background-gray-level-2">
                                <span className="upload-drop-span">
                                    Drag and drop or
                                </span>
                                {"\u00A0"}
                                <span
                                    className="upload-browse-span"
                                    data-mode="files"
                                >
                                    choose your file
                                </span>
                                <div
                                    style={{
                                        position: "absolute",
                                        top: "104px"
                                    }}
                                >
                                    Supported file type: PDF
                                </div>
                            </div>
                        </Upload>
                        <div className="gray-text-12 p-b-10">
                            CONTRACT SIGNED DATE
                        </div>
                        <CalendarPicker
                            className="calendar-picker-w-100 p-b-36"
                            value={date}
                            onValueChange={(isValid, value) => {
                                setValidDate(isValid && !!value);
                                setDate(new Date(value));
                            }}
                            mode="large"
                            editable={false}
                            format="mm/dd/yyyy"
                            minDate={from}
                            maxDate={to}
                        />
                    </div>
                </div>
                <EditSummary
                    user={submitter}
                    product={product}
                    formState={formState}
                    formData={formData}
                />
            </div>
            <div className="p-t-36 p-b-185">
                <div className="content flex-justify-center">
                    <div>
                        <Checkbox
                            checked={acknowledged}
                            onChange={handleCheck}
                            disabled={submitting}
                        >
                            I acknowledge the negotiated contract has been
                            approved by the legal team and signed by the client.
                        </Checkbox>
                        <div className="nav-buttons m-t-36">
                            <Button onClick={gotoViewOrder} kind={"secondary"}>
                                DISCARD
                            </Button>
                            <Button
                                onClick={handleUpload}
                                disabled={
                                    !acknowledged || !validDate || !hasFile
                                }
                            >
                                UPLOAD CONTRACT
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            {submitting && (
                <FullScreenModal
                    content={"Just a moment while we upload the contract..."}
                />
            )}
        </>
    );
};

export default UploadContract;
