import WrappedSelect from "../../common/WrappedSelect";
import SelectOption from "appkit-react/lib/Select/SelectOption";

const CountrySelect = (props) => {
    // TODO : skip the remote call and put countryList in Constants.js?
    const options = [];
    for (let i = 0; i < window.countryList.length; ++i) {
        const country = window.countryList[i];
        options.push(
            <SelectOption key={i} value={country}>
                {country}
            </SelectOption>
        );
    }

    return (
        <WrappedSelect
            name={props.name}
            label={props.label}
            placeholder={props.placeholder}
            hasError={props.hasError}
            errorMessage={props.errorMessage}
            options={options}
            onChange={props.onChange}
            value={props.value}
        />
    );
};

export default CountrySelect;
