import "./Menu.css";

export const MENU_LABELS = {
    ALL_ORDERS: "All Orders",
    VIEW_ORDER: "View Order",
    EDIT_ORDER: "Edit Order",
    SUBMIT_TO_CLIENT: "Submit order to client",
    CLIENT_DETAILS: "Client details",
    ORDER_TERMS: "Order terms",
    PWC_CONTACTS: "PwC Contacts",
    UPLOAD_CONTRACT: "Upload signed negotiated contract",
    SUBMIT_AFS: "Submit New AFS",
    RESEND_ORDER: "Resend Order"
};

const Menu = (props) => {
    // assume that labels and actions are both arrays and actions.length = labels.lenght - 1
    // i.e. the last label has no action
    const items = [];
    for (let i = 0; i < props.labels.length; ++i) {
        const last = i === props.labels.length - 1;
        const item = !last ? (
            <span key={i}>
                <button
                    className="link-button orange-link"
                    onClick={props.actions[i]}
                >
                    {props.labels[i]}
                </button>
                <span className="lighter-gray-text-16 p-h-18">/</span>
            </span>
        ) : (
            <span key={i} className="gray-text-12">
                {props.labels[i]}
            </span>
        );
        items.push(item);
    }

    return (
        <div className="content flex-justify-center p-t-44 p-b-66">
            <div>{items}</div>
        </div>
    );
};

export default Menu;
