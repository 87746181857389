// holds data that is sent back to the server
export const INITIAL_FORM_DATA = {
    // contact info
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    title: "",
    // company info
    companyName: "",
    parentCompany: "",
    country: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    usState: "",
    zip: "",
    externalAuditor: "",
    otherAuditor: "",
    rules: "",
    // referral info
    hearAbout: "",
    referralSource: "",
    pwcContact: "",
    // viewpoint
    orderViewpoint: false,
    usContent: 0,
    usContentTotal: 0,
    globalContent: 0,
    globalContentTotal: 0,
    canadaContent: 0,
    canadaContentTotal: 0,
    ukContent: 0,
    ukContentTotal: 0,
    gaapContent: 0,
    gaapContentTotal: 0,
    japanContent: 0,
    japanContentTotal: 0,
    gasbContent: 0,
    gasbContentTotal: 0,
    viewpointTotal: 0,
    // disclosure checklist
    orderDisclosureChecklist: false,
    additionalLicenses: 0,
    additionalLicensesTotal: 0,
    disclosureChecklistTotal: 0,
    companyDomainNames: "",
    engagementTeamManager: "",
    tcApproverName: "",
    tcApproverEmail: "",
    agreeToTC: false,
    accessToAll:"",
    legalNamesOfOtherEntities:"",
    // validations that use multiple fields
    nonZeroOrder: null,
};

// holds frontend state (based on country selection)
export const INITIAL_FORM_STATE = {
    additionalLicense: 0,
    billingTerritory: "",
    currencyCode: "",
    currencyName: "",
    firstLicense: 0,
    theatre: "",
    viewpointConfirmationNumber: "",
    disclosureChecklistConfirmationNumber: "",
    contentAreaPrices: {}
};

export const DISCLOSURE_CHECKLIST = "Disclosure Checklist";
export const INFORM_CONTENT = "Inform Content";

export const AMERICAS = "Americas";
export const UNITED_STATES = "United States";
export const UNITED_KINGDOM = "United Kingdom";
export const IRELAND = "Ireland";
export const JAPAN = "Japan";

export const CURRENCY_CODE_TO_SYMBOL = {
    AUD: "$",
    CAD: "$",
    GBP: "£",
    JPY: "¥",
    USD: "$",
}

export const US_STATES = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "District of Columbia",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
    "Puerto Rico",
    "Virgin Island",
    "Northern Mariana Islands",
    "Guam",
    "American Samoa",
    "Palau"
];

export const OTHER = "Other";
export const AUDITORS = ["PwC", "Deloitte", "Ernst & Young", "KPMG", OTHER];

export const RULES = ["US SEC", "GAO", "No"];
export const YES = "Yes";
export const NO = "No";
export const ACCESS = [YES, NO];

export const PWC_CONTACT = "PwC Contact";
export const HEAR_ABOUT = [
    "Internet Search",
    "Online Advertisement",
    "Print Advertisement",
    PWC_CONTACT,
    "PwC Website",
    OTHER
];