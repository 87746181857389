import { ROUTES } from "../../../../Routes";
import "./SortingTable.css";

const ViewOrderLink = (props) => {
    const { opportunityId, productId } = props;

    return (
        <a
            className="orange-link link-button right"
            href={"#" + ROUTES.viewOrderFragment(productId, opportunityId)}
        >
            View order
        </a>
    );
};

export default ViewOrderLink;
