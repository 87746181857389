import { useNavigate, useParams } from "react-router-dom";
import { Success } from "./Success";
import watchTechnology from "../../../../../assets/watchTechnology.svg";
import { formatDate } from "../../../../common/Util";
import { gotoManageDefault } from "../../../../../Routes";
import useProduct from "../../common/UseProduct";

const TerminatedOrder = () => {
    const product = useProduct();
    const { clientName, terminationDate } = useParams();
    
    const subtitle = `The ${product.label} order for ${decodeURIComponent(
        clientName
    )} has successfully been
    terminated. The client subscription will end on ${formatDate(
        new Date(decodeURIComponent(terminationDate))
    )}.`;

    const image = { src: watchTechnology, width: "246px" };

    const navigate = useNavigate();
    const action = {
        text: "VIEW ALL ORDERS",
        onClick: () => gotoManageDefault(navigate, product),
        wide: true
    };

    return (
        <Success
            title="Order terminated"
            subtitle={subtitle}
            description="Please follow up with the internal product team to complete the cancellation process."
            image={image}
            action={action}
        />
    );
};

export default TerminatedOrder;
