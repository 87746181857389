import { CONTRACT_TYPE } from "../../Constants";
const DOD = {
    [CONTRACT_TYPE.fourtyFiveDays]: [
        "Fees set forth above will be invoiced in advance of the first Renewal Term and are due on the first day of that Renewal Term. If a party has provided notice of non-renewal prior to the end of the Initial Term, then no fees will be payable.",
        "Subsequent annual fees are invoiced by PwC in advance of each Renewal Term as applicable and Client will pay PwC amounts due within thirty (30) days after the date of invoice. Annual fees may be updated for any subsequent Renewal Term upon prior written notice from PwC.",
        "Client may add additional components, modules, assets and add-ons for the Offering that are made available by PwC at any time during the Term subject to PwC and Client’s execution of a supplement to this Order or an additional Order, as applicable (each an “Additional Order”) and Client’s payment of additional fees calculated in accordance with PwC’s then-current list price for such items as set forth in the Additional Order. Certain components, modules and digital assets of the Offering that have not been procured by Client under this Order or an Additional Order may be made visible to client in read-only format. Use of such components, modules and digital assets will be subject to the execution of an Additional Order."
    ],
    [CONTRACT_TYPE.ninetyDays]: [
        "One-time fees will be invoiced upon execution of the Order.",
        "Annual fees are invoiced by PwC in advance of each Renewal Term as applicable. Annual fees may be updated for any subsequent Renewal Term upon prior written notice from PwC.",
        "Client may add additional components, modules, assets and add-ons for the Offering that are made available by PwC at any time during the Term subject to PwC and Client’s execution of a supplement to this Order or an additional Order, as applicable (each an “Additional Order”) and Client’s payment of additional fees calculated in accordance with PwC’s then-current list price for such items as set forth in the Additional Order. Certain components, modules and digital assets of the Offering that have not been procured by Client under this Order or an Additional Order may be made visible to client in read-only format. Use of such components, modules and digital assets will be subject to the execution of an Additional Order.",
        "Client will pay PwC amounts due within thirty (30) days after the date of invoice."
    ],
    [CONTRACT_TYPE.twelveMonths]: [
        "Total Annual Fees for the Initial Term and any one-time fees (if applicable) will be invoiced upon execution of the Order.",
        "Annual fees for any Renewal Term are invoiced by PwC in advance of each Renewal Term as applicable. Annual fees may be updated for any subsequent Renewal Term upon prior written notice from PwC.",
        "Client may add additional components, modules, assets and add-ons for the Offering that are made available by PwC at any time during the Term subject to PwC and Client’s execution of a supplement to this Order or an additional Order, as applicable (each an “Additional Order”) and Client’s payment of additional fees calculated in accordance with PwC’s then-current list price for such items as set forth in the Additional Order. Certain components, modules and digital assets of the Offering that have not been procured by Client under this Order or an Additional Order may be made visible to client in read-only format. Use of such components, modules and digital assets will be subject to the execution of an Additional Order.",
        "Client will pay PwC amounts due within thirty (30) days after the date of invoice."
    ]
};
export default DOD;
