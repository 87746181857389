import "./SomethingWentWrong.css";
import Header from "../Header";
import { WrongPage } from "../products/layout/contract/StatusPage";
import SupportBanner from "../products/layout/SupportBanner";
import Footer from "../Footer";

const SomethingWentWrong = (props) => {
    return (
        <div>
            <Header />
            <WrongPage />
            <SupportBanner />
            <Footer />
        </div>
    );
};

export default SomethingWentWrong;
