import "./Banner.css";

function Banner() {
    return (
        <div className="banner-text">
            <div className="p-t-40">
                <a
                    className="a-decorated"
                    href="https://wwwpwcnetwork.pwc.myshn.net/hub?id=sc_cat_item&sys_id=3f1dd0320a0a0b99000a53f7604a2ef9&channel=5166dcdddba16384a416f5261d961957&shn-direct"
                    target="_blank"
                    rel="noreferrer"
                >
                    Visit our portal
                </a>
                &nbsp;to request technical support, report issues, or view
                knowledge articles for any question you may have.
            </div>
        </div>
    );
}

export default Banner;
