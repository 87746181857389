// Order Additional Service Fees template
import DOD from "./DOD";
import WFO from "./WFO";
import LISTEN from "./LISTEN";
import CN from "./CN";
const ADDITIONAL_SERVICE_FEES = {
    DOD,
    WFO,
    LISTEN,
    CN
};

export default ADDITIONAL_SERVICE_FEES;
