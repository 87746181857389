const TermsConditionsUK = () => {
  return (
    <div>
      <p>Last updated: 18 February 2022</p>
      <p>
        THIS AGREEMENT DESCRIBES THE TERMS ON WHICH PRICEWATERHOUSECOOPERS LLP,
        A LIMITED LIABILITY PARTNERSHIP REGISTERED IN ENGLAND WITH REGISTERED
        NUMBER OC303525 AND VAT NUMBER GB 243 8239 57 WITH ITS REGISTERED OFFICE
        AT 1 EMBANKMENT PLACE, LONDON, ENGLAND, WC2 6RH ("We" or "Us"*), ALLOWS
        BUSINESS ENTITIES WHOSE REGISTRATIONS WE HAVE ACCEPTED TO ACCESS AND USE
        THE PwC VIEWPOINT WEB SITE (THE "WEB SITE"). BY ACCEPTING THE TERMS AND
        CONDITIONS BELOW, THE ENTITY AS IDENTIFIED IN THE REGISTRATION FOR THIS
        WEB SITE ("USER" OR "YOU") ACCEPTS EACH OF THE TERMS AND CONDITIONS SET
        OUT BELOW, INCLUDING THOSE TERMS AND CONDITIONS INCORPORATED BY
        REFERENCE. YOU FORM A CONTRACT WITH US AT THE TIME YOU ACCEPT THE TERMS
        AND CONDITIONS BELOW.
      </p>
      <p>
        *WHERE YOU ARE INCORPORATED IN SOUTH AFRICA OR INDIA, THIS AGREEMENT IS
        BETWEEN YOU AND PRICEWATERHOUSECOOPERS OVERSEAS LIMITED, A LIMITED
        LIABILITY COMPANY REGISTERED IN ENGLAND WITH REGISTERED NUMBER 3575515
        WITH ITS REGISTERED OFFICE AT 1 EMBANKMENT PLACE, LONDON, ENGLAND, WC2
        6RH, AND "We" AND "Us" SHALL MEAN PRICEWATERHOUSECOOPERS OVERSEAS
        LIMITED. IN ADDITION REFERENCES TO "PRICEWATERHOUSECOOPERS LLP" IN
        SECTIONS 13.3 AND 15 SHALL BE DEEMED TO BE REFERENCES TO
        "PRICEWATERHOUSECOOPERS OVERSEAS LIMITED”. THE VAT NUMBER FOR
        PRICEWATERHOUSECOOPERS OVERSEAS LIMITED IS 4380260119.
      </p>
      <p>
        WHERE YOU ARE INCORPORATED IN RUSSIA, THIS AGREEMENT IS BETWEEN YOU AND
        PRICEWATERHOUSECOOPERS ADVISORY SERVICES LIMITED, A LIMITED LIABILITY
        COMPANY REGISTERED IN ENGLAND WITH REGISTERED NUMBER 03580655 WITH ITS
        REGISTERED OFFICE AT 1 EMBANKMENT PLACE, LONDON, ENGLAND, WC2 6RH, AND
        “We” AND “Us” SHALL MEAN PRICEWATERHOUSECOOPERS ADVISORY SERVICES
        LIMITED. IN ADDITION REFERENCES TO "PRICEWATERHOUSECOOPERS LLP" IN
        SECTIONS 13.3 AND 15 SHALL BE DEEMED TO BE REFERENCES TO
        "PRICEWATERHOUSECOOPERS ADVISORY SERVICES LIMITED”. THE RUSSIAN TAX
        IDENTIFICATION (INN) NUMBER FOR PRICEWATERHOUSECOOPERS ADVISORY SERVICES
        LIMITED IS 9909598610.
      </p>
      <p>
        We may amend this Agreement at any time by posting the amended terms on
        the Web Site. The new terms shall automatically be effective and binding
        on You 15 days after they are initially posted on the Web Site. You may
        terminate this Agreement pursuant to Section 12.3 if You do not wish to
        accept such amendment. This Agreement may not be otherwise amended.
      </p>
      <ol>
        <li>
          <p>
            <strong>Disclaimers and Limitations of Liability</strong>
          </p>
          <ol>
            <li>
              <p>
                <strong>
                  This Section 1.1 applies to all parts of the Web Site, the
                  information and services made available thereon and use
                  thereof.
                </strong>
              </p>
              <ol>
                <li>
                  The information contained in the Web Site is for general
                  guidance on matters of interest only. The application and
                  impact of laws can vary widely based on the specific facts
                  involved. Given the changing nature of laws, rules and
                  regulations, and the inherent hazards of electronic
                  communication, there may be delays, omissions or inaccuracies
                  in information contained in the Web Site. Accordingly, the
                  information on the Web Site is provided with the understanding
                  that it does not constitute the provision by Us or the authors
                  of legal, accounting, tax, financial, regulatory or other
                  professional advice and services.
                </li>
                <li>
                  No professional relationship of any nature is created by any
                  exchanges of information occurring through the Web Site.
                </li>
                <li>
                  The information on the Web Site, including any guidance notes,
                  should not be used as a substitute for consultation with
                  professional accounting, tax, legal, financial or other
                  competent advisers.
                </li>
                <li>
                  You should not act or rely on any information obtained from
                  the Web Site and before making any decision or taking or
                  refraining from taking any action, You should consult a
                  PricewaterhouseCoopers professional.
                </li>
                <li>
                  Although we make every effort to ensure the accuracy of
                  information and content sourced from third parties and
                  reproduced on the web site, neither we nor our third party
                  suppliers accept any liability for the accuracy or content of
                  such reproduced information.
                </li>
                <li>
                  <strong>
                    YOU AGREE THAT UNDER NO CIRCUMSTANCE WILL WE, ANY PWC
                    ENTITY, ITS OR OUR RELATED PARTNERSHIPS OR CORPORATIONS, OR
                    THE PARTNERS, AGENTS, OFFICERS OR EMPLOYEES THEREOF BE
                    RESPONSIBLE FOR INFORMATION CONTAINED IN THIS WEB SITE.
                  </strong>
                </li>
                <li>
                  <strong>
                    WE DISCLAIM ALL LIABILITY IN RESPECT OF SUCH INFORMATION
                    WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE) OR
                    OTHERWISE AND WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
                    IMPLIED (INCLUDING, BUT NOT LIMITED TO, WARRANTIES THAT THE
                    INFORMATION ON THE SITE WILL BE PROVIDED OR PREPARED USING
                    REASONABLE SKILL OR CARE).
                  </strong>
                </li>
              </ol>
            </li>
            <li>
              <p>
                We do not guarantee continuous, uninterrupted or secure access
                to the Web Site and You acknowledge that the reliability,
                availability, performance and other aspects of the Internet are
                beyond our reasonable control and therefore:
              </p>
              <ol>
                <li>
                  We do not represent or warrant that the functions of the Web
                  Site will meet Your requirements;
                </li>
                <li>
                  We make no guarantee or warranty that any information
                  delivered to You from or through the Web Site, will not be
                  captured or monitored by one or more third parties, or that
                  such information will arrive at Your site. You acknowledge
                  that We cannot guarantee the information posted on the Site or
                  sent to You by email will be secure or maintained as
                  confidential; and
                </li>
                <li>
                  We will not be responsible for unauthorised access to or
                  alteration of Your transmissions or data, any material or data
                  sent or received or not sent or received.
                </li>
                <li>
                  Although the Web Site is capable of encrypting traffic between
                  Your browser and the Web Site at the highest level currently
                  available, We cannot guarantee that You will have this level
                  of security as this will depend on the encryption level
                  supported by the browser that You use. We do not accept any
                  increased risk associated with using the Web Site at a lower
                  level of encryption.
                </li>
              </ol>
            </li>
            <li>
              We will use standard, commercially-available virus-checking
              software in relation to any document or files We may send to You
              in relation to the Web Site however We do not represent or warrant
              that the Web Site or any documents or files downloaded from the
              Web Site will be error-free, free from viruses or other harmful
              components.
            </li>
            <li>
              You agree that Your relationship is solely with Us. We will be
              responsible for any acts of any Subcontractor in connection with
              the Web Site and any service offered on the Web Site.
            </li>
            <li>
              Certain links in the Web Site connect to other web sites
              maintained by third parties and such other web sites may or may
              not be presented within a frame on the Web Site. We have not
              verified the contents of such third party web sites and do not
              endorse, warrant, promote or recommend any information, services
              or products which may be provided or accessible through them or
              any body or person which may provide them.
            </li>
          </ol>
        </li>
        <li>
          <p>
            <strong>Indemnities</strong>
          </p>
          <ol>
            <li>
              To give effect to the provisions of Section1.4, You agree not to
              and will procure that no undertaking in which You have an
              interest, whether directly or indirectly, or which is a member of
              Your Group brings or enforces any claim against any Subcontractor,
              their partners or its or our officers or employees, in respect of
              any liability in connection with the Web Site. You will indemnify
              and keep indemnified any Subcontractor, their partners or its or
              our officers and employees against each loss, liability and cost
              incurred by any of them arising out of any claim or enforcement
              brought by any such undertaking or member of Your Group, both
              while they are members of Your Group and thereafter.
            </li>
            <li>
              You will ensure that each person who uses the Web Site or accesses
              the Web Site using the user name and password for Your
              Administrator or any Authorised User complies with all the terms
              hereof and You will keep Us, our directors, officers and employees
              indemnified against any liabilities, losses, expenses or other
              costs We or they reasonably incur arising from a breach of any of
              the terms hereof by Your Administrator, any Authorised User or any
              other person who accesses the Web Site using the user name and
              password of Your Administrator or any Authorised User or to whom
              You distribute any information obtained from the Web Site.
            </li>
            <li>
              The indemnities provided in Sections 2.1 and 2.2 shall extend to
              legal and other costs and expenses, internal and external,
              incurred by any Subcontractor, their officers or its or our
              partners or employees in respect of any such claim or enforcement.
            </li>
          </ol>
        </li>
        <li>
          <p>
            <strong>Eligibility</strong>
          </p>
          <ol>
            <ol>
              <li>
                <p>You are not eligible to access or use the Web Site if:</p>
                <ol>
                  <li>
                    You are incorporated, registered or established under the
                    laws of any jurisdiction not specified in Your registration
                    form or are using the Web Site from any such country; or
                  </li>
                  <li>
                    You are not permitted to access the Web Site under any
                    applicable laws, rules or regulations.
                  </li>
                </ol>
              </li>
              <p>
                If You do not qualify under the foregoing eligibility
                requirements, You are not eligible to use the Web Site, and if
                You do use the Web Site, You acknowledge and represent that You
                are eligible to use the Web Site and that You are not a person
                referred to above.
              </p>
              <li>
                In the event that a person which does not meet the eligibility
                criteria set out above nevertheless registers and uses the Web
                Site, such non-eligible party shall have all the obligations,
                responsibilities and liabilities as would an eligible entity
                that is a party to the transaction and this Agreement.
              </li>
            </ol>
          </ol>
        </li>
        <li>
          <p>
            <strong>Registration for the Web Site</strong>
          </p>
          <ol>
            <li>
              Your registration form must contain the relevant information in
              all the required fields indicated on the registration form.
            </li>
            <li>
              If You are registering for the Subscription Service, We will (if
              We have not already agreed the Initial Fee with You) contact You
              after We have received Your registration, at the telephone number
              specified on Your registration form to confirm (i) whether We will
              accept Your registration and (ii) the Initial Fee applicable for
              Your subscription. If We agree the foregoing with You, You must
              provide Us with the name of a Qualifying Individual who may access
              the Web Site on Your behalf and who will act as Your administrator
              in relation to the Web Site (Your "<strong>Administrator</strong>
              "). We will email an account activation link for Your
              Administrator to Your Email Address. If We have agreed the Initial
              Fee with You prior to Your registration, We will send an account
              activation link for Your Administrator by email to Your Email
              Address when We have received and accepted Your completed
              registration.
            </li>
            <li>
              Your Administrator may register as users of the Web Site any
              Authorised Users (who must be Qualifying Individuals and who are
              authorised by You to access the Web Site on Your behalf) by
              following the procedure set out on the Web Site for such purpose
              from time to time.
            </li>
            <li>
              If any Authorised User ceases to be a Qualifying Individual or
              authorised by You to access the Web Site on Your behalf, Your
              Administrator must deactivate the registration for such Authorised
              User by following the procedure set out on the Web Site for such
              purpose from time to time.
            </li>
            <li>
              If Your Administrator ceases to be a Qualifying Individual or
              authorised by You to access the Web Site on Your behalf and act as
              Your Administrator, You must notify Us without delay by sending an
              email to our Email Address and We shall be entitled to disable the
              password for such Administrator. You must, at the same time,
              nominate a replacement Administrator (who must be a Qualifying
              Individual) by following the procedure set out in the Web Site for
              such purpose from time to time.
            </li>
            <li>
              We reserve the right, acting in our sole discretion, to accept or
              reject Your registration for the Web Site.
            </li>
          </ol>
        </li>
        <li>
          <p>
            <strong>Subscription</strong>
          </p>
          <ol>
            <li>
              If We accept Your registration for the Subscription Service, We
              will send You an invoice for the relevant Initial Fee and You must
              pay such amount by one of the methods specified on the invoice in
              accordance with the following provisions. If We have agreed with
              You that there will be a trial subscription period for the
              Subscription Service (the "<strong>Trial Period</strong>") You
              must pay on or before the expiry of such Trial Period unless You
              have notified Us before the Subscription Date that You do not wish
              to subscribe for the Subscription Service (as the case may be). If
              We have not agreed a Trial Period with You, You must pay within 30
              days of the date of the invoice.
            </li>
            <li>
              The initial term of this Agreement begins when We have accepted
              Your registration and ends on the first anniversary of the
              Subscription Date unless terminated earlier in accordance with the
              terms of this Agreement. If You have not paid the Initial Fee by
              the relevant date as specified above, this Agreement and Your
              access to the Web Site will be terminated from such date.
            </li>
            <li>
              If You have subscribed for the Subscription Service, at least 30
              days prior to each Renewal Date, We will send You an invoice for
              the fee (the "<strong>Renewal Fee</strong>") for renewal of Your
              subscription for a further period of twelve months from such
              Renewal Date. If You have not paid such Renewal Fee (and any
              applicable taxes) by such Renewal Date, this Agreement (and Your
              access to the Web Site) will be terminated from such Renewal Date.
            </li>
            <li>
              All Fees will be stated exclusive of Value Added Tax (VAT)/Goods
              and Services Tax (GST) or any other sales tax. You will be
              responsible for paying any VAT/GST or any other sales tax arising
              from this Agreement for which You are legally liable at the
              applicable rate.
            </li>
            <li>
              All payments to be made by you under this Agreement shall be made
              in immediately available funds free and clear of and without any
              withholding or deduction for any and all present or future taxes
              (including VAT, sales or service taxes), duties or other charges
              unless you are compelled by your domestic law to make such
              withholdings or deductions. If you are compelled by law to make
              any such withholding or deduction and if any or all of that
              withholding or deduction becomes irrecoverable in the United
              Kingdom, then you shall pay to us such additional amounts as are
              required to enable us to receive the amount which we would have
              received if no such irrecoverable withholding or deduction had
              been made. You shall provide us with evidence that such taxes,
              duties or charges have been paid by forwarding to us official
              receipts within 30 days of payment.
            </li>
          </ol>
        </li>
        <li>
          <p>
            <strong>Services Available on the Web Site</strong>
          </p>
          <ol>
            <li>
              We will make the Web Site available in accordance with the terms
              and conditions of this Agreement.
            </li>
            <li>
              Your access to the Web Site permits Your Administrator and
              Authorised Users to use those areas of the Web Site identified on
              the Web Site as being available for the level of service for which
              You have registered.
            </li>
            <li>
              Where We have indicated on the Web Site that additional charges
              are required for any service made available on the Web Site, use
              of such services will be subject to such payment being made as set
              out on the Web Site.
            </li>
            <li>
              We may make software available on the Web Site and, if We do so,
              access to such software will be subject to acceptance of
              additional terms and conditions as set out on the Web Site.
            </li>
            <li>
              We reserve the right at our sole discretion to delete any material
              from any part of the Web Site from time to time as We deem
              appropriate.
            </li>
          </ol>
        </li>
        <li>
          <p>
            <strong>Educational Materials &ndash; CPE Points Record</strong>
          </p>
          <ol>
            <li>
              This Section 7 will apply to any use of any training materials,
              quizzes, videos or other information or downloads which may be
              available on or through this Web Site, by Your Administrator or
              Authorised Users.
            </li>
            <li>
              You, Your Administrator or Authorised User (as the case may be)
              will be responsible for maintaining Your or their own record of
              any CPE points associated with any training modules, quizzes,
              videos or other educational information or downloads which may be
              available on or through this Web Site.
            </li>
          </ol>
        </li>
        <li>
          <p>
            <strong>Your Obligations</strong>
          </p>
          <ol>
            <li>
              In using the Web Site, You will comply with all procedures and
              requirements described on the Web Site.
            </li>
            <li>
              <p>You agree:</p>
              <ol>
                <li>
                  to comply with all laws, rules, regulations and duties
                  applicable to your use of the Web Site and any information
                  obtained using the Web Site;
                </li>
                <li>
                  not to post any application programme or downloadable file to
                  any part of the Web Site;
                </li>
                <li>
                  not to upload or post to the Web Site any software or file
                  which contains any virus, worm, harmful component or corrupted
                  data or disrupt the normal flow of communications over the Web
                  Site or otherwise act in a manner that negatively affects
                  other users, individuals or entities.
                </li>
                <li>
                  not to reproduce, distribute, transmit, publish or otherwise
                  transfer or commercially exploit any software, file,
                  information, communication or other content received or
                  accessed through the Web Site without first having obtained
                  our written permission or the written permission of the
                  relevant contributor or user;
                </li>
                <li>
                  not to use any information or material on the Web Site for
                  marketing purposes;
                </li>
                <li>
                  not to use any device, software, or routine to interfere or
                  attempt to interfere with the proper working of the Web Site
                  or to bypass any security features of the Web Site or any part
                  thereof;
                </li>
                <li>
                  not to take any action which imposes an unreasonable or
                  disproportionately large load on the Web Site's
                  infrastructure;
                </li>
                <li>
                  not to include any hyperlinks to any part of the Web Site
                  without our prior written consent;
                </li>
                <li>
                  not to disclose to or share the user name or password for Your
                  Administrator or Authorised Users with any third parties
                  (other than Your Administrator or such Authorised User) nor
                  use any such user name or password for any purpose not
                  permitted by this Agreement;
                </li>
                <li>
                  to submit all registration information to the Web Site
                  utilising a secure connection and computer system;
                </li>
                <li>
                  to notify Us immediately of any unauthorised use of Your
                  account, user name or password or any other breach of
                  security; and
                </li>
                <li>
                  to keep all information contained in Your profile up to date.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <p>
            <strong>Permitted Use</strong>
          </p>
          <ol>
            <li>
              Except as expressly provided for in this Section, the information
              obtained from the Web Site is for the use of Your Administrator
              and Authorised Users only and neither You nor Your Administrator
              or Authorised Users may distribute it or make it available to any
              other person or entity without our written permission unless
              expressly stated otherwise in this Agreement.
            </li>
            <li>
              Subject to Section 9.3 and to any further restrictions indicated
              on the Web Site for any particular information, Your Administrator
              and Authorised Users may reproduce in hard copy for their sole use
              a single hard copy of information obtained from the Web Site
              provided that this is done{" "}
              <strong>on an "ad hoc" basis only</strong> and provided that no
              more than a single extract is reproduced on each occasion. You
              must not make any derivatives, modify, publish or further
              distribute any material accessed on or through the Web Site
              without our express written permission or the express written
              permission of the owner of such material.
            </li>
            <li>
              Where any article or extract obtained from the Web Site is
              reproduced in accordance with the terms of this Section, You shall
              ensure that the appropriate copyright or proprietary notice
              appears on the copy of such article or extract.
            </li>
          </ol>
        </li>
        <li>
          <p>
            <strong>Intellectual Property Rights</strong>
          </p>
          <ol>
            <li>
              We and/or our suppliers retain all property rights in and to the
              Web Site (including the software and the content provided through
              or on the Web Site), and nothing contained on the Web Site or in
              the content shall be construed as granting to You any ownership
              rights in the Web Site.
            </li>
            <li>
              We and/or our suppliers own all the intellectual property rights
              (including, without limitation, any copyright and database rights)
              in all systems, techniques, methodologies, ideas, concepts,
              information, compilations and know-how developed for the Web Site
              and We may develop or use for other clients or users any such
              systems, techniques, methodologies, ideas, concepts, information,
              compilations and know-how.
            </li>
            <li>
              We hereby grant You permission to access the Web Site as set out
              in this Agreement but do not grant You any licence or right under
              any of our trademarks, copyrights, patents or other proprietary
              rights. You may not make copies of or download any part of the Web
              Site (including the software and content provided by or on the Web
              Site) other than as expressly provided in this Agreement.
            </li>
          </ol>
        </li>
        <li>
          <p>
            <strong>Confidentiality</strong>
          </p>
          <ol>
            <li>
              You are entirely responsible for maintaining the confidentiality
              of all user names and passwords for Your Administrator and
              Authorised Users.
            </li>
            <li>
              You agree to maintain the confidentiality of the Web Site using at
              least as great a degree of care as You use to maintain the
              confidentiality of Your own most confidential information. The
              forgoing confidentiality obligations will not extend to
              information that (i) is, as of the time of its disclosure, or
              thereafter becomes part of the public domain through a source
              other than You; (ii) was known to You at the time of its
              disclosure; (iii) is independently developed by You without use of
              or reference to the confidential information; (iv) is subsequently
              learned from a third party not under a confidentiality obligation
              to Us or any of our licensors; or (v) is required to be disclosed
              pursuant to court order, subpoena, or government authority,
              whereupon You will provide notice to us prior to such disclosure.
            </li>
          </ol>
        </li>
        <li>
          <p>
            <strong>Suspension and Termination</strong>
          </p>
          <ol>
            <li>
              <p>
                We may without terminating this Agreement suspend the provision
                of the Web Site in whole or in part until further notice with
                immediate effect:
              </p>
              <ol>
                <li>to repair, maintain or improve the Web Site;</li>
                <li>
                  to comply with any order, instruction or request of any
                  government, any emergency services organisation or other
                  competent judicial, administrative or regulatory authority; or
                </li>
                <li>
                  if We have reasonable cause to believe the Web Site is being
                  used fraudulently or in such a way as to constitute a breach
                  of any provision of this Agreement.
                </li>
              </ol>
            </li>
            <li>
              <p>
                We may terminate this Agreement forthwith at any time by giving
                You notice of such termination to Your Email Address:
              </p>
              <ol>
                <li>
                  if You breach this Agreement and, in the case of a breach
                  capable of remedy, fail to do so within 14 days of a notice to
                  Your Email Address requesting You to do so; or
                </li>
                <li>
                  if You pass a resolution for Your winding up or Your directors
                  present a petition for Your winding up or an order for Your
                  winding up is made or if an administrative receiver or other
                  receiver or manager is appointed over any of Your assets or if
                  You enter into any kind of arrangement or compromise for the
                  benefit of any of Your creditors; or
                </li>
                <li>
                  by giving You two days notice of such termination to Your
                  Email Address.
                </li>
              </ol>
            </li>
            <li>
              You may terminate this Agreement at any time by sending an email
              to our Email Address informing Us of the effective date of such
              termination.
            </li>
            <li>
              If We terminate this Agreement pursuant to Section 12.2.3, We will
              refund a pro rata amount of any Fee You have paid for the period
              from the effective date of such termination to the next Renewal
              Date. In the event of termination for any other reason, You will
              not be entitled to a refund of any Fee (or part thereof).
            </li>
            <li>
              Subject to Section12.6, each party's further rights and
              obligations cease immediately on termination of this Agreement but
              termination does not affect a party's accrued rights and
              obligations at the date of termination.
            </li>
            <li>
              Notwithstanding the termination of this Agreement for any reason
              (including, without limitation, a fundamental breach of this
              Agreement by either of us), Sections 1., 9.2, 9.3, 10, 11 and 13
              of this Agreement shall remain in full force and effect.
            </li>
          </ol>
        </li>
        <li>
          <p>
            <strong>Governing Law</strong>
          </p>
          <ol>
            <li>
              THIS AGREEMENT AND ALL RELATIONS BETWEEN THE PARTIES ARISING OUT
              OF THIS AGREEMENT (INCLUDING IN RELATION TO TORT OR ITS
              EQUIVALENT) SHALL BE GOVERNED BY THE LAWS OF ENGLAND.
            </li>
            <li>
              THE COURTS OF ENGLAND SHALL HAVE EXCLUSIVE JURISDICTION TO SETTLE
              ANY DISPUTE ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT.
            </li>
            <li>
              CLAUSE13.2 IS FOR THE BENEFIT OF PRICEWATERHOUSECOOPERS LLP ONLY.
              AS A RESULT IT DOES NOT PREVENT PRICEWATERHOUSECOOPERS LLP FROM
              TAKING PROCEEDINGS RELATING TO A DISPUTE IN ANY OTHER COURTS WITH
              JURISDICTION AND TO THE EXTENT ALLOWED BY LAW,
              PRICEWATERHOUSECOOPERS LLP MAY TAKE CONCURRENT PROCEEDINGS IN ANY
              NUMBER OF JURISDICTIONS.
            </li>
            <li>
              THE PARTIES AGREE TO TRY TO RESOLVE ANY DISPUTE (WHETHER IN
              RELATION TO BREACH OF CONTRACT OR TORT (OR ITS EQUIVALENT))
              BETWEEN US IN GOOD FAITH BY SENIOR LEVEL NEGOTIATIONS. WHERE BOTH
              OF US AGREE THAT IT MAY BE BENEFICIAL, WE WILL SEEK TO RESOLVE THE
              DISPUTE THROUGH MEDIATION USING THE CENTRE FOR DISPUTE RESOLUTION
              TO FACILITATE THE MEDIATION PROCESS. IF THE DISPUTE IS NOT
              RESOLVED THROUGH NEGOTIATION OR MEDIATION EACH OF US AGREES THAT
              THE DISPUTE SHALL BE DEALT WITH AS SET OUT IN SECTION 13.2.
            </li>
          </ol>
        </li>
        <li>
          <p>
            <strong>Information about PricewaterhouseCoopers LLP</strong>
          </p>
          <ol>
            <li>
              PricewaterhouseCoopers LLP is a member of the Institute of
              Chartered Accountants in England and Wales ("
              <strong>ICAEW</strong>") and is bound by the Guide to Professional
              Ethics which can be found at{" "}
              <a href="http://www.icaew.co.uk" target="_blank" rel="noreferrer">
                www.icaew.co.uk
              </a>
              .
            </li>
            <li>
              <p>
                You can contact us by by emailing the Viewpoint Help Center
                within your respective region:
              </p>
              <ul>
                <li>
                  Americas and Asia-Pacific support:{" "}
                  <a
                    href="mailto:us_product-licensing-support@pwc.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    us_product-licensing-support@pwc.com
                  </a>
                </li>
                <li>
                  Canada support:{" "}
                  <a
                    href="mailto:CA_Viewpoint@pwc.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    CA_Viewpoint@pwc.com
                  </a>
                </li>
                <li>
                  UK, Europe, Middle East, Africa, Mainland China, Hong Kong SAR
                  and Macau SAR support:{" "}
                  <a
                    href="mailto:uk_viewpoint@pwc.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    uk_viewpoint@pwc.com
                  </a>
                </li>
                <li>
                  Japan support:{" "}
                  <a
                    href="mailto:jp_aarata_viewpoint-mbx@pwc.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    jp_aarata_viewpoint-mbx@pwc.com.
                  </a>
                </li>
              </ul>
            </li>
          </ol>
        </li>
        <li>
          <p>
            <strong>Novation of Terms and Conditions</strong>
          </p>
          <ol>
            <li>
              <p>
                If a person ("the <strong>Successor</strong>") succeeds (whether
                by sale, assignment, transfer, merger, operation of law or
                otherwise) to the whole or part of the business of
                PricewaterhouseCoopers LLP, we shall have the right to novate to
                the Successor, and the Successor shall have the right to accept
                and assume PwC's rights and obligations under or in connection
                with this Agreement. In such case PricewaterhouseCoopers LLP and
                the Successor may effect the novation by giving you written
                notice in which the Successor agrees to accept and assume the
                rights and the obligations of PricewaterhouseCoopers LLP. The
                effect of such notice will be that:
              </p>
              <ol>
                <li>
                  the Successor will be substituted for PricewaterhouseCoopers
                  LLP with effect from the date specified in the notice and
                  PricewaterhouseCoopers LLP will no longer have those rights
                  and obligations but such notice shall not affect any rights or
                  obligations in respect of work performed prior to that date;
                </li>
                <li>
                  PricewaterhouseCoopers LLP will be entitled and bound to pass
                  over to the Successor all information, records and other data
                  which they have in relation to the Agreement and the Services
                  but may retain one copy for record-keeping purposes subject to
                  any obligations of confidentiality which may be applicable;
                  and
                </li>
                <li>
                  any limit on the liability of PricewaterhouseCoopers LLP under
                  the Agreement will apply to PricewaterhouseCoopers LLP and the
                  Successor on an aggregated basis so that the maximum combined
                  liability of PricewaterhouseCoopers LLP and the Successor will
                  not exceed the limit of liability of PricewaterhouseCoopers
                  LLP before the novation takes effect; but where a Successor
                  succeeds to part of the business of PricewaterhouseCoopers
                  LLP, PricewaterhouseCoopers LLP and the Successor may only
                  exercise their rights under this clause where this Agreement
                  falls within the said part.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <p>
            <strong>General</strong>
          </p>
          <ol>
            <li>
              <p>In the case of a conflict between:</p>
              <ol>
                <li>
                  any procedures or requirements set out in the Web Site and the
                  terms and conditions of this Agreement set out in this page,
                  the terms and conditions of this Agreement will prevail;
                </li>
                <li>
                  any provision of the Privacy Policy and the terms and
                  conditions of this Agreement, these terms and conditions will
                  prevail; and
                </li>
                <li>
                  any provision of Section 1 and any other term or condition of
                  this Agreement, the provisions of Section 1 will prevail.
                </li>
              </ol>
            </li>
            <li>
              Neither of us will be liable to the other for any delay or failure
              to fulfil their obligations under this Agreement to the extent
              that any such delay or failure arises from causes beyond their
              control, including but not limited to System Failure, fire,
              floods, acts of God, acts or regulations of any governmental or
              supranational authority, war, riot, strike, lockouts and
              industrial disputes.
            </li>
            <li>
              The persons referred to in Sections 1.1.5, 2.1 and 2.2 may enforce
              the terms of such Sections subject to and in accordance with the
              provisions of the Contracts (Rights of Third Parties) Act 1999.
              Except as aforementioned, a person who is not a party to this
              Agreement has no right under the Contracts (Rights of Third
              Parties) Act 1999 to enforce any term of this Agreement but this
              does not affect any right or remedy of a third party which exists
              or is available apart from that Act.
            </li>
            <li>
              You may not assign, transfer, charge or otherwise seek to deal
              with any of Your rights or obligations under this Agreement
              without our prior written consent. We may assign or novate this
              Agreement to any other PwC Entity at any time by sending You an
              email informing You of such novation to Your Email Address. If You
              do not wish to accept such novation or assignment, You may
              terminate this Agreement in accordance with Section 12.
            </li>
            <li>
              No waiver of any term or condition of this Agreement will be
              effective unless made in writing.
            </li>
            <li>
              Other than as expressly set out herein, notices must be served
              either personally, sent by prepaid post or faxed to the address of
              the other party given in this Agreement (in the case of notices to
              be sent to Us) or to the address specified in Your registration
              (in the case of notices to be sent to You). Any notice sent by
              post will be deemed to have been delivered 48 hours after sending.
              Any notice sent by fax or served personally will be deemed to have
              been delivered on the first working day following its dispatch.
            </li>
            <li>
              The provisions of this Agreement which expressly or by implication
              are intended to survive its termination or expiry will survive and
              continue to bind both of us.
            </li>
            <li>
              If any provision of this Agreement is held to be invalid in whole
              or in part, such provision shall be deemed not to form part of
              this Agreement. In any event the enforceability of the remainder
              of this Agreement will not be affected.
            </li>
            <li>
              This Agreement, including any referenced documents or pages of the
              Web Site, forms the entire agreement between us relating to the
              Web Site. It replaces and supersedes any previous proposals,
              correspondence, understandings or other communications whether
              written or oral (except that We do not exclude liability for any
              fraudulent pre-contractual misrepresentations made by Us on which
              You can be shown to have relied). The headings and titles in this
              Agreement are included to make it easier to read but do not form
              part of this Agreement.
            </li>
            <li>
              All electronic communications in connection with this Agreement
              will be considered equal to written communications and will have
              the same value as original documents. Neither party shall make any
              complaint before any court concerning the electronic character of
              any communication.
            </li>
          </ol>
        </li>
        <li>
          <p>
            <strong>Definitions</strong>
          </p>
          <p>In this Agreement:</p>
          <ol>
            <li className="no-list-item">
              <ol>
                <li>
                  "<strong>Administrator</strong>" has the meaning set out in
                  Section 4.2;
                </li>
                <li>
                  "<strong>Authorised User</strong>" means a Qualifying
                  Individual who is authorised to access the Web Site on Your
                  behalf in accordance with Section 4.2 or 4.3;
                </li>
                <li>
                  "<strong>Email Address</strong>" means:
                  <p>
                    (1) in relation to Us, the email address specified on the
                    Web Site as such from time to time; and
                  </p>
                  <p>
                    (2) in relation to You, the email address indicated as such
                    by You as part of Your registration for the Web Site;
                  </p>
                </li>
                <li>
                  "<strong>Fee</strong>" means the Initial Fee or any Renewal
                  Fee;
                </li>
                <li>
                  "<strong>Group</strong>" means You, any of Your parent
                  undertakings and any subsidiary undertaking of such parent
                  undertakings from time to time (for the purpose of this
                  definition, "parent undertaking" and "subsidiary undertaking"
                  shall bear the meanings set out in Section 258 of the
                  Companies Act 1985);
                </li>
                <li>
                  "<strong>Initial Fee</strong>" means the fee agreed with You
                  (i) in accordance with Section 4.2 or (ii) prior to Your
                  registration;
                </li>
                <li>
                  "<strong>PwC Entity</strong>" means Us and/or any other entity
                  (whether or not incorporated) which carries on business under
                  a name which includes all or part of the
                  PricewaterhouseCoopers name or is otherwise within (or
                  associated or connected with an entity within) or is a
                  correspondent firm of the world-wide network of
                  PricewaterhouseCoopers firms;
                </li>
                <li>
                  "<strong>Qualifying Individual</strong>" means:
                  <p>
                    (1) in relation to Your Administrator, an individual who is
                    Your employee, director, officer, partner or principal; and
                  </p>
                  <p>
                    (2) in relation to any Authorised User, an individual who is
                    an employee, director, officer, partner or principal of any
                    member of Your Group
                  </p>
                  <p>
                    in each case, who is eligible to use the Web Site in
                    accordance with Section 3;
                  </p>
                </li>
                <li>
                  "<strong>Renewal Date</strong>" means each anniversary of the
                  Subscription Date;
                </li>
                <li>
                  "<strong>Renewal Fee</strong>" has the meaning set out in
                  Section 5.3 ;
                </li>
                <li>
                  "<strong>Subscription Service</strong>" means the service
                  described as such on the Web Site from time to time;
                </li>
                <li>
                  "<strong>Subcontractor</strong>" means any professional
                  service firm to whom we decide to subcontract the Service
                  including without limitation any PwC Entity;
                </li>
                <li>
                  "<strong>Subscription Date</strong>" means, where We have
                  agreed a Trial Period with You, the expiry of such Trial
                  Period and in any other event, the date on which We have
                  accepted Your registration for the Subscription Service;
                </li>
                <li>
                  "<strong>Systems Failure</strong>" means any event or
                  circumstance which causes the complete or partial loss or
                  non-availability of the Web Site or which prevents the normal
                  operation of the Web Site or the performance of any task or
                  function of the Web Site or any event or circumstance
                  similarly affecting Us; and
                </li>
                <li>
                  "<strong>Web Site</strong>" means the PwC Viewpoint web site
                  with the URL{" "}
                  <a
                    href="http://viewpoint.pwc.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    viewpoint.pwc.com
                  </a>{" "}
                  or such other URL as may replace it from time to time.
                </li>
              </ol>
            </li>
          </ol>
        </li>
      </ol>
      <br />
      <p>
        By accepting these <strong>Terms and Conditions</strong>, You
        agree to be bound by these terms and conditions and you acknowledge the
        collection, processing, use and disclosure (including international
        transfer) of personal data for the purposes identified in the Privacy
        Statement). Please note that you can withdraw your registration and
        consent to receiving marketing from us at any time by sending an email
        message using the Feedback facility on the Web Site.
      </p>
    </div>
  );
};

export default TermsConditionsUK;
