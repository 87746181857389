const ClientDetails = (props) => {
    return (
        <div key={props.id}>
            <div className="gray-text-16 bold">
                {props.firstName} {props.lastName}
            </div>
            <div className="light-gray-text-12 half-bold">{props.title}</div>
            <div className="text-12 orange half-bold p-v-8">{props.email}</div>
            <div className="text-12 orange half-bold">{props.phone}</div>
            {!props.isLast && <div className="separator-line"></div>}
        </div>
    );
};

export default ClientDetails;
