import { LOOKUP_MESSAGE_STATES } from "../Constants";
import { PAGE_STATUS } from "../layout/contract/Constants";
import { PRODUCT_STAKEHOLDERS } from "../Constants";
import { WFO_OPTION_TO_FIELD } from "../layout/create/order/contract/wfo/InitialTerm";
import { LISTEN_OPTION_TO_FIELD } from "../layout/create/order/contract/listen/InitialTerm";
import { TAB } from "../layout/manage/Constants";
import { PRODUCTS } from "../Config";
// TODO : decompose this by functional area

const SUCCESS = "Success";
// new order //
export const getClientOpportunity = (
    opportunityId,
    product,
    formState,
    setFormState,
    callback
) => {
    const newFormState = { ...formState };

    const getClientOpportunityCallback = (result, event) => {
        if (event.status) {
            newFormState.clientAccount = {
                id: result.account.id ?? "",
                prid: result.account.prid ?? "",
                gupPrid: result.account.gupPrid ?? "",
                name: result.account.name ?? "",
                address: result.account.address ?? "",
                city: result.account.city ?? "",
                state: result.account.state ?? "",
                country: result.account.country ?? "",
                zip: result.account.zip ?? ""
            };
            newFormState.clientOpportunity = {
                id: result.opportunity.id ?? "",
                name: result.opportunity.name ?? "",
                owner: result.opportunity.owner ?? "",
                email: result.opportunity.email ?? "",
                phone: result.opportunity.phone ?? ""
            };
            newFormState.lookupMessage = result.message;
        } else {
            newFormState.lookupMessage = LOOKUP_MESSAGE_STATES.TA_ERROR;
        }
        setFormState(newFormState);
        callback();
    };

    window.Visualforce.remoting.Manager.invokeAction(
        window.endpoints.getClientOpportunity,
        opportunityId,
        product,
        getClientOpportunityCallback,
        { escape: false }
    );
};

export const areDomainsUnique = (domains, valid, callback) => {
    window.Visualforce.remoting.Manager.invokeAction(
        window.endpoints.areDomainsUnique,
        domains,
        (result, event) => {
            if (event.status) {
                callback(true, valid, result);
            } else {
                callback(false);
            }
        },
        { escape: false }
    );
};

export const getPricingOptions = (productId, callback) => {
    if (productId === PRODUCTS.POD.id) {
        // POD does not have pricing options, but it does have contact options
        // use this hook to load them instead
        window.Visualforce.remoting.Manager.invokeAction(
            window.endpoints.getContactOptions,
            (result, event) => {
                if (event.status) {
                    callback(true, result);
                } else {
                    callback(false);
                }
            },
            { escape: false }
        );
    } else {
        window.Visualforce.remoting.Manager.invokeAction(
            window.endpoints.getPricingOptions,
            productId,
            (result, event) => {
                if (event.status) {
                    callback(true, result);
                } else {
                    callback(false);
                }
            },
            { escape: false }
        );
    }
};

export const getPricing = (options, callback) => {
    window.Visualforce.remoting.Manager.invokeAction(
        window.endpoints.getPricing,
        options,
        (result, event) => {
            if (event.status) {
                callback(true, result);
            } else {
                callback(false);
            }
        },
        { escape: false }
    );
};

const buildOrderData = (formData, formState, product, user) => {
    const orderData = {
        // acceptance info //
        // TODO : this should not be passed to the back end
        gupShowSlpWaringMsg:
            formState.lookupMessage === LOOKUP_MESSAGE_STATES.NOT_SUPPORTED,
        // client info //
        // TODO : generalize this when the back end supports it...
        firstName: formData.clientFirstName[0],
        lastName: formData.clientLastName[0],
        email: formData.clientEmail[0],
        jobTitle: formData.clientTitle[0],
        phoneNumber: formData.clientPhone[0],
        addiEmail: formData.clientEmail[1] ?? "",
        //  TODO : generalize this when the back end supports it...
        // domains: formData.clientDomainName[0].toLowerCase(),
        prid: formState.clientAccount.prid,
        NewaccId: formState.clientAccount.id,
        // product inf//
        prodSelected: product.id,
        // order terms //
        contractType: formData.contractType,
        serviceModule:
            formData.serviceModule && formData.serviceModule.length > 0
                ? formData.serviceModule
                : "",
        clientTier: formData.clientTier,
        renewAnnual: formData.autoRenew,
        initialTermTotal: formState.initialTermTotal,
        renewalTermTotal: formState.renewalTermTotal,
        // submitter //
        yourName: user.name,
        yourEmail: user.email,
        // required for searchAccountByPRIDDuns, TODO : why send this back?
        clientEntityName: formState.clientAccount.name,
        country: formState.clientAccount.country,
        state: formState.clientAccount.state,
        city: formState.clientAccount.city,
        address: formState.clientAccount.address,
        zip: formState.clientAccount.zip,
        // pricing //
        selectedProductId: formState.selectedProductId,
        selectedRenewalProductId: formState.selectedRenewalProductId,
        selectedWaiveEnablementProdId: formState.selectedWaiveEnablementProdId,
        waiveEnablementFee: formData.waiveEnablementFee,
        GCRMgupPrid: formState.clientAccount.gupPrid,
        // TODO : for LISTEN only... standardize and remove
        headCountFeeProdId: formState.headCountFeeProdId,
        headCountQuantity: formData.numberOfUsers,
        // TODO : for WFO only... standardize and remove
        numberOfUsers: formData.numberOfUsers,
        communicationChannel: formData.communicationChannel,
        // TODO : for POD only... standardize and remove
        afsRefNum: formData.afsNumber,
        afsApprovalDate: formData.afsApprovalDate
    };

    // contact info
    const populatePwCContacts = (source) => {
        for (let key in source) {
            const salesforceField = PRODUCT_STAKEHOLDERS[key].salesforceField;
            const salesforceFields = PRODUCT_STAKEHOLDERS[key].salesforceFields;

            if (salesforceField) {
                orderData[salesforceField] = source[key];
            } else if (salesforceFields) {
                for (const fieldsKey in salesforceFields) {
                    orderData[salesforceFields[fieldsKey]] =
                        source[key][fieldsKey];
                }
            }
        }
    };
    populatePwCContacts(formData.requiredContacts);
    populatePwCContacts(formData.nonEditableContacts);
    populatePwCContacts(formData.additionalContacts);

    // TODO : for POD only... standardize and remove
    // user 0 is passed as individaul fields
    orderData.additionalUsers = [];
    for (let i = 1; i < formData.clientFirstName.length; ++i) {
        orderData.additionalUsers.push({
            firstName: formData.clientFirstName[i],
            lastName: formData.clientLastName[i],
            email: formData.clientEmail[i],
            jobTitle: formData.clientTitle[i],
            phoneNumber: formData.clientPhone[i],
            id: ""
        });
    }

    orderData.domains = [];
    for (let i = 0; i < formData.clientDomainName.length; ++i) {
        orderData.domains.push({ name: formData.clientDomainName[i] });
    }

    // TODO : for WFO only... standardize and remove
    for (const option in WFO_OPTION_TO_FIELD) {
        if (formData.serviceModule?.includes(option)) {
            const id = WFO_OPTION_TO_FIELD[option].id;
            orderData[id] = formState[id];
        }
    }
    // TODO : for LISTEN only... standardize and remove
    for (const option in LISTEN_OPTION_TO_FIELD) {
        if (formData.contractType.includes(option)) {
            const id = LISTEN_OPTION_TO_FIELD[option].id;
            orderData[id] = formState[id];
        }
    }
    // TODO : why is the id sent in the url field?
    const productInfo = { url: product.id };

    return [orderData, productInfo];
};

export const submitNewOrder = (
    formData,
    formState,
    product,
    user,
    callback
) => {
    const [orderData, productInfo] = buildOrderData(
        formData,
        formState,
        product,
        user
    );
    // Salesforce forces two remote calls here... =(
    // https://help.salesforce.com/s/articleView?id=000339840&type=1
    const invokeAddContractPDF = (opportunityId) => {
        window.Visualforce.remoting.Manager.invokeAction(
            window.endpoints.addContractPDF,
            opportunityId,
            (result, event) => {
                if (event.status && result.flag === SUCCESS) {
                    callback(true, opportunityId);
                } else {
                    callback(false);
                }
            },
            { escape: false }
        );
    };

    const invokeSubmitNewOrder = () => {
        window.Visualforce.remoting.Manager.invokeAction(
            window.endpoints.submitNewOrder,
            orderData,
            productInfo,
            (result, event) => {
                if (event.status && result.flag === SUCCESS) {
                    const opportunityId = result.results[0];
                    // POD does not have a contract PDF
                    if (product.id !== PRODUCTS.POD.id) {
                        invokeAddContractPDF(opportunityId);
                    } else {
                        callback(true, opportunityId);
                    }
                } else {
                    callback(false);
                }
            },
            { escape: false }
        );
    };

    invokeSubmitNewOrder();
};

export const updateOrder = (
    opportunityId,
    formData,
    formState,
    product,
    user,
    isClientUpdate,
    isContactUpdate,
    isPricingUpdate,
    callback
) => {
    const [orderData, productInfo] = buildOrderData(
        formData,
        formState,
        product,
        user
    );
    orderData.oppId = opportunityId;
    orderData.isClientUpdate = isClientUpdate;
    orderData.isContactUpdate = isContactUpdate;
    orderData.isPricingUpdate = isPricingUpdate;

    // Salesforce forces two remote calls here... =(
    // https://help.salesforce.com/s/articleView?id=000339840&type=1
    const invokeAddContractPDF = () => {
        window.Visualforce.remoting.Manager.invokeAction(
            window.endpoints.addContractPDF,
            opportunityId,
            (result, event) => {
                if (event.status && result.flag === SUCCESS) {
                    callback(true, result.results);
                } else {
                    callback(false);
                }
            },
            { escape: false }
        );
    };

    const invokeUpdateOrder = () => {
        window.Visualforce.remoting.Manager.invokeAction(
            window.endpoints.updateOrder,
            orderData,
            productInfo,
            (result, event) => {
                if (event.status && result.flag === SUCCESS) {
                    invokeAddContractPDF();
                } else {
                    callback(false);
                }
            },
            { escape: false }
        );
    };

    invokeUpdateOrder();
};

export const submitDraftOrder = (opportunityId, callback) => {
    window.Visualforce.remoting.Manager.invokeAction(
        window.endpoints.submitDraftOrder,
        opportunityId,
        (result, event) => {
            if (event.status && result.flag === SUCCESS) {
                callback(true, result.results);
            } else {
                callback(false);
            }
        },
        { escape: false }
    );
};

// contract //
export const getOrderDetails = (opportunityId, setOrderDetails, setStatus) => {
    window.Visualforce.remoting.Manager.invokeAction(
        window.endpoints.getOrderDetails,
        opportunityId,
        (result, event) => {
            if (event.status) {
                const res = result.results;
                setOrderDetails(res[0]);
                if (res[0].isWithdraw || res[0].isSignedContract) {
                    setStatus(PAGE_STATUS.unavailable);
                } else if (res) {
                    setStatus(PAGE_STATUS.order);
                }
            } else {
                setStatus(PAGE_STATUS.wrong);
            }
        },
        { escape: false }
    );
};

export const completeOrder = (opportunityId, callback) => {
    window.Visualforce.remoting.Manager.invokeAction(
        window.endpoints.completeOrder,
        opportunityId,
        (result, event) => {
            if (event.status && result.flag === SUCCESS) {
                callback(true);
            } else {
                callback(false);
            }
        },
        { escape: false }
    );
};

// manage //
export const getOrders = (productId, tabId, pageNumber, size, callback) => {
    const tabToEndpoint = {
        [TAB.active.id]: window.endpoints.getActiveOrders,
        [TAB.draft.id]: window.endpoints.getDraftOrders,
        [TAB.pending.id]: window.endpoints.getPendingOrders,
        [TAB.renewal.id]: window.endpoints.getRenewalOrders,
        [TAB.afs.id]: window.endpoints.getAFSOrders
    };

    window.Visualforce.remoting.Manager.invokeAction(
        tabToEndpoint[tabId],
        productId,
        pageNumber,
        size,
        (result, event) => {
            if (event.status) {
                callback(true, event.result);
            } else {
                callback(false);
            }
        },
        { escape: false }
    );
};
