import { useNavigate, useParams } from "react-router-dom";
import { Success } from "./Success";
import peopleAtDesk from "../../../../../assets/peopleAtDesk.svg";
import { gotoViewOrder } from "../../../../../Routes";
import useProduct from "../../common/UseProduct";
import { PRODUCTS } from "../../../Config";

const CreatedOrder = () => {
    const product = useProduct();
    const { opportunityId, clientName, offline } = useParams();

    const WORKFORCE_TEXT = (productLabel, clientName) => {
        return [
            `The ${productLabel} contract for ${decodeURIComponent(
                clientName
            )} has successfully been sent to the Product Support Team to obtain EAF approval.`,
            "Once the Product Support Team confirms that an EAF request has been approved, you can continue to submit the order."
        ];
    };

    const CREATED_ORDER_TEXT = {
        [PRODUCTS.DOD.id]: (clientName, offline) => {
            return [
                `The ${PRODUCTS.DOD.label} contract for ${decodeURIComponent(
                    clientName
                )} has successfully been sent to Deals Desk to obtain EAF approval.`,
                offline === "true" ? (
                    <div className="orange">
                        Please note that the standard SLP order form is not
                        supported for this client. Please complete the
                        contracting offline and upload the signed contract to
                        SLP.
                    </div>
                ) : (
                    "Once Deals Desk confirms that an EAF request has been approved, you can continue to submit the order."
                )
            ];
        },
        [PRODUCTS.WFO.id]: (clientName) => {
            return WORKFORCE_TEXT(PRODUCTS.WFO.label, clientName);
        },
        [PRODUCTS.CN.id]: (clientName) => {
            return WORKFORCE_TEXT(PRODUCTS.CN.label, clientName);
        },
        [PRODUCTS.LISTEN.id]: (clientName) => {
            return WORKFORCE_TEXT(PRODUCTS.LISTEN.label, clientName);
        },
        [PRODUCTS.POD.id]: () => {
            return [
                "Thank you for submitting the order.",
                "The system will generate and send the activation email directly to the contract signatory, copying the Lead Tax Partner."
            ];
        }
    };

    const [subtitle, description] = CREATED_ORDER_TEXT[product.id](
        clientName,
        offline
    );

    const image = { src: peopleAtDesk, width: "248px" };

    const navigate = useNavigate();
    const action = {
        text: "VIEW ORDER",
        onClick: () => gotoViewOrder(navigate, product.id, opportunityId)
    };

    return (
        <Success
            title="Success!"
            subtitle={subtitle}
            description={description}
            image={image}
            action={action}
        />
    );
};

export default CreatedOrder;
