import { CONTRACT_TYPE } from "../../Constants";
const LISTEN = {
    [CONTRACT_TYPE.employeeExperience]: {
        preText:
            "This Order incorporates the following documents by reference: (a) the PricewaterhouseCoopers Standard Hosted Product License Terms and Conditions at ",
        lastText:
            " (the “Standard Terms”); and (b) documents incorporated into the Standard Terms by reference, including the Listen Platform Offering Overview, Listen Platform Maintenance and Support terms, and Listen Platform Data Processing Agreement, each available as described in the Standard Terms.",
        url: "https://productcentral.products.pwc.com/legal/Hosted-Software-Terms"
    },
    [CONTRACT_TYPE.customerExperience]: {
        preText:
            "This Order incorporates the following documents by reference: (a) the PricewaterhouseCoopers Standard Hosted Product License Terms and Conditions at ",
        lastText:
            " (the “Standard Terms”); and (b) documents incorporated into the Standard Terms by reference, including the Listen Platform Offering Overview, Listen Platform Maintenance and Support terms, and Listen Platform Data Processing Agreement, each available as described in the Standard Terms.",
        url: "https://productcentral.products.pwc.com/legal/Hosted-Software-Terms"
    }
};
export default LISTEN;
